import React, { useCallback, useEffect, useState } from "react";
import { FaPen } from "react-icons/fa";
import style from "./scss/Headtable.module.scss";
//import { indexOrgGrp as Form } from "./form";
import { toast } from "react-hot-toast";
import icon from "../../assets/img/Icon";
import Pagination from "../../components/pagination/Pagination";
import { QueryGetAll } from "../../helpers/api.helper";
import { defaultApi } from "../../services/api";
import { convertDateThai } from "../../utils/format/Date.format";
import Message from "../../utils/message/Message";
import { FormManageUserData } from "./form/FormManageUserData";
import { exportCsvFile, exportXlsxFile } from "../../helpers/func.helper";
import ExportPopup from "../../layouts/exportpopup/ExportPopup";

let filterSearch = {
  username: '',
  firstname: '',
  userGroupId: '',
  userLocalGroupId: '',
  status: '',
}

//let toastState = false

export const ManageUserData = ({ toggerCheck }) => {

  const [_fstLoad, fstLoad] = useState(true)

  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [_setUserProfile, setUserProfile] = useState([])
  const [_packSetItemUser, packSetItemUser] = useState("")

  //const [_setInfoProfile, setInfoProfile] = useState(false)

  const [_setUserGrp, setUserGroup] = useState()
  const [_setLocalGrp, setLocalGrp] = useState()

  const [_stateHideSubGrp, stateHideSubGrp] = useState(false)

  const [_setPlaceholder, setPlaceholder] = useState({
    username: false,
    name: false
  })

  const [statusList, setStatusList] = useState([])

  //let _outletFilter = ""

  const [_outletFilter, outletFilter] = useState("")
  const [_toastState, toastState] = useState(false)

  const [_triggerExport, setTriggerExport] = useState(false)
  const downloadCSV = async () => {

    const loading = toast

    try {
      // ข้อมูลที่ต้องส่งไปให้ ฟังก์ชั่น ใช้งาน
      let exportName = "UserReport";
      let fileType = "excel";

      /**
       * username / str
  userLocalgroupId / int
  userGroupId / int
  userOrgId / int
       */
      let obj = {
        //enterpriseId: enterpriseData.id,
      };
      // Ready
      exportCsvFile(exportName, fileType, obj);



      //await defaultApi.exportCsv("UserReport/excel")
      toast.success(Message.notify.loading_success, { duration: 3000 })
    } catch (error) {
      toast.error(Message.notify.loading_error, { duration: 3000 })
    } finally {
      toast.dismiss(loading)
    }
  }

  const downloadXlsx = async () => {

    const loading = toast

    try {
      // ข้อมูลที่ต้องส่งไปให้ ฟังก์ชั่น ใช้งาน
      let exportName = "UserReport";
      let fileType = "excel";

      /**
       * username / str
  userLocalgroupId / int
  userGroupId / int
  userOrgId / int
       */
      let obj = {
        //enterpriseId: enterpriseData.id,
      };
      // Ready
      exportXlsxFile(exportName, fileType, obj)



      //await defaultApi.exportCsv("UserReport/excel")
      toast.success(Message.notify.loading_success, { duration: 3000 })
    } catch (error) {
      toast.error(Message.notify.loading_error, { duration: 3000 })
    } finally {
      toast.dismiss(loading)
    }
  }

  const infoProfile = useCallback(async () => {

    const _top = currentPage * 10;
    let qryGetAll = {
      $count: "%24count=true",
      $expand: "&%24expand=userGroup%2CuserLocalGroup%2CuserOrg",
      $top: "&%24top=10",
      $skip: `&%24skip=${_top > 10 ? currentPage * 10 - 10 : "0"}`,
      $filter: `${_outletFilter}`,
      $orderby: "&%24orderby=createdAt desc",
    };

    let qryGetAll1 = {
      $count: "%24count=true",
      $expand: "",
      $top: `&%24top=10`,
      $skip: `&%24skip=${_top > 10 ? currentPage * 10 - 10 : "0"}`,
      $filter: "",
      $orderby: "",
    };

    let qryGetAll2 = {
      $count: "%24count=true",
      $expand: "",
      $top: `&%24top=10`,
      $skip: `&%24skip=${_top > 10 ? currentPage * 10 - 10 : "0"}`,
      $filter: "",
      $orderby: "",
    };

    const _query = QueryGetAll(qryGetAll);
    const _query1 = QueryGetAll(qryGetAll1);
    const _query2 = QueryGetAll(qryGetAll2);

    const loading = toast;

    try {
      const res = await defaultApi.getUser(_query);

      const res1 = await defaultApi.getUserGroup(_query1);
      setUserGroup(res1.data)
      const res2 = await defaultApi.getLocalGrp(_query2);
      setLocalGrp(res2.data.datas)

      const res3 = await defaultApi.getGbStatus(``)
      let arrStatus = []
      let i = 0
      for (let index = 0; index < res3?.data.length; index++) {
        const element = res3?.data[index];
        if (element?.id === 'active' || element?.id === 'disable') {
          arrStatus[i] = element
          i++
        }

      }
      setStatusList(arrStatus)


      setUserProfile(res.data.datas)
      //setInfoProfile(true)
      setTotalCount(res.data.total)
      if (_toastState === true && res.data.total <= 0) {
        toast.error(Message.notify.search_not_found, { duration: 3000 })
      }

    } catch (error) {
      console.log(error);
    } finally {
      toast.dismiss(loading);
      toastState(false)
    }

  }, [currentPage, _outletFilter, _toastState])

  const onPaginate = useCallback(async (page) => {
    // if (page === 0) {
    //   setCurrentPage(1);
    // }

    if (Number(currentPage) === Number(page)) {
      return
    }

    const _top = page * 10;

    const qryGetAll = {
      $count: "%24count=true",
      $expand: "&%24expand=userGroup%2CuserLocalGroup%2CuserOrg",
      $top: `&%24top=10`,
      $skip: `&%24skip=${_top > 10 ? page * 10 - 10 : "0"}`,
      $filter: `${_outletFilter}`,
      $orderby: "&%24orderby=createdAt desc",
    };

    const _query = QueryGetAll(qryGetAll);

    const loading = toast;

    try {
      const res = await defaultApi.getUser(_query);

      if (res.data.datas.length === 0) return;

      setCurrentPage(page);
      setUserProfile(res.data.datas);
      setTotalCount(res.data.total);

      if (_toastState === true && res.data.total <= 0) {
        toast.error(Message.notify.search_not_found, { duration: 3000 })
      }
    } catch (error) {
      console.log(error);
    } finally {
      toast.dismiss(loading);
      toastState(false)
    }

  }, [currentPage, _outletFilter, _toastState])

  const unPackJSON = (obj) => {
    const obj_js = JSON.parse(JSON.stringify(obj))
    if (obj_js !== null) {
      return obj_js.name
    } else {
      return "-"
    }
  }

  const dataLst = () => {
    //let irow = 0
    return (
      // <></>
      //dataFakeApi      
      _setUserProfile.map((data, index) => {
        return (
          <tr key={data.id}>
            <th scope="row">{(currentPage - 1) * 10 + (index + 1)}</th>
            <td>{data.username}</td>
            <td>{data.title}</td>
            <td>{data.firstname}</td>
            <td>{data.lastname}</td>
            <td>{data.email}</td>
            <td>{data.mobile}</td>
            <td>{unPackJSON(data.userGroup)}</td>
            <td>{unPackJSON(data.userOrg)}</td>
            <td>{unPackJSON(data.userLocalGroup)}</td>
            <td>{data.status === "active" ? "ใช้งาน" : "ไม่ใช้งาน"}</td>
            <td>{convertDateThai(data.createdAt)}</td>
            <td><FaPen className={`${style.iconsize}`} onClick={
              () => {
                document.getElementById("show_modal_edit_" + index).click();
                packSetItemUser(data.id);
              }} /></td>
          </tr>
        )
      })

    )
  }

  const renderForm = () => {
    return _setUserProfile.map((data, index) => {
      let strIndex = "show_modal_edit_" + index;
      let formId = "#exampleModal" + index
      return (
        <div key={index}>
          <button type="button" id={strIndex} className="btn btn-primary" data-bs-toggle="modal" data-bs-target={formId} hidden></button>
          <FormManageUserData userid={_packSetItemUser} idForm={index} infoProfile={infoProfile} amountForm={_setUserProfile.length} />
        </div>
      )
    })
  }

  const loopItemOutletOption = (item) => {

    try {
      return item.map((data, i) => {
        return (
          <option key={i + data.id} value={data.id}>{data.name}</option>
        )
      })
    } catch (error) {

    }
  }

  const setFilterItem = useCallback(() => {

    let arrItem = []
    Object.keys(filterSearch).map((data) => {
      switch (data) {
        case "username":
          if (filterSearch.username !== '') {
            arrItem.push(`contains%28username%2C%20%27${filterSearch.username}%27%29`)
          }
          break;
        case "firstname":
          if (filterSearch.firstname !== '') {
            arrItem.push(`contains%28firstname%2C%20%27${filterSearch.firstname}%27%29`)
          }
          break;
        case "userGroupId":
          if (filterSearch.userGroupId !== '') {
            arrItem.push(`userGroupId%20eq%20${Number(filterSearch.userGroupId)}`)
          }

          break;
        case "userLocalGroupId":
          if (filterSearch.userLocalGroupId !== '') {
            arrItem.push(`userLocalGroupId%20eq%20${Number(filterSearch.userLocalGroupId)}`)
          }

          break;
        case "status":
          if (filterSearch.status !== '') {
            arrItem.push(`status%20eq%20%27${filterSearch.status}%27`)
          }
          break;
        default:
          break;
      }
      return null
    })

    let xOuter = ""

    for (let index = 0; index < arrItem.length; index++) {
      if (index === 0) {
        xOuter += "&%24filter=" + arrItem[index]
      } else {
        xOuter += "%20and%20" + arrItem[index]
      }
    }

    // if (_toastState === false && currentPage > 1 && _lastPage !== 0) {
    //   setCurrentPage(1)
    // }
    // if (_toastState === false && currentPage === 1 && _lastPage !== 0) {
    //   setCurrentPage(_lastPage)
    // }

    if (_toastState === false && currentPage > 1 && totalCount <= 10) {
      setCurrentPage(1)
    }
    // && _outletFilter === ""
    if (_toastState === false && currentPage > 1 && totalCount >= 11) {
      setCurrentPage(1)
    }

    outletFilter(xOuter)
    toastState(true)

  }, [_toastState, currentPage, totalCount])
  //currentPage, totalCount, _lastPage



  useEffect(() => {
    if (_fstLoad === true) {
      infoProfile()
      fstLoad(false)
    }
  }, [infoProfile, _fstLoad])

  useEffect(() => {
    if (_toastState === true) {
      infoProfile()
    }
    if (totalCount <= 10 && currentPage > 1) {
      setFilterItem()
    }

  }, [infoProfile, setFilterItem, _toastState, totalCount, currentPage])

  return (
    <>
      <div style={toggerCheck === true ? { display: 'flex' } : { display: 'none' }} className={`${style.bodytab0}`}>
        <div className={`${style.hpsub}`}>
          <div className='col'>
            {/* <h5><b>Username : </b></h5> */}
            <input type={"text"} style={{ width: '100%', margin: '0 1%' }}
              //_setPlaceholder
              id={"username_key"}
              placeholder={_setPlaceholder.username === false ? "ค้นหาชื่อผู้ใช้งาน" : ""}
              onFocus={() => { setPlaceholder({ ..._setPlaceholder, username: true }) }}
              onBlur={() => { setPlaceholder({ ..._setPlaceholder, username: false }) }}
              onChange={(e) => { filterSearch.username = e.target.value; }} />
          </div>
          <div className='col'>
            {/* <h5><b>ชื่อ : </b></h5> */}
            <input type={"text"} style={{ width: '100%', margin: '0 1%' }}
              id={"name_key"}
              placeholder={_setPlaceholder.name === false ? "ค้นหา ชื่อ" : ""}
              onFocus={() => { setPlaceholder({ ..._setPlaceholder, name: true }) }}
              onBlur={() => { setPlaceholder({ ..._setPlaceholder, name: false }) }}
              onChange={(e) => { filterSearch.firstname = e.target.value; }} />
          </div>
          <div className='col'>
            {/* <h5><b>กลุ่ม : </b></h5> */}
            <select
              style={{ width: '100%', margin: '0 1%' }}
              id={'dfGrpItem'}
              onChange={(e) => {
                filterSearch.userGroupId = e.target.value;
                Number(e.target.value) === 3 ? stateHideSubGrp(true) : stateHideSubGrp(false);
                if (e.target.value === '') { filterSearch.userLocalGroupId = '' }
              }}
              defaultValue={filterSearch?.userGroupId || ''}
            >
              <option key={`dfGrp0`} value="" >เลือกกลุ่ม</option>
              {
                loopItemOutletOption(_setUserGrp)
              }
            </select>
          </div>
          {_stateHideSubGrp &&
            <div className='col'>
              {/* <h5><b>กลุ่มผู้ใช้งานย่อย : </b></h5> */}
              <select
                id={'dfGrpSupItem'}
                style={{ width: '100%', margin: '0 1%' }}
                onChange={(e) => { filterSearch.userLocalGroupId = e.target.value; }}
                defaultValue={filterSearch?.userLocalGroupId || ''}
              >
                <option key={`dfGrpSup0`} value="" >เลือกกลุ่มผู้ใช้งานย่อย</option>
                {
                  loopItemOutletOption(_setLocalGrp)
                }
              </select>
            </div>
          }
          <div className='col'>
            {/* <h5><b>สถานะ : </b></h5> */}
            <select
              id={'dfStatusItem'}
              style={{ width: '100%', margin: '0 1%' }}
              onChange={(e) => { filterSearch.status = e.target.value }}
              defaultValue={filterSearch?.status || ''}
            >
              <option key={`dfsts0`} value="" >เลือกสถานะ</option>
              {statusList?.map((datax, index) => {
                return <option key={`sts${index}`} value={datax?.id}>{datax?.statusName}</option>
              })}
            </select>
          </div>
          <div className='col'><img id={`searchBtn`} src={icon.search} alt='ค้นหา' style={{ "cursor": "pointer" }} onClick={(e) => { setFilterItem() }} /></div>
        </div>
        <div className={`collapse_table table-responsive ${style.boxResponse}`}>
          <table className="table text-center">
            <thead>
              <tr>
                <td colSpan={13}>
                </td>
              </tr>
              <tr>
                <th scope="col">ลำดับ</th>
                <th scope="col">ชื่อผู้ใช้งาน</th>
                <th scope="col">คำนำหน้า</th>
                <th scope="col">ชื่อ</th>
                <th scope="col">นามสกุล</th>
                <th scope="col">อีเมล</th>
                <th scope="col">เบอร์โทรศัพท์</th>
                <th scope="col">กลุ่มผู้ใช้งาน</th>
                <th scope="col">หน่วยงาน</th>
                <th scope="col">กลุ่มผู้ใช้งานย่อย</th>
                <th scope="col">สถานะ</th>
                <th scope="col">วัน/เดือน/ปี</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {
                dataLst()
              }
            </tbody>
          </table>
          {
            renderForm()
          }
          {/* <button type="button" id='show_modal_edit_' className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" hidden></button>
          <FormManageUserData userid={_packSetItemUser} infoProfile={infoProfile} /> */}
        </div>
        <div>
          <Pagination pageSize={10} totalCount={totalCount} currentPage={currentPage} onPageChange={(page) => onPaginate(page)} />
        </div>
        <div style={{ position: "absolute", right: "3%", bottom: "1.5%" }}>
          <ExportPopup label={'document'} trigger={_triggerExport} event={{ csv: downloadCSV, xlsx: downloadXlsx }} />
          <button className={`${style.btn_}`}
            onClick={() => { setTriggerExport(!_triggerExport) }}
          >Export</button>
        </div>
      </div >

    </>
  )

};