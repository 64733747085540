import { useLocation, useNavigate } from "react-router-dom";

const useSwitchPath = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const _location = location.pathname;

  return [_location, navigate];
};

export default useSwitchPath;
