import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  profileData: {},
  editabled: false,
};

export const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    setProfileData(state, { payload }) {
      state.profileData = payload;
    },
    setEditabled(state, { payload }) {
      state.editabled = payload;
    },
  },
});

export const { setProfileData, setEditabled } = authSlice.actions;

export const getProfileData = (state) => state.authSlice.profileData;
export const getEditabled = (state) => state.authSlice.editabled;
