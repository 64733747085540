import React, { useEffect } from "react";
import styles from "./SwitchMenu.module.scss";

const SwitchMenu = ({ onSwitch, _onSwitch2, _onSwitchPage, btn1, btn2 }) => {
  useEffect(() => {
    switchMenuActive();
  });
  return (
    <div className={`${styles.container}`}>
      <button
        className="btn_menu active"
        onClick={() => {
          onSwitch(false);
          _onSwitch2(false);
          _onSwitchPage(null);
        }}
      >
        {btn1}
      </button>
      <button className="btn_menu" onClick={() => onSwitch(true)}>
        {btn2}
      </button>
    </div>
  );
};

function switchMenuActive() {
  const btn = document.querySelectorAll(".btn_menu");
  btn.forEach((item) => {
    item.addEventListener("click", () => {
      btn.forEach((item) => item.classList.remove("active"));
      item.classList.add("active");
    });
  });
}

export default SwitchMenu;
