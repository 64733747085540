import React, { useCallback, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//import { useSelector } from "react-redux";
import icon from "../../../assets/img/Icon";
//import { QueryGetAll } from "../../../helpers/api.helper";
//import { defaultApi } from "../../../services/api";
import { getProfileData } from "../../../services/auth.slice";
import {
  convertDateEu,
  convertDateThai,
} from "../../../utils/format/Date.format";
//import ChartsEstimate from "./charts/ChartsEstimate";
import { useSelector } from "react-redux";
import { defaultApi } from "../../../services/api";
import { QueryGetAll } from "../../../helpers/api.helper";
import styles from "./scss/ReportEstimate.module.scss";

let _dfDate = "";
let _dfToDate = "";
let date = "",
  _toDate = "";

const ReportEstimate = ({ themeColor }) => {
  // const [date, setDate] = useState('')
  // const [_toDate, toSetDate] = useState('')

  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [openDatePicker2, setOpenDatePicker2] = useState(false);
  const profileData = useSelector(getProfileData);

  const [_fstLoad, fstLoad] = useState(true);

  const [_cropItem, setCropItem] = useState([]);

  let _yieldCrop = 0,
    _yieldTarget = 0;

  // const iSearch = async (dateSel) => {
  //     let i = 0
  //     let years = null
  //     years = dateSel.split("-")
  //     years = years[0]

  //     const dfset = await defaultApi.getReportDashboard("powergenYear=" + years + "&factoryTransactionYear=" + years + "&enterpriseTransactionYear=" + years)
  //     const res = await defaultApi.getReportPlantingArea("factoryId=" + _setKeyFact.factId + "&enterpriseId=" + _setKeyFact.entpId + "&atDate=" + dateSel + "&toDate=" + dateSel)
  //     setDfset(dfset.data)
  //     resDate(res.data.plantingArea)
  //     for (let index = 0; index < res.data.plantingArea.length; index++) {
  //       const element = res.data.plantingArea[index].area;
  //       i = i + element
  //     }
  //     resTotal(i)
  // }

  const setDateDf = () => {
    _dfDate = "";
    _dfToDate = "";
    date = "";
    _toDate = "";

    const date1 = new Date();
    const dateFormat = convertDateEu(date1);
    _dfDate = dateFormat;
    _dfToDate = dateFormat;
    //setDate(dateFormat);
    //setYear(date.getFullYear())
  };

  const onChangeDateStartL = (e) => {
    const dateFormat = convertDateEu(e);
    //setDate(dateFormat);
    date = dateFormat;
    setOpenDatePicker(!openDatePicker);
    info();
  };

  const onChangeDateStartR = (e) => {
    const dateFormat = convertDateEu(e);
    //toSetDate(dateFormat);
    _toDate = dateFormat;
    setOpenDatePicker2(!openDatePicker2);
    info();
  };

  const info = useCallback(async () => {
    try {
      let qryGetAll = {
        $count: "%24count=true",
        $expand: "",
        $top: "",
        $skip: "",
        $filter: `&%24filter=createdBy%20eq%20${profileData?.id}`,
        $orderby: "",
      };

      const resAllEnp = await defaultApi.getAllEnterpriseMember(``);

      if (resAllEnp?.data?.length > 0) {
        for (let index = 0; index < resAllEnp?.data?.length; index++) {
          const element = resAllEnp?.data[index];
          if (+element?.userId === +profileData?.id) {
            qryGetAll = {
              $count: "%24count=true",
              $expand: "",
              $top: "",
              $skip: "",
              $filter: `&%24filter=id%20eq%20${element.enterpriseId}`,
              $orderby: "",
            };
          }
        }
      }

      const _query = QueryGetAll(qryGetAll);
      const res_entp = await defaultApi.getAllEnterprise(_query);
      //let atDate = date.toString() === '' ? _dfDate.toString() : date.toString()
      let atDate = date === "" ? _dfDate : date;
      let toDate = _toDate === "" ? _dfToDate : _toDate;
      //let toDate = _toDate.toString() === '' ? _dfToDate.toString() : _toDate.toString()

      const res = await defaultApi.getContractSignDate(
        "enterpriseId=" + res_entp.data.datas[0].id + "&toDate=" + toDate
      );

      let idate = res?.data?.contractSignDate[0]?.signDate;
      let datex = idate.split("T")[0];
      _dfDate = datex;

      if (date.toString() === "" && _toDate.toString() === "") {
        const res = await defaultApi.getReportEnterpriseYieldPlanting(
          "enterpriseId=" +
          res_entp.data.datas[0].id +
          "&atDate=" +
          datex +
          "&toDate=" +
          toDate
        );
        setCropItem(res);
      } else {
        const res = await defaultApi.getReportEnterpriseYieldPlanting(
          "enterpriseId=" +
          res_entp.data.datas[0].id +
          "&atDate=" +
          atDate +
          "&toDate=" +
          toDate
        );
        setCropItem(res);
      }
    } catch (error) { }
  }, [profileData]);

  const loopItemList = (res) => {
    try {
      let item01,
        outlet = [];
      let crop = 0,
        target = 0;
      //let maxRow = 15
      target = res?.data.contractAmount[0].contractAmount;
      item01 = res?.data.enterpriseYieldPlanting;


      item01 = item01?.sort((a, b) => {
        const [ad, am, ay] = convertDateThai(a?.cropHarvestDate).split("/")
        const [bd, bm, by] = convertDateThai(b?.cropHarvestDate).split("/")
        return ((+by * 365) + (+bm * 30) + (+bd * 1)) - ((+ay * 365) + (+am * 30) + (+ad * 1))
      })


      outlet = item01.map((data, index) => {
        //target = data?.contractAmount[0]?.contractAmount

        crop = crop + data?.cropYield;

        //crop = Number(crop) + Number(data?.cropYield)
        return (
          <tr key={`countRow_` + index}>
            <td>{++index}</td>
            <td>{convertDateThai(data?.cropHarvestDate)}</td>
            <td>
              {data?.farmerFirstname} {data?.farmerLastname}
            </td>
            {/* <td ></td> */}
            <td>{data?.plantypeName}</td>
            <td>
              {Number(data?.cropYield).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </td>
            {/* <td >{Number(data?.cropYieldTarget).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td> */}
          </tr>
        );
      });

      // let i = item01.length + 1
      // if (i < maxRow) {
      //     for (let index = i; index < maxRow; index++) {
      //         outlet[index] = (
      //             <>
      //                 <tr key={`countRow_` + index}>
      //                     <td >{index}</td>
      //                     <td ></td>
      //                     <td ></td>
      //                     <td ></td>
      //                     <td ></td>
      //                     {/* <td ></td> */}
      //                     {/* <td ></td> */}
      //                 </tr>
      //             </>
      //         )
      //     }
      // }

      _yieldCrop = crop;
      _yieldTarget = target;
      return outlet;
    } catch (error) { }
  };

  const colorEstimate = (sum, target) => {
    if (sum < target) {
      return { background: "#ED5A5A" };
    } else if (sum > target) {
      return { background: "#5A9DED" };
    } else if (sum === target) {
      return { background: "#5AEDA1" };
    }
  };

  useEffect(() => {
    if (_fstLoad === true) {
      setDateDf();
      info();
      fstLoad(false);
    }
  }, [_fstLoad, info]);

  useEffect(() => {
    /* Function close dropdown 'DatePicker and DatePicker2' : START */
    const onCloseDropdownDatePicker = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#ent_datepicker_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenDatePicker(false);
      }
    };
    const onCloseDropdownDatePicker2 = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#ent_datepicker2_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenDatePicker2(false);
      }
    };

    if (openDatePicker) {
      document.addEventListener("click", onCloseDropdownDatePicker);
    }
    if (openDatePicker2) {
      document.addEventListener("click", onCloseDropdownDatePicker2);
    }
    /* Function close dropdown 'DatePicker and DatePicker2' : END */

    return () => {
      document.removeEventListener("click", onCloseDropdownDatePicker);
      document.removeEventListener("click", onCloseDropdownDatePicker2);
    };
  }, [openDatePicker, openDatePicker2]);

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.date_filter}`}>
        <div className={`${styles.group_}`}>
          <div className={styles.input_unit}>
            <div className={styles.info_box}>
              <p style={{ color: "black" }}>
                {date !== "" ? convertDateThai(date) : "วันที่"}
              </p>
            </div>
            <div
              className={styles.unit}
              onClick={() => {
                setOpenDatePicker(!openDatePicker);
              }}
            >
              <img src={icon.calendar} alt="" id="ent_datepicker_d" />
            </div>
          </div>
          {openDatePicker && (
            <DatePicker onChange={onChangeDateStartL} inline />
          )}
        </div>
        <label style={{ margin: "0 7px" }}> ถึง : </label>
        <div className={`${styles.group_}`}>
          <div className={styles.input_unit}>
            <div className={styles.info_box}>
              <p style={{ color: "black" }}>
                {_toDate !== "" ? convertDateThai(_toDate) : "วันที่"}
              </p>
            </div>
            <div
              className={styles.unit}
              onClick={() => {
                setOpenDatePicker2(!openDatePicker2);
              }}
            >
              <img src={icon.calendar} alt="" id="ent_datepicker2_d" />
            </div>
          </div>
          {openDatePicker2 && (
            <DatePicker onChange={onChangeDateStartR} inline />
          )}
        </div>
      </div>

      <h4
        className={`${styles.title_text}`}
        style={{ background: themeColor.backgroundColor }}
      >
        ผลผลิตเชื้อเพลิงที่ผลิตได้จริง
      </h4>

      <div className={`${styles.boxtable}`}>
        <table>
          <thead>
            <tr>
              <th colSpan={"5"}>
                ระหว่างวันที่{" "}
                {date === "" ? convertDateThai(_dfDate) : convertDateThai(date)}{" "}
                ถึง{" "}
                {_toDate === ""
                  ? convertDateThai(_dfToDate)
                  : convertDateThai(_toDate)}
              </th>
            </tr>
            <tr>
              <th>ลำดับ</th>
              <th>วันที่เก็บเกี่ยว</th>
              <th>ชื่อ - นามสกุล</th>
              <th>ชนิดเชื้อเพลิง</th>
              <th>จำนวน(ตัน)</th>
              {/* <th>เป้าหมายการผลิต</th> */}
            </tr>
          </thead>
          <tbody>{loopItemList(_cropItem)}</tbody>
          <tfoot>
            <tr>
              <th colSpan={"4"}>ผลรวมที่ผลิตได้จริง ( ตัน )</th>
              <th style={colorEstimate(_yieldCrop, _yieldTarget)}>
                {Number(_yieldCrop).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </th>
            </tr>
            <tr>
              <th colSpan={"4"}>เป้าหมายที่ต้องส่งมอบตามสัญญา ( ตัน )</th>
              <th>
                {Number(_yieldTarget).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </th>
            </tr>
          </tfoot>
        </table>
        <div className={`${styles.boxlegend}`}>
          <div style={{ background: "#5A9DED" }}>ผลิตได้เกิน</div>
          <div style={{ background: "#5AEDA1" }}>ผลิตได้พอดี</div>
          <div style={{ background: "#ED5A5A" }}>ผลิตได้น้อย</div>
        </div>
      </div>

      {/* <h5 className={`${styles.title_text_}`}>
                {_resName}
            </h5> */}

      {/* <div className={`${styles.content_} row mx-auto`}>
                <div className={`${styles.left_} col-9`}>
                    <ChartsEstimate ref={chart} dfset={_setDfset} datain={_resDate} />
                </div>
                <div className={`${styles.right_} col-3`}>
                    <div style={date === "" && dateDf === '' ? { display: "none" } : { display: "block" }}>
                        <p>{date === "" ? convertDateThai(dateDf) : convertDateThai(date)}</p>
                        <p>พื้นที่ทั้งหมด {Number(_resTotal).toLocaleString(undefined, { maximumFractionDigits: 2 })} ไร่</p>
                    </div>
                </div>
            </div> */}

      {/* <div className={`${styles.export_}`}>
                <button id="exporting" type="button" className={`${styles.btn_}`}
                //onClick={downloadCSV}
                >
                    Export
                </button>
            </div> */}
    </div>
  );
};

export default ReportEstimate;
