import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import Autocomplete from "react-autocomplete";
import { toast } from "react-hot-toast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { defaultApi } from "../../../../../../../services/api";
import {
  convertDateEu,
  convertDateThai,
} from "../../../../../../../utils/format/Date.format";
import Message from "../../../../../../../utils/message/Message";
import {
  CheckNumberDot,
  CheckValidLength,
} from "../../../../../../../utils/validation_input/Validation";
import { getFactoryData } from "../../../../../../../services/factory.slice";
import icon from "../../../../../../../assets/img/Icon";
import { QueryGetAll } from "../../../../../../../helpers/api.helper";
import styles from "./EditSalesRecordForm.module.scss";

const buyerArr = [
  {
    id: 0,
    name: "การไฟฟ้าส่วนภูมิภาค (PEA)",
  },
];

const EditForm = ({ _onSwitch, _dataObj, _fetchData, _paymentStatus }) => {
  const factoryData = useSelector(getFactoryData);

  const [_data, setData] = useState({});
  const [allContractPpa, setAllContractPpa] = useState([]);
  const [pdfName, setPdfName] = useState("");
  const [selectedDate, setSelectDate] = useState({
    sellDate: new Date(),
    payDate: new Date(),
  });
  const [openSellDate, setOpenSellDate] = useState(false);
  const [openPayDate, setOpenPayDate] = useState(false);
  const [validErr, setValidErr] = useState(false);

  /* DatePicker : START */
  const onChangeSellDate = (e) => {
    const dateFormat = convertDateEu(e);
    setData({ ..._data, sellDate: dateFormat });
    setSelectDate({ ...selectedDate, sellDate: e });
    setOpenSellDate(!openSellDate);
  };
  const onChangePayDate = (e) => {
    const dateFormat = convertDateEu(e);
    setData({ ..._data, payDate: dateFormat });
    setSelectDate({ ...selectedDate, payDate: e });
    setOpenPayDate(!openPayDate);
  };
  /* DatePicker : END */

  const onChange = (e) => {
    const { name, value } = e.target;
    setData({ ..._data, [name]: value });
  };

  const getMultipleApi = useCallback(async () => {
    if (!factoryData?.id) return;

    let factoryId = factoryData?.id;
    const qryGetAll = {
      $count: "%24count=true",
      $expand: "",
      $top: "",
      $skip: "",
      $filter: `&%24filter=factoryId%20eq%20${factoryId}`,
      $orderby: "",
    };
    const _queryAll = QueryGetAll(qryGetAll);
    try {
      const [allContractPpa] = await Promise.all([
        defaultApi.getAllContractPpa(_queryAll),
      ]);

      setAllContractPpa(allContractPpa.data.datas);
    } catch (error) {
      console.log(error);
    }
  }, [factoryData]);
  const getFilePdfName = () => {
    const file = document.querySelector("#add_file");

    file?.addEventListener("change", () => {
      if (file.value) {
        setPdfName(file.files[0].name);
      } else {
        setPdfName("");
      }
    });
  };

  const createFileDetail = async (e) => {
    console.log("createFile");
    const file = document.querySelector("#add_file");

    const loading = toast.loading(Message.notify.add_file_loading);
    const param1 = "PpaTransaction";
    const param2 = new FormData();
    param2.append("FormFile", e.target.files[0]);

    try {
      const res = await defaultApi.createFileDetail(param1, param2);
      console.log(res.statusText);

      setData({ ..._data, receipt: res?.data.id });
    } catch (error) {
      console.log(error);
    } finally {
      file.value = "";
      toast.dismiss(loading);
    }
  };
  const updateFileDetail = async (e) => {
    console.log("updateFile");
    const file = document.querySelector("#add_file");

    if (!window.confirm(Message.notify.add_file_update)) {
      file.value = "";
      setPdfName("");
      return;
    }
    if (!e.target.files.length) {
      return setData({
        ..._data,
        receipt: _data?.receipt,
      });
    }

    const loading = toast.loading(Message.notify.add_file_loading);
    const _id = _data?.receipt;
    const param1 = "PpaTransaction";
    const param2 = new FormData();
    param2.append("FormFile", e.target.files[0]);

    try {
      const res = await defaultApi.updateFileDetail(_id, param1, param2);
      console.log(res.statusText);
      setData({ ..._data, receipt: res?.data.id });
      toast.success(Message.notify.update_success);
      file.value = "";
    } catch (error) {
      console.log(error);
      toast.error(Message.notify.update_error);
    } finally {
      toast.dismiss(loading);
    }
  };
  const updatePpaTransaction = async () => {
    if (CheckValidLength(_data.payee)) return setValidErr(true);
    if (CheckValidLength(_data.buyer)) return setValidErr(true);
    if (CheckNumberDot(_data.powers)) return setValidErr(true);
    if (CheckNumberDot(_data.total)) return setValidErr(true);

    const loading = toast.loading(Message.notify.loading);
    const params = {};
    Object.keys(_data).forEach((key) => {
      if (key === "powers") {
        params[key] = +_data[key];
      } else if (key === "priceRate") {
        params[key] = +_data[key];
      } else if (key === "total") {
        params[key] = +_data[key];
      } else {
        params[key] = _data[key];
      }
    });
    try {
      const res = await defaultApi.updatePpaTransaction(_data.id, params);
      console.log(res.statusText);

      _onSwitch(false);
      _fetchData();
      toast.success(Message.notify.update_success, { duration: 3000 });
    } catch (error) {
      console.log(error);
      toast.error(Message.notify.update_error, { duration: 3000 });
    } finally {
      toast.dismiss(loading);
    }
  };

  useEffect(() => {
    // Use functions
    setData(_dataObj);
    getMultipleApi();
    getFilePdfName();
  }, [_dataObj, getMultipleApi]);

  useEffect(() => {
    /* Function close dropdown 'SellDate and PayDate' : START */
    const onCloseDropdownSellDate = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#fac_sell_date_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenSellDate(false);
      }
    };
    const onCloseDropdownPayDate = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#fac_pay_date_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenPayDate(false);
      }
    };

    if (openSellDate) {
      document.addEventListener("click", onCloseDropdownSellDate);
    }
    if (openPayDate) {
      document.addEventListener("click", onCloseDropdownPayDate);
    }
    /* Function close dropdown 'SellDate and PayDate' : END */

    return () => {
      document.removeEventListener("click", onCloseDropdownSellDate);
      document.removeEventListener("click", onCloseDropdownPayDate);
    };
  }, [openSellDate, openPayDate]);

  return (
    <>
      <form className={`${styles.input_form}`}>
        <div className={`${styles.input_flex}`}>
          <div className={`${styles.input_1}`}>
            <h5>ผู้ขายไฟฟ้า(ผู้ผลิตไฟฟ้า)</h5>
            <input
              type="text"
              name="payee"
              value={String(_data.payee)}
              className={`${validErr && _data.payee === "" ? "error" : ""}`}
              onChange={onChange}
            />
            {validErr && (
              <p className={`${styles.err_message}`}>
                {CheckValidLength(_data.payee)}
              </p>
            )}
          </div>
          <div className={`${styles.input_2}`}>
            <h5>ผู้ซื้อไฟฟ้า(การไฟฟ้า)</h5>
            <div className={`${styles.auto_com_box}`}>
              <Autocomplete
                inputProps={{
                  className: `${styles.input_box} ${
                    validErr && _data.buyer === "" ? "error" : ""
                  }`,
                }}
                wrapperStyle={{ width: "100%", fontSize: "16px" }}
                items={buyerArr}
                shouldItemRender={(item, value) =>
                  item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
                }
                getItemValue={(item) => item.name}
                renderItem={(item, highlighted) => (
                  <div
                    key={item.id}
                    style={{
                      backgroundColor: highlighted ? "#eee" : "transparent",
                    }}
                  >
                    {item.name}
                  </div>
                )}
                value={_data.buyer}
                onChange={(e) => setData({ ..._data, buyer: e.target.value })}
                onSelect={(name) => setData({ ..._data, buyer: name })}
              />
            </div>
            {validErr && (
              <p className={`${styles.err_message}`}>
                {CheckValidLength(_data.buyer)}
              </p>
            )}
          </div>
        </div>
        <div className={`${styles.input_flex}`}>
          <div className={`${styles.input_1}`}>
            <h5>
              สถานะการจ่ายเงิน <sup className={`${styles.asterisk}`}>*</sup>
            </h5>
            <select name="paymentStatus" onChange={onChange}>
              <option style={{ display: "none" }}>
                {
                  _paymentStatus?.find(
                    (item) => item.id === _data.paymentStatus
                  )?.statusName
                }
              </option>
              {_paymentStatus.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.statusName}
                  </option>
                );
              })}
            </select>
          </div>
          <div className={`${styles.input_2}`}>
            <h5>
              วันที่บันทึกการขาย <sup className={`${styles.asterisk}`}>*</sup>
            </h5>
            <div className={`${styles.input_unit}`}>
              <div className={`${styles.info_box}`}>
                <p>
                  {_data.sellDate !== "" ? convertDateThai(_data.sellDate) : ""}
                </p>
              </div>
              <div
                className={`${styles.unit}`}
                onClick={() => {
                  setOpenSellDate(!openSellDate);
                }}
              >
                <img src={icon.calendar} alt="" id="fac_sell_date_d" />
              </div>
            </div>
            {openSellDate && (
              <DatePicker
                selected={selectedDate.sellDate}
                onChange={onChangeSellDate}
                inline
              />
            )}
          </div>
        </div>
        <div className={`${styles.input_flex}`}>
          <div className={`${styles.input_1}`}>
            <h5>
              เลขที่สัญญา PPA <sup className={`${styles.asterisk}`}>*</sup>
            </h5>
            <select name="ppaId" onChange={onChange}>
              <option style={{ display: "none" }}>{_data.ppa?.no}</option>
              {allContractPpa.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.no}
                  </option>
                );
              })}
            </select>
          </div>
          <div className={`${styles.input_2}`}>
            <h5>กำหนดชำระเงิน</h5>
            <div className={`${styles.input_unit}`}>
              <div className={`${styles.info_box}`}>
                <p>
                  {_data.payDate !== "" ? convertDateThai(_data.payDate) : ""}
                </p>
              </div>
              <div
                className={`${styles.unit}`}
                onClick={() => {
                  setOpenPayDate(!openPayDate);
                }}
              >
                <img src={icon.calendar} alt="" id="fac_pay_date_d" />
              </div>
            </div>
            {openPayDate && (
              <DatePicker
                selected={selectedDate.payDate}
                onChange={onChangePayDate}
                inline
              />
            )}
          </div>
        </div>
        <div className={`${styles.input_flex}`}>
          <div className={`${styles.input_1}`}>
            <h5>
              ปริมาณไฟฟ้าที่เสนอขาย(KWh){" "}
              <sup className={`${styles.asterisk}`}>*</sup>
            </h5>
            <input
              type="text"
              name="powers"
              value={String(_data.powers)}
              className={`${validErr && _data.powers === "" ? "error" : ""}`}
              onChange={onChange}
            />
            {validErr && (
              <p className={`${styles.err_message}`}>
                {CheckNumberDot(_data.powers)}
              </p>
            )}
          </div>
          <div className={`${styles.input_2}`}>
            <h5>ราคาต่อหน่วย(บาท)</h5>
            <input
              type="text"
              name="priceRate"
              value={String(_data.priceRate)}
              onChange={onChange}
            />
          </div>
        </div>
        <div className={`${styles.input_flex}`}>
          <div className={`${styles.input_1}`}>
            <h5>
              ยอดรวมสุทธิ(บาท) <sup className={`${styles.asterisk}`}>*</sup>
            </h5>
            <input
              type="text"
              name="total"
              value={String(_data.total)}
              className={`${validErr && _data.total === "" ? "error" : ""}`}
              onChange={onChange}
            />
            {validErr && (
              <p className={`${styles.err_message}`}>
                {CheckNumberDot(_data.total)}
              </p>
            )}
          </div>
          <div className={`${styles.input_2}`}>
            <div className={`${styles.input_file}`}>
              <h5>
                ใบแจ้งหนี้ <sup className={`${styles.asterisk}`}>*</sup>
              </h5>
              <div className={`${styles.input_unit} ${styles.mb_0}`}>
                <div className={`${styles.info_box}`}>
                  <p>
                    {pdfName !== ""
                      ? pdfName
                      : _data.ppaTransactionReceiptNavigation?.fileName}
                  </p>
                </div>
                <div className={`${styles.unit}`}>
                  <img
                    src={icon.document}
                    alt=""
                    title="แนบไฟล์"
                    onClick={() =>
                      document.querySelector("#file-label").click()
                    }
                  />
                </div>
              </div>
              <span className={`${styles.desc_text}`}>
                *ระบบรองรับไฟล์ที่มีนามสกุล .pdf และมีขนาดไม่เกิน 5 MB
              </span>
              <input
                type="file"
                name="receipt"
                id="add_file"
                accept="application/pdf"
                onChange={
                  _data?.receipt !== null ? updateFileDetail : createFileDetail
                }
                hidden
              />
              <label htmlFor="add_file" id="file-label" hidden></label>
            </div>
          </div>
        </div>
      </form>

      <div className={`${styles.button_center}`}>
        <button
          type="button"
          className="button_sm"
          onClick={updatePpaTransaction}
        >
          บันทึก
        </button>
        <button
          type="button"
          className="button_sm"
          onClick={() => _onSwitch(false)}
        >
          ยกเลิก
        </button>
      </div>
    </>
  );
};

export default EditForm;
