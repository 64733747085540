/**
 * factory id
 * meter_produce
 * meter_selling
 */

import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { defaultApi } from '../../../services/api';
import Message from '../../../utils/message/Message';
import { AdminMeter } from '../../../utils/validation_input/Validation';
import styles from '../scss/Popupform.module.scss';

const FormManageFactoryMeter = ({ datain, info, infoDatax, idForm }) => {

    //const [txtInput01, settxtInput01] = useState('')
    //const [txtInput02, settxtInput02] = useState('')

    const [validError01, setValidError01] = useState(false)
    const [validError02, setValidError02] = useState(false)

    const [_validText, setValidText] = useState({
        input1: '',
        input2: ''
    })

    const submitEdit = async (closeFrm, infoDatax) => {

        const loading = toast.loading(Message.notify.update_loading);

        try {
            let params = {}

            Object.keys(infoDatax).forEach((key) => {
                if (key === "geom") {
                    params[key] = null;
                } else if (key === "buyGeom") {
                    params[key] = null;
                } else if (key === 'meterGen') {
                    params[key] = _validText.input1 ? Number(_validText.input1) : infoDatax.meterGen
                } else if (key === 'meterSell') {
                    params[key] = _validText.input2 ? Number(_validText.input2) : infoDatax.meterSell
                } else {
                    params[key] = infoDatax[key];
                }
            });
            
            // Object.assign(params, { buyPoint: [[+infoDatax?.buyLat, +infoDatax?.buyLng]] });
            // Object.assign(params, { point: [[+infoDatax?.lat, +infoDatax?.lng]] });
            if (Number(params?.meterGen<0) || Number(params?.meterSell<0)) {
                toast.error('ไม่สามารถบันทึกข้อมูล "ค่าว่าง" หรือ "0" ได้', { duration: 3000 });
                return;
            }

            // params = await {
            //     ...infoDatax,
            //     meterGen: txtInput01 ? Number(txtInput01) : infoDatax.meterGen,
            //     meterSell: txtInput02 ? Number(txtInput02) : infoDatax.meterSell,
            // }
            await defaultApi.updateFactory(datain, params)
            toast.success(Message.notify.update_success, { duration: 3000 });
            document.getElementById(closeFrm).click()
            info()
        } catch (error) {
            console.log(error);
            toast.error(Message.notify.update_error, { duration: 3000 });
        } finally {
            toast.dismiss(loading);
        }
    }

    const chkNum = useCallback((tag, value) => {
        switch (tag) {
            case 1:
                setValidText({
                    ..._validText, input1: value,
                })
                if (AdminMeter(value) === null) {
                    setValidError01(false)
                } else {
                    setValidError01(true)
                }
                break;
            case 2:
                setValidText({
                    ..._validText, input2: value,
                })
                if (AdminMeter(value) === null) {
                    setValidError02(false)
                } else {
                    setValidError02(true)
                }
                break;
            default:
                break;
        }
    },[_validText])

    const clearValid = () => {
        setValidText({
            input1: '',
            input2: ''
        })
        setValidError01(false);
        setValidError02(false);
    }

    useEffect(() => {
        try {
            setValidText({
                input1: infoDatax.meterGen,
                input2: infoDatax.meterSell
            })
        } catch (error) {

        }
    }, [idForm, infoDatax])

    return (
        <div
            className="modal fade"
            id={idForm}
            //key={"modal" + idForm}
            //id="exampleModal2"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"

        //className="modal fade" id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
        >
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                        <h1 className={`modal-title mt-3 fs-5 ${styles.h5}`} id="exampleModalLabel">แก้ไขเลขมิเตอร์ไฟฟ้าของโรงงาน</h1>
                        <hr className={`w-100 me-1 ms-1 border border-dark-subtle`} />
                    </div>
                    <div className="modal-body ">
                        <form id={"form" + idForm} className={`${styles.form_} mx-auto`}>
                            <div className={`${styles.input_flex}`}>
                                <div className={`${styles.box_error} d-flex flex-row justify-content-between m-2 ${styles.input_}`}>
                                    <h5 className={`col-6 ${styles.h5}`}>เลขมิเตอร์เริ่มต้นที่ผลิต</h5>
                                    <input className={`col ${validError01 ? styles.error : null}`}
                                        id={"input1" + idForm}
                                        //id="input1"
                                        type="text"
                                        defaultValue={infoDatax?.meterGen}
                                        onChange={(e) => { chkNum(1, e.target.value) }}
                                        //value={txtInput01 === "" ? datain.subname : txtInput01}
                                        name="emterGen" />
                                </div>
                                {validError01 && (
                                    <div className={`${styles.box_error} w-100 d-flex justify-content-center`}>
                                        <small className={`${styles.err_message}`}>
                                            {AdminMeter(_validText.input1)}
                                        </small>
                                    </div>
                                )}

                                <div className={`${styles.box_error} d-flex flex-row justify-content-between m-2 ${styles.input_}`}>
                                    <h5 className={`col-6 ${styles.h5}`}>เลขมิเตอร์เริ่มต้นที่ขาย</h5>
                                    <input className={`col ${validError02 ? styles.error : null}`}
                                        id={"input2" + idForm}
                                        //id="input1"
                                        type="text"
                                        defaultValue={infoDatax?.meterSell}
                                        onChange={(e) => { chkNum(2, e.target.value) }}
                                        //value={txtInput01 === "" ? datain.subname : txtInput01}
                                        name="meterSell" />
                                </div>
                                {validError02 && (
                                    <div className={`${styles.box_error} w-100 d-flex justify-content-center`}>
                                        <small className={`${styles.err_message}`}>
                                            {AdminMeter(_validText.input2)}
                                        </small>
                                    </div>
                                )}
                            </div>
                        </form>
                    </div>
                    <div className={`d-flex justify-content-center mb-3 ${styles.boxpopup}`}>
                        <button className={`${styles.savechangebtn}`} onClick={() => { submitEdit("btnCloseForm2", infoDatax) }}><h4>บันทึก</h4></button><h4>|</h4><button id="btnCloseForm2" className={`${styles.savechangebtn}`} onClick={() => { clearValid(); document.getElementById('form' + idForm).reset(); }} data-bs-dismiss="modal"><h4>ยกเลิก</h4></button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormManageFactoryMeter