import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
require("highcharts/modules/accessibility.js")(Highcharts);
require("highcharts/modules/variable-pie")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/annotations")(Highcharts);

const HighCharts = React.forwardRef((props, ref) => {
  /* React.useEffect(() => {
    Highcharts.setOptions({
      lang: {
        thousandsSep: ",",
      },
    });
  },[]); */
  return (
    <>
      <HighchartsReact ref={ref} highcharts={Highcharts} options={props.options} />
    </>
  );
});

export default HighCharts;
