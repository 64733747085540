import React, { useState, useEffect, useCallback } from "react";
import { Collection, Feature } from "ol";
import { Modify } from "ol/interaction";
import { Fill, Stroke, Style } from "ol/style";
import CircleStyle from "ol/style/Circle";
import { Polygon } from "ol/geom";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { getArea } from "ol/sphere";

const INITIAL_STATE = {
  modify: null,
};

const LayerEdit2 = ({
  _map,
  _switch2,
  _onSwitch2,
  _setPolygon,
  _setCropData,
  _createLayerCrops,
  _parcelId,
  _feature,
  _setFeature,
}) => {
  const [controls_, setControls] = useState(INITIAL_STATE);

  const clickHandler = useCallback(
    (evt) => {
      _map?.removeInteraction(controls_.modify);
      /* Clear parcel layers : START */
      _map
        .getLayers()
        .getArray()
        .filter((layer) => layer.get("coordEdit"))
        .forEach((layer) => _map.removeLayer(layer));

      _setFeature(null);
      /* Clear parcel layers : END */

      if (_switch2) return;
      _setPolygon(null);
      _onSwitch2(false);
      if ((evt.frameState?.layerStatesArray?.length || 0) < 2) return;

      evt.map.forEachFeatureAtPixel(evt.pixel, (feature, layer) => {
        const parcelId = feature.getProperties().parcelId;
        if (!parcelId) {
          _setFeature(feature);
          _setPolygon(null);
          _onSwitch2(false);
          _createLayerCrops(_parcelId);
        }
      });
    },
    [
      _map,
      _switch2,
      _onSwitch2,
      _setPolygon,
      _createLayerCrops,
      _parcelId,
      _setFeature,
      controls_,
    ]
  );

  const calculateArea = (geometry) => {
    const areaRai =
      geometry instanceof Polygon
        ? getArea(geometry)
        : Math.PI * Math.pow(geometry.getRadius(), 2);

    // const rai = Math.floor(areaRai / 1600); // แบบไม่มีทศนิยม
    const rai = Math.floor((areaRai / 1600) * 100) / 100; // แบบมีทศนิยม 2 ตำแหน่ง

    return rai;
  };

  const actEditLayer = useCallback(() => {
    setControls((prev) => {
      if (prev.modify) _map.removeInteraction(prev.modify);
      return INITIAL_STATE;
    });

    const modify = new Modify({
      features: new Collection([_feature]), // 'feature' is the polygon feature you want to edit
      style: [
        new Style({
          stroke: new Stroke({
            color: "#FFFFFF",
            width: 3 + 2,
          }),
        }),
        new Style({
          stroke: new Stroke({
            color: "#0500FF",
            width: 3,
          }),
        }),
        new Style({
          image: new CircleStyle({
            radius: 6,
            fill: new Fill({
              color: "#0500FF",
            }),
            stroke: new Stroke({
              color: "#FFFFFF",
              width: 3 / 2,
            }),
          }),
          zIndex: 3,
        }),
      ],
    });

    _map?.addInteraction(modify);

    setControls({ modify });

    modify.on("modifyend", (event) => {
      const modifiedFeature = event.features.getArray()[0];
      const modifiedGeometry = modifiedFeature.getGeometry().getCoordinates();
      // Perform any additional actions with the modified feature or geometry

      /* Clear crop clone layers : START */
      _map
        ?.getLayers()
        .getArray()
        .filter((layer) => layer.get("coordEdit"))
        .forEach((layer) => _map.removeLayer(layer));
      /* Clear crop clone layers : END */
      let covert4326 = modifiedFeature
        .getGeometry()
        .clone()
        .transform("EPSG:3857", "EPSG:4326");
      let coord = covert4326.getCoordinates();
      _setPolygon(coord[0]);

      let polygonFeature = new Feature({
        geometry: new Polygon(modifiedGeometry),
      });
      _setFeature(polygonFeature);
      const layer = new VectorLayer({
        source: new VectorSource({
          features: [polygonFeature],
        }),
        style: () => {
          return [
            new Style({
              stroke: new Stroke({
                color: "blue",
                width: 2,
              }),
              fill: new Fill({
                color: "rgba(0, 0, 255, 0.1)",
              }),
            }),
          ];
        },
        zIndex: 3,
      });
      layer.setProperties({
        coordEdit: coord,
      });
      _map?.addLayer(layer);

      /* คำนวณพื้นที่ที่วาด (ไร่) : START */
      if (coord[0] && coord[0].length > 2) {
        const areaRai = calculateArea(modifiedFeature.getGeometry());
        // console.log("Area in rai:", areaRai);
        _setCropData((prev) => ({ ...prev, area: areaRai }));
      }
      /* คำนวณพื้นที่ที่วาด (ไร่) : END */
    });
  }, [_map, _setPolygon, _setCropData, _feature, _setFeature]);

  const actEditLayerClear = useCallback(() => {
    setControls((prev) => {
      if (prev.modify) _map.removeInteraction(prev.modify);
      return INITIAL_STATE;
    });
    _setPolygon(null);
    /* Clear parcel layers : START */
    _map
      ?.getLayers()
      .getArray()
      .filter((layer) => layer.get("coordEdit"))
      .forEach((layer) => _map.removeLayer(layer));
    /* Clear parcel layers : END */
  }, [_map, _setPolygon]);

  useEffect(() => {
    if (_switch2) {
      actEditLayer();
    } else {
      actEditLayerClear();
    }
  }, [_switch2, actEditLayer, actEditLayerClear]);

  useEffect(() => {
    _map?.un("click", clickHandler);
    _map?.on("click", clickHandler);
  }, [_map, clickHandler]);
  return <></>;
};

export default LayerEdit2;
