import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import { Map, View } from "ol";
import "ol/ol.css";
import { fromLonLat } from "ol/proj";
import { defaults as defaultControls } from "ol/control";
import { defaults as defaultInteractions } from "ol/interaction";
import { GOOGLE_HYBRID_LAYER } from "../../../helpers/map.helper";
import { getFactoryData } from "../../../services/factory.slice";
import styles from "./MapElement.module.scss";

const MapElement = React.memo(
  ({ onInit, _coordFeature, _setLayerType, _setCoordFeature }) => {
    const factoryData = useSelector(getFactoryData);
    const mountRef = useRef();
    const mapRef = useRef();

    /** @type {Array.<Map|Function>} */
    const [map, setMap] = useState(null);

    const _createMap = useCallback(
      (el) => {
        const layers = [GOOGLE_HYBRID_LAYER];

        const newMap = new Map({
          target: el,
          view: new View({
            minZoom: 5,
            maxZoom: 23,
            center: [0, 0],
            zoom: 8,
          }),
          pixelRatio: 1,
          layers,
          controls: defaultControls({
            zoom: false,
            attribution: false,
          }),
          interactions: defaultInteractions({ mouseWheelZoom: true }).extend(
            []
          ),
        });

        setMap(newMap);
        onInit(newMap);

        console.log("Map initialized");
      },
      [onInit]
    );

    const dispose = useCallback(() => {
      map?.setTarget(null);
      _setLayerType("factory");
      _setCoordFeature(null);
    }, [map, _setLayerType, _setCoordFeature]);

    useEffect(() => {
      // Waiting for layout initialized.
      if (!mountRef.current && mapRef.current) {
        mountRef.current = true;
        setTimeout(() => _createMap(mapRef.current), 450);
      }
      // setCenter
      if (!_coordFeature) {
        map
          ?.getView()
          .setCenter(fromLonLat([factoryData?.lng, factoryData?.lat]));
      } else {
        /* map
        ?.getView()
        .setCenter(fromLonLat([_coordFeature[0], _coordFeature[1]])); */
      }
    }, [mountRef, mapRef, _createMap, map, factoryData, _coordFeature]);

    useEffect(() => {
      return () => dispose();
    }, [dispose]);

    return <div ref={mapRef} className={`${styles.container}`}></div>;
  }
);

export default MapElement;
