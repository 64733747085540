import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { GrFormClose } from "react-icons/gr";
import { useSelector } from "react-redux";
import icon from "../../../assets/img/Icon";
import { QueryGetAll } from "../../../helpers/api.helper";
import { defaultApi } from "../../../services/api";
import { getProfileData } from "../../../services/auth.slice";
import {
  convertDateEu,
  convertDateThai,
  convertDateThai2,
} from "../../../utils/format/Date.format";
import ChartsPlantationArea from "./charts/ChartsPlantationArea";
import ExportPopup from "../../../layouts/exportpopup/ExportPopup";
import styles from "./scss/ReportPlantationArea.module.scss";

const dateFormatt = {
  atDate: "",
  toDate: "",
  active: false,
};

const ReportPlantationArea = () => {
  const chart = useRef();

  const [date, setDate] = useState({
    atDate: "",
    toDate: "",
  });

  const [_triggerExport, setTriggerExport] = useState(false);

  //const [_dfDate, dfDate] = useState("")

  const [_setKeyFact, keyFact] = useState({
    factId: "",
  });

  const [_resData, resData] = useState(null);
  //const [_resDfSet, resDfSet] = useState(null)
  let _resDfSet = null;
  const [_resTotal, resTotal] = useState(0);

  //const [_resName, resName] = useState(null)
  const [stateDate, setStateDate] = useState(dateFormatt);
  const [openAtDate, setOpenAtDate] = useState(false);
  const [openToDate, setOpenToDate] = useState(false);

  const [_fstLoad, fstLoad] = useState(true);

  const [_colors, setColors] = useState({});

  const onChangeAtDate = (e) => {
    const dateFormat = convertDateEu(e);
    setDate({ ...date, atDate: dateFormat });
    setOpenAtDate(!openAtDate);

    setStateDate((prev) => ({ ...prev, atDate: dateFormat }));

    iSearch();
  };
  const onChangeToDate1 = (e) => {
    const dateFormat = convertDateEu(e);
    setDate({ ...date, toDate: dateFormat });
    setOpenToDate(!openToDate);

    setStateDate((prev) => ({ ...prev, toDate: dateFormat, active: true }));

    iSearch();
  };

  const downloadImage = () => {
    if (chart && chart.current && chart.current.chart) {
      chart.current.chart.setTitle({
        text: "พื้นที่รอเก็บเกี่ยวของกลุ่มที่อยู่ภายใต้โรงไฟฟ้า",
      });

      chart.current.chart.exportChart({
        type: "image/jpeg",
        filename: "chart",
      });

      chart.current.chart.setTitle({
        text: "",
      });
    }
  };

  const downloadCSV = () => {
    if (chart && chart.current && chart.current.chart) {
      chart.current.chart.downloadCSV();
    }
  };

  const profileData = useSelector(getProfileData);

  const info = useCallback(async () => {
    let qryGetAll = {
      $count: "%24count=true",
      $expand: "",
      $top: "",
      $skip: "",
      $filter: `&%24filter=createdBy%20eq%20${profileData.id}`,
      $orderby: "",
    };

    const resAllFac = await defaultApi.getAllFactoryMember(``);

    if (resAllFac?.data?.length > 0) {
      for (let index = 0; index < resAllFac?.data?.length; index++) {
        const element = resAllFac?.data[index];

        if (+element?.userId === +profileData?.id) {
          qryGetAll = {
            $count: "%24count=true",
            $expand: "",
            $top: "",
            $skip: "",
            $filter: `&%24filter=id%20eq%20${element.factoryId}`,
            $orderby: "",
          };
        }
      }
    }

    const _query = QueryGetAll(qryGetAll);
    const res_entp = await defaultApi.getAllFactory(_query);
    const factId = res_entp.data.datas[0].id;

    keyFact({
      factId: factId,
    });

    setStateDate({ atDate: "", toDate: "" });

    let date = new Date();
    //let month = date.getMonth()
    let year = date.getFullYear();

    let xdate = date.toISOString().split("T")[0];

    let i = 0;
    //let yearItem =
    // const res_dfset = await defaultApi.getReportDashboard("powergenYear=" + year + "&factoryTransactionYear=" + year + "&enterpriseTransactionYear=" + year)
    const res = await defaultApi.getReportPlantingArea(
      "factoryId=" + factId + "&atDate=" + xdate + "&toDate=" + xdate
    );
    const colors = await defaultApi.getColors();

    setColors(colors);
    setDate({
      ...date,
      atDate: xdate,
      toDate: xdate,
    });

    //dfDate(xdate)
    // resDfSet(res_dfset.data)
    resData(res.data.plantingArea);
    for (let index = 0; index < res.data.plantingArea.length; index++) {
      const element = res.data.plantingArea[index].area;
      i = i + element;
    }
    resTotal(i);
  }, [profileData]);

  const iSearch = async () => {
    if (stateDate.toDate !== "") {
      let i = 0;
      //let yearItem =
      let atDates = stateDate.atDate.split("-");
      // const res_dfset = await defaultApi.getReportDashboard("powergenYear=" + atDates[0] + "&factoryTransactionYear=" + atDates[0] + "&enterpriseTransactionYear=" + atDates[0])
      const res = await defaultApi.getReportPlantingArea(
        "factoryId=" +
          _setKeyFact.factId +
          "&atDate=" +
          stateDate.toDate +
          "&toDate=" +
          stateDate.toDate
      );

      setDate({
        ...date,
        atDate: dateFormatt.atDate,
        toDate: dateFormatt.toDate,
      });
      // resDfSet(res_dfset.data)
      resData(res.data.plantingArea);
      for (let index = 0; index < res.data.plantingArea.length; index++) {
        const element = res.data.plantingArea[index].area;
        i = i + element;
      }
      resTotal(i);

      setStateDate((prev) => ({ ...prev, active: false }));
    }
  };

  const dateDefault = useMemo(() => {
    const date = stateDate.toDate || new Date();

    return convertDateThai2(date);
  }, [stateDate]);

  useEffect(() => {
    if (stateDate.active) iSearch();
  }, [stateDate]);

  useEffect(() => {
    if (_fstLoad === true) {
      info();
      fstLoad(false);
    }
  }, [info, _fstLoad]);

  useEffect(() => {
    /* Function close dropdown 'ToDate' : START */
    const onCloseDropdownToDate = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#fac_to_date2_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenToDate(false);
      }
    };

    if (openToDate) {
      document.addEventListener("click", onCloseDropdownToDate);
    }
    /* Function close dropdown 'ToDate' : END */

    return () => {
      document.removeEventListener("click", onCloseDropdownToDate);
    };
  }, [openToDate]);

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.date_filter}`}>
        <div className={`${styles.group_}`}>
          <div className={styles.input_unit}>
            <div className={styles.info_box}>
              <p style={{ color: "black" }}>
                {stateDate.toDate !== ""
                  ? convertDateThai(stateDate.toDate)
                  : "วันที่"}
              </p>
              {/* {dateFormatt.toDate !== "" && (
                <GrFormClose
                  size={25}
                  cursor={"pointer"}
                  onClick={() =>
                    setDate({ ...date, toDate: _dfDate })
                  }
                />
              )} */}
            </div>
            <div
              className={styles.unit}
              onClick={() => {
                setOpenToDate(!openToDate);
              }}
            >
              <img src={icon.calendar} alt="" id="fac_to_date2_d" />
            </div>
          </div>
          {openToDate && <DatePicker onChange={onChangeToDate1} inline />}
        </div>
      </div>

      <h4 className={`${styles.title_text}`}>
        {/* {_resName} */}
        พื้นที่รอเก็บเกี่ยวของกลุ่มที่อยู่ภายใต้โรงไฟฟ้า
      </h4>
      <div className={`${styles.charts_}`}>
        <ChartsPlantationArea
          ref={chart}
          colors={_colors}
          datain={_resData}
          datein={date}
          totalareain={_resTotal}
          dfset={_resDfSet}
          dateDefault={dateDefault}
        />
      </div>
      <div className={`${styles.export_}`}>
        <ExportPopup
          label={"report"}
          trigger={_triggerExport}
          event={{ csv: downloadCSV, image: downloadImage }}
        />
        <button
          type="button"
          className={`${styles.btn_}`}
          onClick={() => setTriggerExport(!_triggerExport)}
        >
          {/* <button type="button" className={`${styles.btn_}`}  onClick={downloadCSV}> */}
          Export
        </button>
      </div>
    </div>
  );
};

export default ReportPlantationArea;
