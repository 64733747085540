import React, { useState, useEffect, useMemo } from "react";
import HighCharts from "../../../components/Highcharts/HighCharts";

const ChartsStatus = ({ _themeColor, datas }) => {
  const [stateChartOptions, setStateChartOptions] = useState({
    chart: {
      type: "bar",
    },
    title: {
      text: "",
    },
    exporting: {
      enabled: false,
    },
    xAxis: {
      categories: [
        "เหนือ",
        "ใต้",
        "กลาง",
        "ตะวันตก",
        "ตะวันออก",
        "ตะวันออกเฉียงเหนือ",
      ],
      labels: {
        style: {
          fontSize: "15px",
        },
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
      },
      labels: {
        style: {
          fontSize: "15px",
        },
      },
    },
    legend: {
      reversed: true,
    },
    plotOptions: {
      series: {
        stacking: "normal",
      },
    },
    colors: ["#235f07", "#789b33", "#ffcf00", "#7E7E7E"],
    series: [],
  });

  const _setinplaned = useMemo(() => {
    let inplaned = [];

    Object.keys(datas).forEach((element) => {
      let index = null;
      switch (element) {
        case "center":
          index = 2;
          inplaned[index] = datas.center[0].value;
          break;
        case "north":
          index = 0;
          inplaned[index] = datas.north[0].value;
          break;
        case "northEast":
          index = 5;
          inplaned[index] = datas.northEast[0].value;
          break;
        case "east":
          index = 4;
          inplaned[index] = datas.east[0].value;
          break;
        case "south":
          index = 1;
          inplaned[index] = datas.south[0].value;
          break;
        case "west":
          index = 3;
          inplaned[index] = datas.west[0].value;
          break;
        default:
          break;
      }
    });

    return inplaned;
  }, [datas]);
  const _setcomplete = useMemo(() => {
    let complete = [];

    Object.keys(datas).forEach((element) => {
      let index = null;
      switch (element) {
        case "center":
          index = 2;
          complete[index] = datas.center[1].value;
          break;
        case "north":
          index = 0;
          complete[index] = datas.north[1].value;
          break;
        case "northEast":
          index = 5;
          complete[index] = datas.northEast[1].value;
          break;
        case "east":
          index = 4;
          complete[index] = datas.east[1].value;
          break;
        case "south":
          index = 1;
          complete[index] = datas.south[1].value;
          break;
        case "west":
          index = 3;
          complete[index] = datas.west[1].value;
          break;
        default:
          break;
      }
    });

    return complete;
  }, [datas]);
  const _setinlicense = useMemo(() => {
    let inlicense = [];

    Object.keys(datas).forEach((element) => {
      let index = null;
      switch (element) {
        case "center":
          index = 2;
          inlicense[index] = datas.center[2].value;
          break;
        case "north":
          index = 0;
          inlicense[index] = datas.north[2].value;
          break;
        case "northEast":
          index = 5;
          inlicense[index] = datas.northEast[2].value;
          break;
        case "east":
          index = 4;
          inlicense[index] = datas.east[2].value;
          break;
        case "south":
          index = 1;
          inlicense[index] = datas.south[2].value;
          break;
        case "west":
          index = 3;
          inlicense[index] = datas.west[2].value;
          break;
        default:
          break;
      }
    });

    return inlicense;
  }, [datas]);
  const _setrunning = useMemo(() => {
    let running = [];

    Object.keys(datas).forEach((element) => {
      let index = null;
      switch (element) {
        case "center":
          index = 2;
          running[index] = datas.center[3].value;
          break;
        case "north":
          index = 0;
          running[index] = datas.north[3].value;
          break;
        case "northEast":
          index = 5;
          running[index] = datas.northEast[3].value;
          break;
        case "east":
          index = 4;
          running[index] = datas.east[3].value;
          break;
        case "south":
          index = 1;
          running[index] = datas.south[3].value;
          break;
        case "west":
          index = 3;
          running[index] = datas.west[3].value;
          break;
        default:
          break;
      }
    });

    return running;
  }, [datas]);

  useEffect(() => {
    setStateChartOptions((prevOptions) => ({
      ...prevOptions,
      series: [
        {
          name: "ดำเนินกิจการ",
          data: _setrunning,
        },
        {
          name: "ขอใบอนุญาต",
          data: _setinlicense,
        },
        {
          name: "สร้างเสร็จแล้ว",
          data: _setcomplete,
        },
        {
          name: "กำลังก่อสร้าง",
          data: _setinplaned,
        },
      ],
    }));
  }, [_setcomplete, _setinlicense, _setinplaned, _setrunning]);

  return (
    <>
      <h5
        className="charts_title d-flex justify-content-center align-items-center"
        style={{ color: _themeColor?.color }}
      >
        จำนวนโรงไฟฟ้าชุมชนจำแนกตามภาพ
      </h5>
      <HighCharts options={stateChartOptions} />
    </>
  );
};

export default ChartsStatus;
