import React from "react";
import { FaInfo, FaSquareFull } from "react-icons/fa";
import HighCharts from "../../../../components/Highcharts/HighCharts";
import styles from "../scss/ReportPlantationArea.module.scss";

const ChartsPlantationArea = React.forwardRef((prop, ref) => {
  let date = {
    sdate: "",
    edate: "",
  };

  let totalarea = 0;
  let area = [];

  const setColorItem = (id, setColor) => {
    for (let index = 0; index < setColor.length; index++) {
      if (id === index) {
        return setColor[index].colorCode;
      }
    }
  };

  const loopItem = (area) => {
    return area.map((data, index) => {
      return (
        <div key={index} className={`${styles.legends_0}`}>
          <i style={{ color: data.color }}>
            <FaSquareFull />
          </i>
          <span>{data.name}</span>
        </div>
      );
    });
  };

  try {
    totalarea = prop.totalareain;
    date.sdate = prop.datein.atDate;
    date.edate = prop.datein.toDate;

    for (let index = 0; index < prop.datain.length; index++) {
      const el = prop.datain[index];

      if (el?.area) {
        area.push({
          name: el.name,
          y: el.area,
          dataLabels: el.name,
          color: setColorItem(index, prop.colors.data.color),
        });
      }
    }
  } catch (error) {}

  const options = {
    chart: {
      type: "pie",
      height: 470,
      // height: 340,
    },
    title: {
      text: null,
    },
    tooltip: {
      formatter: function () {
        return (
          this.key +
          " " +
          Number(this.point.y).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) +
          " ไร่"
        );
        //console.log(this.key);
      },
      // pointFormat: "<b>{point.y} ไร่</b>",
      style: {
        fontSize: "18px",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        showInLegend: true,
      },
      series: {
        dataLabels: {
          enabled: true,
          formatter: function () {
            return (
              Number(this.point.y).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) + " ไร่"
            );
            //console.log(this.key);
          },
          // format: "{point.y} ไร่",
          style: {
            fontSize: "18px",
          },
        },
      },
    },
    //colors: ["#1dd8bd", "#e83b81", "#c324c7", "#0086ea"],
    exporting: {
      chartOptions: {
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              formatter: function () {
                return (
                  Number(this.point.y).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }) + " ไร่"
                );
                //console.log(this.key);
              },
              // format: "{key} {point.y} ไร่",
              style: {
                fontSize: "9px",
              },
            },
          },
        },
      },
      buttons: {
        contextButton: {
          enabled: false,
        },
        button: {
          text: "Export",
          theme: {
            fill: "#f9df70",
            stroke: "#f9df70",
            states: {
              hover: {
                fill: "#fcc",
                stroke: "#f00",
              },
              select: {
                fill: "#cfc",
                stroke: "#0f0",
              },
            },
          },
          menuItems: [
            "viewFullscreen",
            "separator",
            "downloadPNG",
            "downloadSVG",
            "downloadPDF",
            "separator",
            "downloadXLS",
          ],
        },
      },
      enabled: false,
    },
    navigation: {
      buttonOptions: {
        align: "right",
        verticalAlign: "bottom",
        y: 0,
      },
    },
    series: [
      {
        minPointSize: 10,
        innerSize: "50%",
        zMin: 0,
        name: "",
        data: area,
      },
    ],
  };
  return (
    <>
      {/* style={(date.sdate === "" || date.edate === "") ? { display: 'none' } : { display: 'block' }} */}
      <div className={`${styles.details}`}>
        <p>{prop.dateDefault}</p>
        <p>
          พื้นที่กำลังเพาะปลูกทั้งหมด
          <br />
          ของเชื้อเพลิง
        </p>
        <p>
          {" "}
          {Number(totalarea).toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}{" "}
          ไร่
        </p>
      </div>

      <div className={`${styles.charts}`}>
        <HighCharts ref={ref} options={options} />
      </div>

      {/* <div className={`${styles.info_legends}`}>
        <div className={`${styles.boxinfo}`}>{loopItem(area)}</div>
      </div> */}
    </>
  );
});

export default ChartsPlantationArea;
