import React from "react";
import HighCharts from "../../../../components/Highcharts/HighCharts";
import styles from "../scss/ReportYearSalesPlan.module.scss";
import { formatterStack, formatterTooltip, formatterXaxis_1, formatterYaxis } from "../../../../helpers/suffixchart.helper";

const ChartsYearSalesPlan = React.forwardRef((prop, ref) => {
  let ranges = [
    { divider: 1e18, suffix: "E" },
    { divider: 1e15, suffix: "P" },
    { divider: 1e12, suffix: "T" },
    { divider: 1e9, suffix: "G" },
    { divider: 1e6, suffix: "M" },
    { divider: 1e3, suffix: "K" },
  ];

  let arrLst = [];
  let totalProduct = [];
  let target = [];

  const countProductTotal = (data, years, months, year) => {
    let total = [];

    try {
      for (let index = 0; index < data.factoryTransaction.length; index++) {
        if (Number(year) === Number(years)) {
          if (Number(data.factoryTransaction[index].month) > Number(months)) {
            total[index] = null;
          } else if (
            Number(data.factoryTransaction[index].month) <= Number(months)
          ) {
            let i = 0;

            if (data.factoryTransaction[index].value) {
              let obj = JSON.parse(data.factoryTransaction[index].value);

              for (let counti = 0; counti < obj.length; counti++) {
                i = i + obj[counti]?.total ?? obj[counti]?.amount;
              }
            } else {
              i = i + 0;
            }

            total[index] = i;
          }
        } else if (Number(year) < Number(years)) {
          let i = 0;

          if (data.factoryTransaction[index].value) {
            let obj = JSON.parse(data.factoryTransaction[index].value);

            for (let counti = 0; counti < obj.length; counti++) {
              i = i + obj[counti]?.total ?? obj[counti]?.amount;
            }
          } else {
            i = i + 0;
          }

          total[index] = i;
        }
      }
      return total;
    } catch (error) {}
  };

  const setColorPlant = (indexid, setcolor) => {
    for (let index = 0; index < setcolor.length; index++) {
      if (indexid === index) {
        return setcolor[index].colorCode;
      }
    }
  };

  const setDataItem = (plantid, setItem, years, months, year) => {
    let arr = [];

    const mapItemLst = (plantidx1, setItemx1) => {
      let i = 0;
      try {
        let item = JSON.parse(setItemx1);

        for (let index = 0; index < item.length; index++) {
          if (plantidx1 === item[index].plant_id && index <= item.length) {
            i = item[index]?.amount;
          } else if (
            plantidx1 !== item[index].plant_id &&
            index === item.length
          ) {
            i = 0;
          }
        }
        return i;
      } catch (error) {}
    };

    try {
      for (let index = 0; index < setItem.factoryTransaction.length; index++) {
        if (Number(year) === Number(years)) {
          if (
            Number(setItem.factoryTransaction[index].month) > Number(months)
          ) {
            arr[index] = null;
          } else if (
            Number(setItem.factoryTransaction[index].month) <= Number(months)
          ) {
            if (setItem.factoryTransaction[index].value) {
              arr[index] = mapItemLst(
                plantid,
                setItem.factoryTransaction[index].value
              );
            } else {
              arr[index] = 0;
            }
          }
        } else if (Number(year) < Number(years)) {
          if (setItem.factoryTransaction[index].value) {
            arr[index] = mapItemLst(
              plantid,
              setItem.factoryTransaction[index].value
            );
          } else {
            arr[index] = 0;
          }
        }
      }
      return arr;
    } catch (error) {}
  };

  try {
    let datain = prop.datain,
      dfSet = prop.dfSet;
    let plant = dfSet.enterprisePlants;
    //let color = dfSet.color

    let date = new Date();
    let years = date.getFullYear();
    let months = date.getMonth() + 1;

    for (let index = 0; index < plant.length; index++) {
      const el = plant[index];

      arrLst.push({
        name: el?.plantName,
        color: setColorPlant(index, prop.colors.data.color),
        data: setDataItem(el?.plantId, datain, years, months, prop?.year),
        type:'column',
        visible : true
      });
    }

    for (let index = 0; index < datain?.factoryTransaction?.length; index++) {
      //const element = datain.factoryTransaction[index].month;
      // let date = new Date(Number(prop.year), Number(element), 0)
      // target[index] = (date.getDate() * datain.factoryTransaction[index].target)
      if (Number(prop.year) === Number(years)) {
        if (Number(datain?.factoryTransaction[index]?.month) > Number(months)) {
          target[index] = null;
        } else if (
          Number(datain?.factoryTransaction[index]?.month) <= Number(months)
        ) {
          target[index] = datain?.factoryTransaction[index]?.target;
        }
      } else if (Number(prop.year) < Number(years)) {
        target[index] = datain?.factoryTransaction[index]?.target;
      }
    }

    totalProduct = countProductTotal(datain, years, months, prop.year);

    // arrLst[arrLst.length + 1] = {
    //   name: "ปริมาณที่ผลิตได้ทั้งหมด",
    //   data: totalProduct,
    //   color: "#000000",
    //   lineWidth: 7,
    //   dashStyle: "solid",
    //   // dataLabels: {
    //   //   enabled: false,
    //   // },
    // };
    arrLst[arrLst.length] = {
      name: "ปริมาณที่ส่งมอบตามสัญญา",
      data: target,
      color: "navy",
      dashStyle: "dash",
      type:'line',
      visible: true
    };

    // arrLst.push({
    //   name: "ปริมาณที่ผลิตได้ทั้งหมด",
    //   data: totalProduct,
    //   color: "#000000",
    //   lineWidth: 7,
    //   dataLabels: {
    //     enabled: false,
    //   },
    // }, {
    //   name: "ปริมาณที่ส่งมอบตามสัญญา",
    //   data: target,
    //   color: "navy",
    //   dashStyle: 'dash'
    // })
  } catch (error) {}

  const options = {
    chart: {
      type: "column",
      height: 450,
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: [
        "ม.ค.",
        "ก.พ.",
        "มี.ค.",
        "เม.ย.",
        "พ.ค.",
        "มิ.ย.",
        "ก.ค.",
        "ส.ค.",
        "ก.ย.",
        "ต.ค.",
        "พ.ย.",
        "ธ.ค.",
      ],
      labels: {
        style: {
          fontSize: "13px",
        },
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "ปริมาณผลผลิต (ตัน)",
      },
      stackLabels: {
        enabled: true,
        formatter: formatterStack,
        style: {
          fontSize: '16px'
        }
      },
      labels: {
        enabled: true,
        formatter: formatterYaxis,
        style: {
          fontSize: "13px",
        },
      },
    },
    tooltip: {
      //pointFormat: "<b>{series.name}: {point.y} ตัน</b>",
      style: {
        fontSize: "18px",
      },
      enabled: true,
      ...formatterTooltip
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          formatter: formatterXaxis_1,
          style: {
            fontSize: "16px",
          },
        },
      },
      line: {
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "16px",
          },
          formatter: formatterXaxis_1,
        },

        enableMouseTracking: true,
      },
      series: {
        lineWidth: 3,
        marker: {
          enabled: false,
        },
      },
    },
    exporting: {
      chartOptions: {
        // specific options for the exported image
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true,
              style: {
                fontSize: "6px",
              },
              formatter: function () {
                for (let i = 0; i < ranges.length; i++) {
                  if (this.y >= ranges[i].divider) {
                    return (
                      Number(this.y / ranges[i].divider).toLocaleString(
                        undefined,
                        { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                      ) + ranges[i].suffix
                    );
                  }
                }
                if (Number(this.y) === 0) {
                  return null;
                  //return this.y.toString();
                } else {
                  return Number(this.y).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  });
                }
              },
            },
            enableMouseTracking: true,
          },
        },
        xAxis: {
          labels: {
            style: {
              fontSize: "6px",
            },
          },
        },
        yAxis: {
          labels: {
            style: {
              fontSize: "6px",
            },
          },
        },
      },
      enabled: false,
    },
    series: arrLst?.filter((item) => {
      return item.data.some((value) => value !== 0 && value !== null);
    }),
  };
  return (
    <div className={`${styles.charts}`}>
      <HighCharts ref={ref} options={options} />
    </div>
  );
});

export default ChartsYearSalesPlan;
