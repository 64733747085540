import React from "react";
import HighCharts from "../../../../components/Highcharts/HighCharts";
import styles from "../scss/ProducePlantInfo.module.scss";
import { formatterMiddle, formatterStack, formatterTooltip, formatterXaxis_1, formatterYaxis } from "../../../../helpers/suffixchart.helper";

const ChartsProducePlant1 = React.forwardRef((prop, ref) => {
  //data: [260,180,430,370,320,240,320,280], data: [480,560,600,490,540,420,670,700], data: [60,60,60,60,60,60,60,60], data: [140,95,110,120,90,150,80,250],

  let ranges = [
    { divider: 1e18, suffix: "E" },
    { divider: 1e15, suffix: "P" },
    { divider: 1e12, suffix: "T" },
    { divider: 1e9, suffix: "G" },
    { divider: 1e6, suffix: "M" },
    { divider: 1e3, suffix: "K" },
  ];

  let totalMonth = []; // เก็บผลรวมข้อมูลเชื้อเพลิงทั้งหมด
  //  let totalPlant = [] // เก็บรายชื่อพืชทั้งหมด
  let totalDetailByMonth = null; //เก็บข้อมูลทั้งหมด
  let days = [];
  let target = [];

  let middlePoint = 0

  const totalMonthAmount = (data) => {
    let total = 0;

    if (data === null) {
      total += 0;
    } else {
      try {
        let i = JSON.parse(data);
        i.forEach((data0) => {
          total += data0?.total || data0?.amount;
        });
      } catch (error) {
        total += 0;
      }
    }
    return total;
  };

  const loopForDetail = (
    data_1,
    data_2,
    dfyears,
    dfmonths,
    dfdays,
    year,
    month
  ) => {
    const loopValue = (plant_id_0, value) => {
      let total = 0;
      if (value) {
        for (let index = 0; index < value.length; index++) {
          let i = value[index];
          let plant_id_1 = i.plant_id;

          if (plant_id_0 === plant_id_1 && value.length >= index) {
            total = i.amount;
          } else if (plant_id_0 !== plant_id_1 && value.length === index) {
            total = 0;
          } else {
          }
        }
      } else {
        total = 0;
      }
      return total;
    };

    let arr0 = [];

    for (let index0 = 0; index0 < data_2.length; index0++) {
      let totalAmonth = [];

      for (let index1 = 0; index1 < data_1.length; index1++) {
        let i = JSON.parse(data_1[index1].value);

        if (year === dfyears) {
          if (month === dfmonths) {
            if (data_1[index1].day > dfdays) {
              totalAmonth[index1] = null;
            } else {
              totalAmonth[index1] = loopValue(data_2[index0].id, i);
            }
          } else if (month > dfmonths) {
            totalAmonth[index1] = null;
          } else if (month < dfmonths) {
            totalAmonth[index1] = loopValue(data_2[index0].id, i);
          }
        } else if (year < dfyears) {
          totalAmonth[index1] = loopValue(data_2[index0].id, i);
        }
      }
      arr0[index0] = {
        name: data_2[index0].name,
        data: totalAmonth,
        color: data_2[index0].color,
        type: 'column',
        visible: true
      };
    }

    totalDetailByMonth = arr0;
  };

  try {

    let date = new Date();
    let dfyears = date.getFullYear();
    let dfmonths = date.getMonth() + 1;
    let dfdays = date.getDate();
    let year = Number(prop.datetime.year);
    let month = Number(prop.datetime.month);


    let datax = prop?.datain;
    let data_1 = datax?.factoryTransaction;
    let data_2 = datax?.plant;

    for (let index = 0; index < data_1?.length; index++) {
      let i = index + 1;
      days[index] = i;
    }

    loopForDetail(data_1, data_2, dfyears, dfmonths, dfdays, year, month);

    data_1.forEach((data, index) => {
      if (year === dfyears) {
        if (month === dfmonths) {
          if (data.day > dfdays) {
            totalMonth[index] = null;
            target[index] = null;
          } else {
            totalMonth[index] = totalMonthAmount(data.value);
            target[index] = data.target;
          }
        } else if (month > dfmonths) {
          totalMonth[index] = null;
          target[index] = null;
        } else if (month < dfmonths) {
          totalMonth[index] = totalMonthAmount(data.value);
          target[index] = data.target;
        }
      } else if (year < dfyears) {
        totalMonth[index] = totalMonthAmount(data.value);
        target[index] = data.target;
      }
    });

    middlePoint = target?.filter((arr, index) => { if (arr > 0) return index })?.length || 0
    middlePoint = Math.ceil(Number(middlePoint / 2))

    // totalDetailByMonth.push({
    //   name: "ปริมาณที่ผลิตได้ทั้งหมด",
    //   data: totalMonth,
    //   color: "black",
    //   lineWidth: 6,
    //   showInLegend: false
    // });

    totalDetailByMonth.push({
      name: "ปริมาณที่ส่งมอบตามสัญญา",
      data: target,
      color: "navy",
      dashStyle: "dash",
      type: "line",
      visible: true
    });

    console.log(totalDetailByMonth);
  } catch (error) {
    // console.log("error",error);
  }

  const options = {
    chart: {
      type: "column",
      // type: "line",
      height: 400,
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: days,
    },
    yAxis: {
      title: {
        text: "ปริมาณผลิต ( ตัน )",
      },
      labels: {
        enabled: true,
        formatter: formatterYaxis
      },
      stackLabels: {
        enabled: true,
        formatter: formatterStack,
        style: {
          fontSize: '16px'
        }
      }
    },
    tooltip: {
      //pointFormat: "<b>{series.name}: {point.y} ตัน</b>",
      style: {
        fontSize: "18px",
      },
      enabled: true,
      ...formatterTooltip
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          // format: "{point.y} คน",
          formatter: formatterXaxis_1,
          style: {
            fontSize: "16px",
          },
        },
      },
      series: {
        dataLabels: {
          enabled: true,
          formatter: function () {
            for (let i = 0; i < ranges.length; i++) {
              if (this.y >= ranges[i].divider) {
                return (
                  Number(this.y / ranges[i].divider).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }) + ranges[i].suffix
                );
              }
            }
            if (Number(this.y) === 0) {
              return null;
              //return this.y.toString();
            } else {
              return Number(this.y).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              });
            }
          },
          style: {
            fontSize: "16px",
          },
        },
      },
      line: {
        dataLabels: {
          enabled: true,
          formatter: function () {
            if (this.x === middlePoint) {
              return Number(this.y).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            }
            return null;
          },
        },
        enableMouseTracking: true,
      },
    },
    exporting: {
      chartOptions: {
        // specific options for the exported image
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true,
              style: {
                fontSize: "6px",
              },
              formatter: function () {
                for (let i = 0; i < ranges.length; i++) {
                  if (this.y >= ranges[i].divider) {
                    return (
                      Number(this.y / ranges[i].divider).toLocaleString(
                        undefined,
                        { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                      ) + ranges[i].suffix
                    );
                  }
                }
                if (Number(this.y) === 0) {
                  return null;
                  //return this.y.toString();
                } else {
                  return Number(this.y).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  });
                }
              },
            },
            enableMouseTracking: true,
          },
        },
        xAxis: {
          labels: {
            style: {
              fontSize: "6px",
            },
          },
        },
        yAxis: {
          labels: {
            style: {
              fontSize: "6px",
            },
          },
        },
      },
      buttons: {
        contextButton: {
          enabled: false,
        },
        button: {
          text: "Export",
          theme: {
            fill: "#f9df70",
            stroke: "#f9df70",
            states: {
              hover: {
                fill: "#fcc",
                stroke: "#f00",
              },
              select: {
                fill: "#cfc",
                stroke: "#0f0",
              },
            },
          },
          menuItems: [
            "viewFullscreen",
            "separator",
            "downloadPNG",
            "downloadSVG",
            "downloadPDF",
            "separator",
            "downloadXLS",
          ],
        },
      },
      enabled: false,
    },
    navigation: {
      buttonOptions: {
        align: "right",
        verticalAlign: "bottom",
        y: 0,
      },
    },
    series: totalDetailByMonth?.filter((item) => {
      return item.data.some((value) => value !== 0 && value !== null);
    }),
  };

  return (
    <div className={`${styles.charts}`}>
      <HighCharts ref={ref} options={options} />
    </div>
  );
});

export default ChartsProducePlant1;
