export const showPass = () => {
  const password = document.querySelector("#password");

  const type =
    password.getAttribute("type") === "password" ? "text" : "password";
  password.setAttribute("type", type);
};

export const showPassCf = () => {
  const password = document.querySelector("#password_check");

  const type =
    password.getAttribute("type") === "password" ? "text" : "password";
  password.setAttribute("type", type);
};
