import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { GrFormClose } from "react-icons/gr";
import { toast } from "react-hot-toast";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Production from "./production/Production";
import Sale from "./sale/Sale";
import AddSale from "./sale/AddSale";
import SwitchMenu from "./_layout/SwitchMenu";
import useSwitch from "../../../../utils/hooks/useSwitch";
import useSwitchPage from "../../../../utils/hooks/useSwitchPage";
import { getEnterpriseData } from "../../../../services/enterprise.slice";
import { defaultApi } from "../../../../services/api";
import { clearObj } from "../../../../utils/format/Object.format";
import {
  convertDateEu,
  convertDateThai,
} from "../../../../utils/format/Date.format";
import { QueryGetAll } from "../../../../helpers/api.helper";
import icon from "../../../../assets/img/Icon";
import Message from "../../../../utils/message/Message";
import styles from "./scss/RawMaterial.module.scss";

const RawMaterial = ({ _themeColor }) => {
  const enterpriseData = useSelector(getEnterpriseData);
  const [_switch, onSwitch, _switch2, onSwitch2] = useSwitch();
  const [switchPage, onSwitchPage] = useSwitchPage();

  const [allEnterpriseSupply, setAllEnterpriseSupply] = useState([]);
  const [allPlant, setAllPlant] = useState([]);
  const [cropStatus, setCropStatus] = useState([]);
  const [allTransaction, setAllTransaction] = useState([]);
  const [parcelIdOpt, setParcelIdOpt] = useState([]);
  const [currentPageL, setCurrentPageL] = useState(1);
  const [filterGroupL, setFilterGroupL] = useState({
    parcelId: "",
    plant: "",
    atDate: "",
    toDate: "",
    firstname: "",
    lastname: "",
    cropStatus: "",
  });
  const [searchText, setSearchText] = useState("");
  const [currentPageR, setCurrentPageR] = useState(1);
  const [totalCountR, setTotalCountR] = useState(0);
  const [filterGroupR, setFilterGroupR] = useState({
    atDate: "",
    toDate: "",
    active: false,
  });
  const [selectedDate, setSelectedDate] = useState({
    dateStartL: new Date(),
    dateEndL: new Date(),
    startDate: new Date(),
    endDate: new Date(),
  });
  const [openDateStartL, setOpenDateStartL] = useState(false);
  const [openDateEndL, setOpenDateEndL] = useState(false);
  const [openStartDate, setOpenStartDate] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);
  const [placeholderDisabled, setPlaceholderDisabled] = useState(false);

  /* DatePicker : START */
  const onChangeDateStartL = (e) => {
    const dateFormat = convertDateEu(e);
    setFilterGroupL({ ...filterGroupL, atDate: dateFormat });
    setSelectedDate({ ...selectedDate, dateStartL: e });
    setOpenDateStartL(!openDateStartL);
  };
  const onChangeDateEndL = (e) => {
    const dateFormat = convertDateEu(e);
    setFilterGroupL({ ...filterGroupL, toDate: dateFormat });
    setSelectedDate({ ...selectedDate, dateEndL: e });
    setOpenDateEndL(!openDateEndL);
  };
  const onChangeAtDate = (e) => {
    const dateFormat = convertDateEu(e);
    setFilterGroupR({ ...filterGroupR, atDate: dateFormat, active: true });
    setSelectedDate({ ...selectedDate, startDate: e });
    setOpenStartDate(!openStartDate);
  };
  const onChangeToDate = (e) => {
    const dateFormat = convertDateEu(e);
    setFilterGroupR({ ...filterGroupR, toDate: dateFormat, active: true });
    setSelectedDate({ ...selectedDate, endDate: e });
    setOpenEndDate(!openEndDate);
  };
  /* DatePicker : END */

  /* Search input : START */
  const searchInputL = (e) => {
    const searchText = e.target.value;
    setSearchText(searchText);
    if (searchText !== "") {
      const str = searchText.split(/(\s+)/).filter((e) => e.trim().length > 0);
      const searchQuery = str.join(" ");
      const [firstname, lastname] = searchText.split(" ");

      if (searchText.indexOf(" ") !== -1) {
        setFilterGroupL({
          ...filterGroupL,
          firstname: firstname,
          lastname: lastname,
        });
      } else {
        setFilterGroupL({
          ...filterGroupL,
          firstname: searchQuery,
          lastname: searchQuery,
        });
      }
    } else {
      setFilterGroupL({
        ...filterGroupL,
        firstname: "",
        lastname: "",
      });
    }
  };
  /* Search input : END */

  const onPaginateR = useCallback(
    async (page) => {
      if (currentPageR === page) return;

      const qryGetAll = {
        $count: "%24count=true",
        $expand: "&%24expand=contractFarming%2C%20enterprise",
        $top: "&%24top=10",
        $skip: `&%24skip=${page <= 1 ? "0" : page * 10 - 10}`,
        $filter:
          filterGroupR.atDate !== "" && filterGroupR.toDate !== ""
            ? `&%24filter=txDate%20ge%20${filterGroupR.atDate}%20and%20
            txDate%20le%20${filterGroupR.toDate}%20and%20
            enterpriseId%20eq%20${enterpriseData.id}
            %20and%20note%20eq%20%27buy%27`
            : `&%24filter=enterpriseId%20eq%20${enterpriseData.id}
            %20and%20note%20eq%20%27buy%27`,
        $orderby: "&%24orderby=createdAt desc",
      };
      const _query = QueryGetAll(qryGetAll);
      try {
        const res = await defaultApi.getAllTransaction(_query);

        setCurrentPageR(page);
        setAllTransaction(res.data.datas);
        setTotalCountR(res.data.total);
      } catch (error) {
        console.log(error);
      }
    },
    [enterpriseData, filterGroupR, currentPageR]
  );

  const getGbStatus = async () => {
    const query = `$filter=(statusCategories in ('4'))`;

    try {
      const res = await defaultApi.getGbStatus(query);
      const { data } = res;

      setCropStatus(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllEnterpriseSupply = useCallback(async () => {
    if (!enterpriseData?.id) return;

    const { parcelId, plant, atDate, toDate, firstname, lastname } =
      filterGroupL;

    let enterpriseId = enterpriseData?.id;
    const formatQry = (obj) => {
      const checkObj = clearObj({
        parcelId: obj.parcelId,
        plant: +obj.plant,
        atDate: obj.atDate,
        toDate: obj.toDate,
        firstname: obj.firstname,
        lastname: obj.lastname,
        cropStatus: obj.cropStatus,
      });
      if (Object.keys(clearObj(checkObj)).length === 0) {
        return "";
      } else {
        let filterParams = [];
        for (const [key, value] of Object.entries(clearObj(checkObj))) {
          filterParams.push(`${key}=${value}`);
        }
        return filterParams.join("&");
      }
    };
    try {
      const res = await defaultApi.getAllEnterpriseSupply(
        enterpriseId,
        formatQry(filterGroupL)
      );
      const getParcelId = res.data.enterpriseSupply?.map((item) => {
        return { value: item.parcelId, label: item.parcelId };
      });
      // Use filter and indexOf to remove duplicates
      const uniqueParcelId = getParcelId?.filter((item, index, self) => {
        return (
          index ===
          self.findIndex(
            (t) => t.value === item.value && t.label === item.label
          )
        );
      });

      setAllEnterpriseSupply(res.data.enterpriseSupply);
      setCurrentPageL(1);
      if (
        parcelId === "" &&
        plant === "" &&
        atDate === "" &&
        toDate === "" &&
        firstname === "" &&
        lastname === ""
      ) {
        setParcelIdOpt(uniqueParcelId);
      }

      if (res.data.enterpriseSupply?.length === 0) {
        toast.error(Message.notify.search_not_found, { duration: 3000 });
      }
    } catch (error) {
      console.log(error);
    }
  }, [enterpriseData, filterGroupL]);
  const getAllPlantType = async () => {
    try {
      const res = await defaultApi.getAllPlantType();
      setAllPlant(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllTransaction = useCallback(async () => {
    if (!enterpriseData?.id) return;
    if (currentPageR > 1) return;

    let enterpriseId = enterpriseData?.id;
    const loading = toast.loading(Message.notify.loading);
    const qryGetAll = {
      $count: "%24count=true",
      $expand: "&%24expand=contractFarming%2C%20enterprise",
      $top: "&%24top=10",
      $skip: "&%24skip=0",
      $filter: `&%24filter=enterpriseId%20eq%20${enterpriseId}and%20note%20eq%20%27buy%27`,
      $orderby: "&%24orderby=createdAt desc",
    };
    const _queryAll = QueryGetAll(qryGetAll);
    try {
      const res = await defaultApi.getAllTransaction(_queryAll);
      // all data set at state
      setAllTransaction(res.data.datas);
      // all total set at state
      setTotalCountR(res.data.total);
      // set currentPage state แบบมีเงื่อนไข
      setCurrentPageR(1);
    } catch (error) {
      console.log(error);
    } finally {
      toast.dismiss(loading);
    }
  }, [enterpriseData, currentPageR]);

  /* Filter data table R : START */
  const filterDataTableR = useCallback(async () => {
    if (!filterGroupR.active) return;
    console.log("Filter active");

    let enterpriseId = enterpriseData?.id;
    const loading = toast.loading(Message.notify.search_loading);
    const qryGetAll = {
      $count: "%24count=true",
      $expand: "&%24expand=contractFarming%2C%20enterprise",
      $top: "&%24top=10",
      $skip: "&%24skip=0",
      $filter: "",
      $orderby: "&%24orderby=createdAt desc",
    };
    const _queryAll = QueryGetAll(qryGetAll);
    const formatQry = (obj) => {
      const checkObj = clearObj({
        paymentStatus: obj.paymentStatus,
        txDate: obj.atDate,
        txDate2: obj.toDate,
      });
      if (Object.keys(clearObj(checkObj)).length === 0) {
        return `${QueryGetAll(
          qryGetAll
        )}&%24filter=enterpriseId%20eq%20${enterpriseId}
        and%20note%20eq%20%27buy%27`;
      } else {
        let objLng = Object.keys(clearObj(checkObj)).length;
        let count = 0;
        let filter1 = "";
        let filter2 = "";
        let filter3 = "";
        for (const [key, value] of Object.entries(clearObj(checkObj))) {
          count++;
          // # แบ่งเป็น 2 เคส #

          // 1 # มี objkey ตัวเดียว #
          if (objLng <= 1) {
            if (key === "txDate") {
              filter1 = `${key}%20ge%20${value}`;
            } else if (key === "txDate2") {
              filter1 = `txDate%20le%20${value}`;
            } else {
              filter1 = `${key}%20eq%20%27${value}%27`;
            }
          } else {
            // 2 # มี objkey มากกว่า 1 ตัว #
            // ตัวแรก
            if (count === 1) {
              if (key === "txDate") {
                filter1 = `${key}%20ge%20${value}%20and%20`;
              } else if (key === "txDate2") {
                filter1 = `txDate%20le%20${value}%20and%20`;
              } else {
                filter1 = `${key}%20eq%20%27${value}%27%20and%20`;
              }
            }
            // ตัวสุดท้าย
            else if (count === objLng) {
              if (key === "txDate") {
                filter3 = `${key}%20ge%20${value}`;
              } else if (key === "txDate2") {
                filter3 = `txDate%20le%20${value}`;
              } else {
                filter3 = `${key}%20eq%20%27${value}%27`;
              }
            }
            // ตัวอื่นๆ
            else {
              if (key === "txDate") {
                filter2 = `${key}%20ge%20${value}%20and%20`;
              } else if (key === "txDate2") {
                filter2 = `txDate%20le%20${value}%20and%20`;
              } else {
                filter2 = `${key}%20eq%20%27${value}%27%20and%20`;
              }
            }
          }
        }
        return `${_queryAll}&%24filter=enterpriseId%20eq%20${enterpriseId}
        and%20note%20eq%20%27buy%27
        %20and%20${filter1}${filter2}${filter3}`;
      }
    };
    try {
      const res = await defaultApi.getAllTransaction(formatQry(filterGroupR));

      setAllTransaction(res.data.datas);
      setTotalCountR(res.data.total);
      setCurrentPageR(1);
      if (res.data.datas.length === 0) {
        toast.error(Message.notify.search_not_found, { duration: 3000 });
      }
    } catch (error) {
      console.log(error);
    } finally {
      toast.dismiss(loading);
      setFilterGroupR({ ...filterGroupR, active: false });
    }
  }, [filterGroupR, enterpriseData]);
  /* Filter data table R : END */

  /* get data + filter : START */
  const getAllTransactionFilter = useCallback(async () => {
    let enterpriseId = enterpriseData?.id;
    const qryGetAll = {
      $count: "%24count=true",
      $expand: "&%24expand=contractFarming%2C%20enterprise",
      $top: "&%24top=10",
      $skip: `&%24skip=${currentPageR <= 1 ? "0" : currentPageR * 10 - 10}`,
      $filter: "",
      $orderby: "&%24orderby=createdAt desc",
    };
    const _queryAll = QueryGetAll(qryGetAll);
    const formatQry = (obj) => {
      const checkObj = clearObj({
        paymentStatus: obj.paymentStatus,
        atDate: obj.atDate,
        atDate2: obj.toDate,
      });
      if (Object.keys(clearObj(checkObj)).length === 0) {
        return `${QueryGetAll(
          qryGetAll
        )}&%24filter=enterpriseId%20eq%20${enterpriseId}
        and%20note%20eq%20%27buy%27`;
      } else {
        let objLng = Object.keys(clearObj(checkObj)).length;
        let count = 0;
        let filter1 = "";
        let filter2 = "";
        let filter3 = "";
        for (const [key, value] of Object.entries(clearObj(checkObj))) {
          count++;
          // # แบ่งเป็น 2 เคส #

          // 1 # มี objkey ตัวเดียว #
          if (objLng <= 1) {
            if (key === "atDate") {
              filter1 = `${key}%20ge%20${value}`;
            } else if (key === "atDate2") {
              filter1 = `atDate%20le%20${value}`;
            } else {
              filter1 = `${key}%20eq%20%27${value}%27`;
            }
          } else {
            // 2 # มี objkey มากกว่า 1 ตัว #
            // ตัวแรก
            if (count === 1) {
              if (key === "atDate") {
                filter1 = `${key}%20ge%20${value}%20and%20`;
              } else if (key === "atDate2") {
                filter1 = `atDate%20le%20${value}%20and%20`;
              } else {
                filter1 = `${key}%20eq%20%27${value}%27%20and%20`;
              }
            }
            // ตัวสุดท้าย
            else if (count === objLng) {
              if (key === "atDate") {
                filter3 = `${key}%20ge%20${value}`;
              } else if (key === "atDate2") {
                filter3 = `atDate%20le%20${value}`;
              } else {
                filter3 = `${key}%20eq%20%27${value}%27`;
              }
            }
            // ตัวอื่นๆ
            else {
              if (key === "atDate") {
                filter2 = `${key}%20ge%20${value}%20and%20`;
              } else if (key === "atDate2") {
                filter2 = `atDate%20le%20${value}%20and%20`;
              } else {
                filter2 = `${key}%20eq%20%27${value}%27%20and%20`;
              }
            }
          }
        }
        return `${_queryAll}&%24filter=enterpriseId%20eq%20${enterpriseId}
        and%20note%20eq%20%27buy%27
        %20and%20${filter1}${filter2}${filter3}`;
      }
    };
    try {
      const res = await defaultApi.getAllTransaction(formatQry(filterGroupR));
      // all data set at state
      setAllTransaction(res.data.datas);
      // all total set at state
      setTotalCountR(res.data.total);
      // set currentPage state
      setCurrentPageR(1);
    } catch (error) {
      console.log(error);
    }
  }, [enterpriseData, filterGroupR, currentPageR]);
  /* get data + filter : END */

  useEffect(() => {
    if (filterGroupR.atDate === "" && filterGroupR.toDate === "") {
      getAllTransaction();
    } else if (filterGroupR.atDate !== "" || filterGroupR.toDate !== "") {
      filterDataTableR();
    }
  }, [filterGroupR, getAllTransaction, filterDataTableR]);

  useEffect(() => {
    // Use functions
    getAllEnterpriseSupply();
  }, [getAllEnterpriseSupply]);
  useEffect(() => {
    /* Use Functions */
    getGbStatus();
    getAllPlantType();
  }, []);

  useEffect(() => {
    /* Function close dropdown 'DateStartL and DateEndL' : START */
    const onCloseDropdownDateStartL = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#ent_date_start_l_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenDateStartL(false);
      }
    };
    const onCloseDropdownDateEndL = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#ent_date_end_l_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenDateEndL(false);
      }
    };

    if (openDateStartL) {
      document.addEventListener("click", onCloseDropdownDateStartL);
    }
    if (openDateEndL) {
      document.addEventListener("click", onCloseDropdownDateEndL);
    }
    /* Function close dropdown 'DateStartL and DateEndL' : END */

    /* Function close dropdown 'DateStartR and DateEndR' : START */
    const onCloseDropdownDateStartR = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#ent_start_date_r_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenStartDate(false);
      }
    };
    const onCloseDropdownDateEndR = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#ent_end_date_r_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenEndDate(false);
      }
    };

    if (openStartDate) {
      document.addEventListener("click", onCloseDropdownDateStartR);
    }
    if (openEndDate) {
      document.addEventListener("click", onCloseDropdownDateEndR);
    }
    /* Function close dropdown 'DateStartR and DateEndR' : END */

    return () => {
      document.removeEventListener("click", onCloseDropdownDateStartL);
      document.removeEventListener("click", onCloseDropdownDateEndL);
      document.removeEventListener("click", onCloseDropdownDateStartR);
      document.removeEventListener("click", onCloseDropdownDateEndR);
    };
  }, [openDateStartL, openDateEndL, openStartDate, openEndDate]);

  return (
    <div className={`${styles.container}`}>
      {!_switch2 && switchPage === null && (
        <div className={`${styles.filter_group}`}>
          {!_switch && (
            <>
              <div className={`${styles.group_}`}>
                <Select
                  className={`${styles.multi_select}`}
                  closeMenuOnSelect={true}
                  isSearchable={true}
                  isClearable={true}
                  options={parcelIdOpt}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      borderRadius: "10px",
                      width: "200px",
                      textAlign: "center",
                    }),
                  }}
                  placeholder={"เลือกรหัสแปลง"}
                  onChange={(e) => {
                    const { value } = e ? e : "";

                    setFilterGroupL({ ...filterGroupL, parcelId: value });
                  }}
                />
              </div>
              <div className={`${styles.group_}`}>
                <input
                  type="search"
                  value={searchText}
                  onChange={searchInputL}
                  onFocus={() => setPlaceholderDisabled(true)}
                  onBlur={() => setPlaceholderDisabled(false)}
                  placeholder={placeholderDisabled ? "" : "ชื่อ นามสกุล"}
                />
              </div>
              <div className={`${styles.group_}`}>
                <select
                  onChange={(e) =>
                    setFilterGroupL({ ...filterGroupL, plant: e.target.value })
                  }
                >
                  <option value="">ชนิดเชื้อเพลิง / วัตถุดิบ</option>
                  {allPlant.map((item) => {
                    return (
                      <option
                        selected={
                          +filterGroupL.plant === item.id ? true : false
                        }
                        key={item.id}
                        value={item.id}
                      >
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className={`${styles.group_}`}>
                <select
                  onChange={(e) =>
                    setFilterGroupL({
                      ...filterGroupL,
                      cropStatus: e.target.value,
                    })
                  }
                >
                  <option value="">สถานะ</option>
                  {cropStatus.map((item) => {
                    return (
                      <option
                        selected={
                          filterGroupL.cropStatus === item.id ? true : false
                        }
                        key={item.id}
                        value={item.id}
                      >
                        {item.statusName}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className={`${styles.group_}`}>
                <div className={styles.input_unit}>
                  <div className={styles.info_box}>
                    <p>
                      {filterGroupL.atDate !== ""
                        ? convertDateThai(filterGroupL.atDate)
                        : "วันที่คาดว่าจะเก็บเกี่ยว"}
                    </p>
                    {filterGroupL.atDate !== "" && (
                      <GrFormClose
                        size={25}
                        cursor={"pointer"}
                        onClick={() => {
                          setFilterGroupL({ ...filterGroupL, atDate: "" });
                          setSelectedDate({
                            ...selectedDate,
                            dateStartL: new Date(),
                          });
                        }}
                      />
                    )}
                  </div>
                  <div
                    className={styles.unit}
                    onClick={() => {
                      setOpenDateStartL(!openDateStartL);
                    }}
                  >
                    <img src={icon.calendar} alt="" id="ent_date_start_l_d" />
                  </div>
                </div>
                {openDateStartL && (
                  <DatePicker
                    selected={selectedDate.dateStartL}
                    onChange={onChangeDateStartL}
                    inline
                  />
                )}
              </div>
              <label>ถึง :</label>
              <div className={`${styles.group_}`}>
                <div className={styles.input_unit}>
                  <div className={styles.info_box}>
                    <p>
                      {filterGroupL.toDate !== ""
                        ? convertDateThai(filterGroupL.toDate)
                        : "วันที่คาดว่าจะเก็บเกี่ยว"}
                    </p>
                    {filterGroupL.toDate !== "" && (
                      <GrFormClose
                        size={25}
                        cursor={"pointer"}
                        onClick={() => {
                          setFilterGroupL({ ...filterGroupL, toDate: "" });
                          setSelectedDate({
                            ...selectedDate,
                            dateEndL: new Date(),
                          });
                        }}
                      />
                    )}
                  </div>
                  <div
                    className={styles.unit}
                    onClick={() => {
                      setOpenDateEndL(!openDateEndL);
                    }}
                  >
                    <img src={icon.calendar} alt="" id="ent_date_end_l_d" />
                  </div>
                </div>
                {openDateEndL && (
                  <DatePicker
                    selected={selectedDate.dateEndL}
                    onChange={onChangeDateEndL}
                    inline
                  />
                )}
              </div>
            </>
          )}
          {_switch && (
            <>
              <div className={`${styles.filter_group}`}>
                <div className={`${styles.group_}`}>
                  <div className={`${styles.input_unit}`}>
                    <div className={`${styles.info_box}`}>
                      <p>
                        {filterGroupR.atDate !== ""
                          ? convertDateThai(filterGroupR.atDate)
                          : "วันที่"}
                      </p>
                      {filterGroupR.atDate !== "" && (
                        <GrFormClose
                          size={25}
                          cursor={"pointer"}
                          onClick={() => {
                            setFilterGroupR({ ...filterGroupR, atDate: "" });
                            setSelectedDate({
                              ...selectedDate,
                              startDate: new Date(),
                            });
                          }}
                        />
                      )}
                    </div>
                    <div
                      className={`${styles.unit}`}
                      onClick={() => {
                        setOpenStartDate(!openStartDate);
                      }}
                    >
                      <img src={icon.calendar} alt="" id="ent_start_date_r_d" />
                    </div>
                  </div>
                  {openStartDate && (
                    <DatePicker
                      selected={selectedDate.startDate}
                      onChange={onChangeAtDate}
                      inline
                    />
                  )}
                </div>
                <label>ถึง :</label>
                <div className={`${styles.group_}`}>
                  <div className={`${styles.input_unit}`}>
                    <div className={`${styles.info_box}`}>
                      <p>
                        {filterGroupR.toDate !== ""
                          ? convertDateThai(filterGroupR.toDate)
                          : "วันที่"}
                      </p>
                      {filterGroupR.toDate !== "" && (
                        <GrFormClose
                          size={25}
                          cursor={"pointer"}
                          onClick={() => {
                            setFilterGroupR({
                              ...filterGroupR,
                              toDate: "",
                              active: false,
                            });
                            setSelectedDate({
                              ...selectedDate,
                              endDate: new Date(),
                            });
                          }}
                        />
                      )}
                    </div>
                    <div
                      className={`${styles.unit}`}
                      onClick={() => {
                        setOpenEndDate(!openEndDate);
                      }}
                    >
                      <img src={icon.calendar} alt="" id="ent_end_date_r_d" />
                    </div>
                  </div>
                  {openEndDate && (
                    <DatePicker
                      selected={selectedDate.endDate}
                      onChange={onChangeToDate}
                      inline
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      )}

      <h4 className={`${styles.title_text}`}>{enterpriseData?.name}</h4>

      <SwitchMenu
        onSwitch={onSwitch}
        _onSwitch2={onSwitch2}
        _onSwitchPage={onSwitchPage}
        btn1={"แผนผลการผลิตเชื้อเพลิง"}
        btn2={"การขายเชื้อเพลิง"}
      />

      <div className={`${styles.content_}`}>
        {!_switch && (
          <div className={`${styles.left_}`}>
            <Production
              _allEnterpriseSupply={allEnterpriseSupply}
              _totalCount={allEnterpriseSupply?.length}
              _currentPage={currentPageL}
              _onPaginate={(page) => setCurrentPageL(page)}
              _obj={{
                enterpriseId: enterpriseData?.id,
                plantTypeId: filterGroupL.plant,
                atDate: filterGroupL.atDate,
                toDate: filterGroupL.toDate,
                firstname: filterGroupL.firstname,
                lastname: filterGroupL.lastname,
                parcelId: filterGroupL.parcelId,
              }}
              _cropStatus={cropStatus}
              _themeColor={_themeColor}
            />
          </div>
        )}
        {_switch && (
          <div className={`${styles.right_}`}>
            {!_switch2 ? (
              <Sale
                _allTransaction={allTransaction}
                _allPlant={allPlant}
                _onPaginate={onPaginateR}
                _filterGroupR={filterGroupR}
                _totalCountR={totalCountR}
                _currentPageR={currentPageR}
                _onSwitch2={onSwitch2}
                _switchPage={switchPage}
                _onSwitchPage={onSwitchPage}
                _fetchData={getAllTransactionFilter}
                _themeColor={_themeColor}
              />
            ) : (
              <AddSale
                _onSwitch2={onSwitch2}
                _fetchData={getAllTransactionFilter}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default RawMaterial;
