import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import InfoList from "./InfoList";
import InfoBox from "./InfoBox";
import Footer from "../../layouts/footer_1/Footer";
import ChartsIncome from "./charts/ChartsIncome";
import ChartsPurchase from "./charts/ChartsPurchase";
import ChartsProduction from "./charts/ChartsProduction";
import ChartsPowerGene from "./charts/ChartsPowerGene";
import logo from "../../assets/img/Logo";
import { defaultApi } from "../../services/api";
import { encodeData } from "../../helpers/encode_decode.helper";
import { setCookiesStorageData } from "../../helpers/cookies_storage.helper";
import Message from "../../utils/message/Message";
import { QueryGetAll } from "../../helpers/api.helper";
import { setProfileData } from "../../services/auth.slice";
import styles from "./scss/Index.module.scss";

const Index = () => {
  const dispatchFn_ = useDispatch();
  const navigate = useNavigate();

  const date = new Date();
  //let month = date.getMonth() + 1;
  let year = date.getFullYear();

  //let _selYear = year
  //const [_selYear, selYear] = useState(year)
  const [_itemResponse, itemResponse] = useState();
  const [_itemResponse2, itemResponse2] = useState();

  const [_fstLoad, fstLoad] = useState(true);

  const [_lstYear, lstYear] = useState();

  const [_selYears, selYears] = useState(year);

  const [_color, Setcolor] = useState({});

  const yearDefault = useCallback((year) => {
    //let sourceYear = _selYear
    let sourceYear = year;
    let arrYear = [];
    for (let index = 0; index < 5; index++) {
      arrYear[index] = { year: sourceYear, yearName: Number(sourceYear + 543) };
      sourceYear = sourceYear - 1;
    }
    lstYear(arrYear);
  }, []);

  const _getProfile = async () => {
    const qryGetAll = {
      $count: "",
      $expand: `%24expand=userGroup%2CuserLocalGroup%2C
      avatarNavigation%2CuserOrg`,
      $top: "",
      $skip: "",
      $filter: "",
      $orderby: "",
    };
    const _queryAll = QueryGetAll(qryGetAll);
    try {
      const res = await defaultApi.getProfile(_queryAll);
      setCookiesStorageData("userProfile", res.data);
      dispatchFn_(setProfileData(res.data));
    } catch (error) {
      console.log(error);
    }
  };

  const onLoginGuest = async () => {
    const loading = toast.loading(Message.notify.loading);
    const param = {
      username: process.env.REACT_APP_ID_GUEST,
      password: process.env.REACT_APP_PASS_GUEST,
    };
    try {
      const res = await defaultApi.login(param);
      const { data } = res;
      const token = encodeData(data?.token);
      // Set Cookies
      setCookiesStorageData("token", token);
      _getProfile();
      // Direct to
      navigate("/home");
    } catch (error) {
      console.log(error);
    } finally {
      toast.dismiss(loading);
    }
  };

  const infomationdata = useCallback(async (_selYear) => {
    try {
      console.log();
      const color = await defaultApi.getColors();
      Setcolor(color);
      const res = await defaultApi.getReportDashboard(
        "powergenYear=" +
          _selYear +
          "&factoryTransactionYear=" +
          _selYear +
          "&enterpriseTransactionYear=" +
          _selYear
      );
      
      itemResponse(res.data);
      selYears(_selYear);
    } catch (error) {}
  }, []);

  const infomationdataDf = useCallback(async (year) => {
    try {
      const color = await defaultApi.getColors();
      Setcolor(color);
      const res = await defaultApi.getReportDashboard(
        "powergenYear=" +
          year +
          "&factoryTransactionYear=" +
          year +
          "&enterpriseTransactionYear=" +
          year
      );
      itemResponse2(res.data);
    } catch (error) {}
  }, []);

  const setYear = (val) => {
    infomationdata(val);
  };

  useEffect(() => {
    if (_fstLoad === true) {
      infomationdata(year);
      infomationdataDf(year);
      yearDefault(year);
      fstLoad(false);
    }
  }, [_fstLoad, infomationdata, infomationdataDf, yearDefault, year]);
  //

  return (
    <div className={`background_1  ${styles.container}`}>
      <h1 className="title text-center">โรงไฟฟ้าชุมชนเพื่อเศรษฐกิจฐานราก</h1>
      {/* box นอก */}
      <div className="container_content">
        <section className={`section ${styles.section_}`}>
          {/* box ใน */}
          <article className={`article ${styles.article_} row`}>
            {/* content ฝั่งซ้าย */}
            <aside className="col-6 border-end">
              <InfoBox valueInput={_itemResponse2} selYear={year} />
              <ChartsPowerGene
                valueInput={_itemResponse2}
                color={_color}
                selYear={year}
              />
              <ChartsPurchase
                valueInput={_itemResponse2}
                color={_color}
                selYear={year}
              />
            </aside>
            {/* content ฝั่งขวา */}
            <aside className="col-6">
              <InfoList valueInput={_itemResponse2} selYear={year} />
              <ChartsProduction
                valueInput={_itemResponse2}
                color={_color}
                selYear={year}
              />
              <ChartsIncome
                valueInput={_itemResponse}
                yearList={_lstYear}
                setYear={setYear}
                selYear={_selYears}
              />
            </aside>
          </article>
          <div className={`${styles.login_register}`}>
            <img src={logo.energyLogo} alt="logo_energy" height="55" />
            <div className="d-flex" style={{ gap: "10px" }}>
              <button
                type="button"
                className="button_sm"
                onClick={onLoginGuest}
              >
                ค้นหาโรงไฟฟ้า
              </button>
              <button
                type="button"
                className="button_sm"
                onClick={() => navigate("/register")}
              >
                ลงทะเบียน
              </button>
              <button
                type="button"
                className="button_sm"
                onClick={() => navigate("/login")}
              >
                เข้าสู่ระบบ
              </button>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Index;
