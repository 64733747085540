import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { getProfileData } from "../../../services/auth.slice";
import { defaultApi } from "../../../services/api";
import { QueryGetAll } from "../../../helpers/api.helper";
import { useSelector } from "react-redux";
import ChartsResourceHarvestFactory from "./charts/ChartsResourceHarvestFactory";
import { convertDateThai2 } from "../../../utils/format/Date.format";
import styles from "./scss/ReportResourceHarvestFactory.module.scss";

const ReportResourceHarvestFactory = () => {
  const profileData = useSelector(getProfileData);
  const chart = useRef();
  const [_setresFac, resFac] = useState([]);
  const [_setkeyFac, keyFac] = useState({
    facId: 0,
    entId: 0,
    month: "",
    year: "",
  });

  window.ChartTitleUnit = {
    title: '',
    unit: ['']
  }

  const [_entList, setEntList] = useState([]);
  const [_resName, resName] = useState("");

  const resApi = useCallback(async () => {
    let entList = [];

    let qryGetAll = {
      $count: "%24count=true",
      $expand: "",
      $top: "",
      $skip: "",
      $filter: `&%24filter=createdBy%20eq%20${profileData.id}`,
      $orderby: "",
    };

    const resAllFac = await defaultApi.getAllFactoryMember(``);

    if (resAllFac?.data?.length > 0) {
      for (let index = 0; index < resAllFac?.data?.length; index++) {
        const element = resAllFac?.data[index];
        if (+element?.userId === +profileData?.id) {
          qryGetAll = {
            $count: "%24count=true",
            $expand: "",
            $top: "",
            $skip: "",
            $filter: `&%24filter=id%20eq%20${element.factoryId}`,
            $orderby: "",
          };
        }
      }
    }

    const _query01 = QueryGetAll(qryGetAll);
    const res_fact = await defaultApi.getAllFactory(_query01);
    const res_fact_enpt = await defaultApi.getAllEnterprise(``);
    const resData01 = res_fact?.data?.datas;
    const resData02 = res_fact_enpt?.data;

    if (resData01?.length > 0 && resData02?.length > 0) {
      let i = 0;
      for (let index = 0; index < resData02?.length; index++) {
        const element = resData02[index];
        if (+resData01[0]?.id === +element?.factoryId) {
          entList[i] = {
            id: element?.id,
            name: element?.name,
          };
          i++;
        }
      }
    }

    setEntList(entList);
    resName(resData01[0]?.name);
    keyFac((prev) => ({
      ...prev,
      facId: resData01[0]?.id,
      entId: entList[0]?.id,
    }));
  }, [profileData]);

  const getHarvestTarget = useCallback(async () => {
    const dateTime = new Date();
    const yearX = dateTime.getFullYear();
    const monthX = dateTime.getMonth() + 1;
    // const param = `enterpriseId=${_setkeyFac?.entId}&atDate=${_setkeyFac?.year || yearX}-${_setkeyFac?.month || monthX}-01`
    const param = `enterpriseId=${_setkeyFac?.entId}`;
    const res_data = await defaultApi.getTargetHarvest(param);
    resFac(res_data?.data);
  }, [_setkeyFac]);

  const dateMonth = () => {
    let monlst = [
      "",
      "ม.ค.",
      "ก.พ.",
      "มี.ค.",
      "เม.ย.",
      "พ.ค.",
      "มิ.ย.",
      "ก.ค.",
      "ส.ค.",
      "ก.ย.",
      "ต.ค.",
      "พ.ย.",
      "ธ.ค.",
    ];

    let month = [];

    for (let index = 0; index <= 12; index++) {
      let num = "";
      index < 10 ? (num = "0" + index) : (num = index);
      month[index] = { month: num.toString(), monthName: monlst[index] };
    }

    let optMonths = month.map((data, index) => {
      let i;
      if (index >= 1) {
        i = (
          <option key={index} value={data.month}>
            {data.monthName}
          </option>
        );
      }
      return i;
    });

    return (
      <>
        <option key={`df_0`} style={{ display: "none" }}>
          เดือน
        </option>
        {optMonths}
      </>
    );
  };

  const dateYear = () => {
    let dt = new Date();
    let year = dt.getFullYear();
    let years = [];

    for (let index = 0; index < 10; index++) {
      years[index] = year;
      year--;
    }

    let optYear = years.map((data, index) => {
      return (
        <option key={index} value={data}>
          {data + 543}
        </option>
      );
    });

    return (
      <>
        <option key={`df_0`} style={{ display: "none" }}>
          ปี
        </option>
        {optYear}
      </>
    );
  };

  const entpList = (value) => {
    let arrLst = [];
    if (value?.length > 0) {
      for (let index = 0; index < value?.length; index++) {
        const element = value[index];
        arrLst[index] = <option value={element?.id}>{element?.name}</option>;
      }
    }
    return arrLst;
  };

  const dateDefault = useMemo(() => {
    const date = new Date();

    return convertDateThai2(date);
  }, []);

  useEffect(() => {
    resApi();
  }, [resApi]);

  useEffect(() => {
    getHarvestTarget();
  }, [getHarvestTarget]);

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.date_filter}`}>
        <select
          style={{ color: "black" }}
          name=""
          id="sel01"
          onChange={(e) => {
            keyFac({ ..._setkeyFac, entId: e.target.value });
          }}
        >
          {entpList(_entList)}
        </select>
        {/* <select style={{ color: 'black' }} name="" id="sel01" onChange={(e) => { keyFac({ ..._setkeyFac, month: e.target.value }) }}>
          {
            dateMonth()
          }
        </select>
        <select style={{ color: 'black' }} name="" id="sel02" onChange={(e) => { keyFac({ ..._setkeyFac, year: e.target.value }) }}>
          {
            dateYear()
          }
        </select> */}
      </div>

      <h4 className={`${styles.title_text}`}>วัตถุดิบที่คาดว่าจะเก็บเกี่ยว</h4>
      <h5 className={`${styles.title_text_}`}>{_resName}</h5>

      <div className={`${styles.show_date}`}>
        <p>ข้อมูลล่าสุด ณ วันที่</p>
        <p>{dateDefault}</p>
      </div>

      <div className={`${styles.charts_}`}>
        <ChartsResourceHarvestFactory ref={chart} datain={_setresFac} />
      </div>
    </div>
  );
};

export default ReportResourceHarvestFactory;
