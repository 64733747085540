import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { FiSquare } from "react-icons/fi";
import { GrFormClose } from "react-icons/gr";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "../../../../../../components/pagination/Pagination";
import {
  exportCsvFile,
  exportXlsxFile,
} from "../../../../../../helpers/func.helper";
import { getFactoryData } from "../../../../../../services/factory.slice";
import { QueryGetAll } from "../../../../../../helpers/api.helper";
import { defaultApi } from "../../../../../../services/api";
import Message from "../../../../../../utils/message/Message";
import {
  convertDateEu,
  convertDateThai,
} from "../../../../../../utils/format/Date.format";
import icon from "../../../../../../assets/img/Icon";
import { clearObj } from "../../../../../../utils/format/Object.format";
import ExportPopup from "../../../../../../layouts/exportpopup/ExportPopup";

const pageSize = 10;

const PurchaseRecord = ({ onSwitchBoxL }) => {
  const factoryData = useSelector(getFactoryData);

  const [allTransaction, setAllTransaction] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [_paymentStatus, setPaymentStatus] = useState([]);
  const [filterGroup, setFilterGroup] = useState({
    paymentStatus: "",
    atDate: "",
    toDate: "",
    active: false,
  });
  const [selectedDate, setSelectedDate] = useState({
    atDate: new Date(),
    toDate: new Date(),
  });
  const [openAtDate, setOpenAtDate] = useState(false);
  const [openToDate, setOpenToDate] = useState(false);
  const [_triggerExport, setTriggerExport] = useState(false);

  const _statusName = useMemo(() => {
    const statusReceived = _paymentStatus.find(
      (item) => item.id === "received"
    )?.id;
    const statusUnreceived = _paymentStatus.find(
      (item) => item.id === "unreceived"
    )?.id;

    return { received: statusReceived, unreceived: statusUnreceived };
  }, [_paymentStatus]);

  /* DatePiceker : START */
  const onChangeAtDate = (e) => {
    const dateFormat = convertDateEu(e);
    setFilterGroup({ ...filterGroup, atDate: dateFormat, active: true });
    setSelectedDate({ ...selectedDate, atDate: e });
    setOpenAtDate(!openAtDate);
  };
  const onChangeToDate = (e) => {
    const dateFormat = convertDateEu(e);
    setFilterGroup({ ...filterGroup, toDate: dateFormat, active: true });
    setSelectedDate({ ...selectedDate, toDate: e });
    setOpenToDate(!openToDate);
  };
  /* DatePiceker : END */

  /* Export csv and excel : START */
  const onExportCsv = () => {
    // ข้อมูลที่ต้องส่งไปให้ ฟังก์ชั่น ใช้งาน
    let exportName = "FactoryTransactionReport";
    let fileType = "csv";
    let obj = {
      factoryId: factoryData?.id,
      paymentStatus: filterGroup.paymentStatus,
      atDate: filterGroup.atDate,
      toDate: filterGroup.toDate,
      note: "buy",
    };
    // Ready
    exportCsvFile(exportName, fileType, obj);
  };
  const onExportXlsx = () => {
    // ข้อมูลที่ต้องส่งไปให้ ฟังก์ชั่น ใช้งาน
    let exportName = "FactoryTransactionReport";
    let fileType = "excel";
    let obj = {
      factoryId: factoryData?.id,
      paymentStatus: filterGroup.paymentStatus,
      atDate: filterGroup.atDate,
      toDate: filterGroup.toDate,
      note: "buy",
    };
    // Ready
    exportXlsxFile(exportName, fileType, obj);
  };
  /* Export csv and excel : END */

  const onPaginate = useCallback(
    async (page) => {
      if (currentPage === page) return;

      let factoryId = factoryData?.id;
      const qryGetAll = {
        $count: "%24count=true",
        $expand: "&%24expand=plantType",
        $top: "&%24top=10",
        $skip: `&%24skip=${page <= 1 ? "0" : page * 10 - 10}`,
        $filter: "",
        $orderby: "&%24orderby=createdAt desc",
      };
      const _queryAll = QueryGetAll(qryGetAll);
      const formatQry = (obj) => {
        const checkObj = clearObj({
          paymentStatus: obj.paymentStatus,
          atDate: obj.atDate,
          atDate2: obj.toDate,
        });
        if (Object.keys(clearObj(checkObj)).length === 0) {
          return `${QueryGetAll(
            qryGetAll
          )}&%24filter=factoryId%20eq%20${factoryId}
          and%20note%20eq%20%27buy%27`;
        } else {
          let objLng = Object.keys(clearObj(checkObj)).length;
          let count = 0;
          let filter1 = "";
          let filter2 = "";
          let filter3 = "";
          for (const [key, value] of Object.entries(clearObj(checkObj))) {
            count++;
            // # แบ่งเป็น 2 เคส #

            // 1 # มี objkey ตัวเดียว #
            if (objLng <= 1) {
              if (key === "atDate") {
                filter1 = `${key}%20ge%20${value}`;
              } else if (key === "atDate2") {
                filter1 = `atDate%20le%20${value}`;
              } else {
                filter1 = `${key}%20eq%20%27${value}%27`;
              }
            } else {
              // 2 # มี objkey มากกว่า 1 ตัว #
              // ตัวแรก
              if (count === 1) {
                if (key === "atDate") {
                  filter1 = `${key}%20ge%20${value}%20and%20`;
                } else if (key === "atDate2") {
                  filter1 = `atDate%20le%20${value}%20and%20`;
                } else {
                  filter1 = `${key}%20eq%20%27${value}%27%20and%20`;
                }
              }
              // ตัวสุดท้าย
              else if (count === objLng) {
                if (key === "atDate") {
                  filter3 = `${key}%20ge%20${value}`;
                } else if (key === "atDate2") {
                  filter3 = `atDate%20le%20${value}`;
                } else {
                  filter3 = `${key}%20eq%20%27${value}%27`;
                }
              }
              // ตัวอื่นๆ
              else {
                if (key === "atDate") {
                  filter2 = `${key}%20ge%20${value}%20and%20`;
                } else if (key === "atDate2") {
                  filter2 = `atDate%20le%20${value}%20and%20`;
                } else {
                  filter2 = `${key}%20eq%20%27${value}%27%20and%20`;
                }
              }
            }
          }
          return `${_queryAll}&%24filter=factoryId%20eq%20${factoryId}
          and%20note%20eq%20%27buy%27
          %20and%20${filter1}${filter2}${filter3}`;
        }
      };
      try {
        const res = await defaultApi.getAllTransaction(formatQry(filterGroup));

        setAllTransaction(res.data.datas);
        setTotalCount(res.data.total);
        setCurrentPage(page);
      } catch (error) {
        console.log(error);
      }
    },
    [factoryData, filterGroup, currentPage]
  );

  /* Filter data table  : START */
  const filterDataTable = useCallback(async () => {
    if (!filterGroup.active) return;
    console.log("Filter active");

    let factoryId = factoryData?.id;
    const loading = toast.loading(Message.notify.search_loading);
    const qryGetAll = {
      $count: "%24count=true",
      $expand: "&%24expand=plantType",
      $top: "&%24top=10",
      $skip: "&%24skip=0",
      $filter: "",
      $orderby: "&%24orderby=createdAt desc",
    };
    const _queryAll = QueryGetAll(qryGetAll);
    const formatQry = (obj) => {
      const checkObj = clearObj({
        paymentStatus: obj.paymentStatus,
        atDate: obj.atDate,
        atDate2: obj.toDate,
      });
      if (Object.keys(clearObj(checkObj)).length === 0) {
        return `${QueryGetAll(
          qryGetAll
        )}&%24filter=factoryId%20eq%20${factoryId}
        and%20note%20eq%20%27buy%27`;
      } else {
        let objLng = Object.keys(clearObj(checkObj)).length;
        let count = 0;
        let filter1 = "";
        let filter2 = "";
        let filter3 = "";
        for (const [key, value] of Object.entries(clearObj(checkObj))) {
          count++;
          // # แบ่งเป็น 2 เคส #

          // 1 # มี objkey ตัวเดียว #
          if (objLng <= 1) {
            if (key === "atDate") {
              filter1 = `${key}%20ge%20${value}`;
            } else if (key === "atDate2") {
              filter1 = `atDate%20le%20${value}`;
            } else {
              filter1 = `${key}%20eq%20%27${value}%27`;
            }
          } else {
            // 2 # มี objkey มากกว่า 1 ตัว #
            // ตัวแรก
            if (count === 1) {
              if (key === "atDate") {
                filter1 = `${key}%20ge%20${value}%20and%20`;
              } else if (key === "atDate2") {
                filter1 = `atDate%20le%20${value}%20and%20`;
              } else {
                filter1 = `${key}%20eq%20%27${value}%27%20and%20`;
              }
            }
            // ตัวสุดท้าย
            else if (count === objLng) {
              if (key === "atDate") {
                filter3 = `${key}%20ge%20${value}`;
              } else if (key === "atDate2") {
                filter3 = `atDate%20le%20${value}`;
              } else {
                filter3 = `${key}%20eq%20%27${value}%27`;
              }
            }
            // ตัวอื่นๆ
            else {
              if (key === "atDate") {
                filter2 = `${key}%20ge%20${value}%20and%20`;
              } else if (key === "atDate2") {
                filter2 = `atDate%20le%20${value}%20and%20`;
              } else {
                filter2 = `${key}%20eq%20%27${value}%27%20and%20`;
              }
            }
          }
        }
        return `${_queryAll}&%24filter=factoryId%20eq%20${factoryId}
        and%20note%20eq%20%27buy%27
        %20and%20${filter1}${filter2}${filter3}`;
      }
    };
    try {
      const res = await defaultApi.getAllTransaction(formatQry(filterGroup));
      // all data set at state
      setAllTransaction(res.data.datas);
      // all total set at state
      setTotalCount(res.data.total);
      // set currentPage state
      setCurrentPage(1);
      // response array = 0
      if (res.data.datas.length === 0) {
        toast.error(Message.notify.search_not_found, { duration: 3000 });
      }
    } catch (error) {
      console.log(error);
    } finally {
      toast.dismiss(loading);
      setFilterGroup({ ...filterGroup, active: false });
    }
  }, [factoryData, filterGroup]);
  /* Filter data table : END */

  const getAllTransaction = useCallback(async () => {
    if (!factoryData?.id) return;
    if (currentPage > 1) return;

    let factoryId = factoryData?.id;
    const loading = toast.loading(Message.notify.loading);
    const qryGetAll = {
      $count: "%24count=true",
      $expand: "&%24expand=plantType",
      $top: "&%24top=10",
      $skip: "&%24skip=0",
      $filter: `&%24filter=factoryId%20eq%20${factoryId}and%20note%20eq%20%27buy%27`,
      $orderby: "&%24orderby=createdAt desc",
    };
    const _queryAll = QueryGetAll(qryGetAll);
    try {
      const res = await defaultApi.getAllTransaction(_queryAll);
      // all data set at state
      setAllTransaction(res.data.datas);
      // all total set at state
      setTotalCount(res.data.total);
      // set currentPage state แบบมีเงื่อนไข
      setCurrentPage(1);
    } catch (error) {
      console.log(error);
    } finally {
      toast.dismiss(loading);
    }
  }, [factoryData, currentPage]);

  /* get data + filter : START */
  const getAllTransactionFilter = useCallback(async () => {
    let factoryId = factoryData?.id;
    const qryGetAll = {
      $count: "%24count=true",
      $expand: "&%24expand=plantType",
      $top: "&%24top=10",
      $skip: `&%24skip=${currentPage <= 1 ? "0" : currentPage * 10 - 10}`,
      $filter: "",
      $orderby: "&%24orderby=createdAt desc",
    };
    const _queryAll = QueryGetAll(qryGetAll);
    const formatQry = (obj) => {
      const checkObj = clearObj({
        paymentStatus: obj.paymentStatus,
        atDate: obj.atDate,
        atDate2: obj.toDate,
      });
      if (Object.keys(clearObj(checkObj)).length === 0) {
        return `${QueryGetAll(
          qryGetAll
        )}&%24filter=factoryId%20eq%20${factoryId}
        and%20note%20eq%20%27buy%27`;
      } else {
        let objLng = Object.keys(clearObj(checkObj)).length;
        let count = 0;
        let filter1 = "";
        let filter2 = "";
        let filter3 = "";
        for (const [key, value] of Object.entries(clearObj(checkObj))) {
          count++;
          // # แบ่งเป็น 2 เคส #

          // 1 # มี objkey ตัวเดียว #
          if (objLng <= 1) {
            if (key === "atDate") {
              filter1 = `${key}%20ge%20${value}`;
            } else if (key === "atDate2") {
              filter1 = `atDate%20le%20${value}`;
            } else {
              filter1 = `${key}%20eq%20%27${value}%27`;
            }
          } else {
            // 2 # มี objkey มากกว่า 1 ตัว #
            // ตัวแรก
            if (count === 1) {
              if (key === "atDate") {
                filter1 = `${key}%20ge%20${value}%20and%20`;
              } else if (key === "atDate2") {
                filter1 = `atDate%20le%20${value}%20and%20`;
              } else {
                filter1 = `${key}%20eq%20%27${value}%27%20and%20`;
              }
            }
            // ตัวสุดท้าย
            else if (count === objLng) {
              if (key === "atDate") {
                filter3 = `${key}%20ge%20${value}`;
              } else if (key === "atDate2") {
                filter3 = `atDate%20le%20${value}`;
              } else {
                filter3 = `${key}%20eq%20%27${value}%27`;
              }
            }
            // ตัวอื่นๆ
            else {
              if (key === "atDate") {
                filter2 = `${key}%20ge%20${value}%20and%20`;
              } else if (key === "atDate2") {
                filter2 = `atDate%20le%20${value}%20and%20`;
              } else {
                filter2 = `${key}%20eq%20%27${value}%27%20and%20`;
              }
            }
          }
        }
        return `${_queryAll}&%24filter=factoryId%20eq%20${factoryId}
        and%20note%20eq%20%27buy%27
        %20and%20${filter1}${filter2}${filter3}`;
      }
    };
    try {
      const res = await defaultApi.getAllTransaction(formatQry(filterGroup));
      // all data set at state
      setAllTransaction(res.data.datas);
      // all total set at state
      setTotalCount(res.data.total);
      // set currentPage state
      setCurrentPage(currentPage);
    } catch (error) {
      console.log(error);
    }
  }, [factoryData, filterGroup, currentPage]);
  /* get data + filter : END */

  const getGbStatus = async () => {
    const query = `%24filter=statusCategories%20eq%20%273%27`;

    try {
      const res = await defaultApi.getGbStatus(query);

      setPaymentStatus(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const updatePaymentStatus = async (id) => {
    if (!window.confirm(Message.notify.update_payment_status)) return;

    const param = { paymentStatus: _statusName.received };
    try {
      const res = await defaultApi.updatePaymentStatus(id, param);
      console.log(res.statusText);

      getAllTransactionFilter();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      filterGroup.atDate === "" &&
      filterGroup.toDate === "" &&
      filterGroup.paymentStatus === ""
    ) {
      getAllTransaction();
    } else if (
      filterGroup.atDate !== "" ||
      filterGroup.toDate !== "" ||
      filterGroup.paymentStatus !== ""
    ) {
      filterDataTable();
    }
  }, [filterGroup, getAllTransaction, filterDataTable]);

  useEffect(() => {
    getGbStatus();
  }, []);

  useEffect(() => {
    /* Function close dropdown 'AtDate and ToDate' : START */
    const onCloseDropdownAtDate = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#fac_at_date_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenAtDate(false);
      }
    };
    const onCloseDropdownToDate = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#fac_to_date_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenToDate(false);
      }
    };

    if (openAtDate) {
      document.addEventListener("click", onCloseDropdownAtDate);
    }
    if (openToDate) {
      document.addEventListener("click", onCloseDropdownToDate);
    }
    /* Function close dropdown 'AtDate and ToDate' : END */

    return () => {
      document.removeEventListener("click", onCloseDropdownAtDate);
      document.removeEventListener("click", onCloseDropdownToDate);
    };
  }, [openAtDate, openToDate]);

  return (
    <div className="report_purchase">
      <div className="filter_groups">
        <div className="group_">
          <select
            onChange={(e) => {
              setFilterGroup({
                ...filterGroup,
                paymentStatus: e.target.value,
                active: true,
              });
              setCurrentPage(1);
            }}
          >
            <option value="">สถานะการรับเงิน</option>
            {_paymentStatus.map((item) => {
              return (
                <option
                  selected={
                    filterGroup.paymentStatus === item.id ? true : false
                  }
                  key={item.id}
                  value={item.id}
                >
                  {item.statusName}
                </option>
              );
            })}
          </select>
        </div>
        <div className="group_">
          <div className="input_unit">
            <div className="info_box">
              <p>
                {filterGroup.atDate !== ""
                  ? convertDateThai(filterGroup.atDate)
                  : "วันที่"}
              </p>
              {filterGroup.atDate !== "" && (
                <GrFormClose
                  size={25}
                  cursor={"pointer"}
                  onClick={() => {
                    setFilterGroup({
                      ...filterGroup,
                      atDate: "",
                      active: true,
                    });
                    if (filterGroup.toDate === "") {
                      setCurrentPage(1);
                    }
                    setSelectedDate({ ...selectedDate, atDate: new Date() });
                  }}
                />
              )}
            </div>
            <div
              className="unit"
              onClick={() => {
                setOpenAtDate(!openAtDate);
              }}
            >
              <img src={icon.calendar} alt="" id="fac_at_date_d" />
            </div>
          </div>
          {openAtDate && (
            <DatePicker
              selected={selectedDate.atDate}
              onChange={onChangeAtDate}
              inline
            />
          )}
        </div>
        <label>ถึง :</label>
        <div className="group_">
          <div className="input_unit">
            <div className="info_box">
              <p>
                {filterGroup.toDate !== ""
                  ? convertDateThai(filterGroup.toDate)
                  : "วันที่"}
              </p>
              {filterGroup.toDate !== "" && (
                <GrFormClose
                  size={25}
                  cursor={"pointer"}
                  onClick={() => {
                    setFilterGroup({
                      ...filterGroup,
                      toDate: "",
                      active: true,
                    });
                    if (filterGroup.atDate === "") {
                      setCurrentPage(1);
                    }
                    setSelectedDate({ ...selectedDate, toDate: new Date() });
                  }}
                />
              )}
            </div>
            <div
              className="unit"
              onClick={() => {
                setOpenToDate(!openToDate);
              }}
            >
              <img src={icon.calendar} alt="" id="fac_to_date_d" />
            </div>
          </div>
          {openToDate && (
            <DatePicker
              selected={selectedDate.toDate}
              onChange={onChangeToDate}
              inline
            />
          )}
        </div>
      </div>

      <div className="table_container">
        <div className="table_box table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>ลำดับ</th>
                <th>วันที่รายงาน</th>
                <th>ชนิดเชื้อเพลิง / วัตถุดิบ</th>
                <th>ปริมาณผลผลิตที่ได้รับ(ตัน)</th>
                <th>จำนวนเงิน(บาท)</th>
                <th>ผู้รับเงิน</th>
                <th>วันที่จ่ายเงิน</th>
                <th>จ่ายเงินแล้ว</th>
              </tr>
            </thead>
            <tbody>
              {allTransaction.map((item, index) => {
                const datas = JSON.parse(item.datas);
                const plantArr = datas?.map((val) => val.plant_name)?.join(",");

                return (
                  <tr key={item.id}>
                    <td>{(currentPage - 1) * 10 + (index + 1)}</td>
                    <td>{convertDateThai(item.createdAt)}</td>
                    <td>{plantArr}</td>
                    <td>{item.amount}</td>
                    <td>{item.total?.toLocaleString()}</td>
                    <td>{item.payee}</td>
                    <td>{convertDateThai(item.payDate)}</td>
                    <td>
                      {item.paymentStatus !== _statusName.received ? (
                        <FiSquare
                          size={20}
                          cursor={"pointer"}
                          onClick={() => updatePaymentStatus(item.id)}
                        />
                      ) : (
                        "✅"
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="pagination_">
          <Pagination
            pageSize={pageSize}
            totalCount={totalCount}
            currentPage={currentPage}
            onPageChange={(page) => onPaginate(page)}
          />
        </div>
      </div>

      <div className="button_right">
        <ExportPopup
          label={"document"}
          trigger={_triggerExport}
          event={{ csv: onExportCsv, xlsx: onExportXlsx }}
        />
        <button
          type="button"
          className="btn_"
          onClick={() => setTriggerExport(!_triggerExport)}
        >
          Export
        </button>
      </div>
    </div>
  );
};

export default PurchaseRecord;
