import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-hot-toast";
import { CgCloseR } from "react-icons/cg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import icon from "../../../../../assets/img/Icon";
import {
  getEnterpriseData,
  getEnterpriseType,
  getFactoryType,
  setEnterpriseData,
} from "../../../../../services/enterprise.slice";
import { defaultApi } from "../../../../../services/api";
import Message from "../../../../../utils/message/Message";
import {
  convertDateEu,
  convertDateThai,
} from "../../../../../utils/format/Date.format";
import {
  CheckMobile,
  CheckNumberDot,
  CheckValidLength,
  CheckFileDoc,
} from "../../../../../utils/validation_input/Validation";
import { QueryGetByID } from "../../../../../helpers/api.helper";
import { createUrlImage } from "../../../../../helpers/func.helper";
import { getProfileData } from "../../../../../services/auth.slice";
import image from "../../../../../assets/img/image";
import styles from "../scss/FarmerDetails.module.scss";

const EditFarmer = ({
  _fetchData,
  _themeColor,
  fetchEntMember,
  allEntMemByEntId,
}) => {
  const dispatchFn_ = useDispatch();
  const navigate = useNavigate();
  const enterpriseData = useSelector(getEnterpriseData);
  const enterpriseType = useSelector(getEnterpriseType);
  const factoryType = useSelector(getFactoryType);
  const profileData = useSelector(getProfileData);

  const [_data, setData] = useState({});
  const [allFactory, setAllFactory] = useState([]);
  const [allUserData, setAllUserData] = useState([]);
  const [allEnterpriseMember, setAllEnterpriseMember] = useState([]);
  const [initDataEntMem, setInitDataEntMem] = useState(null);
  const [image, setImage] = useState({
    file: "",
  });
  const [pdfName, setPdfName] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [validErr, setValidErr] = useState(false);
  const [_openDate, setOpenDate] = useState(false);
  const [placeholderDisabled, setPlaceholderDisabled] = useState(false);

  const entMemberOpt = useMemo(() => {
    // สร้างเซตของ userId ที่ต้องการตัดออก
    const userIdsToRemove = new Set(
      allEnterpriseMember.map((member) => member.userId)
    );

    // กรองข้อมูลใน allUserData โดยตรวจสอบว่า userId อยู่ในเซต userIdsToRemove หรือไม่
    const filteredUserData = allUserData.filter(
      (user) => !userIdsToRemove.has(user.id)
    );

    // Loop data เพื่อสร้าง data ใหม่
    let data = [];
    for (let i = 0; i < filteredUserData.length; i++) {
      const element = filteredUserData[i];
      let newObj = {
        value: element.id,
        label: element.firstname + " " + element.lastname,
        email: element.email,
        mobile: element.mobile,
        status: element.status,
        entId: enterpriseData?.id,
      };

      data[i] = newObj;
    }

    return data;
  }, [allUserData, allEnterpriseMember, enterpriseData]);

  /* DatePicker : START */
  const onChangeOpenDate = (e) => {
    const dateFormat = convertDateEu(e);
    setData({ ..._data, openDate: dateFormat });
    setSelectedDate(e);
    setOpenDate(!_openDate);
  };
  /* DatePicker : END */

  const changeImg = async (e) => {
    const file = e.target.files[0];
    const file_val = document.querySelector("#file");
    const param1 = "Profile";
    const param2 = new FormData();
    param2.append("FormFile", e.target.files[0]);

    if (!_data?.enterpriseImage) {
      console.log("Create image");
      try {
        const res = await defaultApi.createFileDetail(param1, param2);
        console.log(res.statusText);

        setData({ ..._data, enterpriseImage: res?.data.id });
        setImage({ file: URL.createObjectURL(file) });
        file_val.value = "";
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("Update image");
      try {
        const res = await defaultApi.updateFileDetail(
          _data?.enterpriseImage,
          param1,
          param2
        );
        console.log(res.statusText);

        setData({ ..._data, enterpriseImage: res?.data.id });
        setImage({ file: URL.createObjectURL(file) });
        file_val.value = "";
      } catch (error) {
        console.log(error);
      }
    }
  };
  const onChange = (e) => {
    const { name, value } = e.target;

    setData({ ..._data, [name]: value });
  };

  const createFileDetail = async (e) => {
    console.log("createFile");
    const file = document.querySelector("#add_file");

    const loading = toast.loading(Message.notify.add_file_loading);
    const param1 = "EnterpriseDoc";
    const param2 = new FormData();
    param2.append("FormFile", e.target.files[0]);

    try {
      const res = await defaultApi.createFileDetail(param1, param2);
      console.log(res.statusText);

      setData({ ..._data, enterpriseDoc: res?.data.id });
    } catch (error) {
      console.log(error);
    } finally {
      file.value = "";
      toast.dismiss(loading);
    }
  };
  const updateFileDetail = async (e) => {
    console.log("updateFile");
    const file = document.querySelector("#add_file");
    if (!window.confirm(Message.notify.add_file_update)) {
      file.value = "";
      setPdfName("");
      return;
    }
    if (!e.target.files.length)
      return setData({ ..._data, enterpriseDoc: _data?.enterpriseDoc });

    const loading = toast.loading(Message.notify.add_file_loading);
    const _id = _data?.enterpriseDoc;
    const param1 = "EnterpriseDoc";
    const param2 = new FormData();
    param2.append("FormFile", e.target.files[0]);

    try {
      const res = await defaultApi.updateFileDetail(_id, param1, param2);
      console.log(res.statusText);

      setData({ ..._data, enterpriseDoc: res?.data.id });
      toast.success(Message.notify.update_success);
      file.value = "";
    } catch (error) {
      console.log(error);
      toast.error(Message.notify.update_error);
    } finally {
      toast.dismiss(loading);
    }
  };

  const getEnterprise = useCallback(async () => {
    const qryGetById = {
      $expand: `%24expand=enterpriseType%2Cfactory%2CenterpriseImageNavigation
          %2CenterpriseDocNavigation`,
      $filter: "",
      $orderby: "",
    };
    const _queryId = QueryGetByID(qryGetById);
    try {
      const res = await defaultApi.getEnterprise(_data?.id, _queryId);

      dispatchFn_(setEnterpriseData(res.data));
    } catch (error) {
      console.log(error);
    }
  }, [_data, dispatchFn_]);
  const getAllFactory = async () => {
    try {
      const res = await defaultApi.getAllFactory();

      setAllFactory(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getFilePdfName = () => {
    const file = document.querySelector("#add_file");

    file.addEventListener("change", () => {
      if (file.value) {
        setPdfName(file.files[0].name);
      } else {
        setPdfName("");
      }
    });
  };
  const getAllEnterpriseMember = async () => {
    try {
      const res = await defaultApi.getAllEnterpriseMember();

      setAllEnterpriseMember(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllUser = useCallback(async () => {
    if (!enterpriseData?.createdBy) return;

    const query = `%24filter=userGroupId%20eq%202%20
    and%20status%20eq%20%27active%27%20
    and%20id%20ne%20${enterpriseData?.createdBy}`;

    try {
      const res = await defaultApi.getUser(query);

      setAllUserData(res.data);
    } catch (error) {
      console.log(error);
    }
  }, [enterpriseData]);

  const updateEnterprise = useCallback(async () => {
    const closeModal = document.querySelector("#update-close");
    // Valid value all input
    if (CheckValidLength(_data?.name)) return setValidErr(true);
    if (CheckNumberDot(_data?.lat)) return setValidErr(true);
    if (CheckNumberDot(_data?.lng)) return setValidErr(true);
    if (CheckValidLength(_data?.address)) return setValidErr(true);
    if (CheckValidLength(_data?.registrationNumber)) return setValidErr(true);
    if (CheckMobile(_data?.mobile)) return setValidErr(true);
    if (CheckValidLength(_data?.enterpriseDoc)) return setValidErr(true);
    if (CheckValidLength(_data?.objective)) return setValidErr(true);

    const loading = toast.loading(Message.notify.loading);
    const params = {};
    Object.keys(_data).forEach((key) => {
      if (key === "enterpriseTypeId") {
        params[key] = +_data[key];
      } else if (key === "lat") {
        params[key] = +_data[key];
      } else if (key === "lng") {
        params[key] = +_data[key];
      } else if (key === "factoryId") {
        params[key] = +_data[key];
      } else if (key === "enterpriseDoc") {
        params[key] = +_data[key];
      } else if (key === "geom") {
        params[key] = null;
      } else {
        params[key] = _data[key];
      }
    });
    Object.assign(params, { point: [[+_data?.lat, +_data?.lng]] });
    try {
      const res = await defaultApi.updateEnterprise(_data?.id, params);
      console.log(res.statusText);
      // Navigate
      if (res.data.factory.id !== res.data.factoryId) {
        // navigate(`/home`);
        navigate(`/manage/${res.data.factoryId}`);
      }
      // Fetch api
      getEnterprise();
      _fetchData();
      // Close modal
      closeModal?.click();
      toast.success(Message.notify.update_success, { duration: 3000 });
    } catch (error) {
      console.log(error);
      toast.error(Message.notify.update_error, { duration: 3000 });
    } finally {
      toast.dismiss(loading);
    }
  }, [_data, getEnterprise, navigate, _fetchData]);
  const createEnterpriseMember = async () => {
    if (!initDataEntMem) return;

    const loading = toast.loading(Message.notify.loading);
    let params = {
      userId: initDataEntMem?.value,
      enterpriseId: initDataEntMem?.entId,
    };

    try {
      const res = await defaultApi.createEnterpriseMember(params);

      console.log(res.statusText);

      getAllEnterpriseMember();
      getAllUser();
      fetchEntMember();

      setInitDataEntMem(null);

      toast.success(Message.notify.add_enterprise_member_success, {
        duration: 3000,
      });
    } catch (error) {
      console.log(error);
      toast.error(Message.notify.add_enterprise_member_error, {
        duration: 3000,
      });
    } finally {
      toast.dismiss(loading);
    }
  };
  const deleteEnterpriseMember = async (idMember, idUser) => {
    if (idUser === profileData?.id)
      return toast.error(Message.notify.delete_member_warning, {
        duration: 4000,
      });

    if (!window.confirm(Message.notify.confirm_delete_member)) return;

    const loading = toast.loading(Message.notify.loading);

    try {
      const res = await defaultApi.deleteEnterpriseMember(idMember);

      console.log(res.statusText);

      getAllEnterpriseMember();
      getAllUser();
      fetchEntMember();

      toast.success(Message.notify.delete_enterprise_member_success, {
        duration: 3000,
      });
    } catch (error) {
      console.log(error);
      toast.error(Message.notify.delete_enterprise_member_error, {
        duration: 3000,
      });
    } finally {
      toast.dismiss(loading);
    }
  };

  const onBackAndReset = () => {
    getEnterprise();
    setOpenDate(false);
    setSelectedDate(new Date());
    setInitDataEntMem(null);
  };

  useEffect(() => {
    /* Format data null => string : START */
    const obj_ = {};
    Object.keys(enterpriseData).forEach((key) => {
      if (key === "address") {
        obj_[key] =
          enterpriseData?.address === null ? "" : enterpriseData?.address;
      } else if (key === "enterpriseDoc") {
        obj_[key] =
          enterpriseData?.enterpriseDoc === null
            ? ""
            : enterpriseData?.enterpriseDoc;
      } else {
        obj_[key] = enterpriseData[key];
      }
    });
    setData(obj_);
    /* Format data null => string : END */
  }, [enterpriseData]);

  useEffect(() => {
    // Use functions
    getFilePdfName();
    getAllFactory();
    getAllEnterpriseMember();
    getAllUser();
  }, [getAllUser]);

  useEffect(() => {
    /* Function close dropdown 'OpenDate' : START */
    const onCloseDropdownOpenDate = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#ent_open_date_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenDate(false);
      }
    };

    if (_openDate) {
      document.addEventListener("click", onCloseDropdownOpenDate);
    }
    /* Function close dropdown 'OpenDate' : END */

    return () => {
      document.removeEventListener("click", onCloseDropdownOpenDate);
    };
  }, [_openDate]);

  return (
    <>
      <div
        className={`modal fade ${styles.edit_farmer}`}
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: _themeColor?.backgroundColor }}
            >
              <h5 className="modal-title" id="staticBackdropLabel">
                แก้ไขรายละเอียดกลุ่มวิสาหกิจชุมชน
              </h5>
            </div>
            <div className="modal-body">
              <div className="input_form">
                <div className="aside_top">
                  <div className="img_con">
                    <h5 style={{ color: _themeColor?.color }}>
                      รูปภาพกลุ่มวิสาหกิจชุมชน
                    </h5>
                    <div className="img_box">
                      {!enterpriseData?.enterpriseImage && !image.file && (
                        <img
                          className="img_false"
                          src={icon.pic}
                          alt=""
                          onClick={openInputImg}
                        />
                      )}
                      {image.file ? (
                        <>
                          <img className="img_true" src={image.file} alt="" />
                          <div className="img_add">
                            <img
                              className="img_add_"
                              src={icon.pic}
                              alt=""
                              onClick={openInputImg}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <img
                            className="img_true"
                            src={createUrlImage(
                              enterpriseData?.enterpriseImageNavigation
                                ?.filePath
                            )}
                            alt=""
                          />
                          <div className="img_add">
                            <img
                              onClick={openInputImg}
                              src={icon.pic}
                              alt=""
                              title="เปลี่ยนรูปภาพ"
                            />
                          </div>
                        </>
                      )}
                      <input
                        type="file"
                        id="file"
                        accept="image/jpeg, image/png"
                        onChange={changeImg}
                        hidden
                      />
                      <label htmlFor="file" id="file-img"></label>
                    </div>
                  </div>
                </div>
                <div className="aside_box">
                  <div className="aside_left">
                    <div className="input_">
                      <h5 style={{ color: _themeColor?.color }}>
                        ชื่อกลุ่มวิสาหกิจชุมชน <sup className="asterisk">*</sup>
                      </h5>
                      <input
                        type="text"
                        name="name"
                        value={_data?.name || ""}
                        className={`${
                          validErr && _data?.name === "" ? "error" : ""
                        }`}
                        onChange={onChange}
                      />
                      {validErr && (
                        <p className="err_message">
                          {CheckValidLength(_data?.name)}
                        </p>
                      )}
                    </div>
                    <div className="input_">
                      <h5 style={{ color: _themeColor?.color }}>
                        ประเภทกลุ่ม <sup className="asterisk">*</sup>
                      </h5>
                      <select name="enterpriseTypeId" onChange={onChange}>
                        <option style={{ display: "none" }}>
                          {enterpriseData?.enterpriseType?.name}
                        </option>
                        {enterpriseType.map((item) => {
                          return (
                            <option
                              selected={
                                item.id === _data?.enterpriseType?.id
                                  ? true
                                  : false
                              }
                              key={item.id}
                              value={item.id}
                            >
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="input_">
                      <h5 style={{ color: _themeColor?.color }}>
                        วันที่จัดตั้ง
                      </h5>
                      <div className="input_unit">
                        <div className="info_box_">
                          <p>
                            {_data?.openDate !== ""
                              ? convertDateThai(_data?.openDate)
                              : ""}
                          </p>
                        </div>
                        <div
                          className="unit"
                          onClick={() => {
                            setOpenDate(!_openDate);
                          }}
                        >
                          <img
                            src={icon.calendar}
                            alt=""
                            id="ent_open_date_d"
                          />
                        </div>
                      </div>
                      {_openDate && (
                        <DatePicker
                          selected={selectedDate}
                          onChange={onChangeOpenDate}
                          inline
                        />
                      )}
                    </div>
                    <div className="input_">
                      <h5 style={{ color: _themeColor?.color }}>
                        ที่ตั้ง <sup className="asterisk">*</sup>
                      </h5>
                      <input
                        type="text"
                        name="address"
                        value={_data?.address || ""}
                        className={`${
                          validErr && _data?.address === "" ? "error" : ""
                        }`}
                        onChange={onChange}
                        onFocus={() => setPlaceholderDisabled(true)}
                        onBlur={() => setPlaceholderDisabled(false)}
                        placeholder={
                          placeholderDisabled
                            ? ""
                            : "ตัวอย่างที่ตั้ง 7/11 ต.ภูผา อ.ภูผา จ.ขอนแก่น 12345"
                        }
                      />
                      {validErr && (
                        <p className="err_message">
                          {CheckValidLength(_data?.address)}
                        </p>
                      )}
                    </div>
                    <div className="input_flex">
                      <div className="input_1">
                        <h5 style={{ color: _themeColor?.color }}>
                          ละติจูด <sup className="asterisk">*</sup>
                        </h5>
                        <input
                          type="text"
                          name="lat"
                          value={_data?.lat || ""}
                          className={`${
                            validErr && _data?.lat === "" ? "error" : ""
                          }`}
                          onChange={onChange}
                        />
                        {validErr && (
                          <p className="err_message">
                            {CheckNumberDot(_data?.lat)}
                          </p>
                        )}
                      </div>
                      <div className="input_2">
                        <h5 style={{ color: _themeColor?.color }}>
                          ลองจิจูด <sup className="asterisk">*</sup>
                        </h5>
                        <input
                          type="text"
                          name="lng"
                          value={_data?.lng || ""}
                          className={`${
                            validErr && _data?.lng === "" ? "error" : ""
                          }`}
                          onChange={onChange}
                        />
                        {validErr && (
                          <p className="err_message">
                            {CheckNumberDot(_data?.lng)}
                          </p>
                        )}
                      </div>
                    </div>
                    <span
                      className="info_how_to"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop99"
                    >
                      วิธีหาค่าพิกัด
                    </span>
                    <div className="input_">
                      <h5 style={{ color: _themeColor?.color }}>
                        เลขที่ทะเบียนวิสาหกิจชุมชน{" "}
                        <sup className="asterisk">*</sup>
                      </h5>
                      <input
                        type="text"
                        name="registrationNumber"
                        value={_data?.registrationNumber || ""}
                        className={`${
                          validErr && _data?.registrationNumber === ""
                            ? "error"
                            : ""
                        }`}
                        onChange={onChange}
                      />
                      {validErr && (
                        <p className="err_message">
                          {CheckValidLength(_data?.registrationNumber)}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="aside_center">
                    <div className="input_">
                      <h5 style={{ color: _themeColor?.color }}>
                        เบอร์ติดต่อวิสาหกิจชุมชน{" "}
                        <sup className="asterisk">*</sup>
                      </h5>
                      <input
                        type="text"
                        name="mobile"
                        value={_data?.mobile || ""}
                        className={`${
                          validErr && _data?.mobile === "" ? "error" : ""
                        }`}
                        onChange={onChange}
                      />
                      {validErr && (
                        <p className="err_message">
                          {CheckMobile(_data?.mobile)}
                        </p>
                      )}
                    </div>
                    <div className="input_">
                      <h5 style={{ color: _themeColor?.color }}>
                        โรงไฟฟ้าที่รับซื้อ <sup className="asterisk">*</sup>
                      </h5>
                      <select name="factoryId" onChange={onChange}>
                        <option style={{ display: "none" }}>
                          {enterpriseData?.factory?.name}
                        </option>
                        {allFactory.map((item) => {
                          return (
                            <option
                              selected={
                                item.id === _data?.factory?.id ? true : false
                              }
                              key={item.id}
                              value={item.id}
                            >
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="input_">
                      <h5 style={{ color: _themeColor?.color }}>
                        ประเภทโรงไฟฟ้า
                      </h5>
                      <input
                        type="text"
                        value={factoryType
                          .filter(
                            (item) =>
                              item.id === enterpriseData?.factory?.factoryTypeId
                          )
                          .map((item) => item.name)}
                        className={`${validErr && _data === "" ? "error" : ""}`}
                        readOnly
                      />
                      {validErr && (
                        <p className="err_message">{CheckValidLength(_data)}</p>
                      )}
                    </div>
                    <div className="input_file">
                      <h5 style={{ color: _themeColor?.color }}>
                        หนังสือจดทะเบียน
                      </h5>
                      <div
                        className={`input_unit mb_0 ${
                          validErr && _data?.enterpriseDoc === "" ? "error" : ""
                        }`}
                      >
                        <div className="info_box">
                          <p>
                            {pdfName !== ""
                              ? pdfName
                              : _data?.enterpriseDocNavigation?.fileName}
                          </p>
                        </div>
                        <div className="unit">
                          <img
                            src={icon.document}
                            alt=""
                            onClick={() =>
                              document.querySelector("#file-label").click()
                            }
                          />
                        </div>
                      </div>
                      {validErr && (
                        <p className="err_message">
                          {CheckFileDoc(_data?.enterpriseDoc)}
                        </p>
                      )}
                      <span className="desc_text">
                        *ระบบรองรับไฟล์ที่มีนามสกุล .pdf และมีขนาดไม่เกิน 5 MB
                      </span>
                      <input
                        type="file"
                        id="add_file"
                        accept="application/pdf"
                        onChange={
                          _data?.enterpriseDoc !== ""
                            ? updateFileDetail
                            : createFileDetail
                        }
                        hidden
                      />
                      <label htmlFor="add_file" id="file-label" hidden></label>
                    </div>
                    <div className="input_">
                      <h5 style={{ color: _themeColor?.color }}>
                        วัตถุประสงค์ <sup className="asterisk">*</sup>
                      </h5>
                      <input
                        type="text"
                        name="objective"
                        value={_data?.objective || ""}
                        className={`${
                          validErr && _data?.objective === "" ? "error" : ""
                        }`}
                        onChange={onChange}
                      />
                      {validErr && (
                        <p className="err_message">
                          {CheckValidLength(_data?.objective)}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="aside_right">
                    <div className="input_">
                      <h5>เพิ่มผู้ประสานงาน</h5>
                      <Select
                        className="multi_select"
                        closeMenuOnSelect={true}
                        isSearchable={true}
                        value={initDataEntMem ? initDataEntMem : []}
                        options={entMemberOpt}
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            borderRadius: "10px",
                          }),
                        }}
                        placeholder={"เลือกผู้ประสานงาน"}
                        onChange={(e) => setInitDataEntMem(e)}
                      />
                    </div>
                    {initDataEntMem && (
                      <div
                        style={{
                          border: "2px dashed #789b33",
                          margin: "0 0 15px 0",
                          padding: "0.4rem",
                        }}
                      >
                        <div
                          className="input_grps"
                          style={{ borderTop: "none" }}
                        >
                          <div className="input_grp">
                            <h5 style={{ color: _themeColor?.color }}>
                              ผู้ประสานงาน
                            </h5>
                            <input
                              type="text"
                              value={initDataEntMem?.label}
                              readOnly
                            />
                          </div>
                          <div className="input_grp">
                            <h5 style={{ color: _themeColor?.color }}>อีเมล</h5>
                            <input
                              type="email"
                              value={initDataEntMem?.email}
                              readOnly
                            />
                          </div>
                          <div className="input_grp">
                            <h5 style={{ color: _themeColor?.color }}>
                              เบอร์ติดต่อ
                            </h5>
                            <input
                              type="text"
                              value={initDataEntMem?.mobile}
                              readOnly
                            />
                          </div>

                          <div className="btn_gen">
                            <button
                              className="btn_"
                              onClick={createEnterpriseMember}
                            >
                              บันทึก
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {allEntMemByEntId?.map((item, i) => {
                      return (
                        <div key={item.id} className="input_grps">
                          <div className="input_grp">
                            <div className="input_">
                              <h5
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                ผู้ประสานงาน (คนที่ {i + 1}) (
                                {item?.user.status})
                                <CgCloseR
                                  size={25}
                                  color="red"
                                  cursor={"pointer"}
                                  onClick={() =>
                                    deleteEnterpriseMember(item.id, item.userId)
                                  }
                                />
                              </h5>
                              <input
                                type="text"
                                value={
                                  item?.user.firstname +
                                  " " +
                                  item?.user.lastname
                                }
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="input_grp">
                            <div className="input_">
                              <h5>อีเมล</h5>
                              <input
                                type="text"
                                value={item?.user.email}
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="input_grp">
                            <div className="input_">
                              <h5>เบอร์ติดต่อ</h5>
                              <input
                                type="text"
                                value={item?.user.mobile}
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="button_center">
                <button className="button_sm" onClick={updateEnterprise}>
                  บันทึก
                </button>
                <button
                  id="update-close"
                  className="button_sm"
                  data-bs-dismiss="modal"
                  onClick={onBackAndReset}
                >
                  ยกเลิก
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalHowTo />
    </>
  );
};

function openInputImg() {
  document.querySelector("#file-img").click();
}

const ModalHowTo = () => {
  return (
    <div
      className={`modal fade ${styles.modal_how_to}`}
      id="staticBackdrop99"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content" style={{ minHeight: "auto" }}>
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              วิธีการหาพิกัดตำแหน่งที่ดิน
            </h5>
          </div>
          <div className="modal-body">
            <div className="box_">
              <p>
                1. เข้า <img src={image.how_to_coor1_1} alt="" /> Google Maps
                ผ่านเว็บบราวน์เซอร์ <img src={image.how_to_coor1_2} alt="" />
              </p>
            </div>
            <div className="box_">
              <p>2. ทำการเปลี่ยนรูปแบบแผนที่เป็นแบบ ภาพถ่ายดาวเทียม</p>
              <div className="img_box">
                <img src={image.how_to_coor2} alt="" />
              </div>
            </div>
            <div className="box_">
              <p>
                3. จากนั้นค้นหาที่ช่องค้นหา ด้วย ชื่อตำบล อำเภอ จังหวัด
                หรือสถานที่ใกล้เคียงกับ ตำแหน่งของโฉนดที่ดิน
              </p>
              <div className="img_box_flex">
                <div className="img_">
                  <img src={image.how_to_coor3_1} alt="" />
                </div>
                <p>หรือ</p>
                <div className="img_">
                  <img src={image.how_to_coor3_2} alt="" />
                </div>
              </div>
            </div>
            <div className="box_">
              <p>
                4. เมื่อพบตำแหน่งที่ดินแล้ว ให้คลิกขวาในพื้นที่ ที่ดินตามโฉนด
                จะปรากฎ พิกัดละติจูด และลองจิจูด
              </p>
              <div className="img_box">
                <img src={image.how_to_coor4} alt="" />
              </div>
            </div>
            <div className="box_">
              <p>5. คลิกที่เลขพิกัด เพื่อคัดลอก</p>
            </div>
            <div className="box_">
              <p>
                6. นำมาใส่ในข้อมูลพิกัด พร้อมทั้งลบ เว้นวรรคหลัง ,
                เพื่อให้ตรงตามรูปแบบ
              </p>
              <div className="img_box" style={{ overflowX: "auto" }}>
                <img src={image.how_to_coor6} alt="" />
              </div>
            </div>

            <div className="btn_center">
              <button type="button" className="btn_" data-bs-dismiss="modal">
                กลับ
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditFarmer;
