import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import useSwitch from "../../../utils/hooks/useSwitch";
import { defaultApi } from "../../../services/api";
import {
  CheckUsername,
  CheckPassword,
  CheckEmail,
} from "../../../utils/validation_input/Validation";
import Message from "../../../utils/message/Message";
import { toast } from "react-hot-toast";
import { setProfileData } from "../../../services/auth.slice";
import { QueryGetAll } from "../../../helpers/api.helper";
import { encodeData } from "../../../helpers/encode_decode.helper";
import { setCookiesStorageData } from "../../../helpers/cookies_storage.helper";
import styles from "../scss/Login.module.scss";

const initData = {
  username: "",
  password: "",
};
const initData2 = {
  username: "",
  email: "",
};

const LoginForm = () => {
  const dispatchFn_ = useDispatch();
  const navigate = useNavigate();

  const [_switch, onSwitch] = useSwitch();
  const [_data, setData] = useState(initData);
  const [_data2, setData2] = useState(initData2);
  const [validErr, setValidErr] = useState(false);
  const [validErr2, setValidErr2] = useState(false);
  const [_showPass, setShowPass] = useState(false);

  const onChange = (e) => {
    const { name, value } = e.target;
    setData({ ..._data, [name]: value });
  };
  const onChange2 = (e) => {
    const { name, value } = e.target;
    setData2({ ..._data2, [name]: value });
  };

  const _getProfile = async () => {
    const qryGetAll = {
      $count: "",
      $expand: `%24expand=userGroup%2CuserLocalGroup%2C
      avatarNavigation%2CuserOrg`,
      $top: "",
      $skip: "",
      $filter: "",
      $orderby: "",
    };
    const _queryAll = QueryGetAll(qryGetAll);
    try {
      const res = await defaultApi.getProfile(_queryAll);
      setCookiesStorageData("userProfile", res.data);
      dispatchFn_(setProfileData(res.data));
    } catch (error) {
      console.log(error);
    }
  };

  const onLogin = async (e) => {
    e.preventDefault();
    if (CheckUsername(_data.username)) return setValidErr(true);
    else if (CheckPassword(_data.password)) return setValidErr(true);

    const loading = toast.loading(Message.notify.login_loading);
    const params = {};
    Object.keys(_data).forEach((key) => {
      params[key] = _data[key];
    });
    try {
      const res = await defaultApi.login(params);
      const { data } = res;
      const token = encodeData(data?.token);
      // Set Cookies
      setCookiesStorageData("token", token);
      _getProfile();
      // Direct to
      navigate("/home");
      // Alert message
      toast.success(Message.notify.login_success, { duration: 3000 });
    } catch (error) {
      setValidErr(true);
      console.log(error);
      if (error?.response?.status === 404) {
        if (_data.username !== "" && _data.password !== "") {
          toast.error(Message.notify.login_invalid_error, { duration: 3000 });
        } else {
          toast.error(Message.notify.login_error, { duration: 3000 });
        }
        toast.dismiss(loading);
      } else if (error?.response?.status === 400) {
        if (error?.response?.data.errors[0]) {
          toast.error(error?.response?.data.errors[0], { duration: 5000 });
          toast.dismiss(loading);
        }
      } else {
        toast.error(Message.notify.login_error, { duration: 3000 });
        toast.dismiss(loading);
      }
    } finally {
      toast.dismiss(loading);
    }
  };

  const onForgetPassword = async () => {
    if (!window.confirm(Message.notify.forgetPassword_confirm)) return;

    if (CheckEmail(_data2.email)) return setValidErr2(true);
    if (CheckUsername(_data2.username)) return setValidErr2(true);

    const loading = toast.loading(Message.notify.forgetPassword_send);
    const params = {};
    Object.keys(_data2).forEach((key) => {
      params[key] = _data2[key];
    });
    try {
      const res = await defaultApi.forgetPassword(params);
      console.log(res.statusText);
      setData(initData2);
      setValidErr2(false);
      onSwitch(false);
      toast.success(Message.notify.forgetPassword_success, { duration: 3000 });
    } catch (error) {
      console.log(error);
      setValidErr2(true);
      toast.error(Message.notify.fotgetPassword_error, { duration: 3000 });
    } finally {
      toast.dismiss(loading);
    }
  };

  return (
    <>
      <h4 className="text-center">
        {!_switch ? "เข้าสู่ระบบ" : "ลืมรหัสผ่าน"}
      </h4>
      <form
        onSubmit={onLogin}
        id="onLogin"
        className={`${styles.form_} mx-auto`}
      >
        {!_switch ? (
          <>
            <div className={`${styles.input_}`}>
              <h5>ชื่อผู้ใช้งาน</h5>
              <input
                type="text"
                name="username"
                value={_data.username}
                className={`${
                  validErr && _data.username === "" ? "error" : ""
                }`}
                id=""
                onChange={onChange}
              />
              {validErr && (
                <small className={`${styles.err_message}`}>
                  {CheckUsername(_data.username)}
                </small>
              )}
            </div>
            <div className={`${styles.input_}`}>
              <h5>รหัสผ่าน</h5>
              <div
                className={`${styles.input_unit} ${
                  validErr && _data.password === "" ? "error" : ""
                }`}
              >
                <input
                  type="password"
                  name="password"
                  id="password"
                  value={_data.password}
                  onChange={onChange}
                />
                <div className={`${styles.unit}`}>
                  {!_showPass ? (
                    <AiFillEye
                      className={`${styles.ai_fill_eye}`}
                      onClick={() => {
                        showPass();
                        setShowPass(true);
                      }}
                    />
                  ) : (
                    <AiFillEyeInvisible
                      className={`${styles.ai_fill_eye}`}
                      onClick={() => {
                        showPass();
                        setShowPass(false);
                      }}
                    />
                  )}
                </div>
              </div>
              {validErr && (
                <small className={`${styles.err_message}`}>
                  {CheckPassword(_data.password)}
                </small>
              )}
            </div>
            <div className={`${styles.passEdit_}`}>
              <Link
                to={`#`}
                onClick={() => {
                  onSwitch(true);
                  setShowPass(false);
                }}
              >
                ลืมรหัสผ่าน
              </Link>
            </div>
          </>
        ) : (
          <>
            <div className={`${styles.input_}`}>
              <h5>อีเมล</h5>
              <input
                type="email"
                name="email"
                id=""
                value={_data2.email}
                className={`${validErr2 && _data2.email === "" ? "error" : ""}`}
                onChange={onChange2}
              />
              {validErr2 && (
                <small className={`${styles.err_message}`}>
                  {CheckEmail(_data2.email)}
                </small>
              )}
            </div>
            <div className={`${styles.input_}`}>
              <h5>ชื่อผู้ใช้งาน</h5>
              <input
                type="text"
                name="username"
                id=""
                value={_data2.username}
                className={`${
                  validErr2 && _data2.username === "" ? "error" : ""
                }`}
                onChange={onChange2}
              />
              {validErr2 && (
                <small className={`${styles.err_message}`}>
                  {CheckUsername(_data2.username)}
                </small>
              )}
            </div>
          </>
        )}
      </form>

      <div className={`${styles.button_center}`}>
        {!_switch ? (
          <>
            <button type="submit" form="onLogin" className="button_sm">
              เข้าสู่ระบบ
            </button>
            <button
              type="button"
              className="button_sm"
              onClick={() => navigate("/register")}
            >
              ลงทะเบียน
            </button>
          </>
        ) : (
          <>
            <button
              type="button"
              className="button_sm"
              onClick={onForgetPassword}
            >
              ยืนยัน
            </button>
            <button
              type="button"
              className="button_sm"
              onClick={() => onSwitch(false)}
            >
              ยกเลิก
            </button>
          </>
        )}
      </div>
    </>
  );
};

function showPass() {
  const password = document.querySelector("#password");

  const type =
    password.getAttribute("type") === "password" ? "text" : "password";
  password.setAttribute("type", type);
}

export default LoginForm;
