import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Autocomplete from "react-autocomplete";
import icon from "../../../../../../assets/img/Icon";
import { defaultApi } from "../../../../../../services/api";
import { getFactoryData } from "../../../../../../services/factory.slice";
import {
  CheckDataStDateEn,
  CheckDateEnDataSt,
  CheckFileDoc,
  CheckNumber,
  CheckNumberDot,
  CheckValidLength,
} from "../../../../../../utils/validation_input/Validation";
import Message from "../../../../../../utils/message/Message";
import {
  convertDateEu,
  convertDateThai,
} from "../../../../../../utils/format/Date.format";
import { QueryGetAll } from "../../../../../../helpers/api.helper";

const initData = {
  no: "",
  status: "",
  signDate: "",
  endDate: "",
  scod: "",
  cod: "",
  factoryId: 0,
  buyer: "",
  period: "",
  amount: "",
  ppaDoc: "",
};

const AddContractPpa = ({ onSwitchBoxR }) => {
  const factoryData = useSelector(getFactoryData);

  const [_data, setData] = useState(initData);
  const [allEnterprise, setAllEnterprise] = useState([]);
  const [buyerArr, setBuyerArr] = useState([]);
  const [_contractStatus, setContractStatus] = useState([]);
  const [selectedDate, setSelectedDate] = useState({
    sign: new Date(),
    end: new Date(),
    scod: new Date(),
    cod: new Date(),
  });
  const [openDateSign, setOpenDateSign] = useState(false);
  const [openDateEnd, setOpenDateEnd] = useState(false);
  const [openDateScod, setOpenDateScod] = useState(false);
  const [openDateCod, setOpenDateCod] = useState(false);
  const [validErr, setValidErr] = useState(false);

  /* DatePicker : START */
  const onChangeDateSign = (e) => {
    const dateFormat = convertDateEu(e);
    setData({ ..._data, signDate: dateFormat });
    setSelectedDate({ ...selectedDate, sign: e });
    setOpenDateSign(!openDateSign);
  };
  const onChangeDateEnd = (e) => {
    const dateFormat = convertDateEu(e);
    setData({ ..._data, endDate: dateFormat });
    setSelectedDate({ ...selectedDate, end: e });
    setOpenDateEnd(!openDateEnd);
  };
  const onChangeDateScod = (e) => {
    const dateFormat = convertDateEu(e);
    setData({ ..._data, scod: dateFormat });
    setSelectedDate({ ...selectedDate, scod: e });
    setOpenDateScod(!openDateScod);
  };
  const onChangeDateCod = (e) => {
    const dateFormat = convertDateEu(e);
    setData({ ..._data, cod: dateFormat });
    setSelectedDate({ ...selectedDate, cod: e });
    setOpenDateCod(!openDateCod);
  };
  /* DatePicker : END */

  const getAllEnterprise = useCallback(async () => {
    if (!factoryData?.id) return;

    let factoryId = factoryData?.id;
    const qryGetAll = {
      $count: "%24count=true",
      $expand: "",
      $top: "",
      $skip: "",
      $filter: `&%24filter=factoryId%20eq%20${factoryId}`,
      $orderby: "",
    };
    const _queryAll = QueryGetAll(qryGetAll);
    try {
      const res = await defaultApi.getAllEnterprise(_queryAll);

      setAllEnterprise(res.data.datas);
    } catch (error) {
      console.log(error);
    }
  }, [factoryData]);
  const getGbStatus = async () => {
    const query = `%24filter=statusCategories%20eq%20%272%27`;

    try {
      const res = await defaultApi.getGbStatus(query);

      setContractStatus(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const createFileDetail = async (e) => {
    const file = document.querySelector("#add_file");

    const loading = toast.loading("กำลังเพิ่มสัญญาฉบับจริง...");
    const param1 = "PPA";
    const param2 = new FormData();
    param2.append("FormFile", e.target.files[0]);

    try {
      const res = await defaultApi.createFileDetail(param1, param2);
      console.log(res.statusText);

      setData({ ..._data, ppaDoc: res?.data.id });
    } catch (error) {
      console.log(error);
      toast.error(Message.notify.insert_error, { duration: 3000 });
    } finally {
      file.value = "";
      toast.dismiss(loading);
    }
  };
  const onChange = (e) => {
    const { name, value } = e.target;
    setData({ ..._data, [name]: value });
  };
  const createContractPpa = async () => {
    if (CheckValidLength(_data.no)) return setValidErr(true);
    if (CheckValidLength(_data.status)) return setValidErr(true);
    if (CheckValidLength(_data.signDate)) return setValidErr(true);
    if (CheckValidLength(_data.endDate)) return setValidErr(true);
    if (CheckValidLength(_data.buyer)) return setValidErr(true);
    if (CheckNumber(_data.period)) return setValidErr(true);
    if (CheckNumberDot(_data.amount)) return setValidErr(true);
    if (CheckValidLength(_data.ppaDoc)) return setValidErr(true);
    if (CheckDataStDateEn(_data.signDate, _data.endDate))
      return setValidErr(true);
    if (CheckDateEnDataSt(_data.endDate, _data.signDate))
      return setValidErr(true);
    if (CheckValidLength(_data.scod)) return setValidErr(true);
    if (CheckValidLength(_data.cod)) return setValidErr(true);

    const loading = toast.loading(Message.notify.loading);
    const params = {};
    Object.keys(_data).forEach((key) => {
      if (key === "factoryId") {
        params[key] = factoryData?.id;
      } else if (key === "period") {
        params[key] = +_data[key];
      } else if (key === "amount") {
        params[key] = +_data[key];
      } else if (key === "ppaDoc") {
        params[key] = +_data[key];
      } else {
        params[key] = _data[key];
      }
    });
    try {
      const res = await defaultApi.createContractPpa(params);
      console.log(res.statusText);
      setData(initData);
      setValidErr(false);
      onSwitchBoxR(false);
      toast.success(Message.notify.insert_success, { duration: 3000 });
    } catch (error) {
      console.log(error);
      setValidErr(true);
      toast.error(Message.notify.insert_error, { duration: 3000 });
    } finally {
      toast.dismiss(loading);
    }
  };

  useEffect(() => {
    /* Format allEnterprise to new arr obj [id,name] : START */
    if (allEnterprise.length) {
      let newArr = [];
      for (let i = 0; i < allEnterprise.length; i++) {
        allEnterprise.forEach((item) => {
          let obj_ = { id: item.id, name: item.name };
          newArr[i] = obj_;
          ++i;
        });
      }
      setBuyerArr(newArr);
    }
    /* Format allEnterprise to new arr obj [id,name] : END */
  }, [allEnterprise]);

  useEffect(() => {
    getFilePdfName();
    getAllEnterprise();
    getGbStatus();
  }, [getAllEnterprise]);

  useEffect(() => {
    /* Function close dropdown 'DateSign and DateEnd and DateScod' : START */
    const onCloseDropdownDateSign = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#fac_sign_date_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenDateSign(false);
      }
    };
    const onCloseDropdownDateEnd = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#fac_end_date_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenDateEnd(false);
      }
    };
    const onCloseDropdownDateScod = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#fac_scod_date_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenDateScod(false);
      }
    };

    if (openDateSign) {
      document.addEventListener("click", onCloseDropdownDateSign);
    }
    if (openDateEnd) {
      document.addEventListener("click", onCloseDropdownDateEnd);
    }
    if (openDateScod) {
      document.addEventListener("click", onCloseDropdownDateScod);
    }
    /* Function close dropdown 'DateSign and DateEnd and DateScod' : END */

    return () => {
      document.removeEventListener("click", onCloseDropdownDateSign);
      document.removeEventListener("click", onCloseDropdownDateEnd);
      document.removeEventListener("click", onCloseDropdownDateScod);
    };
  }, [openDateSign, openDateEnd, openDateScod]);

  return (
    <div className="add_ppa">
      <h5 className="title_text">เพิ่มสัญญาขายไฟฟ้า</h5>

      <form className="input_form">
        <div className="input_flex">
          <div className="input_1">
            <h5>
              สัญญาเลขที่ <sup className="asterisk">*</sup>
            </h5>
            <input
              type="text"
              name="no"
              className={validErr && _data.no === "" ? "error" : ""}
              onChange={onChange}
            />
            {validErr && (
              <p className="err_message">{CheckValidLength(_data.no)}</p>
            )}
          </div>
          <div className="input_2">
            <h5>
              สถานะสัญญา <sup className="asterisk">*</sup>
            </h5>
            <select
              name="status"
              className={validErr && _data.status === "" ? "error" : ""}
              onChange={onChange}
            >
              <option style={{ display: "none" }}>เลือกสถานะสัญญา</option>
              {_contractStatus.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.statusName}
                  </option>
                );
              })}
            </select>
            {validErr && (
              <p className="err_message">{CheckValidLength(_data.status)}</p>
            )}
          </div>
        </div>
        <div className="input_flex">
          <div className="input_1">
            <h5>
              วันเริ่มต้นสัญญา PPA <sup className="asterisk">*</sup>
            </h5>
            <div
              className={`input_unit ${
                validErr && _data.signDate === "" ? "error" : ""
              }`}
            >
              <div className="info_box">
                <p>
                  {_data.signDate !== "" ? convertDateThai(_data.signDate) : ""}
                </p>
              </div>
              <div
                className="unit"
                onClick={() => {
                  setOpenDateSign(!openDateSign);
                }}
              >
                <img src={icon.calendar} alt="" id="fac_sign_date_d" />
              </div>
            </div>
            {openDateSign && (
              <DatePicker
                selected={selectedDate.sign}
                onChange={onChangeDateSign}
                inline
              />
            )}
            {validErr && (
              <p className="err_message">{CheckValidLength(_data.signDate)}</p>
            )}
            {validErr && _data.signDate !== "" && (
              <p className="err_message">
                {CheckDataStDateEn(_data.signDate, _data.endDate)}
              </p>
            )}
          </div>
          <div className="input_2">
            <h5>
              วันสิ้นสุดสัญญา PPA <sup className="asterisk">*</sup>
            </h5>
            <div
              className={`input_unit ${
                validErr && _data.endDate === "" ? "error" : ""
              }`}
            >
              <div className="info_box">
                <p>
                  {_data.endDate !== "" ? convertDateThai(_data.endDate) : ""}
                </p>
              </div>
              <div
                className="unit"
                onClick={() => {
                  setOpenDateEnd(!openDateEnd);
                }}
              >
                <img src={icon.calendar} alt="" id="fac_end_date_d" />
              </div>
            </div>
            {openDateEnd && (
              <DatePicker
                selected={selectedDate.end}
                onChange={onChangeDateEnd}
                inline
              />
            )}
            {validErr && (
              <p className="err_message">{CheckValidLength(_data.endDate)}</p>
            )}
            {validErr && _data.endDate !== "" && (
              <p className="err_message">
                {CheckDateEnDataSt(_data.endDate, _data.signDate)}
              </p>
            )}
          </div>
        </div>
        <div className="input_flex">
          <div className="input_1">
            <h5>
              การไฟฟ้าคู่สัญญา <sup className="asterisk">*</sup>
            </h5>
            <div className="auto_com_box">
              <Autocomplete
                inputProps={{
                  className: `input_box ${
                    validErr && _data.buyer === "" ? "error" : ""
                  }`,
                }}
                wrapperStyle={{ fontSize: "20px" }}
                items={buyerArr}
                shouldItemRender={(item, value) =>
                  item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
                }
                getItemValue={(item) => item.name}
                renderItem={(item, highlighted) => (
                  <div
                    key={item.id}
                    style={{
                      backgroundColor: highlighted ? "#eee" : "transparent",
                    }}
                  >
                    {item.name}
                  </div>
                )}
                value={_data.buyer}
                onChange={(e) => setData({ ..._data, buyer: e.target.value })}
                onSelect={(name) => setData({ ..._data, buyer: name })}
              />
              <span>และ</span>
            </div>
            {validErr && (
              <p className="err_message">{CheckValidLength(_data.buyer)}</p>
            )}
          </div>
          <div className="input_2">
            <h5>ผู้ผลิตไฟฟ้าคู่สัญญา</h5>
            <input
              type="text"
              name="factoryId"
              defaultValue={factoryData?.name}
              readOnly
            />
          </div>
        </div>
        <div className="input_flex">
          <div className="input_1">
            <h5>
              ระยะเวลาสัญญา <sup className="asterisk">*</sup>
            </h5>

            <div
              className={`input_unit ${
                validErr && _data.period === "" ? "error" : ""
              }`}
            >
              <input type="text" name="period" onChange={onChange} />
              <div className="unit">
                <span>ปี</span>
              </div>
            </div>
            {validErr && (
              <p className="err_message">{CheckNumber(_data.period)}</p>
            )}
          </div>
          <div className="input_2">
            <h5>
              ปริมาณไฟฟ้าเสนอขาย <sup className="asterisk">*</sup>
            </h5>
            <div
              className={`input_unit ${
                validErr && _data.amount === "" ? "error" : ""
              }`}
            >
              <input type="text" name="amount" onChange={onChange} />
              <div className="unit">
                <span>MW</span>
              </div>
            </div>
            {validErr && (
              <p className="err_message">{CheckNumberDot(_data.amount)}</p>
            )}
          </div>
        </div>
        <div className="input_flex">
          <div className="input_1">
            <div className="input_file">
              <h5>
                สัญญาฉบับจริง <sup className="asterisk">*</sup>
              </h5>
              <div
                className={`input_unit mb_0 ${
                  validErr && _data.ppaDoc === "" ? "error" : ""
                }`}
              >
                <div className="info_box">
                  <p id="file_name"></p>
                </div>
                <div className="unit">
                  <img
                    src={icon.document}
                    alt=""
                    title="แนบไฟล์"
                    onClick={() =>
                      document.querySelector("#file-label").click()
                    }
                  />
                </div>
              </div>
              {validErr && (
                <p className="err_message">{CheckFileDoc(_data.ppaDoc)}</p>
              )}
              <span className="desc_text">
                *ระบบรองรับไฟล์ที่มีนามสกุล .pdf และมีขนาดไม่เกิน 5 MB
              </span>
              <input
                type="file"
                name="ppaDoc"
                id="add_file"
                accept="application/pdf"
                onChange={createFileDetail}
                hidden
              />
              <label htmlFor="add_file" id="file-label" hidden></label>
            </div>
          </div>
          <div className="input_2">
            <h5>
              SCOD <sup className="asterisk">*</sup>
            </h5>
            <div
              className={`input_unit ${
                validErr && _data.scod === "" ? "error" : ""
              }`}
            >
              <div className="info_box">
                <p>{_data.scod !== "" ? convertDateThai(_data.scod) : ""}</p>
              </div>
              <div
                className="unit"
                onClick={() => {
                  setOpenDateScod(!openDateScod);
                }}
              >
                <img src={icon.calendar} alt="" id="fac_scod_date_d" />
              </div>
            </div>
            {openDateScod && (
              <DatePicker
                selected={selectedDate.scod}
                onChange={onChangeDateScod}
                inline
              />
            )}
            {validErr && (
              <p className="err_message">{CheckValidLength(_data.scod)}</p>
            )}
          </div>
        </div>
        <div className="input_flex">
          <div className="input_1">
            <h5>
              COD <sup className="asterisk">*</sup>
            </h5>
            <div
              className={`input_unit ${
                validErr && _data.cod === "" ? "error" : ""
              }`}
            >
              <div className="info_box">
                <p>{_data.cod !== "" ? convertDateThai(_data.cod) : ""}</p>
              </div>
              <div
                className="unit"
                onClick={() => {
                  setOpenDateCod(!openDateCod);
                }}
              >
                <img src={icon.calendar} alt="" id="fac_scod_date_d" />
              </div>
            </div>
            {openDateCod && (
              <DatePicker
                selected={selectedDate.cod}
                onChange={onChangeDateCod}
                inline
              />
            )}
            {validErr && (
              <p className="err_message">{CheckValidLength(_data.cod)}</p>
            )}
          </div>
          <div className="input_2"></div>
        </div>
      </form>

      <div className="button_center">
        <button type="button" className="button_sm" onClick={createContractPpa}>
          บันทึก
        </button>
        <button
          type="button"
          className="button_sm"
          onClick={() => onSwitchBoxR(false)}
        >
          ยกเลิก
        </button>
      </div>
    </div>
  );
};

const getFilePdfName = () => {
  const file = document.querySelector("#add_file");
  const label = document.querySelector("#file_name");

  file?.addEventListener("change", () => {
    if (file.value) {
      label.innerHTML = file.files[0].name;
    } else {
      label.innerHTML = "";
    }
  });
};

export default AddContractPpa;
