import React from "react";
import styles from "./scss/MenuBar.module.scss";

const MenuBar = ({ _location, _navigate, _layerId }) => {
  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.checkParentBox}`}>
        <input
          type="checkbox"
          id="checkMenu1"
          checked={
            !_location.includes("members") &&
            !_location.includes("raw-material") &&
            !_location.includes("income")
          }
          onClick={() => _navigate(`/manage/${_layerId}`)}
          readOnly
        />
        <label htmlFor="checkMenu1">ข้อมูลกลุ่มวิสาหกิจชุมชน</label>
      </div>
      <div className={`${styles.checkParentBox}`}>
        <input
          type="checkbox"
          id="checkMenu2"
          checked={_location.includes("members")}
          onClick={() => _navigate(`/manage/${_layerId}/members`)}
          readOnly
        />
        <label htmlFor="checkMenu2">ข้อมูลสมาชิก</label>
      </div>
      <div className={`${styles.checkParentBox}`}>
        <input
          type="checkbox"
          id="checkMenu3"
          checked={_location.includes("raw-material")}
          onClick={() => _navigate(`/manage/${_layerId}/raw-material`)}
          readOnly
        />
        <label htmlFor="checkMenu3">การจัดการวัตถุดิบ</label>
      </div>
      <div className={`${styles.checkParentBox}`}>
        <input
          type="checkbox"
          id="checkMenu4"
          checked={_location.includes("income")}
          onClick={() => _navigate(`/manage/${_layerId}/income`)}
          readOnly
        />
        <label htmlFor="checkMenu4">รายได้อื่นๆ</label>
      </div>
    </div>
  );
};

export default MenuBar;
