import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { QueryGetAll } from "../../../helpers/api.helper";
import { defaultApi } from "../../../services/api";
import { getProfileData } from "../../../services/auth.slice";
import ChartsStatus from "./charts/ChartsStatus";
import ExportPopup from "../../../layouts/exportpopup/ExportPopup";
import { convertDateThai2 } from "../../../utils/format/Date.format";
import styles from "./scss/ReportStatus.module.scss";

const ReportStatus = ({ themeColor }) => {
  const chart = useRef();

  const [_setKeyFact, keyFact] = useState({
    entpId: "",
    factId: "",
  });

  const [_resName, resName] = useState(null);
  const [_resItem, resItem] = useState(null);

  const profileData = useSelector(getProfileData);

  const [_triggerExport, setTriggerExport] = useState(false);
  const [stateYear, setStateYear] = useState(new Date().getFullYear());

  window.ChartTitleUnit = {
    title: 'เดือน',
    unit: ['คน'],
    totalStatus : false
  }

  const downloadCSV = () => {
    // if (chart && chart.current && chart.current.chart) {
    //   chart.current.chart.downloadCSV();
    // }
    if (chart && chart.current && chart.current.chart) {
      chart.current.chart.downloadCSV();
    }
  };

  const downloadImage = () => {
    if (chart && chart.current && chart.current.chart) {
      chart.current.chart.setTitle({
        text: "สถานะการถือครองที่ดินการปลูกเชื้อเพลิง",
      });
      chart.current.chart.setSubtitle({ text: _resName });

      chart.current.chart.exportChart({
        type: "image/jpeg",
        filename: "chart",
      });

      chart.current.chart.setTitle({ text: "" });
      chart.current.chart.setSubtitle({ text: "" });
    }
  };
  const info = useCallback(async () => {
    let qryGetAll = {
      $count: "%24count=true",
      $expand: "",
      $top: "",
      $skip: "",
      $filter: `&%24filter=createdBy%20eq%20${profileData.id}`,
      $orderby: "",
    };

    const resAllEnp = await defaultApi.getAllEnterpriseMember(``);

    if (resAllEnp?.data?.length > 0) {
      for (let index = 0; index < resAllEnp?.data?.length; index++) {
        const element = resAllEnp?.data[index];
        if (+element?.userId === +profileData?.id) {
          qryGetAll = {
            $count: "%24count=true",
            $expand: "",
            $top: "",
            $skip: "",
            $filter: `&%24filter=id%20eq%20${element.enterpriseId}`,
            $orderby: "",
          };
        }
      }
    }

    const _query = QueryGetAll(qryGetAll);
    const res_entp = await defaultApi.getAllEnterprise(_query);
    const entpId = res_entp.data.datas[0].id;
    const factId = res_entp.data.datas[0].factory.id;
    keyFact({
      entpId: entpId,
      factId: factId,
    });

    let date = new Date();
    //let month = date.getMonth()
    let year = date.getFullYear();

    const res_item = await defaultApi.getpreportEnterpriseMemberOwnershipYear(
      "enterpriseId=" + entpId + "&atYear=" + year
    );
    resItem(res_item.data);

    resName(res_entp.data.datas[0].name);
  }, [profileData]);

  const dateTime = () => {
    let dt = new Date();
    let year = dt.getFullYear();
    let years = [];

    for (let index = 0; index < 10; index++) {
      years[index] = year;
      year--;
    }

    let optYear = years.map((data, index) => {
      return (
        <option key={index} value={data}>
          {data + 543}
        </option>
      );
    });

    return (
      <>
        <option key={`df_0`} style={{ display: "none" }}>
          ปี
        </option>
        {optYear}
      </>
    );
  };

  const iSearch = async (item) => {
    setStateYear(item);
    const res_item = await defaultApi.getpreportEnterpriseMemberOwnershipYear(
      "enterpriseId=" + _setKeyFact.entpId + "&atYear=" + item
    );
    resItem(res_item.data);
  };

  const dateDefault = useMemo(() => {
    const date = new Date();

    return stateYear
      ? convertDateThai2(stateYear.toString(), "year")
      : convertDateThai2(date, "year");
  }, [stateYear]);

  useEffect(() => {
    info();
  }, [info]);

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.date_filter}`}>
        <select
          style={{ color: "black" }}
          name=""
          id=""
          onChange={(e) => {
            iSearch(e.target.value);
          }}
        >
          {dateTime()}
        </select>
      </div>

      <h4
        className={`${styles.title_text}`}
        style={{ background: themeColor.backgroundColor }}
      >
        สถานะการถือครองที่ดินการปลูกเชื้อเพลิง
      </h4>

      <h5 className={`${styles.title_text_}`}>{_resName}</h5>

      <div className={`${styles.show_date}`}>
        <p>ข้อมูลล่าสุด ณ ปีที่</p>
        <p>{dateDefault}</p>
      </div>

      <div className={`${styles.charts_}`}>
        <ChartsStatus ref={chart} datain={_resItem} yearChk={stateYear} />
      </div>

      <div className={`${styles.export_}`}>
        <ExportPopup
          label={"report"}
          trigger={_triggerExport}
          event={{ csv: downloadCSV, image: downloadImage }}
        />
        <button
          id="exporting"
          type="button"
          className={`${styles.btn_}`}
          onClick={() => {
            setTriggerExport(!_triggerExport);
          }}
        >
          Export
        </button>
      </div>
    </div>
  );
};

export default ReportStatus;
