import React, { useState, useEffect } from "react";
import HighCharts from "../../../components/Highcharts/HighCharts";
import styles from "../scss/Index.module.scss";
import { formatterTooltip, formatterTooltip_baht, formatterXaxis_1, formatterYaxis } from "../../../helpers/suffixchart.helper";

let ranges = [
  { divider: 1e18, suffix: "E" },
  { divider: 1e15, suffix: "P" },
  { divider: 1e12, suffix: "T" },
  { divider: 1e9, suffix: "G" },
  { divider: 1e6, suffix: "M" },
  { divider: 1e3, suffix: "k" },
];

const ChartIncome = ({ valueInput, yearList, setYear, selYear }) => {
  window.ChartTitleUnit = {
    title: 'เดือน',
    unit: ['บาท'],
    totalStatus : false
  }

  const [stateChartOptions, setStateChartOptions] = useState({
    chart: {
      type: "column",
      height: 190,
    },
    title: {
      text: "",
    },
    exporting: {
      enabled: false,
    },
    xAxis: {
      categories: [
        "ม.ค.",
        "ก.พ.",
        "มี.ค.",
        "เม.ย.",
        "พ.ค.",
        "มิ.ย.",
        "ก.ค.",
        "ส.ค.",
        "ก.ย.",
        "ต.ค.",
        "พ.ย.",
        "ธ.ค.",
      ],
      labels: {
        style: {
          fontSize: "11px",
        },
      },
    },
    yAxis: {
      title: {
        text: "บาท",
      },
      stackLabels: {
        enabled: false
      },
      labels: {
        formatter: formatterYaxis,
        style: {
          fontSize: "11px",
        },
      },
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
          formatter: formatterXaxis_1
        },
      },
      line: {
        dataLabels: {
          enabled: true,
        },
        enableMouseTracking: false,
      },
    },
    tooltip: {
      enabled: true,
      ...formatterTooltip_baht
    },
    colors: ["#0086ea"],
    series: [],
  });

  const optionYear = (lst) => {
    try {
      return lst.map((data, index) => {
        return (
          <option key={index} value={data.year}>
            {data.yearName}
          </option>
        );
      });
    } catch (error) { }
  };

  useEffect(() => {
    let income = [];

    try {
      for (
        let index = 0;
        index < valueInput?.enterpriseIncome?.length;
        index++
      ) {
        const item = valueInput?.enterpriseIncome[index]?.value;
        if (item === null) {
          income[index] = 0;
        } else {
          income[index] = item;
        }
      }
    } catch (error) { }

    const handleResize = () => {
      const infoListHeight =
        document.querySelector("#info_list")?.clientHeight + 4;
      const newHeight =
        document.querySelector("aside")?.clientHeight / 2 - infoListHeight;

      setStateChartOptions((prevOptions) => ({
        ...prevOptions,
        chart: {
          ...prevOptions.chart,
          height: newHeight,
        },
        series: [
          {
            name: "รายได้ประจำปี",
            data: income,
          },
        ],
      }));
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [valueInput]);

  return (
    <div className={`${styles.chart_income} mx-auto`}>
      <div className="d-flex justify-content-end align-items-center">
        <span className={`${styles.year_title}`}>ปี :</span>
        <select
          style={{ color: "black" }}
          className={`${styles.select_year}`}
          onChange={(e) => {
            setYear(e.target.value);
          }}
        >
          {optionYear(yearList)}
        </select>
      </div>
      <h6
        className="d-flex justify-content-center text-center"
        style={{ fontSize: "14px" }}
      >
        รายได้เฉลี่ยของวิสาหกิจประจำปี {Number(selYear) + 543}
      </h6>
      <HighCharts options={stateChartOptions} />
    </div>
  );
};

export default ChartIncome;
