import React from "react";
import HighCharts from "../../../../components/Highcharts/HighCharts";
import styles from '../scss/PowerPlantsInfo.module.scss';
import { formatterYaxis, formatterXaxis_1, formatterTooltip } from "../../../../helpers/suffixchart.helper"

const ChartsPowerPlants = React.forwardRef((prop, ref) => {

  let ranges = [
    { divider: 1e18, suffix: "E" },
    { divider: 1e15, suffix: "P" },
    { divider: 1e12, suffix: "T" },
    { divider: 1e9, suffix: "G" },
    { divider: 1e6, suffix: "M" },
    { divider: 1e3, suffix: "K" },
  ];


  let target = []
  let produce = []

  try {

    let date = new Date()
    let dfyears = date.getFullYear()
    let dfmonths = date.getMonth() + 1
    let year = Number(prop.datetime.year)

    let x0 = prop.datain.factoryPowerGenerate
    let x1 = prop.datain.factoryPowerGenerate


    for (let index = 0; index < x0.length; index++) {
      if (year === dfyears) {
        if (x0[index].month <= dfmonths) {
          produce[index] = Number(Number(x0[index].powerGenerate).toLocaleString(undefined, { maximumFractionDigits: 2 }).replace(",", ""))
        } else if (x0[index].month > dfmonths) {
          produce[index] = null
        }
      } else if (year < dfyears) {
        produce[index] = Number(Number(x0[index].powerGenerate).toLocaleString(undefined, { maximumFractionDigits: 2 }).replace(",", ""))
      }

    }

    for (let index = 0; index < x1.length; index++) {
      if (year === dfyears) {
        if (x1[index].month <= dfmonths) {
          target[index] = Number(Number(x1[index].factoryCapacity).toLocaleString(undefined, { maximumFractionDigits: 2 }).replace(",", ""))
        } else if (x1[index].month > dfmonths) {
          target[index] = null
        }
      } else if (year < dfyears) {
        target[index] = Number(Number(x1[index].factoryCapacity).toLocaleString(undefined, { maximumFractionDigits: 2 }).replace(",", ""))
      }


    }
  } catch (error) {

  }

  const options = {
    chart: {
      type: "column",
      // type: "line",
      height: 400,
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: [
        "ม.ค.",
        "ก.พ.",
        "มี.ค.",
        "เม.ย.",
        "พ.ค.",
        "มิ.ย.",
        "ก.ค.",
        "ส.ค.",
        "ก.ย.",
        "ต.ค.",
        "พ.ย.",
        "ธ.ค.",
      ],
    },
    yAxis: {
      labels: {
        enabled: true,
        formatter: formatterYaxis,
      },
      title: {
        text: "ปริมาณผลิตไฟฟ้า (kWh)",
      },
    },
    tooltip: {
      //pointFormat: "<b>{series.name}: {point.y} MW</b>",
      style: {
        fontSize: "18px",
      },
      enabled: true,
      ...formatterTooltip
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          // format: "{point.y} คน",
          style: {
            fontSize: "16px",
          },
          formatter: formatterXaxis_1,
        },
      },
      series: {
        marker: {
          enabled: false
        },
        enableMouseTracking: true,
        dataLabels: {
          enabled: true,
          formatter: function () {
            for (let i = 0; i < ranges.length; i++) {
              if (this.y >= ranges[i].divider) {
                return (
                  Number(this.y / ranges[i].divider).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + ranges[i].suffix
                );
              }
            }
            if (Number(this.y) === 0) {
              return null
              //return this.y.toString();
            } else {
              return Number(this.y).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
            }
          },
          style: {
            fontSize: "16px",
          },
        }
      },
    },
    exporting: {
      chartOptions: { // specific options for the exported image
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true,
              style: {
                fontSize: "6px",
              },
              formatter: function () {
                for (let i = 0; i < ranges.length; i++) {
                  if (this.y >= ranges[i].divider) {
                    return (
                      Number(this.y / ranges[i].divider).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + ranges[i].suffix
                    );
                  }
                }
                if (Number(this.y) === 0) {
                  return null
                  //return this.y.toString();
                } else {
                  return Number(this.y).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
                }
              },
            },
            enableMouseTracking: true,
          },
        },
        xAxis: {
          labels: {
            style: {
              fontSize: '6px'
            },
          }
        },
        yAxis: {
          labels: {
            style: {
              fontSize: '6px'
            },
          }
        },
      },
      buttons: {
        contextButton: {
          enabled: false,
        },
        button: {
          text: "Export",
          theme: {
            fill: "#f9df70",
            stroke: "#f9df70",
            states: {
              hover: {
                fill: "#fcc",
                stroke: "#f00",
              },
              select: {
                fill: "#cfc",
                stroke: "#0f0",
              },
            },
          },
          menuItems: [
            "viewFullscreen",
            "separator",
            "downloadPNG",
            "downloadSVG",
            "downloadPDF",
            "separator",
            "downloadXLS",
          ],
        },
      },
      enabled: false,
    },
    navigation: {
      buttonOptions: {
        align: "right",
        verticalAlign: "bottom",
        y: 0,
      },
    },
    series: [
      {
        name: "ปริมาณไฟฟ้าที่ผลิตได้จริง",
        data: produce,
        color: "navy",
        //dashStyle: 'solid'
      },
      {
        type: 'line',
        name: "กำลังการผลิตไฟฟ้าของโรงงาน",
        data: target,
        color: "navy",
        dashStyle: 'dash'
      },
    ],
  };


  return (
    <div className={`${styles.charts}`}>
      <HighCharts ref={ref} options={options} />
    </div>
  );
});

export default ChartsPowerPlants;
