import React, { useCallback, useEffect, useMemo } from "react";
import WKT from "ol/format/WKT";
import { Vector as VectorSource } from "ol/source";
import { Vector as VectorLayer } from "ol/layer";
import { Fill, Stroke, Style } from "ol/style";
import { defaultApi } from "../../../services/api";

const CropLayer = ({
  _map,
  _allCrop,
  _layerType,
  _newPlantArr,
  _setCropDetail,
  _setOpened,
  _setLayerClick,
  _statusName4,
}) => {
  const filterCrp = useMemo(() => {
    const datas = _allCrop.filter(
      (item) =>
        item.geom !== null && item.cropStatus !== _statusName4?.harvested
    );
    return datas;
  }, [_allCrop, _statusName4]);

  const setStrokeColor = useCallback(
    (plant) => {
      let color;
      _newPlantArr?.forEach((item) => {
        if (item.name === plant) {
          color = item.color;
        }
      });
      return color;
    },
    [_newPlantArr]
  );
  const setFillColor = useCallback(
    (plant) => {
      let color;
      let alpha = 0.6;
      _newPlantArr?.forEach((item) => {
        if (item.name === plant) {
          color = item.color;
        }
      });
      // Remove the '#' symbol if present
      color = color?.replace("#", "");

      // Extract the RGB components
      const red = parseInt(color?.substring(0, 2), 16);
      const green = parseInt(color?.substring(2, 4), 16);
      const blue = parseInt(color?.substring(4, 6), 16);

      // Convert alpha to a value between 0 and 1
      alpha = parseFloat(alpha);

      // Create and return the RGBA string
      const rgba = `rgba(${red}, ${green}, ${blue}, ${alpha})`;
      return rgba;
    },
    [_newPlantArr]
  );

  const onClearLayer = useCallback(() => {
    /* Clear crop layers : START */
    _map
      ?.getLayers()
      .getArray()
      .filter((layer) => layer.get("crpId"))
      .forEach((layer) => _map.removeLayer(layer));
    /* Clear crop layers : END */
  }, [_map]);
  const _createLayerCrp = useCallback(() => {
    /* Clear crop layers : START */
    _map
      ?.getLayers()
      .getArray()
      .filter((layer) => layer.get("crpId"))
      .forEach((layer) => _map.removeLayer(layer));
    /* Clear crop layers : END */

    /* Create crop layers : START */
    for (let i = 0; i < filterCrp.length; i++) {
      const element = filterCrp[i];
      const wkt = `${element.geom}`;
      const format = new WKT();
      const feature = format.readFeature(wkt, {
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857",
      });
      const vector = new VectorLayer({
        source: new VectorSource({
          features: [feature],
          loader: () => {
            feature.setProperties({
              crpId: element.id,
              plantType: element?.plantType?.name,
            });
          },
        }),
        style: (feature) => {
          const plantType = feature.getProperties().plantType;
          return [
            new Style({
              stroke: new Stroke({
                color: setStrokeColor(plantType),
                // lineDash: [4],
                width: 2,
              }),
              fill: new Fill({
                color: setFillColor(plantType),
              }),
            }),
          ];
        },
      });
      vector.setProperties({
        crpId: element.id,
        plantType: element?.plantType?.name,
      });
      _map?.addLayer(vector);
    }
    /* Create crop layers : END */
  }, [_map, filterCrp, setStrokeColor, setFillColor]);

  const getCrop = useCallback(
    async (id) => {
      try {
        const res = await defaultApi.getCrop(id);
        _setCropDetail(res.data);
      } catch (error) {
        console.log(error);
      }
    },
    [_setCropDetail]
  );

  const clickHandler = useCallback(
    (evt) => {
      _map.forEachFeatureAtPixel(evt.pixel, (feature, layer) => {
        const crpId = feature.getProperties().crpId;
        if (crpId) {
          getCrop(crpId);
          _setLayerClick("crop");
          _setOpened(true);
        }
      });
    },
    [_map, _setOpened, _setLayerClick, getCrop]
  );

  useEffect(() => {
    if (_layerType.active3) {
      _createLayerCrp();
      _map?.un("click", clickHandler);
      _map?.on("click", clickHandler);
    } else {
      onClearLayer();
      _map?.un("click", clickHandler);
      _setCropDetail(null);
    }
  }, [
    _createLayerCrp,
    onClearLayer,
    clickHandler,
    _layerType,
    _map,
    _setCropDetail,
  ]);

  return <></>;
};

export default CropLayer;
