import React, { useState } from 'react'
import { toast } from 'react-hot-toast'
import { defaultApi } from '../../../services/api'
import Message from '../../../utils/message/Message'
import styles from '../scss/Popupform.module.scss'


export const FormManageCordData1 = ({ data, lst, setDefaultState, getInfomation, searchByFactory }) => {

    const [_factoryChange, setFactoryChange] = useState('')

    const clearData = () => {
        setFactoryChange(null)
        document.getElementById('exampleModal1').reset();
        document.getElementById('frmFactoryCor').reset();
    }


    const submitData = async () => {
        const loading = toast.loading(Message.notify.update_loading);

        const parames = {
            ...data?.item, factoryId: _factoryChange ? +_factoryChange : +data?.item?.factoryId
        }
        const { user, ...newParames } = parames

        try {
            await defaultApi.updateFactoryMember(+data?.item?.id, newParames)
            toast.success(Message.notify.update_success, { duration: 3000 });
            getInfomation()
            searchByFactory(_factoryChange ? +_factoryChange : +data?.item?.factoryId)
            document.getElementById("clsBtnFormManageCordData1").click()
        } catch (error) {
            console.log(error);
            toast.error(Message.notify.update_error, { duration: 3000 })
        } finally {
            toast.dismiss(loading);
        }
    }

    return (
        <>
            <div
                className="modal fade"
                id="exampleModal1"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="d-flex flex-column align-items-center justify-content-center">

                            <h1 className={`modal-title mt-3 fs-5 ${styles.h5}`} id="exampleModalLabel">แก้ไขผู้ประสานงานโรงงานไฟฟ้า</h1>
                            <hr className={`w-100 me-1 ms-1 border border-dark-subtle`} />
                        </div>
                        <div className="modal-body ">
                            <form id='frmFactoryCor' className={`${styles.form_} mx-auto`}>
                                <div key={'0'}>
                                    <div className={`${styles.input_flex}`}>
                                        <div className={` d-flex flex-row justify-content-between m-2 ${styles.input_}`}>
                                            <h5 className={`col ${styles.h5}`}>โรงไฟฟ้า : </h5>
                                            <select id='selFactory'
                                                className='col'
                                                value={`${_factoryChange ? _factoryChange : data?.item?.factoryId}`}
                                                onChange={(e) => {
                                                    setFactoryChange(e.target.value)
                                                }}
                                            >
                                                {lst.map((datax) => {
                                                    return (<option value={`${datax?.id}`}>{datax?.name}</option>)
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className={`d-flex justify-content-center mb-3 ${styles.boxpopup}`}>
                            <button className={`${styles.savechangebtn}`} onClick={() => { submitData() }}><h4>บันทึก</h4></button>
                            <h4>|</h4>
                            <button id="clsBtnFormManageCordData1" className={`${styles.savechangebtn}`} data-bs-dismiss="modal" onClick={() => { clearData(); }}><h4>ยกเลิก</h4></button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
