import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/img/Logo";
import icon from "../../assets/img/Icon";
import useSwitch from "../../utils/hooks/useSwitch";
import { getProfileData } from "../../services/auth.slice";
import { removeCookies } from "../../helpers/cookies_storage.helper";
import { removeLocalStorage } from "../../helpers/local_storage.helper";
import styles from "./Header.module.scss";

const Header = () => {
  const navigate = useNavigate();
  const profile_ = useSelector(getProfileData);
  const [_switch, onSwitch] = useSwitch();

  const profileData = useSelector(getProfileData);

  const changeIconByRole = useMemo(() => {
    const role = profile_?.userGroupId;
    let _icon1;
    let _icon2;

    if (role === 1 || role === 5 || role === 4) {
      _icon1 = icon.profileFac;
      _icon2 = icon.logoutFac;
    } else if (role === 2) {
      _icon1 = icon.profileEnt;
      _icon2 = icon.logoutEnt;
    } else if (role === 3) {
      _icon1 = icon.profileGvm;
      _icon2 = icon.logoutGvm;
    }

    return { _icon1, _icon2 };
  }, [profile_]);

  const onLogout = () => {
    removeCookies("token");
    removeCookies("userProfile");
    removeLocalStorage("allFactory");
    removeLocalStorage("allEnterprise");
    navigate("/");
  };
  const onLogoutGuest = (pathname) => {
    removeCookies("token");
    removeCookies("userProfile");
    removeLocalStorage("allFactory");
    removeLocalStorage("allEnterprise");
    navigate(pathname);
  };

  useEffect(() => {
    const onCloseDropdownMenu = (event) => {
      const element = event.target;
      const dropdownIcon = document.querySelector("#menu_icon");
      const dropdownBox = document.querySelector("#meun_dropdown_box");

      if (!dropdownIcon || !dropdownBox) {
        return;
      }

      if (!dropdownIcon.contains(element) & !dropdownBox.contains(element)) {
        onSwitch(false);
      }
    };

    if (_switch) {
      document.addEventListener("click", onCloseDropdownMenu);
    }

    return () => {
      document.removeEventListener("click", onCloseDropdownMenu);
    };
  }, [_switch]);

  return (
    <header className={`${styles.container}`}>
      <h1 className={`title ${styles.title_}`}>
        โรงไฟฟ้าชุมชนเพื่อเศรษฐกิจฐานราก
      </h1>
      <div className={`${styles.icon_menu}`}>
        <img src={logo.energyLogo} alt="" width="170" />
        <img
          id="menu_icon"
          className={`${styles.icon_}`}
          src={icon.hamburger}
          alt=""
          onClick={() => {
            onSwitch((prev) => !prev);
          }}
        />
      </div>

      {_switch && (
        <div id="meun_dropdown_box" className={`${styles.modal_menu}`}>
          <div
            className={`${styles.menu_}`}
            onClick={() => {
              if (profileData.userGroupId === 4) {
                onLogoutGuest("/login");
              } else if (profileData.userGroupId === 5) {
                navigate("/admin/profile");
              } else {
                navigate("/profile");
              }
              //navigate("/profile");
              onSwitch(false);
            }}
          >
            {profileData.userGroupId !== 4 ? (
              <>
                <img src={changeIconByRole._icon1} alt="" />
                <p>โปรไฟล์ผู้ใช้งาน</p>
              </>
            ) : (
              <>
                <img src={changeIconByRole._icon1} alt="" />
                <p>เข้าสู่ระบบ</p>
              </>
            )}
          </div>
          <div
            className={`${styles.menu_}`}
            onClick={() => {
              onLogout();
              onSwitch(false);
            }}
          >
            {profileData.userGroupId !== 4 ? (
              <>
                <img src={changeIconByRole._icon2} alt="" />
                <p>ออกจากระบบ</p>
              </>
            ) : (
              <>
                <img src={changeIconByRole._icon2} alt="" />
                <p>กลับหน้าแรก</p>
              </>
            )}
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
