import React from "react";
import SwitchMenu from "../_layout/SwitchMenu";
import EditContractF from "./contract_farming/EditContractF";
import AddContractF from "./contract_farming/AddContractF";
import EditContractPpa from "./contract_ppa/EditContractPpa";
import AddContractPpa from "./contract_ppa/AddContractPpa";
import useSwitch from "../../../../../utils/hooks/useSwitch";
import useSwitchBox from "../../../../../utils/hooks/useSwitchBox";
import styles from "./scss/Contract.module.scss";

const Contract = () => {
  const [_switch, onSwitch] = useSwitch();
  const [switchBoxR, onSwitchBoxR, switchBoxL, onSwitchBoxL] = useSwitchBox();
  return (
    <div className={`${styles.container}`}>
      <h4 className={`${styles.title_text}`}>การจัดการสัญญา</h4>
      <SwitchMenu
        onSwitch={onSwitch}
        btn1={"สัญญาการรับซื้อเชื้อเพลิง(Contract Farming)"}
        btn2={"สัญญาขายไฟฟ้า (PPA)"}
      />
      <div className={`${styles.content_box}`}>
        {!_switch && (
          <div className={`${styles.content_left}`}>
            <div className={`${styles.contract_farming} mx-auto`}>
              {switchBoxL && <AddContractF onSwitchBoxL={onSwitchBoxL} />}
              {!switchBoxL && <EditContractF onSwitchBoxL={onSwitchBoxL} />}
            </div>
          </div>
        )}
        {_switch && (
          <div className={`${styles.content_right}`}>
            <div className={`${styles.contract_ppa} mx-auto`}>
              {switchBoxR && <AddContractPpa onSwitchBoxR={onSwitchBoxR} />}
              {!switchBoxR && <EditContractPpa onSwicthBoxR={onSwitchBoxR} />}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Contract;
