import React, { useMemo } from "react";
import HighCharts from "../../../../components/Highcharts/HighCharts";
import styles from "../scss/ReportCompare.module.scss";
import { formatterTooltip, formatterYaxis } from "../../../../helpers/suffixchart.helper";

const ChartsCompare = React.forwardRef((prop, ref) => {
  let ranges = [
    { divider: 1e18, suffix: "E" },
    { divider: 1e15, suffix: "P" },
    { divider: 1e12, suffix: "T" },
    { divider: 1e9, suffix: "G" },
    { divider: 1e6, suffix: "M" },
    { divider: 1e3, suffix: "K" },
  ];

  const datas = useMemo(() => {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let currYear = [];
    let lastYeat = [];

    for (
      let index = 0;
      index < prop.datain?.enterpriseMemberCountFirstYear?.length;
      index++
    ) {
      if (Number(year) === Number(prop.yearChk)) {
        if (prop.datain.enterpriseMemberCountFirstYear[index].month > month) {
          currYear[index] = null;
          lastYeat[index] = null;
        } else {
          currYear[index] =
            prop.datain.enterpriseMemberCountFirstYear[index].value;
          lastYeat[index] =
            prop.datain.enterpriseMemberCountSecondYear[index].value;
        }
      } else if (Number(prop.yearChk) < Number(year)) {
        currYear[index] =
          prop.datain.enterpriseMemberCountFirstYear[index].value;
        lastYeat[index] =
          prop.datain.enterpriseMemberCountSecondYear[index].value;
      }
    }

    return { currYear, lastYeat };
  }, [prop.datain, prop.yearChk]);

  const options = {
    chart: {
      type: "line",
      height: 450,
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: [
        "ม.ค.",
        "ก.พ.",
        "มี.ค.",
        "เม.ย.",
        "พ.ค.",
        "มิ.ย.",
        "ก.ค.",
        "ส.ค.",
        "ก.ย.",
        "ต.ค.",
        "พ.ย.",
        "ธ.ค.",
      ],
      labels: {
        style: {
          fontSize: "13px",
        },
      },
    },
    yAxis: {
      title: {
        text: "จำนวน (คน)",
      },
      labels: {
        formatter: formatterYaxis,
        style: {
          fontSize: "13px",
        },
      },
    },
    tooltip: {
      enabled: true,
      ...formatterTooltip,
      // shared: true,
      // split: false,
      style: {
        fontSize: "18px",
      },
      // formatter: function () {
      //   var point = this;
      //   let i = "";
      //   for (let index = 0; index < point.points.length; index++) {
      //     const element = point.points[index];
      //     //console.log(element);
      //     if (index === 0) {
      //       i =
      //         i +
      //         (point.x +
      //           "<br/><b>" +
      //           element.series.name +
      //           "</b> " +
      //           Number(element.y).toLocaleString() +
      //           " คน");
      //     } else {
      //       i =
      //         i +
      //         ("<br/><b>" +
      //           element.series.name +
      //           "</b> " +
      //           Number(element.y).toLocaleString() +
      //           " คน");
      //     }
      //   }
      //   return i;
      // },
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
          formatter: function () {
            for (let i = 0; i < ranges.length; i++) {
              if (this.y >= ranges[i].divider) {
                return this.y.toString() + ranges[i].suffix;
              }
            }
            if (Number(this.y) === 0) {
              return null;
            } else {
              return this.y.toString();
            }
          },
          style: {
            fontSize: "16px",
          },
        },
      },
      line: {
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "16px",
          },
          formatter: function () {
            for (let i = 0; i < ranges.length; i++) {
              if (this.y >= ranges[i].divider) {
                return this.y.toString() + ranges[i].suffix;
              }
            }
            if (Number(this.y) === 0) {
              return null;
            } else {
              return this.y.toString();
            }
          },
        },
        enableMouseTracking: true,
      },
      series: {
        lineWidth: 3,
        marker: {
          enabled: false,
        },
      },
    },
    colors: ["#ffb92e", "#0086ea"],
    exporting: {
      chartOptions: {
        // specific options for the exported image
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true,
              style: {
                fontSize: "6px",
              },
              formatter: function () {
                for (let i = 0; i < ranges.length; i++) {
                  if (this.y >= ranges[i].divider) {
                    return (
                      Number(this.y / ranges[i].divider).toLocaleString(
                        undefined,
                        { maximumFractionDigits: 0, minimumFractionDigits: 0 }
                      ) + ranges[i].suffix
                    );
                  }
                }
                if (Number(this.y) === 0) {
                  return null;
                  //return this.y.toString();
                } else {
                  return Number(this.y).toLocaleString(undefined, {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  });
                }
              },
            },
            enableMouseTracking: true,
          },
        },
        xAxis: {
          labels: {
            style: {
              fontSize: "6px",
            },
          },
        },
        yAxis: {
          labels: {
            style: {
              fontSize: "6px",
            },
          },
        },
      },
      buttons: {
        contextButton: {
          enabled: false,
        },
        button: {
          text: "Export",
          theme: {
            fill: "#f9df70",
            stroke: "#f9df70",
            states: {
              hover: {
                fill: "#fcc",
                stroke: "#f00",
              },
              select: {
                fill: "#cfc",
                stroke: "#0f0",
              },
            },
          },
          menuItems: [
            "viewFullscreen",
            "separator",
            "downloadPNG",
            "downloadSVG",
            "downloadPDF",
            "separator",
            "downloadXLs",
          ],
        },
      },
      enabled: false,
    },
    navigation: {
      buttonOptions: {
        align: "right",
        verticalAlign: "bottom",
        y: 0,
      },
    },
    series: [
      {
        name:
          prop.yearCompare.stYear === ""
            ? "ปีก่อน"
            : Number(prop.yearCompare.stYear) + 543,
        data: datas?.lastYeat,
      },
      {
        name:
          prop.yearCompare.edYear === ""
            ? "ปีปัจจุบัน"
            : Number(prop.yearCompare.edYear) + 543,
        data: datas?.currYear,
      },
    ],
  };
  return (
    <div className={`${styles.charts}`}>
      <HighCharts ref={ref} options={options} />
    </div>
  );
});

export default ChartsCompare;
