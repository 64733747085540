import React from "react";
import HighCharts from "../../../../components/Highcharts/HighCharts";
import styles from '../scss/MemberInfo.module.scss';
import { formatterTooltip, formatterXaxis_1, formatterYaxis } from "../../../../helpers/suffixchart.helper";

const ChartsMemberAmount2 = React.forwardRef((prop, ref) => {

  let ranges = [
    { divider: 1e18, suffix: "E" },
    { divider: 1e15, suffix: "P" },
    { divider: 1e12, suffix: "T" },
    { divider: 1e9, suffix: "G" },
    { divider: 1e6, suffix: "M" },
    { divider: 1e3, suffix: "K" },
  ];


  let curYearLst = []
  let lstYearLst = []
  let fstYear = '', sndYear = ''

  try {
    let date = new Date()
    let dfyears = date.getFullYear()
    let dfmonths = date.getMonth() + 1
    let year = Number(prop.datetime.year)

    let curYear = prop.datain.enterpriseMemberCountFirstYear
    let lstYear = prop.datain.enterpriseMemberCountSecondYear

    fstYear = prop.datetimelegend.year
    sndYear = prop.datetimelegend.toyear

    curYear.forEach((data, index) => {

      if (year === dfyears) {
        if (data.month <= dfmonths) {
          curYearLst[index] = data.value
        } else if (data.month > dfmonths) {
          curYearLst[index] = null
        }
      } else if (year < dfyears) {
        curYearLst[index] = data.value
      }

    })

    lstYear.forEach((data, index) => {

      if (year === dfyears) {
        if (data.month <= dfmonths) {
          lstYearLst[index] = data.value
        } else if (data.month > dfmonths) {
          lstYearLst[index] = null
        }
      } else if (year < dfyears) {
        lstYearLst[index] = data.value
      }

    })
  } catch (error) {

  }

  const options = {
    chart: {
      type: "column",
      // type: "line",
      height: 400,
    },
    title: {
      text: "",
    },
    xAxis: {
      title: {
        text: "เดือนที่"
      },
      categories: [
        "ม.ค.",
        "ก.พ.",
        "มี.ค.",
        "เม.ย.",
        "พ.ค.",
        "มิ.ย.",
        "ก.ค.",
        "ส.ค.",
        "ก.ย.",
        "ต.ค.",
        "พ.ย.",
        "ธ.ค.",
      ],
    },
    yAxis: {
      labels: {
        enabled: true,
        formatter: formatterYaxis,
      },
      title: {
        text: "จำนวน ( คน )",
      },
    },
    tooltip: {
      //pointFormat: "<b>{series.name}: {point.y} คน</b>",
      style: {
        fontSize: "18px",
      },
      enabled: true,
      ...formatterTooltip
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          // format: "{point.y} คน",
          formatter: formatterXaxis_1,
          // formatter: function () {
          //   for (let i = 0; i < ranges.length; i++) {
          //     if (this.y >= ranges[i].divider) {
          //       return (
          //         (this.y / ranges[i].divider).toString() + ranges[i].suffix
          //       );
          //     }
          //   }
          //   return this.y.toString();
          // },
          style: {
            fontSize: "16px",
          },
        },
      },
      series: {
        dataLabels: {
          enabled: true,
          formatter: function () {
            for (let i = 0; i < ranges.length; i++) {
              if (this.y >= ranges[i].divider) {
                return (
                  (this.y / ranges[i].divider).toString() + ranges[i].suffix
                );
              }
            }
            if (this.y === 0) {
              return null
            } else {
              return this.y.toString();
            }
          },
          style: {
            fontSize: "16px",
          },
        },
        marker: {
          enabled: false
        },
        enableMouseTracking: true,
      },
      // line: {
      //   enableMouseTracking: false,
      // },
    },
    exporting: {
      chartOptions: { // specific options for the exported image
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true,
              style: {
                fontSize: "6px",
              },
              formatter: function () {
                for (let i = 0; i < ranges.length; i++) {
                  if (this.y >= ranges[i].divider) {
                    return (
                      Number(this.y / ranges[i].divider).toLocaleString(undefined, { maximumFractionDigits: 0, minimumFractionDigits: 0 }) + ranges[i].suffix
                    );
                  }
                }
                if (Number(this.y) === 0) {
                  return null
                  //return this.y.toString();
                } else {
                  return Number(this.y).toLocaleString(undefined, { maximumFractionDigits: 0, minimumFractionDigits: 0 });
                }
              },
            },
            enableMouseTracking: true,
          },
        },
        xAxis: {
          labels: {
            style: {
              fontSize: '6px'
            },
          }
        },
        yAxis: {
          labels: {
            style: {
              fontSize: '6px'
            },
          }
        },
      },
      buttons: {
        contextButton: {
          enabled: false,
        },
        button: {
          text: "Export",
          theme: {
            fill: "#f9df70",
            stroke: "#f9df70",
            states: {
              hover: {
                fill: "#fcc",
                stroke: "#f00",
              },
              select: {
                fill: "#cfc",
                stroke: "#0f0",
              },
            },
          },
          menuItems: [
            "viewFullscreen",
            "separator",
            "downloadPNG",
            "downloadSVG",
            "downloadPDF",
            "separator",
            "downloadXLS",
          ],
        },
      },
      enabled: false,
    },
    navigation: {
      buttonOptions: {
        align: "right",
        verticalAlign: "bottom",
        y: 0,
      },
    },

    /**
     * fstYear = prop.datetimelegend.year
    sndYear
     */
    series: [
      {
        name: fstYear === '' || fstYear === undefined || fstYear === 'ปี' ? "ปีปัจจุบัน" : (Number(fstYear) + 543),
        data: curYearLst,
        color: "pink",
      },
      {
        name: sndYear === '' || sndYear === undefined || sndYear === 'ปี' ? "ปีก่อน" : (Number(sndYear) + 543),
        data: lstYearLst,
        color: "yellow",
      },
    ],
  };

  return (
    <div className={`${styles.charts}`}>
      <HighCharts ref={ref} options={options} />
    </div>
  )
  // return (
  //   <div className="charts_member_amount position-relative">
  //     <HighCharts options={options} />
  //     <div className="button_y mt-3 d-flex justify-content-end align-items-center">
  //       <button className="btn_">Export</button>
  //     </div>
  //   </div>
  // );
});

export default ChartsMemberAmount2;
