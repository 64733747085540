import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { toast } from "react-hot-toast";
import Header from "../../layouts/header/Header";
import Navbar from "../../layouts/navbar/Navbar";
import Footer from "../../layouts/footer_2/Footer";
import useSwitch from "../../utils/hooks/useSwitch";
import { defaultApi } from "../../services/api";
import {
  CheckTitle,
  CheckFname,
  CheckLname,
  CheckEmail,
  CheckMobile,
  CheckPassword,
  CheckPasswordNotMatch,
} from "../../utils/validation_input/Validation";
import Message from "../../utils/message/Message";
import { showPass, showPassCf } from "./functions/index";
import { QueryGetAll } from "../../helpers/api.helper";
import { createUrlImage } from "../../helpers/func.helper";
import { getProfileData } from "../../services/auth.slice";
import { removeCookies } from "../../helpers/cookies_storage.helper";
import { removeLocalStorage } from "../../helpers/local_storage.helper";
import styles from "./scss/Profile.module.scss";

const title = [
  {
    id: 1,
    name: "นาย",
  },
  {
    id: 2,
    name: "นาง",
  },
  {
    id: 3,
    name: "นางสาว",
  },
];

// const initData = {
//   title: "",
//   firstname: "",
//   lastname: "",
//   username: "",
//   email: "",
//   mobile: "",
//   userGroupId: "",
//   avatar: "",
//   userOrgId: "",
//   userLocalgroupid: "",
// };

const Profile = () => {
  const navigate = useNavigate();
  const profile_ = useSelector(getProfileData);
  const [_switch, onSwitch, _switch2, onSwitch2] = useSwitch();

  const [_data, setData] = useState([]);
  const [dataRes, setDataRes] = useState([]);
  const [imgData, setImgData] = useState({
    file: "",
  });
  const [validErr, setValidErr] = useState(false);
  const [validErr2, setValidErr2] = useState(false);
  const [_showPass, setShowPass] = useState(false);
  const [_showPassCf, setShowPassCf] = useState(false);

  const changeThemeByRole = useMemo(() => {
    const role = profile_?.userGroupId;
    let theme;

    if (role === 2) {
      theme = {
        color: "#789b33",
        backgroundColor: "#789b33",
        class: "ent",
      };
    } else if (role === 3) {
      theme = {
        color: "#2f9ced",
        backgroundColor: "#2f9ced",
        class: "gov",
      };
    } else {
      theme = "";
    }

    return theme;
  }, [profile_]);

  const changeImg = async (e) => {
    const file = e.target.files[0];
    const file_val = document.querySelector("#file");
    const param1 = "Profile";
    const param2 = new FormData();
    param2.append("FormFile", e.target.files[0]);

    if (!_data?.avatar) {
      console.log("Create image");
      try {
        const res = await defaultApi.createFileDetail(param1, param2);
        console.log(res.statusText);
        setData({ ..._data, avatar: res?.data.id });
        setImgData({ file: URL.createObjectURL(file) });
        file_val.value = "";
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("Update image");
      try {
        const res = await defaultApi.updateFileDetail(
          _data?.avatar,
          param1,
          param2
        );
        console.log(res.statusText);
        setData({ ..._data, avatar: res?.data.id });
        setImgData({ file: URL.createObjectURL(file) });
        file_val.value = "";
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setData({ ..._data, [name]: value });
  };
  const onUpdateProfile = async () => {
    if (CheckFname(_data.firstname)) return setValidErr(true);
    if (CheckLname(_data.lastname)) return setValidErr(true);
    if (CheckEmail(_data.email)) return setValidErr(true);
    if (CheckMobile(_data.mobile)) return setValidErr(true);

    const loading = toast.loading(Message.notify.loading);
    const params = {};
    Object.keys(_data).forEach((key) => {
      params[key] = _data[key];
    });
    try {
      const res = await defaultApi.updateProfile(params);
      console.log(res.statusText);
      onSwitch(false);
      toast.success(Message.notify.update_success, { duration: 3000 });
      setValidErr(false);
      _getProfile();
    } catch (error) {
      console.log(error);
      setValidErr(true);
      toast.error(Message.notify.update_error, { duration: 3000 });
    } finally {
      toast.dismiss(loading);
    }
  };
  const onChangePassword = async () => {
    if (CheckPasswordNotMatch(_data.oldPassword, _data.newPassword))
      return setValidErr2(true);

    const loading = toast.loading(Message.notify.loading);
    // const userID = _data.id;
    const params = {
      oldPassword: _data.oldPassword,
      newPassword: _data.newPassword,
    };
    try {
      const res = await defaultApi.changePassword(params);
      console.log(res.statusText);
      toast.success(Message.notify.change_pass_ok, { duration: 3000 });
      setValidErr2(false);
      onSwitch2(false);
    } catch (error) {
      console.log(error);
      setValidErr2(true);
      if (error?.response.status === 400) {
        toast.error(Message.notify.change_pass_err_, { duration: 3000 });
      } else {
        toast.error(Message.notify.change_pass_err, { duration: 3000 });
      }
    } finally {
      toast.dismiss(loading);
    }
  };
  const onCancelChangePassword = () => {
    onSwitch2(false);
    setShowPass(false);
    setShowPassCf(false);
    _getProfile();
    setValidErr2(false);
  };

  const _getProfile = async () => {
    const qryGetAll = {
      $count: "",
      $expand: `%24expand=userGroup%2CuserLocalGroup%2C
      avatarNavigation%2CuserOrg`,
      $top: "",
      $skip: "",
      $filter: "",
      $orderby: "",
    };
    const _queryAll = QueryGetAll(qryGetAll);
    try {
      const res = await defaultApi.getProfile(_queryAll);
      const _data_ = {
        id: res.data.id,
        title: res.data.title,
        firstname: res.data.firstname,
        lastname: res.data.lastname,
        username: res.data.username,
        email: res.data.email,
        mobile: res.data.mobile,
        userGroupId: res.data.userGroupId,
        userGroup: res.data.userGroup,
        avatar: res.data.avatar,
        avatarNavigation: res.data.avatarNavigation,
        userOrgId: res.data.userOrgId,
        userLocalGroupid: res.data.userLocalGroupid,
        oldPassword: "",
        newPassword: "",
      };
      setData(_data_);
      setDataRes(_data_);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        removeCookies("token");
        removeCookies("userProfile");
        removeLocalStorage("allFactory");
        removeLocalStorage("allEnterprise");
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    _getProfile();
  }, []);

  return (
    <div className={`${styles.container} background_1`}>
      <Header />
      <section className={`section ${styles.section_}`}>
        <Navbar />
        <h3 style={{ backgroundColor: changeThemeByRole?.backgroundColor }}>
          โปรไฟล์
        </h3>
        <article className={`article ${styles.article_}`}>
          <aside className={`${styles.content_left}`}>
            <div className={`${styles.box}`}>
              <div className={`${styles.box_}`}>
                <div className={`${styles.box_img}`}>
                  {!_data?.avatar && !imgData.file && (
                    <div className={`${styles.no_img}`}>
                      <i>
                        <FaUser />
                      </i>
                    </div>
                  )}
                  {imgData.file ? (
                    <img src={imgData.file} alt="" />
                  ) : (
                    <img
                      src={createUrlImage(_data?.avatarNavigation?.filePath)}
                      alt=""
                    />
                  )}
                </div>
                {_switch && (
                  <Link to={`#`} onClick={openInputFile}>
                    เปลี่ยนรูปภาพ
                  </Link>
                )}
              </div>
              {/* input_file>hidden : START */}
              <input
                type="file"
                id="file"
                accept="image/jpeg, image/png"
                onChange={changeImg}
                hidden
              />
              <label htmlFor="file" id="file-label" hidden></label>
              {/* input_file>hidden : END */}
            </div>
          </aside>
          <aside className={`${styles.content_right}`}>
            <div className={`${styles.box}`}>
              {!_switch ? (
                <div className={`${styles.input_form}`}>
                  <div className={`${styles.input_flex}`}>
                    <div className={`${styles.input_1}`}>
                      <h5 style={{ color: changeThemeByRole?.color }}>
                        คำนำหน้าชื่อ
                      </h5>
                      <div className={`${styles.info_box}`}>
                        <p>{dataRes?.title}</p>
                      </div>
                    </div>
                    <div className={`${styles.input_2}`}></div>
                  </div>
                  <div className={`${styles.input_flex}`}>
                    <div className={`${styles.input_1}`}>
                      <h5 style={{ color: changeThemeByRole?.color }}>ชื่อ</h5>
                      <div className={`${styles.info_box}`}>
                        <p>{dataRes?.firstname}</p>
                      </div>
                    </div>
                    <div className={`${styles.input_2}`}>
                      <h5 style={{ color: changeThemeByRole?.color }}>
                        นามสกุล
                      </h5>
                      <div className={`${styles.info_box}`}>
                        <p>{dataRes?.lastname}</p>
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.input_flex}`}>
                    <div className={`${styles.input_1}`}>
                      <h5 style={{ color: changeThemeByRole?.color }}>อีเมล</h5>
                      <div className={`${styles.info_box}`}>
                        <p>{dataRes?.email}</p>
                      </div>
                    </div>
                    <div className={`${styles.input_2}`}>
                      <h5 style={{ color: changeThemeByRole?.color }}>
                        เบอร์โทรศัพท์
                      </h5>
                      <div className={`${styles.info_box}`}>
                        <p>{dataRes?.mobile}</p>
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.input_}`}>
                    <h5 style={{ color: changeThemeByRole?.color }}>
                      กลุ่มผู้ใช้งาน
                    </h5>
                    <div className={`${styles.info_box}`}>
                      <p>{dataRes?.userGroup?.name}</p>
                    </div>
                  </div>
                  <div className={`${styles.input_}`}>
                    <h5 style={{ color: changeThemeByRole?.color }}>
                      ชื่อผู้ใช้งาน
                    </h5>
                    <div className={`${styles.info_box}`}>
                      <p>{dataRes?.username}</p>
                    </div>
                  </div>
                  {!_switch2 ? (
                    <>
                      <div className={`${styles.input_}`}>
                        <h5 style={{ color: changeThemeByRole?.color }}>
                          รหัสผ่าน
                        </h5>
                        <div className={`${styles.info_box}`}>
                          <p>*********</p>
                        </div>
                      </div>
                      <div className={`${styles.password_edit}`}>
                        <button
                          type="button"
                          className={`${styles.btn_}`}
                          onClick={() => onSwitch2(true)}
                        >
                          แก้ไขรหัสผ่าน
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <form>
                        <div className={`${styles.input_}`}>
                          <h5 style={{ color: changeThemeByRole?.color }}>
                            รหัสผ่าน <span>(ใส่รหัสผ่านเดิม)</span>
                          </h5>
                          <div
                            className={`${styles.input_unit} ${
                              validErr2 && _data.oldPassword === ""
                                ? "error"
                                : ""
                            }`}
                          >
                            <input
                              type="password"
                              name="oldPassword"
                              id="oldPassword"
                              onChange={onChange}
                            />
                            <div className={`${styles.unit}`}>
                              {!_showPass ? (
                                <AiFillEye
                                  id="showPass"
                                  className={`${styles.ai_fill_eye}`}
                                  onClick={() => {
                                    showPass();
                                    setShowPass(true);
                                  }}
                                />
                              ) : (
                                <AiFillEyeInvisible
                                  id="showPass"
                                  className={`${styles.ai_fill_eye}`}
                                  onClick={() => {
                                    showPass();
                                    setShowPass(false);
                                  }}
                                />
                              )}
                            </div>
                          </div>
                          {validErr2 && (
                            <small className={`${styles.err_message}`}>
                              {CheckPassword(_data.oldPassword)}
                            </small>
                          )}
                        </div>
                        <div className={`${styles.input_}`}>
                          <h5 style={{ color: changeThemeByRole?.color }}>
                            รหัสผ่านใหม่
                          </h5>
                          <div
                            className={`${styles.input_unit} ${
                              validErr2 && _data.newPassword === ""
                                ? "error"
                                : ""
                            }`}
                          >
                            <input
                              type="password"
                              name="newPassword"
                              id="newPassword"
                              onChange={onChange}
                            />
                            <div className={`${styles.unit}`}>
                              {!_showPassCf ? (
                                <AiFillEye
                                  id="showPassCf"
                                  className={`${styles.ai_fill_eye}`}
                                  onClick={() => {
                                    showPassCf();
                                    setShowPassCf(true);
                                  }}
                                />
                              ) : (
                                <AiFillEyeInvisible
                                  id="showPassCf"
                                  className={`${styles.ai_fill_eye}`}
                                  onClick={() => {
                                    showPassCf();
                                    setShowPassCf(false);
                                  }}
                                />
                              )}
                            </div>
                          </div>
                          {validErr2 && (
                            <small className={`${styles.err_message}`}>
                              {CheckPasswordNotMatch(
                                _data.oldPassword,
                                _data.newPassword
                              )}
                            </small>
                          )}
                        </div>
                      </form>
                      <div className={`${styles.password_edit_right}`}>
                        <button
                          type="button"
                          form="pass_edit"
                          className={`${styles.btn_}`}
                          onClick={() => onChangePassword()}
                        >
                          ยืนยัน
                        </button>
                        <button
                          type="button"
                          className={`${styles.btn_}`}
                          onClick={onCancelChangePassword}
                        >
                          ยกเลิก
                        </button>
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <div className={`${styles.input_form}`}>
                  <form>
                    <div className={`${styles.input_flex}`}>
                      <div className={`${styles.input_1}`}>
                        <h5 style={{ color: changeThemeByRole?.color }}>
                          คำนำหน้าชื่อ
                        </h5>
                        <select name="title" id="" onChange={onChange}>
                          <option style={{ display: "none" }}>
                            {_data?.title}
                          </option>
                          {title.map((item) => {
                            return (
                              <option key={item.id} value={item.name}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                        {validErr && (
                          <small className={`${styles.err_message}`}>
                            {CheckTitle(_data.title)}
                          </small>
                        )}
                      </div>
                      <div className={`${styles.input_2}`}></div>
                    </div>
                    <div className={`${styles.input_flex}`}>
                      <div className={`${styles.input_1}`}>
                        <h5 style={{ color: changeThemeByRole?.color }}>
                          ชื่อ
                        </h5>
                        <input
                          type="text"
                          name="firstname"
                          id=""
                          className={`${
                            validErr && _data.firstname === "" ? "error" : ""
                          }`}
                          defaultValue={_data?.firstname}
                          onChange={onChange}
                        />
                        {validErr && (
                          <small className={`${styles.err_message}`}>
                            {CheckFname(_data.firstname)}
                          </small>
                        )}
                      </div>
                      <div className={`${styles.input_2}`}>
                        <h5 style={{ color: changeThemeByRole?.color }}>
                          นามสกุล
                        </h5>
                        <input
                          type="text"
                          name="lastname"
                          id=""
                          className={`${
                            validErr && _data.lastname === "" ? "error" : ""
                          }`}
                          defaultValue={_data?.lastname}
                          onChange={onChange}
                        />
                        {validErr && (
                          <small className={`${styles.err_message}`}>
                            {CheckLname(_data.lastname)}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className={`${styles.input_flex}`}>
                      <div className={`${styles.input_1}`}>
                        <h5 style={{ color: changeThemeByRole?.color }}>
                          อีเมล
                        </h5>
                        <input
                          type="email"
                          name="email"
                          id=""
                          className={`${
                            validErr && _data.email === "" ? "error" : ""
                          }`}
                          defaultValue={_data?.email}
                          onChange={onChange}
                        />
                        {validErr && (
                          <small className={`${styles.err_message}`}>
                            {CheckEmail(_data.email)}
                          </small>
                        )}
                      </div>
                      <div className={`${styles.input_2}`}>
                        <h5 style={{ color: changeThemeByRole?.color }}>
                          เบอร์โทรศัพท์
                        </h5>
                        <input
                          type="text"
                          name="mobile"
                          id=""
                          className={`${
                            validErr && _data.mobile === "" ? "error" : ""
                          }`}
                          value={_data?.mobile}
                          onChange={onChange}
                        />
                        {validErr && (
                          <small className={`${styles.err_message}`}>
                            {CheckMobile(_data.mobile)}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className={`${styles.input_}`}>
                      <h5 style={{ color: changeThemeByRole?.color }}>
                        กลุ่มผู้ใช้งาน
                      </h5>
                      <input
                        type="text"
                        value={_data?.userGroup?.name}
                        readOnly
                      />
                    </div>
                    <div className={`${styles.input_}`}>
                      <h5 style={{ color: changeThemeByRole?.color }}>
                        ชื่อผู้ใช้งาน
                      </h5>
                      <input
                        type="text"
                        defaultValue={_data?.username}
                        readOnly
                      />
                    </div>
                  </form>
                </div>
              )}
            </div>
          </aside>
          <aside className={`${styles.content_bottom}`}>
            <div className={`${styles.button_center}`}>
              {!_switch ? (
                !_switch2 && (
                  <>
                    <button
                      type="button"
                      className="button_sm"
                      onClick={() => onSwitch(true)}
                    >
                      แก้ไข
                    </button>
                    <button
                      type="button"
                      onClick={() => navigate("/home")}
                      className="button_sm"
                    >
                      กลับ
                    </button>
                  </>
                )
              ) : (
                <>
                  <button
                    type="button"
                    className="button_sm"
                    onClick={() => onUpdateProfile()}
                  >
                    บันทึก
                  </button>
                  <button
                    type="button"
                    onClick={() => onSwitch(false)}
                    className="button_sm"
                  >
                    ยกเลิก
                  </button>
                </>
              )}
            </div>
          </aside>
        </article>
      </section>
      <Footer _themeColor={changeThemeByRole} />
    </div>
  );
};

function openInputFile() {
  document.querySelector("#file-label").click();
}

export default Profile;
