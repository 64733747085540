import React from "react";
import RegisterForm from "./form/RegisterForm";
import Footer from "../../layouts/footer_1/Footer";
import logo from "../../assets/img/Logo";
import styles from "./scss/Register.module.scss";

const Register = () => {
  return (
    <div className={`${styles.container} background_1`}>
      <h1 className="title text-center">โรงไฟฟ้าชุมชนเพื่อเศรษฐกิจฐานราก</h1>
      <div className="container_content">
        <section className={`section ${styles.section_}`}>
          <article className={`article ${styles.article_} row d-flex mx-auto`}>
            <aside className="col">
              <div className={`${styles.input_form}`}>
                <h4 className="text-center">ลงทะเบียน</h4>
                <RegisterForm />
                <img src={logo.energyLogo} alt="logo_energy" />
              </div>
            </aside>
            <aside className="col"></aside>
          </article>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Register;
