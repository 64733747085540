import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { Overlay } from "ol";
import WKT from "ol/format/WKT";
import { Vector as VectorSource } from "ol/source";
import { Vector as VectorLayer } from "ol/layer";
import { Fill, Stroke, Style } from "ol/style";
import icon from "../../../assets/img/Icon";
import { convertCoordinates } from "../../../helpers/map.helper";
import { defaultApi } from "../../../services/api";
import { QueryGetAll } from "../../../helpers/api.helper";
import styles from "./EnterprisePopup.module.scss";

const EnterprisePopup = ({
  _map,
  _allEnterprise,
  _layerType,
  _allParcel,
  _newEntArr,
  _setEnterpriseDetail,
  _setOpened,
  _setLayerClick,
}) => {
  const container = useRef([]);
  const filterEnt = useMemo(() => {
    const datas = _allEnterprise.filter((item) => item?.geom !== null);
    return datas;
  }, [_allEnterprise]);
  const filterParcel = useMemo(() => {
    const datas = _allParcel.filter((item) => item?.geom !== null);
    return datas;
  }, [_allParcel]);

  const [allFarmer, setAllFarmer] = useState([]);

  const addRefArr = (el) => {
    if (el && !container.current.includes(el)) {
      container.current.push(el);
    }
  };
  const allFarmerC = (id) => {
    let count;
    allFarmer.forEach((item) => {
      if (item.entId === id) {
        count = item.data?.length;
      }
    });
    return count;
  };
  const entIcon = (id) => {
    let image;
    if (1 === id) {
      image = icon.index_2;
    } else if (2 === id) {
      image = icon.index_3;
    } else if (3 === id) {
      image = icon.group10;
    } else if (4 === id) {
      image = icon.group11;
    }
    return image;
  };
  const setStrokeColor = useCallback(
    (id) => {
      let color;
      _newEntArr?.forEach((item) => {
        if (item.entId === id) {
          color = item.color;
        }
      });
      return color;
    },
    [_newEntArr]
  );
  const setFillColor = useCallback(
    (id) => {
      let color;
      let alpha = 0.6;
      _newEntArr?.forEach((item) => {
        if (item.entId === id) {
          color = item.color;
        }
      });
      // Remove the '#' symbol if present
      color = color?.replace("#", "");

      // Extract the RGB components
      const red = parseInt(color?.substring(0, 2), 16);
      const green = parseInt(color?.substring(2, 4), 16);
      const blue = parseInt(color?.substring(4, 6), 16);

      // Convert alpha to a value between 0 and 1
      alpha = parseFloat(alpha);

      // Create and return the RGBA string
      const rgba = `rgba(${red}, ${green}, ${blue}, ${alpha})`;
      return rgba;
    },
    [_newEntArr]
  );
  const setFillColorParcel = () => {
    let color = "#BADA55";
    let alpha = 0.6;
    // Remove the '#' symbol if present
    color = color?.replace("#", "");

    // Extract the RGB components
    const red = parseInt(color?.substring(0, 2), 16);
    const green = parseInt(color?.substring(2, 4), 16);
    const blue = parseInt(color?.substring(4, 6), 16);

    // Convert alpha to a value between 0 and 1
    alpha = parseFloat(alpha);

    // Create and return the RGBA string
    const rgba = `rgba(${red}, ${green}, ${blue}, ${alpha})`;
    return rgba;
  };

  const onPopupClick = async (id) => {
    try {
      const res = await defaultApi.getEnterprise(id);
      _setEnterpriseDetail(res.data);
      _setOpened(true);
      _setLayerClick("enterprise");
    } catch (error) {
      console.log(error);
    }
  };

  const getAllFarmer = useCallback(async () => {
    let arr = [];
    for (let i = 0; i < filterEnt.length; i++) {
      const entId = filterEnt[i]?.id;
      const qryGetAll = {
        $count: "%24count=true",
        $expand: "",
        $top: "",
        $skip: "",
        $filter: `&%24filter=enterpriseId%20eq%20${entId}`,
        $orderby: "",
      };
      const _queryAll = QueryGetAll(qryGetAll);
      try {
        const res = await defaultApi.getAllFarmer(_queryAll);
        const newObj = { entId: entId, data: res.data.datas };
        arr[i] = newObj;
      } catch (error) {
        console.log(error);
      }
    }
    setAllFarmer(arr);
  }, [filterEnt]);

  const onClearOverlay = useCallback(() => {
    /* Clear overlay : START */
    _map?.getOverlays().forEach((overlay) => {
      let entId = overlay.get("entId");
      if (entId) overlay.setPosition(undefined);
    });
    /* Clear overlay : END */

    /* Clear parcel layers : START */
    _map
      ?.getLayers()
      .getArray()
      .filter((layer) => layer.get("parcelId"))
      .forEach((layer) => _map.removeLayer(layer));
    /* Clear parcel layers : END */
  }, [_map]);
  const _createOverlay = useCallback(() => {
    /* Remove overlay at position === undefined : START */
    const overlays = _map?.getOverlays().getArray();

    overlays.forEach((overlay) => {
      if (overlay.getPosition() === undefined) {
        _map.removeOverlay(overlay);
      }
    });
    /* Remove overlay at position === undefined : END */

    /* Clear parcel layers : START */
    _map
      ?.getLayers()
      .getArray()
      .filter((layer) => layer.get("parcelId"))
      .forEach((layer) => _map.removeLayer(layer));
    /* Clear parcel layers : END */

    /* Create overlay layer : START */
    for (let i = 0; i < filterEnt.length; i++) {
      const element = filterEnt[i];
      const el = container.current[i];
      const overlay = new Overlay({
        element: el,
        position: convertCoordinates(element?.lng, element?.lat),
        stopEvent: false,
      });
      overlay.setProperties({ entId: element?.id });
      _map?.addOverlay(overlay);
    }
    /* Create overlay layer : END */
  }, [_map, filterEnt]);

  const _createLayerParcel = useCallback(() => {
    for (let i = 0; i < filterParcel.length; i++) {
      const element = filterParcel[i];
      const wkt = `${element.geom}`;
      const format = new WKT();
      const feature = format.readFeature(wkt, {
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857",
      });
      const vector = new VectorLayer({
        source: new VectorSource({
          features: [feature],
          loader: () => {
            feature.setProperties({
              parcelId: element.id,
              entId: element.enterpriseId,
            });
          },
        }),
        style: (feature) => {
          const entId = feature.getProperties().entId;
          return [
            new Style({
              stroke: new Stroke({
                color: "#BADA55",
                width: 2,
              }),
              fill: new Fill({
                color: setFillColorParcel(entId),
              }),
            }),
          ];
        },
      });
      vector.setProperties({
        parcelId: element.id,
        entId: element.enterpriseId,
      });
      _map?.addLayer(vector);
    }
  }, [_map, filterParcel, setStrokeColor]);

  useEffect(() => {
    if (_layerType.active2) {
      _createOverlay();
      _createLayerParcel();
    } else {
      onClearOverlay();
      _setEnterpriseDetail(null);
    }
  }, [
    _createOverlay,
    _createLayerParcel,
    onClearOverlay,
    _layerType,
    _setEnterpriseDetail,
  ]);

  useEffect(() => {
    // Use functions
    getAllFarmer();
  }, [getAllFarmer]);

  return filterEnt.map((item, index) => {
    return (
      <div
        id={`popup_ent${index}`}
        className={`ol_popup ${styles.ol_popup}`}
        ref={addRefArr}
        key={item?.id || index}
        style={{ backgroundColor: setStrokeColor(item?.id) }}
        onClick={() => onPopupClick(item?.id)}
      >
        <div
          id="ent_popup_content"
          className={`popup_content ${styles.popup_content}`}
        >
          <div className={`${styles.img_icon}`}>
            <img src={entIcon(item?.enterpriseTypeId)} alt="" />
          </div>
          <div className={`${styles.text_content}`}>
            <div>
              <p id="ent_text_name">{item?.name}</p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "5px",
              }}
            >
              <p id="ent_text_details">{allFarmerC(item?.id)}</p>
              <span>คน</span>
            </div>
          </div>
        </div>
      </div>
    );
  });
};

export default EnterprisePopup;
