import React from "react";
import { toast } from "react-hot-toast";
import { ScaleLine } from "ol/control";
import { getPointResolution } from "ol/proj";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import icon from "../../../assets/img/Icon";
import Message from "../../../utils/message/Message";
import { OSM_LAYER, GOOGLE_HYBRID_LAYER } from "../../../helpers/map.helper";
import styles from "./Measurement.module.scss";

const Measurement = ({ _map }) => {
  const actPrint = (obj) => {
    _map?.getLayers().setAt(0, OSM_LAYER);
    document.body.style.cursor = "progress";
    const loading = toast.loading(Message.notify.loading);

    const format = obj?.paper ?? "a4";
    const scale = obj?.size ?? "100";

    const dims = {
      a0: [1189, 841],
      a1: [841, 594],
      a2: [594, 420],
      a3: [420, 297],
      a4: [297, 210],
      a5: [210, 148],
    };

    const exportOptions = {
      useCORS: true,
      ignoreElements: (el) => {
        const className = String(el?.className || "");

        return (
          className.includes("ol-control") &&
          !className.includes("ol-scale") &&
          (!className.includes("ol-attribution") ||
            !className.includes("ol-uncollapsible"))
        );
      },
    };

    const resolution = 100;
    const dim = dims[format];
    const width = Math.round((dim[0] * resolution) / 25.4);
    const height = Math.round((dim[1] * resolution) / 25.4);
    const viewResolution = _map.getView().getResolution();
    const scaleResolution =
      scale /
      getPointResolution(
        _map.getView().getProjection(),
        resolution / 25.4,
        _map.getView().getCenter()
      );

    const controls = _map.getControls();
    let scaleLine;
    controls.forEach((control) => {
      if (control instanceof ScaleLine) {
        scaleLine = control;
      }
    });

    _map.once("rendercomplete", () => {
      exportOptions.width = width;
      exportOptions.height = height;
      html2canvas(_map.getViewport(), exportOptions).then((canvas) => {
        const pdf = new jsPDF({
          orientation: "landscape",
          unit: undefined, // Set the unit to millimeters
          format: format,
        });
        pdf.addImage(
          canvas.toDataURL("image/jpeg"),
          "JPEG", // Remove the invalid "JEPG" parameter
          0,
          0,
          dim[0],
          dim[1]
        );
        pdf.save("map.pdf");
        // Reset original map size
        scaleLine.setDpi();
        _map.getTargetElement().style.width = "";
        _map.getTargetElement().style.height = "";
        _map.updateSize();
        _map.getView().setResolution(viewResolution);
        document.body.style.cursor = "auto";
      });
      toast.dismiss(loading);
      toast.success(Message.notify.loading_success, { duration: 3000 });
      _map?.getLayers().setAt(0, GOOGLE_HYBRID_LAYER);
    });

    // Set print size
    scaleLine.setDpi(resolution);
    _map.getTargetElement().style.width = `${width}px`;
    _map.getTargetElement().style.height = `${height}px`;
    _map.updateSize();
    _map.getView().setResolution(scaleResolution);
  };

  const actZoomExt = () => {
    /* Zoom to Overlay : START */
    // ดึง Overlay ทั้งหมดจากแผนที่
    const overlays = _map
      .getOverlays()
      .getArray()
      .filter((overlay) => overlay.getPosition() !== undefined);

    // สุ่มเลือก Overlay หนึ่งในรายการ
    const randomOverlay = overlays[Math.floor(Math.random() * overlays.length)];

    // ดึงพิกัดของ Overlay ที่สุ่มได้
    const position = randomOverlay?.getPosition();

    if (position) {
      // ซูมเข้าสู่พิกัดของ Overlay
      _map.getView().animate({
        center: position,
        zoom: 15, // ซูมสูงสุดที่อนุญาต
        duration: 1000, // Animation duration in milliseconds
      });
    }
    /* Zoom to Overlay : END */

    /* Zoom to Layer : START */
    const allLayers = _map
      .getLayers()
      .getArray()
      .filter((item) => item.get("crpId"));
    if (allLayers.length === 0) return;

    const randomLayer = allLayers[Math.floor(Math.random() * allLayers.length)];
    const randomExtent = randomLayer.getSource().getExtent();

    _map.getView().fit(randomExtent, {
      duration: 1000, // Animation duration in milliseconds
      maxZoom: 17, // ซูมสูงสุดที่อนุญาต
    });
    /* Zoom to Layer : END */
  };

  const actZoom = (zoom) => {
    const mapView = _map.getView();
    const targetZoom = mapView.getZoom() + zoom;
    const nextZoom = Math.min(
      Math.max(targetZoom, mapView.getMinZoom()),
      mapView.getMaxZoom()
    );
    mapView.animate({ zoom: nextZoom, duration: 450 });
  };

  const actTool = (target, options) => {
    switch (target) {
      case "zoomIn":
        actZoom(options);
        break;
      case "zoomOut":
        actZoom(options);
        break;
      case "print":
        actPrint(options);
        break;
      default:
        break;
    }
  };

  return (
    <div className={`${styles.container}`}>
      <div
        className={`${styles.menu_print}`}
        onClick={() =>
          actTool("print", {
            paper: null,
            size: (_map.getView().getResolution() * 86.9) / 0.0254 / 1000,
          })
        }
      >
        <img src={icon.map_icon1} alt="" />
      </div>
      <div className={`${styles.menu_extent}`} onClick={actZoomExt}>
        <img src={icon.map_icon3} alt="" />
      </div>
      <div className={`${styles.menu_zoom}`}>
        <div
          className={`${styles.zoom_in}`}
          onClick={() => actTool("zoomIn", 0.25)}
        >
          <img src={icon.map_icon2} alt="" />
        </div>
        <div
          className={`${styles.zoom_out}`}
          onClick={() => actTool("zoomOut", -0.25)}
        >
          <img src={icon.map_icon4} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Measurement;
