import React from "react";
import { useSelector } from "react-redux";
import { getAllFactoryData } from "../../services/factory.slice";

const CollapseTable = ({ _themeColor }) => {
  const _allFactory = useSelector(getAllFactoryData);
  return (
    <div className="collapse_table table-responsive">
      <table className="table text-center">
        <thead style={{ backgroundColor: _themeColor?.backgroundColor }}>
          <tr>
            <th scope="col">ลำดับ</th>
            <th scope="col">เลขที่คำเสนอขอขายไฟฟ้า</th>
            <th scope="col">ชื่อผู้ยื่นขอผลิตไฟฟ้าขนาดเล็กมาก</th>
            <th scope="col">จังหวัด</th>
            <th scope="col">อำเภอ</th>
            <th scope="col">ตำบล</th>
            <th scope="col">ประเภทโรงไฟฟ้า</th>
            <th scope="col">ปริมาณไฟฟ้าที่เสนอขาย (MW)</th>
            <th scope="col">ประมาณการค่าเชื้อเพลิง (บาท/ปี)</th>
            <th scope="col">อัตรา FIT (บาท/หน่วย)</th>
          </tr>
        </thead>
        <tbody>
          {_allFactory?.map((item, index) => {
            const capacityProductionMonth = item.proposalPower
              ? Number(item.proposalPower).toFixed(1)
              : "-";
            const capacityCost = item.capacityCost
              ? Number(item.capacityCost)?.toLocaleString()
              : "0";
            const fit = item.fit ? Number(item.fit).toFixed(2) : "-";

            return (
              <tr key={item.id}>
                <th scope="row">{index + 1}</th>
                <td>{item.proposalNo}</td>
                <td>{item.name}</td>
                <td>{item.addressProvince}</td>
                <td>{item.addressAmphoe}</td>
                <td>{item.addressTambol}</td>
                <td>{item.factoryType.name}</td>
                <td>{capacityProductionMonth}</td>
                <td>{capacityCost}</td>
                <td>{fit}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CollapseTable;
