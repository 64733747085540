import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { FaEye } from "react-icons/fa";
import { GrFormClose } from "react-icons/gr";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Table from "./Table";
import Pagination from "../../../../components/pagination/Pagination";
import useSwitchPage from "../../../../utils/hooks/useSwitchPage";
import { defaultApi } from "../../../../services/api";
import { QueryGetAll } from "../../../../helpers/api.helper";
import { getFactoryData } from "../../../../services/factory.slice";
import icon from "../../../../assets/img/Icon";
import {
  convertDateEu,
  convertDateThai,
} from "../../../../utils/format/Date.format";
import { clearObj } from "../../../../utils/format/Object.format";
import { exportCsvFile, exportXlsxFile } from "../../../../helpers/func.helper";
import ExportPopup from "../../../../layouts/exportpopup/ExportPopup";
import styles from "./scss/RawMaterial.module.scss";

const pageSize = 10;

const RawMaterial = () => {
  const factoryData = useSelector(getFactoryData);
  const [switchPage, onSwitchPage] = useSwitchPage();

  const [allEnterprise, setAllEnterprise] = useState([]);
  const [allPlant, setAllPlant] = useState([]);
  const [allFactorySupply, setAllFactorySupply] = useState([]);
  const [allEnterpriseSupply, setAllEnterpriseSupply] = useState([]);
  const [parcelIdOpt, setParcelIdOpt] = useState([]);
  const [nameEnterprise, setNameEnterprise] = useState("");
  const [filterGroupL, setFilterGroupL] = useState("");
  const [filterGroupR, setFilterGroupR] = useState({
    parcelId: "",
    plant: "",
    atDate: "",
    toDate: "",
    firstname: "",
    lastname: "",
  });
  const [currentPageL, setCurrentPageL] = useState(1);
  const [currentPageR, setCurrentPageR] = useState(1);
  const [selectedDate, setSelectedDate] = useState({
    dateStartR: new Date(),
    dateEndR: new Date(),
  });
  const [openDateStartR, setOpenDateStartR] = useState(false);
  const [openDateEndR, setOpenDateEndR] = useState(false);
  const [placeholderDisabled, setPlaceholderDisabled] = useState(false);
  const [placeholderDisabled2, setPlaceholderDisabled2] = useState(false);
  const [_triggerExport, setTriggerExport] = useState(false);

  /* Pagination table : START */
  const currentTableDataL = useMemo(() => {
    const firstPageIndex = (currentPageL - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;

    return allFactorySupply.slice(firstPageIndex, lastPageIndex);
  }, [allFactorySupply, currentPageL]);
  /* Pagination table : END */

  const formatJson = (val) => {
    return val;
    /* const jsonObj = JSON.parse(val);
    const plantType = jsonObj?.map((item) => item.name).join(",");
    if (!val) {
      return "-";
    } else {
      return plantType;
    } */
  };

  /* DatePicker : START */
  const onChangeDateStartR = (e) => {
    const dateFormat = convertDateEu(e);
    setFilterGroupR({ ...filterGroupR, atDate: dateFormat });
    setSelectedDate({ ...selectedDate, dateStartR: e });
    setOpenDateStartR(!openDateStartR);
  };
  const onChangeDateEndR = (e) => {
    const dateFormat = convertDateEu(e);
    setFilterGroupR({ ...filterGroupR, toDate: dateFormat });
    setSelectedDate({ ...selectedDate, dateEndR: e });
    setOpenDateEndR(!openDateEndR);
  };
  /* DatePicker : END */

  /* Search input : START */
  const searchInputR = (e) => {
    const searchText = e.target.value;
    if (searchText !== "") {
      const str = searchText.split(/(\s+)/).filter((e) => e.trim().length > 0);
      const searchQuery = str.join(" ");
      const [firstname, lastname] = searchText.split(" ");

      if (searchText.indexOf(" ") !== -1) {
        setFilterGroupR({
          ...filterGroupR,
          firstname: firstname,
          lastname: lastname,
        });
      } else {
        console.log(firstname);
        setFilterGroupR({
          ...filterGroupR,
          firstname: searchQuery,
          lastname: searchQuery,
        });
      }
    } else {
      setFilterGroupR({
        ...filterGroupR,
        firstname: "",
        lastname: "",
      });
    }
  };
  /* Search input : END */

  /* Export csv and excel : START */
  const onExportCsv = () => {
    // ข้อมูลที่ต้องส่งไปให้ ฟังก์ชั่น ใช้งาน
    let exportName = "FactorySupplyReport";
    let fileType = "csv";
    let obj = {
      factoryId: factoryData.id,
    };
    // Ready
    exportCsvFile(exportName, fileType, obj);
  };
  const onExportXlsx = () => {
    // ข้อมูลที่ต้องส่งไปให้ ฟังก์ชั่น ใช้งาน
    let exportName = "FactorySupplyReport";
    let fileType = "excel";
    let obj = {
      factoryId: factoryData.id,
    };
    // Ready
    exportXlsxFile(exportName, fileType, obj);
  };
  /* Export csv and excel : END */

  const getMultipleApi = useCallback(async () => {
    if (!factoryData?.id) return;

    let factoryId = factoryData?.id;
    const qryGetAll1 = {
      $count: "%24count=true",
      $expand: "&%24expand=enterpriseType%2C%20factory",
      $top: "",
      $skip: "",
      $filter: `&%24filter=factoryId%20eq%20${factoryId}`,
      $orderby: "",
    };
    const _queryAll1 = QueryGetAll(qryGetAll1);
    try {
      const [allEnterprise, allPlant] = await Promise.all([
        defaultApi.getAllEnterprise(_queryAll1),
        defaultApi.getAllPlantType(),
      ]);

      setAllEnterprise(allEnterprise.data.datas);
      setAllPlant(allPlant.data);
    } catch (error) {
      console.log(error);
    }
  }, [factoryData]);
  const getAllFactorySupply = useCallback(async () => {
    if (!factoryData?.id) return;

    let factoryId = factoryData?.id;
    try {
      const res = await defaultApi.getAllFactorySupply(factoryId);
      /* Search filter table : START */
      const filterData = res.data.factorySupply.filter((item) =>
        item.name
          .toString()
          .toLowerCase()
          .includes(filterGroupL.toString().toLowerCase())
      );
      /* Search filter table : END */
      setAllFactorySupply(filterData);
      setCurrentPageL(1);
    } catch (error) {
      console.log(error);
    }
  }, [factoryData, filterGroupL]);
  const getAllEnterpriseSupply = useCallback(async () => {
    if (!switchPage) return;

    const { parcelId, plant, atDate, toDate, firstname, lastname } =
      filterGroupR;

    let enterpriseId = switchPage;
    const formatQry = (obj) => {
      const checkObj = clearObj({
        parcelId: obj.parcelId,
        plant: +obj.plant,
        atDate: obj.atDate,
        toDate: obj.toDate,
        firstname: obj.firstname,
        lastname: obj.lastname,
      });
      if (Object.keys(clearObj(checkObj)).length === 0) {
        return "";
      } else {
        let filterParams = [];
        for (const [key, value] of Object.entries(clearObj(checkObj))) {
          filterParams.push(`${key}=${value}`);
        }
        return filterParams.join("&");
      }
    };
    try {
      const res = await defaultApi.getAllEnterpriseSupply(
        enterpriseId,
        formatQry(filterGroupR)
      );
      const getParcelId = res.data.enterpriseSupply?.map((item) => ({
        value: item.parcelId,
        label: item.parcelId,
      }));
      // Use Set to remove duplicates based on the 'value' property
      const uniqueParcelId = Array.from(
        new Set(getParcelId?.map((item) => item.value))
      )?.map((value) => ({ value, label: value }));

      setAllEnterpriseSupply(res.data.enterpriseSupply);
      setCurrentPageR(1);
      if (
        parcelId === "" &&
        plant === "" &&
        atDate === "" &&
        toDate === "" &&
        firstname === "" &&
        lastname === ""
      ) {
        setParcelIdOpt(uniqueParcelId);
      }
    } catch (error) {
      console.log(error);
    }
  }, [switchPage, filterGroupR]);

  useEffect(() => {
    // Use functions
    getMultipleApi();
    getAllFactorySupply();
    getAllEnterpriseSupply();
  }, [getMultipleApi, getAllFactorySupply, getAllEnterpriseSupply]);

  useEffect(() => {
    /* Function close dropdown 'DateStartR and DateEndR' : START */
    const onCloseDropdownDateStartR = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#fac_datestart_r_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenDateStartR(false);
      }
    };
    const onCloseDropdownDateEndR = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#fac_dateend_r_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenDateEndR(false);
      }
    };

    if (openDateStartR) {
      document.addEventListener("click", onCloseDropdownDateStartR);
    }
    if (openDateEndR) {
      document.addEventListener("click", onCloseDropdownDateEndR);
    }
    /* Function close dropdown 'DateStartR and DateEndR' : END */

    return () => {
      document.removeEventListener("click", onCloseDropdownDateStartR);
      document.removeEventListener("click", onCloseDropdownDateEndR);
    };
  }, [openDateStartR, openDateEndR]);

  return (
    <div className={`${styles.container}`}>
      <h4 className={`${styles.title_text}`}>การจัดการวัตถุดิบ</h4>
      {switchPage === null ? (
        <h5 className={`${styles.title_text_}`}>{factoryData.name}</h5>
      ) : (
        <h5 className={`${styles.title_text_}`}>{nameEnterprise}</h5>
      )}

      <div className={`${styles.filter_groups}`}>
        {switchPage !== null && (
          <div className={`${styles.group_}`}>
            <Select
              className={`${styles.multi_select}`}
              closeMenuOnSelect={true}
              isSearchable={true}
              isClearable={true}
              options={parcelIdOpt}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  borderRadius: "10px",
                  width: "200px",
                  textAlign: "center",
                }),
              }}
              placeholder={"เลือกรหัสแปลง"}
              onChange={(e) => {
                const { value } = e ? e : "";

                setFilterGroupR({ ...filterGroupR, parcelId: value });
              }}
            />
          </div>
        )}
        <div className={`${styles.group_}`}>
          {switchPage === null ? (
            <input
              type="search"
              onChange={(e) => setFilterGroupL(e.target.value)}
              onFocus={() => setPlaceholderDisabled(true)}
              onBlur={() => setPlaceholderDisabled(false)}
              placeholder={placeholderDisabled ? "" : "ค้นหากลุ่มวิสาหกิจชุมชน"}
            />
          ) : (
            <input
              type="search"
              onChange={searchInputR}
              onFocus={() => setPlaceholderDisabled2(true)}
              onBlur={() => setPlaceholderDisabled2(false)}
              placeholder={placeholderDisabled2 ? "" : "ชื่อ นามสกุล"}
            />
          )}
        </div>
        {switchPage !== null && (
          <>
            <div className={`${styles.group_}`}>
              <select
                onChange={(e) =>
                  setFilterGroupR({ ...filterGroupR, plant: e.target.value })
                }
              >
                <option value="">ชนิดเชื้อเพลิง / วัตถุดิบ</option>
                {allPlant.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className={`${styles.group_}`}>
              <div className={`${styles.input_unit}`}>
                <div className={`${styles.info_box}`}>
                  <p>
                    {filterGroupR.atDate !== ""
                      ? convertDateThai(filterGroupR.atDate)
                      : "วันที่คาดว่าจะเก็บเกี่ยว"}
                  </p>
                  {filterGroupR.atDate !== "" && (
                    <GrFormClose
                      size={25}
                      cursor={"pointer"}
                      onClick={() => {
                        setFilterGroupR({ ...filterGroupR, atDate: "" });
                        setSelectedDate({
                          ...selectedDate,
                          dateStartR: new Date(),
                        });
                      }}
                    />
                  )}
                </div>
                <div
                  className={`${styles.unit}`}
                  onClick={() => {
                    setOpenDateStartR(!openDateStartR);
                  }}
                >
                  <img src={icon.calendar} alt="" id="fac_datestart_r_d" />
                </div>
              </div>
              {openDateStartR && (
                <DatePicker
                  selected={selectedDate.dateStartR}
                  onChange={onChangeDateStartR}
                  inline
                />
              )}
            </div>
            <label>ถึง :</label>
            <div className={`${styles.group_}`}>
              <div className={`${styles.input_unit}`}>
                <div className={`${styles.info_box}`}>
                  <p>
                    {filterGroupR.toDate !== ""
                      ? convertDateThai(filterGroupR.toDate)
                      : "วันที่คาดว่าจะเก็บเกี่ยว"}
                  </p>
                  {filterGroupR.toDate !== "" && (
                    <GrFormClose
                      size={25}
                      cursor={"pointer"}
                      onClick={() => {
                        setFilterGroupR({ ...filterGroupR, toDate: "" });
                        setSelectedDate({
                          ...selectedDate,
                          dateEndR: new Date(),
                        });
                      }}
                    />
                  )}
                </div>
                <div
                  className={`${styles.unit}`}
                  onClick={() => {
                    setOpenDateEndR(!openDateEndR);
                  }}
                >
                  <img src={icon.calendar} alt="" id="fac_dateend_r_d" />
                </div>
              </div>
              {openDateEndR && (
                <DatePicker
                  selected={selectedDate.dateEndR}
                  onChange={onChangeDateEndR}
                  inline
                />
              )}
            </div>
          </>
        )}
      </div>

      {switchPage === null ? (
        <div className={`${styles.table_containerL}`}>
          <div className={`${styles.table_box} table-responsive`}>
            <table className={`table`}>
              <thead>
                <tr>
                  <th>ลำดับ</th>
                  <th>ชื่อกลุ่มวิสาหกิจชุมชน</th>
                  <th>จำนวนสมาชิก (คน)</th>
                  <th>พื้นที่เพาะปลูกเชื้อเพลิง (ไร่)</th>
                  <th>ชนิดเชื้อเพลิง / วัตถุดิบ</th>
                  <th>ปริมาณผลผลิต(ตัน)</th>
                  <th>ดูรายละเอียด</th>
                </tr>
              </thead>
              <tbody>
                {currentTableDataL.map((item, index) => {
                  return (
                    <tr key={item.id}>
                      <td>{(currentPageL - 1) * pageSize + (index + 1)}</td>
                      <td>{item.name}</td>
                      <td>
                        {item.farmerCount?.toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                        })}
                      </td>
                      <td>
                        {item.parcelArea?.toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                        }) || "-"}
                      </td>
                      <td>{formatJson(item.plantType) || "-"}</td>
                      <td>{item.yield?.toFixed(2) || "-"}</td>
                      <td>
                        <FaEye
                          className={`${styles.fa_eye}`}
                          onClick={() => {
                            onSwitchPage(item.id);
                            setNameEnterprise(item.name);
                            setFilterGroupL("");
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          {switchPage === null && (
            <div className={`${styles.pagination_}`}>
              <Pagination
                pageSize={pageSize}
                totalCount={allEnterprise.length}
                currentPageL={currentPageL}
                onPageChange={(page) => setCurrentPageL(page)}
              />
            </div>
          )}
        </div>
      ) : (
        <Table
          _allEnterpriseSupply={allEnterpriseSupply}
          _currentPage={currentPageR}
          _setCurrentPage={setCurrentPageR}
          _onSwitchPage={onSwitchPage}
          _obj={{
            enterpriseId: switchPage,
            plantTypeId: filterGroupR.plant,
            atDate: filterGroupR.atDate,
            toDate: filterGroupR.toDate,
            firstname: filterGroupR.firstname,
            lastname: filterGroupR.lastname,
            parcelId: filterGroupR.parcelId,
          }}
          _resetFilter={() => {
            setFilterGroupR({
              plant: "",
              atDate: "",
              toDate: "",
              firstname: "",
              lastname: "",
            });
            setOpenDateStartR(false);
            setOpenDateEndR(false);
            setSelectedDate({
              dateStartR: new Date(),
              dateEndR: new Date(),
            });
          }}
        />
      )}

      {switchPage === null && (
        <div className={`${styles.export_}`}>
          <ExportPopup
            label={"document"}
            trigger={_triggerExport}
            event={{ csv: onExportCsv, xlsx: onExportXlsx }}
          />
          <button
            type="button"
            className={`${styles.btn_}`}
            onClick={() => setTriggerExport(!_triggerExport)}
          >
            Export
          </button>
        </div>
      )}
    </div>
  );
};

export default RawMaterial;
