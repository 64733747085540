import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import WKT from "ol/format/WKT";
import Header from "../../layouts/header/Header";
import Navbar from "../../layouts/navbar/Navbar";
import Footer from "../../layouts/footer_2/Footer";
import MenuBar from "./_partials/MenuBar";
import MapElement from "./_partials/MapElement";
import FactoryPopup from "./_partials/FactoryPopup";
import EnterprisePopup from "./_partials/EnterprisePopup";
import CropLayer from "./_partials/CropLayer";
import Legends from "./_partials/Legends";
import FeatureInfo from "./_partials/FeatureInfo";
import Measurement from "./_partials/Measurement";
import { defaultApi } from "../../services/api";
import { getProfileData } from "../../services/auth.slice";
import { getLocalStorageData } from "../../helpers/local_storage.helper";
import styles from "./scss/MapOverview.module.scss";

const initLayerType = {
  checked1: null,
  active1: null,
  checked2: null,
  active2: null,
  checked3: null,
  active3: null,
  activeCount: [],
};

const MapOverview = () => {
  const profile_ = useSelector(getProfileData);

  const [_map, setMap] = useState(null);
  const [allFactory, setAllFactory] = useState([]);
  const [allEnterprise, setAllEnterprise] = useState([]);
  const [allFactoryMember, setAllFactoryMember] = useState([]);
  const [allEnterpriseMember, setAllEnterpriseMember] = useState([]);
  const [allCrop, setCrop] = useState([]);
  const [allParcel, setAllParcel] = useState([]);
  const [allFarmer, setAllFarmer] = useState([]);
  const [_colors, setColors] = useState([]);
  const [allPlant, setAllPlant] = useState([]);
  const [newPlantArr, setNewPlantArr] = useState([]);
  const [newEntArr, setNewEntArr] = useState([]);
  const [factoryDetail, setFactoryDetail] = useState(null);
  const [enterpriseDetail, setEnterpriseDetail] = useState(null);
  const [cropDetail, setCropDetail] = useState(null);
  const [cropStatus, setCropStatus] = useState([]);
  const [layerType, setLayerType] = useState(initLayerType);
  const [layerClick, setLayerClick] = useState(null);
  const [_opened, setOpened] = useState(false);

  const changeThemeByRole = useMemo(() => {
    const role = profile_?.userGroupId;
    let theme;

    if (role === 2) {
      theme = {
        color: "#789b33",
        backgroundColor: "#789b33",
        class: "ent",
      };
    } else if (role === 3) {
      theme = {
        color: "#2f9ced",
        backgroundColor: "#2f9ced",
        class: "gov",
      };
    } else {
      theme = "";
    }

    return theme;
  }, [profile_]);
  const checkUserFacMember = useMemo(() => {
    const allFactory = getLocalStorageData("allFactory");
    const datas = allFactory;
    // สร้างเซตของ factoryId ที่ต้องการตัดออก
    const facIdsToRemove = new Set(datas?.map((factory) => factory?.id));

    // กรองข้อมูลใน allFacMemByFacId โดยตรวจสอบว่า factoryId อยู่ในเซต factoryIdsToRemove หรือไม่
    const filteredUserData = allFactoryMember?.filter((user) =>
      facIdsToRemove.has(user.factoryId)
    );

    // กรองข้อมูลใน filteredUserData โดยตรวจสอบว่า userId ตรงกับ profileData.id หรือไม่
    const findedUserData = filteredUserData?.find(
      (user) => user.userId === profile_?.id
    );

    return findedUserData?.factoryId;
  }, [allFactoryMember, profile_]);
  const checkUserEntMember = useMemo(() => {
    const allEnterprise = getLocalStorageData("allEnterprise");
    const datas = allEnterprise;
    // สร้างเซตของ enterpriseId ที่ต้องการตัดออก
    const entIdsToRemove = new Set(datas?.map((enterprise) => enterprise?.id));

    // กรองข้อมูลใน allFacMemByFacId โดยตรวจสอบว่า factoryId อยู่ในเซต factoryIdsToRemove หรือไม่
    const filteredUserData = allEnterpriseMember?.filter((user) =>
      entIdsToRemove?.has(user.enterpriseId)
    );

    // กรองข้อมูลใน filteredUserData โดยตรวจสอบว่า userId ตรงกับ profileData.id หรือไม่
    const findedUserData = filteredUserData?.find(
      (user) => user.userId === profile_?.id
    );

    return findedUserData?.enterpriseId;
  }, [allEnterpriseMember, profile_]);
  const _statusName4 = useMemo(() => {
    const newObj = cropStatus?.reduce((acc, item) => {
      acc[item.id] = item.id;

      return acc;
    }, {});

    return newObj;
  }, [cropStatus]);

  const extentToLayer = (e, wkt) => {
    const format = new WKT();
    const feature = format.readFeature(wkt, {
      dataProjection: "EPSG:4326",
      featureProjection: "EPSG:3857",
    });
    const target = feature?.getGeometry();
    // Extent
    _map?.getView()?.fit(target, {
      duration: 1000,
      maxZoom: 17,
    });
  };

  const getMultipleApi = useCallback(async () => {
    const query = `%24filter=statusCategories%20eq%20%274%27`;

    try {
      const [
        allFactory,
        allEnterprise,
        allCrop,
        allParcel,
        colors,
        allPlant,
        allFarmer,
        allFactoryMember,
        allEnterpriseMember,
        gbStatus,
      ] = await Promise.all([
        defaultApi.getAllFactory(),
        defaultApi.getAllEnterprise(),
        defaultApi.getAllCrop(),
        defaultApi.getAllParcel(),
        defaultApi.getColors(),
        defaultApi.getAllPlantType(),
        defaultApi.getAllFarmer(),
        defaultApi.getAllFactoryMember(),
        defaultApi.getAllEnterpriseMember(),
        defaultApi.getGbStatus(query),
      ]);

      if (profile_?.userGroupId === 1) {
        /* เจ้าหน้าที่โรงไฟฟ้า */

        /* โรงไฟฟ้าที่สร้างโดย ไอดีผู้ใช้ : START */
        const facCreatedBy = allFactory.data?.find(
          (item) =>
            item.createdBy === profile_?.id || item.id === checkUserFacMember
        );
        /* โรงไฟฟ้าที่สร้างโดย ไอดีผู้ใช้ : END */

        /* กลุ่มวิสาหกิจที่อยู่ภายใต้ โรงไฟฟ้า : START */
        const entOfFac = allEnterprise.data?.filter(
          (item) => item.factoryId === facCreatedBy?.id
        );
        /* กลุ่มวิสาหกิจที่อยู่ภายใต้ โรงไฟฟ้า : END */

        /* โฉนดที่ดินที่อยู่ภายใต้ กลุ่มวิสาหกิจ : START */
        let parcelOfEnt = [];
        for (let i = 0; i < entOfFac.length; i++) {
          const element = entOfFac[i];

          allParcel.data?.forEach((item) => {
            if (item.enterpriseId === element.id) {
              parcelOfEnt.push(item);
            }
          });
        }
        /* โฉนดที่ดินที่อยู่ภายใต้ กลุ่มวิสาหกิจ : END */

        /* แปลงเพาะปลูกที่อยู่ภายใต้ โฉนดที่ดิน : START */
        let cropOfParcel = [];
        for (let i = 0; i < parcelOfEnt.length; i++) {
          const element = parcelOfEnt[i];

          allCrop.data?.forEach((item) => {
            if (item.parcelId === element.id) {
              cropOfParcel.push(item);
            }
          });
        }
        /* แปลงเพาะปลูกที่อยู่ภายใต้ โฉนดที่ดิน : END */

        if (facCreatedBy !== undefined) {
          setAllFactory([facCreatedBy]);
        }
        setAllEnterprise(entOfFac);
        setAllParcel(parcelOfEnt);
        setCrop(cropOfParcel);
      } else if (profile_?.userGroupId === 2) {
        /* กลุ่มวิสาหกิจชุมชน */

        /* กลุ่มวิสาหกิจที่สร้างโดย ไอดีผู้ใช้ : START */
        const entCreatedBy = allEnterprise.data?.find(
          (item) =>
            item.createdBy === profile_?.id || item.id === checkUserEntMember
        );
        /* กลุ่มวิสาหกิจที่สร้างโดย ไอดีผู้ใช้ : END */

        /* โรงไฟฟ้าที่กลุ่มวิสาหกิจอยู่ภายใต้ : START */
        const facOFent = allFactory.data?.filter(
          (item) => item.id === entCreatedBy?.factoryId
        );
        /* โรงไฟฟ้าที่กลุ่มวิสาหกิจอยู่ภายใต้ : END */

        /* โฉนดที่ดินที่อยู่ภายใต้ กลุ่มวิสาหกิจ : START */
        const parcelOfEnt = allParcel.data?.filter(
          (item) => item.enterpriseId === entCreatedBy?.id
        );
        /* โฉนดที่ดินที่อยู่ภายใต้ กลุ่มวิสาหกิจ : END */

        /* แปลงเพาะปลูกที่อยู่ภายใต้ โฉนดที่ดิน : START */
        let cropOfParcel = [];
        for (let i = 0; i < parcelOfEnt.length; i++) {
          const element = parcelOfEnt[i];

          allCrop.data?.forEach((item) => {
            if (item.parcelId === element.id) {
              cropOfParcel.push(item);
            }
          });
        }
        /* แปลงเพาะปลูกที่อยู่ภายใต้ โฉนดที่ดิน : END */

        if (entCreatedBy !== undefined) {
          setAllEnterprise([entCreatedBy]);
        }
        setAllFactory(facOFent);
        setAllParcel(parcelOfEnt);
        setCrop(cropOfParcel);
      } else {
        /* แอดมิน และ เจ้าหน้าที่รัฐ */

        setAllFactory(allFactory.data);
        setAllEnterprise(allEnterprise.data);
        setCrop(allCrop.data);
        setAllParcel(allParcel.data);
      }

      setColors(colors.data.color);
      setAllPlant(allPlant.data);
      setAllFarmer(allFarmer.data);
      setAllFactoryMember(allFactoryMember.data);
      setAllEnterpriseMember(allEnterpriseMember.data);
      setCropStatus(gbStatus.data);
    } catch (error) {
      console.log(error);
    }
  }, [profile_, checkUserFacMember, checkUserEntMember]);

  useEffect(() => {
    /* Format current data to new data obj : START */
    let arr = [];
    for (let i = 0; i < allPlant.length; i++) {
      const element = allPlant[i];
      const newObj = { name: element.name, color: _colors[i].colorCode };
      arr[i] = newObj;
    }

    setNewPlantArr(arr);
    /* Format current data to new data obj : END */

    /* Format current data to new data obj : START */
    let arr2 = [];
    for (let i = 0; i < allEnterprise.length; i++) {
      const element = allEnterprise[i];
      const newObj = { entId: element?.id, color: _colors[i].colorCode };
      arr2[i] = newObj;
    }

    setNewEntArr(arr2);
    /* Format current data to new data obj : END */
  }, [allPlant, _colors, allEnterprise]);

  useEffect(() => {
    // Use functions
    getMultipleApi();
  }, [getMultipleApi]);

  return (
    <div className={`${styles.container} background_1`}>
      <Header />
      <section className={`section ${styles.section_}`}>
        <Navbar />
        <article className={`article ${styles.article_} row mx-auto`}>
          <aside className={`${styles.menu_bar} col-3`}>
            <MenuBar
              _setLayerType={setLayerType}
              _setLayerClick={setLayerClick}
              _themeColor={changeThemeByRole}
            />
          </aside>
          <aside className={`${styles.map_} col`}>
            <div className={`${styles.map_element}`}>
              <MapElement _onInit={(map) => setMap(map)} />
              <FactoryPopup
                _map={_map}
                _factory={allFactory}
                _layerType={layerType}
                _setFactoryDetail={setFactoryDetail}
                _setOpened={setOpened}
                _setLayerClick={setLayerClick}
              />

              <EnterprisePopup
                _map={_map}
                _allEnterprise={allEnterprise}
                _layerType={layerType}
                _allParcel={allParcel}
                _newEntArr={newEntArr}
                _setEnterpriseDetail={setEnterpriseDetail}
                _setOpened={setOpened}
                _setLayerClick={setLayerClick}
              />

              <CropLayer
                _map={_map}
                _allCrop={allCrop}
                _layerType={layerType}
                _newPlantArr={newPlantArr}
                _setCropDetail={setCropDetail}
                _setOpened={setOpened}
                _setLayerClick={setLayerClick}
                _statusName4={_statusName4}
              />
            </div>
            {layerType.active3 && <Legends _newPlantArr={newPlantArr} />}
            <FeatureInfo
              _allFactory={allFactory}
              _factoryDetail={factoryDetail}
              _allEnterprise={allEnterprise}
              _enterpriseDetail={enterpriseDetail}
              _allFarmer={allFarmer}
              _allCrop={allCrop}
              _cropDetail={cropDetail}
              _allParcel={allParcel}
              _layerType={layerType}
              _setLayerType={setLayerType}
              _opened={_opened}
              _setOpened={setOpened}
              _setFactoryDetail={setFactoryDetail}
              _setEnterpriseDetail={setEnterpriseDetail}
              _setCropDetail={setCropDetail}
              _layerClick={layerClick}
              _setLayerClick={setLayerClick}
              _extentToLayer={extentToLayer}
              _themeColor={changeThemeByRole}
            />
            <Measurement _map={_map} />
          </aside>
        </article>
      </section>
      <Footer _themeColor={changeThemeByRole} />
    </div>
  );
};

export default MapOverview;
