import $ from "jquery";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { defaultApi } from "../../../services/api";
import ChartsIncomeTotal1 from "./charts/ChartsIncomeTotal1";
import ChartsIncomeTotal2 from "./charts/ChartsIncomeTotal2";
import ExportPopup from "../../../layouts/exportpopup/ExportPopup";
import { convertDateThai2 } from "../../../utils/format/Date.format";
import styles from "./scss/FarmerInfo.module.scss";
import { QueryGetAll } from "../../../helpers/api.helper";
// import useScrollContent from "../utils/useScrollContent";

// const fakeData = [
//   { id: 1, mouth: "มกราคม", mAmount: 110, plantArea: 505, money: 560000 },
//   { id: 2, mouth: "กุมภาพันธ์", mAmount: 114, plantArea: 520, money: 390000 },
//   { id: 3, mouth: "มีนาคม", mAmount: 116, plantArea: 522, money: 620000 },
//   { id: 4, mouth: "เมษายน", mAmount: 120, plantArea: 540, money: 320000 },
//   { id: 5, mouth: "พฤษภาคม", mAmount: 120, plantArea: 540, money: 500000 },
//   { id: 6, mouth: "มิถุนายน", mAmount: 122, plantArea: 550, money: 360000 },
//   { id: 7, mouth: "กรกฎาคม", mAmount: 122, plantArea: 550, money: 400000 },
//   { id: 8, mouth: "สิงหาคม", mAmount: 124, plantArea: 555, money: 690000 },
//   { id: 9, mouth: "กันยายน", mAmount: 124, plantArea: 555, money: 550000 },
//   { id: 10, mouth: "ตุลาคม", mAmount: 135, plantArea: 604, money: 620000 },
//   { id: 11, mouth: "พฤศจิกายน", mAmount: 139, plantArea: 631, money: 490000 },
//   { id: 12, mouth: "ธันวาคม", mAmount: 140, plantArea: 632, money: 630000 },
// ];

// /report/enterprise/income/month
// /report/enterprise/income/year

let enterpriseList = [];

const FarmerInfo = ({ swCotent, _theme, barFact, typeFact }) => {
  const chart = useRef();
  const [_triggerExport, setTriggerExport] = useState(false);

  const [_entLst, setEntLst] = useState(null);

  const [_resYear, setResYear] = useState(null);
  const [_resMonth, setResMonth] = useState(null);

  const [_activeSelect, setActiveSelect] = useState(false)

  const [_valueRes, setValueRes] = useState({
    entpid: "",
    factid: "",
    facttype: "",
    day: "",
    month: "",
    year: "",
  })

  const downloadCSV = () => {
    // if (chart && chart.current && chart.current.chart) {
    //   chart.current.chart.downloadCSV();
    // }
    if (chart && chart.current && chart.current.chart) {
      chart.current.chart.downloadCSV();
    }
  };

  const downloadImage = () => {
    if (chart && chart.current && chart.current.chart) {
      chart.current.chart.setTitle({
        text: "พื้นที่การเพาะปลูกรวมของพืชแต่ละชนิด",
      });
      chart.current.chart.setSubtitle({
        text: checkWordTitle
      });
      // chart.current.chart.setSubtitle({
      //   text: allEn === "เลือกกลุ่มเกษตรกร" || allEn === "" ? "ทั้งหมด" : allEn,
      // });

      chart.current.chart.exportChart({
        type: "image/jpeg",
        filename: "chart",
      });

      chart.current.chart.setTitle({ text: "" });
      chart.current.chart.setSubtitle({ text: "" });
    }
  };

  const apiItem = async (barFact, typeFact) => {

    try {
      let strquery = ""

      if (+typeFact?.id !== -99 && +barFact?.id !== -99) {
        strquery = `&$filter=factory/factoryTypeId eq ${typeFact?.id} and factory/id eq ${barFact?.id}`
      } else if (+typeFact?.id === -99 && +barFact?.id !== -99) {
        strquery = `&$filter=factory/id eq ${barFact?.id}`
      } else if (+typeFact?.id !== -99 && +barFact?.id === -99) {
        strquery = `&$filter=factory/factoryTypeId eq ${typeFact?.id}`
      } else {
        strquery = ""
      }

      let _query = {
        $count: "",
        $expand: ``,
        $top: "",
        $skip: ``,
        $filter: `${strquery}`,
        $orderby: "",
      };

      const query = QueryGetAll(_query);

      const res_ent = await defaultApi.getAllEnterprise(query);

      enterpriseList = []

      for (let index = 0; index < res_ent.data.length; index++) {
        const element = res_ent.data[index];
        enterpriseList[index] = { id: element.id, name: element.name };
      }

      setEntLst(enterpriseList);

      let date = new Date();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();

      // let formattname = enterpriseList?.length === 1 ? enterpriseList?.[0]?.name : "ทั้งหมด";
      // let entpid = enterpriseList?.length === 1 ? +enterpriseList?.[0]?.id : "";
      let factid = +barFact?.id === -99 ? "" : +barFact?.id
      let facttype = +typeFact?.id === -99 ? "" : +typeFact?.id

      setValueRes((prev) => ({
        ...prev,
        entpid: "",
        factid: factid,
        facttype: facttype,
        day: prev?.day ? prev?.day : date,
        month: prev?.month ? prev?.month : month,
        year: prev?.year ? prev?.year : Number(year),
      }))

      enterpriseList = [];
    } catch (error) {
    }
  };

  const checkWordTitle = useMemo(() => {
    const entpId = _valueRes?.entpid
    const factId = _valueRes?.factid
    const factTp = _valueRes?.facttype

    let formattname = "กลุ่มวิสาหกิจทั้งหมด";

    if (factTp) {
      formattname = `กลุ่มวิสาหกิจที่อยู่ภายใต้โรงไฟฟ้าประเภท${typeFact?.name}`
    }
    if (factId) {
      formattname = `กลุ่มวิสาหกิจที่อยู่ภายใต้${barFact?.name}`
    }
    if (entpId) {
      formattname = `${_entLst?.find((item) => +item?.id === +entpId)?.name || ""}`
    }

    return formattname
  }, [_valueRes, barFact, typeFact, _entLst])

  const entpLst = (pin) => {
    let x = null;
    try {
      x = _entLst.map((datax, index) => {
        return (
          <option key={index} value={datax.id}>
            {datax.name}
          </option>
        );
      });

      return (
        <>
          <option key={`df_${pin}`} value={""}>
            เลือกกลุ่มเกษตรกร
          </option>
          {x}
        </>
      );
    } catch (error) { }
  };

  const selectMonth = (pin) => {
    let monthNameLst = [
      "เดือน",
      "ม.ค.",
      "ก.พ.",
      "มี.ค.",
      "เม.ย.",
      "พ.ค.",
      "มิ.ย.",
      "ก.ค.",
      "ส.ค.",
      "ก.ย.",
      "ต.ค.",
      "พ.ย.",
      "ธ.ค.",
    ];

    return monthNameLst.map((datax, index) => <option key={`${pin}_${index}`} value={index === 0 ? "" : index}>
      {datax}
    </option>);
  };

  const selectYear = (pin) => {
    const date = new Date();
    let year = date.getFullYear();
    let yearLst = [];
    yearLst.push({ yearen: year-1, yearth: year-1 + 543 });
    for (let index = 0; index < 20; index++) {
      yearLst.push({ yearen: year, yearth: year + 543 });
      year = year + 1;
    }

    return yearLst.map((datax, index) => <option key={`${pin}_${index}`} value={datax.yearen}>
      {datax.yearth}
    </option>);
  };

  const resSearchMonth = useCallback(async () => {
    try {
      let entp = `enterpriseId=${_valueRes?.entpid}`
      let mont = `&atMonth=${_valueRes?.month}`
      let year = `&atYear=${_valueRes?.year}`
      let factid = `&factoryId=${_valueRes?.factid}`
      let facttype = `&factoryTypeId=${_valueRes?.facttype}`
      let monthParame = `${entp}${mont}${year}${factid}${facttype}`

      const res = await defaultApi.getreportEnterpriseIncomeMonth(
        monthParame
      );
      setResMonth(res?.data);
    } catch (error) {

    }
  }, [_valueRes])

  const resSearchYear = useCallback(async () => {
    try {
      let entp = `enterpriseId=${_valueRes?.entpid}`
      let year = `&atYear=${_valueRes?.year}`
      let factid = `&factoryId=${_valueRes?.factid}`
      let facttype = `&factoryTypeId=${_valueRes?.facttype}`
      let yearParame = `${entp}${year}${factid}${facttype}`

      const res = await defaultApi.getreportEnterpriseIncomeYear(
        yearParame
      );
      setResYear(res?.data);
    } catch (error) {

    }
  }, [_valueRes])

  const dateForm = useMemo(() => {
    if (swCotent === "11") {
      if (_activeSelect) {
        return `${convertDateThai2(
          _valueRes?.month.toString(),
          "month"
        )}/${convertDateThai2(_valueRes?.year.toString(), "year")}`
      } else {
        return `${convertDateThai2(_valueRes?.day, "day")}/${convertDateThai2(
          _valueRes?.month.toString(),
          "month"
        )}/${convertDateThai2(_valueRes?.year.toString(), "year")}`
      }
    }
    if (swCotent === "12") {
      return `${convertDateThai2(_valueRes?.year.toString(), "year")}`
    }
  }, [swCotent, _valueRes, _activeSelect])

  useEffect(() => {
    resSearchMonth()
  }, [resSearchMonth])

  useEffect(() => {
    resSearchYear()
  }, [resSearchYear])

  useEffect(() => {
    apiItem(barFact, typeFact);
  }, [barFact, typeFact]);

  // if (swCotent === "11") {
  //   return (
  //     <div className={`${styles.container}`}>
  //       <div className={`${styles.boxcontainer}`}>
  //         <div className={`${styles.drop_filter}`}>
  //           <select
  //             name="entp1"
  //             id="sel01"
  //             style={{ color: "black" }}
  //             onChange={(e) => {
  //               setValueRes((prev) => ({ ...prev, entpid: e?.target?.value }))
  //             }}
  //             value={_valueRes?.entpid === "" ? "" : _valueRes?.entpid}
  //           >
  //             {entpLst(11)}
  //           </select>
  //         </div>
  //         <div className={`${styles.drop_filter}`}>
  //           <select
  //             style={{ color: "black" }}
  //             id="sel02"
  //             onChange={(e) => {
  //               setValueRes((prev) => ({ ...prev, month: e?.target?.value }))
  //               setActiveSelect(true)
  //             }}
  //             value={_valueRes?.month === "" ? "" : _valueRes?.month}
  //           >
  //             {selectMonth(1)}
  //           </select>
  //         </div>
  //         <div className={`${styles.drop_filter}`}>
  //           <select
  //             style={{ color: "black" }}
  //             id="sel03"
  //             onChange={(e) => {
  //               setValueRes((prev) => ({ ...prev, year: e?.target?.value }))
  //               setActiveSelect(true)
  //             }}
  //             value={_valueRes?.year === "" ? "" : _valueRes?.year}
  //           >
  //             {selectYear(1)}
  //           </select>
  //         </div>
  //       </div>

  //       <h4
  //         className={`${styles.title_text}`}
  //         style={{ background: _theme?.backgroundColor }}
  //       >
  //         รายได้รวมของกลุ่มวิสาหกิจชุมชน
  //       </h4>
  //       <h4 id="subtitle01" className={`${styles.subtitle_text}`}>
  //         {checkWordTitle}
  //       </h4>

  //       <div className={`${styles.show_date}`}>
  //         <p>ข้อมูล ณ</p>
  //         <p>{dateForm}</p>
  //       </div>

  //       <div className={`${styles.charts_}`}>
  //         <ChartsIncomeTotal1
  //           ref={chart}
  //           datain={_resMonth}
  //           datetime={_valueRes}
  //         />
  //       </div>

  //       <div className={`${styles.export_}`}>
  //         <ExportPopup
  //           label={"report"}
  //           trigger={_triggerExport}
  //           event={{ csv: downloadCSV, image: downloadImage }}
  //         />
  //         <button
  //           id="exporting01"
  //           type="button"
  //           className={`${styles.btn_}`}
  //           onClick={() => {
  //             setTriggerExport(!_triggerExport);
  //           }}
  //         >
  //           Export
  //         </button>
  //       </div>
  //     </div>
  //   );
  // }

  if (swCotent === "12") {
    window.ChartTitleUnit = {
      title: 'เดือน',
      unit: ['บาท'],
      totalStatus : false
    }

    return (
      <div className={`${styles.container}`}>
        <div className={`${styles.boxcontainer}`}>
          <div className={`${styles.drop_filter}`}>
            {/* <label>กลุ่มเกษตรกร :</label> */}
            <select
              name=""
              style={{ color: "black" }}
              id="sel04"
              onChange={(e) => {
                setValueRes((prev) => ({ ...prev, entpid: e?.target?.value }))
              }}
              value={_valueRes?.entpid === "" ? "" : _valueRes?.entpid}
            >
              {entpLst(2)}
            </select>
          </div>

          <div className={`${styles.drop_filter}`}>
            <select
              name=""
              style={{ color: "black" }}
              id="sel05"
              onChange={(e) => {
                setValueRes((prev) => ({ ...prev, year: e?.target?.value }))
              }}
              value={_valueRes?.year === "" ? "" : _valueRes?.year}
            >
              {selectYear(2)}
            </select>
          </div>
        </div>

        <h4
          className={`${styles.title_text}`}
          style={{ background: _theme?.backgroundColor }}
        >
          รายได้รวมของกลุ่มวิสาหกิจชุมชน
        </h4>
        <h4 id="subtitle02" className={`${styles.subtitle_text}`}>
          {checkWordTitle}
        </h4>

        <div className={`${styles.show_date}`}>
          <p>ข้อมูลล่าสุด ณ ปีที่</p>
          <p>{dateForm}</p>
        </div>

        <div className={`${styles.charts_}`}>
          <ChartsIncomeTotal2
            ref={chart}
            datain={_resYear}
            datetime={_valueRes}
          />
        </div>

        <div className={`${styles.export_}`}>
          <ExportPopup
            label={"report"}
            trigger={_triggerExport}
            event={{ csv: downloadCSV, image: downloadImage }}
          />
          <button
            id="exporting02"
            type="button"
            className={`${styles.btn_}`}
            onClick={() => {
              setTriggerExport(!_triggerExport);
            }}
          >
            Export
          </button>
        </div>
      </div>
    );
  }
};

export default FarmerInfo;
