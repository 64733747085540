import { useState } from "react";

const useSwitchBox = () => {
  const [switchBoxL, setSwitchBoxL] = useState(false);
  const [switchBoxR, setSwitchBoxR] = useState(false);
  const onSwitchBoxR = (boolean) => {
    setSwitchBoxR(boolean);
  };
  const onSwitchBoxL = (boolean) => {
    setSwitchBoxL(boolean);
  };
  return [switchBoxR, onSwitchBoxR, switchBoxL, onSwitchBoxL];
};

export default useSwitchBox;
