import React from "react";
import icon from "../../../../assets/img/Icon";
import HighCharts from "../../../../components/Highcharts/HighCharts";
import styles from "../scss/ReportYearIncome.module.scss";
import { formatterTooltip, formatterXaxis_1, formatterYaxis } from "../../../../helpers/suffixchart.helper";

const ChartsYearIncome = React.forwardRef((prop, ref) => {

  let currYear = []
  let lastYear = []

  let totalLast = 0
  let totalCurr = 0

  let legendYear = Number(prop?.yearChk)

  try {
    let date = new Date()
    let year = date.getFullYear()
    let month = date.getMonth() + 1

    let datain = prop.datain
    for (let index = 0; index < datain.enterpriseIncomeCurrentYear.length; index++) {
      if (Number(prop.yearChk) === Number(year)) {
        if (Number(datain.enterpriseIncomeCurrentYear[index].month) > Number(month)) {
          currYear[index] = null
          lastYear[index] = null

          totalCurr = totalCurr + 0
          totalLast = totalLast + 0
        } else {
          currYear[index] = datain.enterpriseIncomeCurrentYear[index]?.value || 0
          lastYear[index] = datain.enterpriseIncomeLastYear[index]?.value || 0

          totalCurr = totalCurr + datain.enterpriseIncomeCurrentYear[index]?.value || 0
          totalLast = totalLast + datain.enterpriseIncomeLastYear[index]?.value || 0
        }
      } else if (Number(prop.yearChk) < Number(year)) {
        currYear[index] = datain.enterpriseIncomeCurrentYear[index]?.value || 0
        lastYear[index] = datain.enterpriseIncomeLastYear[index]?.value || 0

        totalCurr = totalCurr + datain.enterpriseIncomeCurrentYear[index]?.value || 0
        totalLast = totalLast + datain.enterpriseIncomeLastYear[index]?.value || 0
      }

    }
  } catch (error) {

  }

  const imgageFlip = (value) => {
    let x
    if (Number(value) > 0) {
      x = (
        <img src={icon.rising} alt="" />
      )
    } else {
      x = (
        <img style={{ transform: 'scaleY(-1)' }} src={icon.rising} alt="" />
      )
    }
    return x
  }

  let ranges = [
    { divider: 1e18, suffix: "E" },
    { divider: 1e15, suffix: "P" },
    { divider: 1e12, suffix: "T" },
    { divider: 1e9, suffix: "G" },
    { divider: 1e6, suffix: "M" },
    { divider: 1e3, suffix: "K" },
  ];
  const options = {
    chart: {
      type: "line",
      height: 380,
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: [
        "ม.ค.",
        "ก.พ.",
        "มี.ค.",
        "ม.ย.",
        "พ.ค.",
        "มิ.ย.",
        "ก.ค.",
        "ส.ค.",
        "ก.ย.",
        "ต.ค.",
        "พ.ย.",
        "ธ.ค.",
      ],
      labels: {
        style: {
          fontSize: "13px",
        },
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "รายได้ (บาท)",
      },
      labels: {
        enabled: true,
        formatter: formatterYaxis,
        style: {
          fontSize: "13px",
        },
      },
    },
    tooltip: {
      //pointFormat: "<b>{series.name}: {point.y} ตัน</b>",
      style: {
        fontSize: "18px",
      },
      enabled: true,
      ...formatterTooltip
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
          formatter: function () {
            for (let i = 0; i < ranges.length; i++) {
              if (this.y >= ranges[i].divider) {
                return (
                  Number(this.y / ranges[i].divider).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + ranges[i].suffix
                );
              }
            }
            return Number(this.y).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
          },
          style: {
            fontSize: "16px",
          },
        },
      },
      line: {
        dataLabels: {
          enabled: true,
          formatter: formatterXaxis_1,
          style: {
            fontSize: "16px",
          },
        },
        enableMouseTracking: true,
      },
      series: {
        lineWidth: 3,
        marker: {
          enabled: false,
        },
      },
    },
    colors: ["#a52352", "#235f07"],
    exporting: {
      chartOptions: { // specific options for the exported image
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true,
              style: {
                fontSize: "6px",
              },
              formatter: function () {
                for (let i = 0; i < ranges.length; i++) {
                  if (this.y >= ranges[i].divider) {
                    return (
                      Number(this.y / ranges[i].divider).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + ranges[i].suffix
                    );
                  }
                }
                if (Number(this.y) === 0) {
                  return null
                  //return this.y.toString();
                } else {
                  return Number(this.y).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
                }
              },
            },
            enableMouseTracking: true,
          },
        },
        xAxis: {
          labels: {
            style: {
              fontSize: '6px'
            },
          }
        },
        yAxis: {
          labels: {
            style: {
              fontSize: '6px'
            },
          }
        },
      },
      enabled: false,
    },
    series: [
      {
        name: ((legendYear - 1)+543),
        // name: "ปีก่อน",
        data: lastYear,
      },
      {
        name: (legendYear + 543),
        // name: "ปีปัจจุบัน",
        data: currYear,
      },
    ],
  };
  return (
    <div className={`${styles.charts}`}>
      <HighCharts ref={ref} options={options} />
      <div className={`${styles.summary}`}>
        <div>
          <p>รายได้รวมทั้งหมด(ปีปัจจุบัน) : {Number(totalCurr).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} บาท</p>
          <p>รายได้รวมทั้งหมด(ปีก่อน) : {Number(totalLast).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} บาท</p>
        </div>
        <div>
          {
            imgageFlip(Number(totalCurr - totalLast))
          }
          {/* <img src={icon.rising} alt="" /> */}
          <p>: {Number(totalCurr - totalLast).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
        </div>
      </div>
    </div>
  );
});

export default ChartsYearIncome;
