import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { FaSquare } from "react-icons/fa";
import { IoMdAlert } from "react-icons/io";
import AddMember from "./add_/AddMember";
import EditMember from "./edit_/EditMember";
import icon from "../../../../assets/img/Icon";
import useSwitch from "../../../../utils/hooks/useSwitch";
import { defaultApi } from "../../../../services/api";
import { QueryGetAll } from "../../../../helpers/api.helper";
import Message from "../../../../utils/message/Message";
import { getEnterpriseData } from "../../../../services/enterprise.slice";
import {
  createUrlImage,
  exportCsvFile,
  exportXlsxFile,
} from "../../../../helpers/func.helper";
import ExportPopup from "../../../../layouts/exportpopup/ExportPopup";
import SwitchMenu from "./SwitchMenu";
import { convertDateEu } from "../../../../utils/format/Date.format";
import styles from "./scss/MemberInfo.module.scss";

const memberStatus = [
  { id: 1, name: "เริ่ม", color: "#789b33" },
  { id: 2, name: "สิ้นสุด", color: "#ff8311" },
];

const MemberInfo = () => {
  const enterpriseData = useSelector(getEnterpriseData);

  const [_switch, onSwitch, _switch2, onSwitch2, _switch3, onSwitch3] =
    useSwitch();
  const [allFarmer, setAllFarmer] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [placeholderDisabled, setPlaceholderDisabled] = useState(false);
  const [_triggerExport, setTriggerExport] = useState(false);

  const checkEndProjectMem = (end_date) => {
    const date = convertDateEu(new Date());
    const endDate = convertDateEu(end_date);

    return date > endDate ? "#ff8311" : "";
  };

  /* Export csv and excel : START */
  const onExportCsv = () => {
    // ข้อมูลที่ต้องส่งไปให้ ฟังก์ชั่น ใช้งาน
    let exportName = "EnterpriseMemberReport";
    let fileType = "csv";
    let obj = {
      enterpriseId: enterpriseData.id,
    };
    // Ready
    exportCsvFile(exportName, fileType, obj);
  };
  const onExportXlsx = () => {
    // ข้อมูลที่ต้องส่งไปให้ ฟังก์ชั่น ใช้งาน
    let exportName = "EnterpriseMemberReport";
    let fileType = "excel";
    let obj = {
      enterpriseId: enterpriseData.id,
    };
    // Ready
    exportXlsxFile(exportName, fileType, obj);
  };
  /* Export csv and excel : END */

  const getAllFarmer = useCallback(async () => {
    if (!enterpriseData?.id) return;

    const qryGetAll = {
      $count: "%24count=true",
      $expand: "&%24expand=avatarNavigation%2Centerprise",
      $top: "",
      $skip: "",
      $filter: `&%24filter=enterpriseId%20eq%20${enterpriseData?.id}`,
      $orderby: "",
    };
    const _queryAll = QueryGetAll(qryGetAll);
    try {
      const res = await defaultApi.getAllFarmer(_queryAll);
      setAllFarmer(res.data.datas);
    } catch (error) {
      console.log(error);
    }
  }, [enterpriseData]);

  const onSearchData = useCallback(async () => {
    const loading = toast.loading(Message.notify.search_loading);

    const str = searchText.split(/(\s+)/).filter((e) => e.trim().length > 0);
    const searchQuery = str.join(" ");
    const [firstname, lastname] = searchText.split(" ");

    let textQuery1;
    let textQuery2;
    if (searchText.indexOf(" ") !== -1) {
      textQuery1 = firstname;
      textQuery2 = lastname;
    } else {
      textQuery1 = searchQuery;
      textQuery2 = searchQuery;
    }

    const qryGetAll = {
      $count: "%24count=true",
      $expand: "&%24expand=avatarNavigation%2Centerprise",
      $top: "",
      $skip: "",
      $filter: `&%24filter=enterpriseId%20eq%20${enterpriseData?.id}
      %20and%20(contains(firstname%2C%27${textQuery1}%27)
      %20or%20contains(lastname%2C%27${textQuery2}%27))`,
      $orderby: "",
    };
    const _queryAll = QueryGetAll(qryGetAll);
    try {
      const res = await defaultApi.getAllFarmer(_queryAll);
      setAllFarmer(res.data.datas);
      if (res.data.datas.length === 0) {
        toast.error(Message.notify.search_not_found, { duration: 3000 });
      }
    } catch (error) {
      console.log(error);
    } finally {
      toast.dismiss(loading);
    }
  }, [searchText, enterpriseData]);

  useEffect(() => {
    // Use functions
    if (searchText === "") getAllFarmer();
    if (!_switch && !_switch2) over_Out();
  }, [searchText, _switch, _switch2, getAllFarmer]);

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.content_}`}>
        {!_switch && !_switch2 && (
          <>
            <div className={`${styles.search_box}`}>
              <label>ค้นหา: </label>
              <div className={`${styles.search_input}`}>
                <input
                  type="search"
                  onChange={(e) => setSearchText(e.target.value)}
                  onFocus={() => setPlaceholderDisabled(true)}
                  onBlur={() => setPlaceholderDisabled(false)}
                  placeholder={
                    placeholderDisabled ? "" : "ค้นหาด้วย(ชื่อ นามสกุล)"
                  }
                />
                <div className={`${styles.btn_}`} onClick={onSearchData}>
                  <img src={icon.search} alt="" />
                </div>
              </div>
            </div>

            <h4 className={`${styles.title_text}`}>
              รายชื่อสมาชิกกลุ่มวิสาหกิจชุมชน
            </h4>

            <h5 className={`${styles.title_text_}`}>
              {enterpriseData?.name} ({allFarmer.length})
            </h5>

            <SwitchMenu
              _onSwitch3={onSwitch3}
              btn1={`สมาชิกในกลุ่ม (${
                allFarmer.filter(
                  (item) => item.status !== "สมาชิกภายนอกกลุ่มวิสาหกิจชุมชน"
                ).length
              })`}
              btn2={`สมาชิกนอกกลุ่ม (${
                allFarmer.filter(
                  (item) => item.status === "สมาชิกภายนอกกลุ่มวิสาหกิจชุมชน"
                ).length
              })`}
            />

            <div className={`${styles.content_box}`}>
              {!_switch3
                ? allFarmer
                    .filter(
                      (item) => item.status !== "สมาชิกภายนอกกลุ่มวิสาหกิจชุมชน"
                    )
                    .map((item) => {
                      return (
                        <div key={item.id} className={`${styles.card_}`}>
                          <div
                            className={`${styles.img_box}`}
                            style={{
                              backgroundColor:
                                item.status ===
                                  "สมาชิกภายนอกกลุ่มวิสาหกิจชุมชน" && "#ff0000",
                            }}
                          >
                            <div className={`${styles.box_}`}>
                              <img
                                src={`${
                                  item.avatar
                                    ? createUrlImage(
                                        item?.avatarNavigation?.filePath
                                      )
                                    : icon.user
                                }`}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className={`${styles.details_box}`}>
                            <div className={`${styles.box_1}`}>
                              <p>
                                {item.title} {item.firstname} {item.lastname}
                              </p>
                              {/* <span>{item.memberNo}</span> */}
                            </div>
                            <div className={`${styles.box_2}`}>
                              <small>{item.address}</small>
                            </div>
                          </div>
                          <div
                            className={`${styles.btn_}`}
                            style={{
                              backgroundColor: checkEndProjectMem(item.endDate),
                            }}
                            onClick={() => {
                              onSwitch2(item.id);
                              onSwitch3(false);
                            }}
                          >
                            <img src={icon.vector71} alt="" />
                          </div>
                        </div>
                      );
                    })
                : allFarmer
                    .filter(
                      (item) => item.status === "สมาชิกภายนอกกลุ่มวิสาหกิจชุมชน"
                    )
                    .map((item) => {
                      return (
                        <div key={item.id} className={`${styles.card_}`}>
                          <div
                            className={`${styles.img_box}`}
                            style={{
                              backgroundColor:
                                item.status ===
                                  "สมาชิกภายนอกกลุ่มวิสาหกิจชุมชน" && "#ff0000",
                            }}
                          >
                            <div className={`${styles.box_}`}>
                              <img
                                src={`${
                                  item.avatar
                                    ? createUrlImage(
                                        item?.avatarNavigation?.filePath
                                      )
                                    : icon.user
                                }`}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className={`${styles.details_box}`}>
                            <div className={`${styles.box_1}`}>
                              <p>
                                {item.title} {item.firstname} {item.lastname}
                              </p>
                              {/* <span>{item.memberNo}</span> */}
                            </div>
                            <div className={`${styles.box_2}`}>
                              <small>{item.address}</small>
                            </div>
                          </div>
                          <div
                            className={`${styles.btn_}`}
                            onClick={() => {
                              onSwitch2(item.id);
                              onSwitch3(false);
                            }}
                          >
                            <img src={icon.vector71} alt="" />
                          </div>
                        </div>
                      );
                    })}
            </div>

            <div className={`${styles.button_right}`}>
              <ExportPopup
                label={"document"}
                trigger={_triggerExport}
                event={{ csv: onExportCsv, xlsx: onExportXlsx }}
              />
              <button
                type="button"
                className={`${styles.btn_}`}
                onClick={() => {
                  onSwitch(true);
                  onSwitch3(false);
                }}
              >
                เพิ่มสมาชิก
              </button>
              <button
                type="button"
                className={`${styles.btn_}`}
                onClick={() => setTriggerExport(!_triggerExport)}
              >
                Export
              </button>
            </div>

            <div className={`${styles.legend_con}`}>
              <IoMdAlert
                className={`btn_`}
                style={{ color: "#000000" }}
                size={25}
                cursor={"pointer"}
              />
              <div className={`legend_box`}>
                {memberStatus?.map((item) => {
                  return (
                    <div key={item.id} className={`${styles.legend_list}`}>
                      <FaSquare
                        color={item.color}
                        className={styles.fa_square}
                      />
                      <h6>{item.name}</h6>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}

        {_switch && (
          <AddMember _fetchData={getAllFarmer} _onSwitch={onSwitch} />
        )}
        {_switch2 && (
          <EditMember
            _fetchData={getAllFarmer}
            farmerID={_switch2}
            _onSwitch2={onSwitch2}
          />
        )}
      </div>
    </div>
  );
};

const over_Out = () => {
  const btn_ = document.querySelector(".btn_");
  const btn_s = document.querySelector(".legend_box");

  btn_.addEventListener("mouseover", () => btn_s.classList.add("show"));
  btn_.addEventListener("mouseout", () => btn_s.classList.remove("show"));
  btn_s.addEventListener("mouseover", () => btn_s.classList.add("show"));
  btn_s.addEventListener("mouseout", () => btn_s.classList.remove("show"));
};

export default MemberInfo;
