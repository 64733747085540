import React, { useEffect, useMemo, useRef } from "react";
import Overlay from "ol/Overlay.js";
import icon from "../../../assets/img/Icon";
import { convertCoordinates } from "../../../helpers/map.helper";
import styles from "./FactoryPopup.module.scss";

const FactoryPopup = ({ _map, _factory, _setLayerType, _setCoordFeature }) => {
  const container = useRef();
  const facIcon = useMemo(() => {
    if (_factory.factoryTypeId === 1) {
      return icon.group4;
    } else {
      return icon.group5;
    }
  }, [_factory]);

  const onPopupClick = () => {
    // Set detail type
    _setLayerType("factory");
    // Set coordi เอาไปทำ map center ตรง feature ที่คลิก
    _setCoordFeature([_factory.lng, _factory.lat]);
    // Clart crop layers
    _map
      .getLayers()
      .getArray()
      .filter((layer) => layer.get("crpId"))
      .forEach((layer) => _map.removeLayer(layer));
  };

  useEffect(() => {
    /* Create overlay layer : START */
    let facData = [_factory];
    for (let i = 0; i < facData.length; i++) {
      const overlay = new Overlay({
        element: container.current,
        position: convertCoordinates(facData[i].lng, facData[i].lat),
        stopEvent: false,
      });
      _map?.addOverlay(overlay);
    }
    /* Create overlay layer : END */
  }, [_map, _factory]);
  return (
    <div
      id="popup_fac"
      className={`ol_popup ${styles.ol_popup}`}
      ref={container}
      style={
        _factory.factoryTypeId === 1
          ? { backgroundColor: "red" }
          : { backgroundColor: "blue" }
      }
      onClick={onPopupClick}
    >
      <div
        id="fac_popup_content"
        className={`popup_content ${styles.popup_content}`}
      >
        <div className={`${styles.img_icon}`}>
          <img src={facIcon} alt="" />
        </div>
        <div className={`${styles.text_content}`}>
          <div>
            <p id="fac_text_name">{_factory.name}</p>
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", gap: "5px" }}
          >
            <p id="fac_text_details">{Number(_factory.proposalPower).toFixed(2)}</p>
            <span>MW</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FactoryPopup;
