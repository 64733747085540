import React from "react";
import HighCharts from "../../../../components/Highcharts/HighCharts";
import styles from "../scss/ReportFarmland.module.scss";

const ChartsFarmland = React.forwardRef((prop, ref) => {
  let ranges = [
    { divider: 1e18, suffix: "E" },
    { divider: 1e15, suffix: "P" },
    { divider: 1e12, suffix: "T" },
    { divider: 1e9, suffix: "G" },
    { divider: 1e6, suffix: "M" },
    { divider: 1e3, suffix: "K" },
  ];

  //datain
  let item = [];
  //let area = []

  try {
    let color = prop.colors.data.color;

    for (let index = 0; index < prop.datain.length; index++) {
      const el = prop.datain[index];

      if (el?.area) {
        item.push({
          name: el.name,
          data: [el.area],
          color: color[index].colorCode,
        });
      }
    }
  } catch (error) {}

  const options = {
    chart: {
      type: "column",
      height: 470,
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: [""],
    },
    yAxis: {
      title: {
        text: null,
      },
      labels: {
        style: {
          fontSize: "13px",
        },
      },
    },
    tooltip: {
      enabled: false,
      style: {
        fontSize: "18px",
      },
      shared: true,
      split: false,
      formatter: function () {
        var point = this;
        let i = "";
        for (let index = 0; index < point.points.length; index++) {
          const element = point.points[index];
          //console.log(element);
          if (index === 0) {
            i =
              i +
              (point.x +
                "<br/><b>" +
                element.series.name +
                "</b> " +
                Number(element.y).toLocaleString() +
                " ไร่");
          } else {
            i =
              i +
              ("<br/><b>" +
                element.series.name +
                "</b> " +
                Number(element.y).toLocaleString() +
                " ไร่");
          }
        }
        return i;
      },
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
          style: {
            fontSize: 16,
          },
          formatter: function () {
            for (let i = 0; i < ranges.length; i++) {
              if (this.y >= ranges[i].divider) {
                return (
                  (this.y / ranges[i].divider).toString() + ranges[i].suffix
                );
              }
            }
            if (Number(this.y) === 0) {
              return null;
            } else {
              return Number(this.y).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              });
            }
          },
        },
      },
      series: {
        dataLabels: {
          enabled: true,
          // formatter: function () {
          //   for (let i = 0; i < ranges.length; i++) {
          //     if (this.y >= ranges[i].divider) {
          //       return (
          //         Number(this.y / ranges[i].divider).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + ranges[i].suffix
          //       );
          //     }
          //   }
          //   return Number(this.y).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
          // },
        },
      },
    },
    //colors: ["#0086ea", "#c324c7", "#1dd8bd", "#e83880"],
    exporting: {
      chartOptions: {
        // specific options for the exported image
        plotOptions: {
          column: {
            dataLabels: {
              enabled: true,
              style: {
                fontSize: "6px",
              },
              formatter: function () {
                for (let i = 0; i < ranges.length; i++) {
                  if (this.y >= ranges[i].divider) {
                    return (
                      Number(this.y / ranges[i].divider).toLocaleString(
                        undefined,
                        { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                      ) + ranges[i].suffix
                    );
                  }
                }
                if (Number(this.y) === 0) {
                  return null;
                  //return this.y.toString();
                } else {
                  return Number(this.y).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  });
                }
              },
            },
            enableMouseTracking: true,
          },
        },
        xAxis: {
          labels: {
            style: {
              fontSize: "6px",
            },
          },
        },
        yAxis: {
          labels: {
            style: {
              fontSize: "6px",
            },
          },
        },
      },
      buttons: {
        contextButton: {
          enabled: false,
        },
        button: {
          text: "Export",
          theme: {
            fill: "#f9df70",
            stroke: "#f9df70",
            states: {
              hover: {
                fill: "#fcc",
                stroke: "#f00",
              },
              select: {
                fill: "#cfc",
                stroke: "#0f0",
              },
            },
          },
          menuItems: [
            "viewFullscreen",
            "separator",
            "downloadPNG",
            "downloadSVG",
            "downloadPDF",
            "separator",
            "downloadXLS",
          ],
        },
      },
      enabled: false,
    },
    navigation: {
      buttonOptions: {
        align: "right",
        verticalAlign: "bottom",
        y: 0,
      },
    },
    series: item,
    // series: [
    //   { name: "หญ้าเนเปียร์", data: [140] },
    //   { name: "ปาล์มน้ำมัน", data: [87] },
    //   { name: "ข้าวโพด", data: [158] },
    //   { name: "มันสำปะหลัง", data: [120] },
    // ],
  };

  return (
    <div className={`${styles.charts}`}>
      <HighCharts ref={ref} options={options} />
    </div>
  );
});

export default ChartsFarmland;
