import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { defaultApi } from "../../../services/api";
import {
  CheckAmphoe,
  CheckLat,
  CheckLng,
  CheckProvince,
  CheckTambol,
  CheckValidLength,
} from "../../../utils/validation_input/Validation";
import {
  convertDateEu,
  convertDateThai,
} from "../../../utils/format/Date.format";
import Message from "../../../utils/message/Message";
import { QueryGetAll } from "../../../helpers/api.helper";
import icon from "../../../assets/img/Icon";
import image from "../../../assets/img/image";
import styles from "../scss/ModalHowTo.module.scss";

const initData = {
  lat: "",
  lng: "",
  addressRegionCode: "",
  addressProvince: "",
  addressProvCode: "",
  addressAmphoe: "",
  addressAmpCode: "",
  addressTambol: "",
  addressTamCode: "",
  name: "",
  enterpriseTypeId: "",
  factoryId: "",
  registrationNumber: "",
  openDate: "",
  manager: "",
  point: "",
  mobile: null,
};

const AddFarmerForm = ({ _checkUserCreatedEnt, _themeColor }) => {
  const navigate = useNavigate();

  const [_data, setData] = useState(initData);
  const [allFactory, setAllFactory] = useState([]);
  const [enterpriseType, setEnterpriseType] = useState([]);
  const [allProvince, setAllProvince] = useState([]);
  const [allAmphoe, setAllAmphone] = useState([]);
  const [allTambol, setAllTambol] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [validErr, setValidErr] = useState(false);

  /* DatePicker : START */
  const onChangeOpenDate = (e) => {
    const dateFormat = convertDateEu(e);
    setData({ ..._data, openDate: dateFormat });
    setSelectedDate(e);
    setOpenDatePicker(!openDatePicker);
  };
  /* DatePicker : END */

  const onChange = (e) => {
    const { name, value } = e.target;
    setData({ ..._data, [name]: value });
  };
  const onChangeMulti = (e) => {
    const { value } = e.target;
    allProvince.forEach((item) => {
      if (item.provName === value) {
        setData({
          ..._data,
          addressProvince: value,
          addressProvCode: item.provCode,
          addressRegioncode: item.region,
          addressAmphoe: "",
          addressAmpCode: "",
        });
      }
    });
    allAmphoe.forEach((item) => {
      if (item.ampName === value) {
        setData({
          ..._data,
          addressAmphoe: value,
          addressAmpCode: item.ampCode,
          addressTambol: "",
          addressTamCode: "",
        });
      }
    });
    allTambol.forEach((item) => {
      if (item.tamName === value) {
        setData({
          ..._data,
          addressTambol: value,
          addressTamCode: item.tamCode,
        });
      }
    });
  };
  const createEnterprise = async (e) => {
    e.preventDefault();
    if (CheckLat(_data.lat)) return setValidErr(true);
    if (CheckLng(_data.lng)) return setValidErr(true);
    if (CheckProvince(_data.addressProvince)) return setValidErr(true);
    if (CheckAmphoe(_data.addressAmphoe)) return setValidErr(true);
    if (CheckTambol(_data.addressTambol)) return setValidErr(true);
    if (CheckValidLength(_data.name)) return setValidErr(true);
    if (CheckValidLength(_data.enterpriseTypeId)) return setValidErr(true);
    if (CheckValidLength(_data.factoryId)) return setValidErr(true);
    if (CheckValidLength(_data.registrationNumber)) return setValidErr(true);
    if (CheckValidLength(_data.openDate)) return setValidErr(true);

    /* เช็คว่ามีกลุ่มวิสาหกิจชุมชนที่ผู้ใช้นั้นๆ ว่ามีการสร้างแล้วหรือไม่ 
    ถ้ามีให้โชว์ข้อความบอก : START */
    if (_checkUserCreatedEnt)
      return toast.error(Message.notify.checkUserCreatedEnt, {
        duration: 5000,
      });
    /* เช็คว่ามีกลุ่มวิสาหกิจชุมชนที่ผู้ใช้นั้นๆ ว่ามีการสร้างแล้วหรือไม่ 
    ถ้ามีให้โชว์ข้อความบอก : END */

    const loading = toast.loading(Message.notify.loading);
    const params = {};
    Object.keys(_data).forEach((key) => {
      if (key === "lat") {
        params[key] = +_data[key];
      } else if (key === "lng") {
        params[key] = +_data[key];
      } else if (key === "enterpriseTypeId") {
        params[key] = +_data[key];
      } else if (key === "factoryId") {
        params[key] = +_data[key];
      } else if (key === "manager") {
        params[key] = JSON.stringify([]);
      } else if (key === "point") {
        params[key] = [[+_data.lat, +_data.lng]];
      } else {
        params[key] = _data[key];
      }
    });
    try {
      const res = await defaultApi.createEnterprise(params);
      console.log(res.statusText);
      // Navigate
      navigate(`/manage/${_data.factoryId}`);
      // Reset input
      setData(initData);
      // Close modal
      document.querySelector("#close_addFf").click();
      // Alert message
      toast.success(Message.notify.add_enterprise_success, { duration: 3000 });
    } catch (error) {
      console.log(error);
      toast.error(Message.notify.add_enterprise_error, { duration: 3000 });
    } finally {
      toast.dismiss(loading);
    }
  };

  const getMultipleApi = async () => {
    try {
      const [allFactory, enterpriseType, allProvince] = await Promise.all([
        defaultApi.getAllFactory(),
        defaultApi.getEnterpriseType(),
        defaultApi.getAllProvince(),
      ]);

      setAllFactory(allFactory.data);
      setEnterpriseType(enterpriseType.data);
      setAllProvince(allProvince.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllAmphoe = useCallback(async () => {
    const qryGetAll = {
      $count: "%24count=true",
      $expand: "",
      $top: "",
      $skip: "",
      $filter: `&%24filter=provCode%20eq%20%27${_data.addressProvCode}%27`,
      $orderby: "",
    };
    const _queryAll = QueryGetAll(qryGetAll);
    try {
      const res = await defaultApi.getAllAmphoe(_queryAll);
      // Create a new Set with unique ampNames
      const uniqueAmpNames = [
        ...new Set(res.data.datas.map((item) => item.ampName)),
      ];

      // Create a new array of objects with unique ampNames
      const uniqueData = uniqueAmpNames.map((ampName) => {
        return res.data.datas.find((item) => item.ampName === ampName);
      });

      setAllAmphone(uniqueData);
    } catch (error) {
      console.log(error);
    }
  }, [_data]);
  const getAllTambol = useCallback(async () => {
    const qryGetAll = {
      $count: "%24count=true",
      $expand: "",
      $top: "",
      $skip: "",
      $filter: `&%24filter=ampCode%20eq%20%27${_data.addressAmpCode}%27
      %20and%20ampName%20eq%20%27${_data.addressAmphoe}%27`,
      $orderby: "",
    };
    const _queryAll = QueryGetAll(qryGetAll);
    try {
      const res = await defaultApi.getAllTambol(_queryAll);
      // Create a new Set with unique ampNames
      const uniqueAmpNames = [
        ...new Set(res.data.datas.map((item) => item.tamName)),
      ];

      // Create a new array of objects with unique ampNames
      const uniqueData = uniqueAmpNames.map((tamName) => {
        return res.data.datas.find((item) => item.tamName === tamName);
      });

      setAllTambol(uniqueData);
    } catch (error) {
      console.log(error);
    }
  }, [_data]);

  const onBackAndReset = () => {
    setData(initData);
    setSelectedDate(new Date());
    setValidErr(false);
  };

  useEffect(() => {
    // Use functions
    getMultipleApi();
    getAllAmphoe();
    getAllTambol();
  }, [getAllAmphoe, getAllTambol]);

  useEffect(() => {
    /* Function close dropdown 'DatePicker' : START */
    const onCloseDropdownDatePicker = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#ent_open_date_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenDatePicker(false);
      }
    };

    if (openDatePicker) {
      document.addEventListener("click", onCloseDropdownDatePicker);
    }
    /* Function close dropdown 'DatePicker' : END */

    return () => {
      document.removeEventListener("click", onCloseDropdownDatePicker);
    };
  }, [openDatePicker]);

  return (
    <>
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: _themeColor?.backgroundColor }}
            >
              <h4 className="modal-title" id="staticBackdropLabel">
                เพิ่มกลุ่มวิสาหกิจชุมชน
              </h4>
            </div>
            <div className="modal-body">
              <form onSubmit={createEnterprise} className="form_input">
                <div className="input_flex">
                  <div className="input_1">
                    <h5 style={{ color: _themeColor?.color }}>
                      ละติจูด <sup className="asterisk">*</sup>
                    </h5>
                    <input
                      type="text"
                      name="lat"
                      value={_data.lat}
                      className={`${
                        validErr && _data.lat === "" ? "error" : ""
                      }`}
                      onChange={onChange}
                    />
                    {validErr && (
                      <small className="err_message">
                        {CheckLat(_data.lat)}
                      </small>
                    )}
                  </div>
                  <div className="input_2">
                    <h5 style={{ color: _themeColor?.color }}>
                      ลองจิจูด <sup className="asterisk">*</sup>
                    </h5>
                    <input
                      type="text"
                      name="lng"
                      value={_data.lng}
                      className={`${
                        validErr && _data.lng === "" ? "error" : ""
                      }`}
                      onChange={onChange}
                    />
                    {validErr && (
                      <small className="err_message">
                        {CheckLng(_data.lng)}
                      </small>
                    )}
                  </div>
                </div>
                <span
                  className="info_how_to"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop99"
                >
                  วิธีหาค่าพิกัด
                </span>
                <div className="input_">
                  <h5 style={{ color: _themeColor?.color }}>
                    จังหวัด <sup className="asterisk">*</sup>
                  </h5>
                  <select
                    name="addressProvince"
                    value={_data.addressProvince}
                    className={`${
                      validErr && _data.addressProvince === "" ? "error" : ""
                    }`}
                    onChange={onChangeMulti}
                  >
                    <option style={{ display: "none" }}>เลือกจังหวัด</option>
                    {allProvince
                      .sort((a, b) => a.provName.localeCompare(b.provName))
                      .map((item) => {
                        return (
                          <option key={item.gid} value={item.provName}>
                            {item.provName}
                          </option>
                        );
                      })}
                  </select>
                  {validErr && (
                    <small className="err_message">
                      {CheckProvince(_data.addressProvince)}
                    </small>
                  )}
                </div>
                <div className="input_">
                  <h5 style={{ color: _themeColor?.color }}>
                    อำเภอ <sup className="asterisk">*</sup>
                  </h5>
                  <select
                    name="addressAmphoe"
                    value={_data.addressAmphoe}
                    className={`${
                      validErr && _data.addressAmphoe === "" ? "error" : ""
                    }`}
                    onChange={onChangeMulti}
                  >
                    <option style={{ display: "none" }}>เลือกอำเภอ</option>
                    {allAmphoe
                      .sort((a, b) => a.ampName.localeCompare(b.ampName))
                      .map((item) => {
                        return (
                          <option key={item.gid} value={item.ampName}>
                            {item.ampName}
                          </option>
                        );
                      })}
                  </select>
                  {validErr && (
                    <small className="err_message">
                      {CheckAmphoe(_data.addressAmphoe)}
                    </small>
                  )}
                </div>
                <div className="input_">
                  <h5 style={{ color: _themeColor?.color }}>
                    ตำบล <sup className="asterisk">*</sup>
                  </h5>
                  <select
                    name="addressTambol"
                    value={_data.addressTambol}
                    className={`${
                      validErr && _data.addressTambol === "" ? "error" : ""
                    }`}
                    onChange={onChangeMulti}
                  >
                    <option style={{ display: "none" }}>เลือกตำบล</option>
                    {allTambol
                      .sort((a, b) => a.tamName.localeCompare(b.tamName))
                      .map((item) => {
                        return (
                          <option key={item.gid} value={item.tamName}>
                            {item.tamName}
                          </option>
                        );
                      })}
                  </select>
                  {validErr && (
                    <small className="err_message">
                      {CheckTambol(_data.addressTambol)}
                    </small>
                  )}
                </div>
                <div className="input_">
                  <h5 style={{ color: _themeColor?.color }}>
                    ชื่อกลุ่มวิสาหกิจชุมชน <sup className="asterisk">*</sup>
                  </h5>
                  <input
                    type="text"
                    name="name"
                    value={_data.name}
                    className={`${
                      validErr && _data.name === "" ? "error" : ""
                    }`}
                    onChange={onChange}
                  />
                  {validErr && (
                    <small className="err_message">
                      {CheckValidLength(_data.name)}
                    </small>
                  )}
                </div>
                <div className="input_">
                  <h5 style={{ color: _themeColor?.color }}>
                    ประเภทกลุ่ม <sup className="asterisk">*</sup>
                  </h5>
                  <select
                    name="enterpriseTypeId"
                    value={_data.enterpriseTypeId}
                    className={`${
                      validErr && _data.enterpriseTypeId === "" ? "error" : ""
                    }`}
                    onChange={onChange}
                  >
                    <option style={{ display: "none" }}>
                      เลือกประเภทกลุ่ม
                    </option>
                    {enterpriseType.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  {validErr && (
                    <small className="err_message">
                      {CheckValidLength(_data.enterpriseTypeId)}
                    </small>
                  )}
                </div>
                <div className="input_">
                  <h5 style={{ color: _themeColor?.color }}>
                    โรงไฟฟ้าที่รับซื้อ <sup className="asterisk">*</sup>
                  </h5>
                  <select
                    name="factoryId"
                    value={_data.factoryId}
                    className={`${
                      validErr && _data.factoryId === "" ? "error" : ""
                    }`}
                    onChange={onChange}
                  >
                    <option style={{ display: "none" }}>
                      เลือกโรงไฟฟ้าที่รับซื้อ
                    </option>
                    {allFactory.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  {validErr && (
                    <small className="err_message">
                      {CheckValidLength(_data.factoryId)}
                    </small>
                  )}
                </div>
                <div className="input_">
                  <h5 style={{ color: _themeColor?.color }}>
                    เลขที่ทะเบียนกลุ่มวิสาหกิจชุมชน{" "}
                    <sup className="asterisk">*</sup>
                  </h5>
                  <input
                    type="text"
                    name="registrationNumber"
                    value={_data.registrationNumber}
                    className={`${
                      validErr && _data.registrationNumber === "" ? "error" : ""
                    }`}
                    onChange={onChange}
                  />
                  {validErr && (
                    <small className="err_message">
                      {CheckValidLength(_data.registrationNumber)}
                    </small>
                  )}
                </div>
                <div className="input_">
                  <h5 style={{ color: _themeColor?.color }}>
                    วันที่จัดตั้งกลุ่มวิสาหกิจชุมชน{" "}
                    <sup className="asterisk">*</sup>
                  </h5>
                  <div
                    className={`input_unit ${
                      validErr && _data.openDate === "" ? "error" : ""
                    }`}
                  >
                    <div className="info_box">
                      <p>
                        {_data.openDate !== ""
                          ? convertDateThai(_data.openDate)
                          : ""}
                      </p>
                    </div>
                    <div
                      className="unit"
                      onClick={() => setOpenDatePicker(!openDatePicker)}
                    >
                      <img src={icon.calendar} alt="" id="ent_open_date_d" />
                    </div>
                  </div>
                  {openDatePicker && (
                    <DatePicker
                      selected={selectedDate}
                      onChange={onChangeOpenDate}
                      inline
                    />
                  )}
                  {validErr && (
                    <small className="err_message">
                      {CheckValidLength(_data.openDate)}
                    </small>
                  )}
                </div>
                <div className="button_center">
                  <button type="submit" className="button_sm">
                    บันทึก
                  </button>
                  <button
                    type="button"
                    id="close_addFf"
                    className="button_sm"
                    data-bs-dismiss="modal"
                    onClick={onBackAndReset}
                  >
                    ยกเลิก
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <ModalHowTo />
    </>
  );
};

const ModalHowTo = () => {
  return (
    <div
      className={`modal fade ${styles.modal_how_to}`}
      id="staticBackdrop99"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content" style={{ minHeight: "auto" }}>
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              วิธีการหาพิกัดตำแหน่งที่ดิน
            </h5>
          </div>
          <div className="modal-body">
            <div className="box_">
              <p>
                1. เข้า <img src={image.how_to_coor1_1} alt="" /> Google Maps
                ผ่านเว็บบราวน์เซอร์ <img src={image.how_to_coor1_2} alt="" />
              </p>
            </div>
            <div className="box_">
              <p>2. ทำการเปลี่ยนรูปแบบแผนที่เป็นแบบ ภาพถ่ายดาวเทียม</p>
              <div className="img_box">
                <img src={image.how_to_coor2} alt="" />
              </div>
            </div>
            <div className="box_">
              <p>
                3. จากนั้นค้นหาที่ช่องค้นหา ด้วย ชื่อตำบล อำเภอ จังหวัด
                หรือสถานที่ใกล้เคียงกับ ตำแหน่งของโฉนดที่ดิน
              </p>
              <div className="img_box_flex">
                <div className="img_">
                  <img src={image.how_to_coor3_1} alt="" />
                </div>
                <p>หรือ</p>
                <div className="img_">
                  <img src={image.how_to_coor3_2} alt="" />
                </div>
              </div>
            </div>
            <div className="box_">
              <p>
                4. เมื่อพบตำแหน่งที่ดินแล้ว ให้คลิกขวาในพื้นที่ ที่ดินตามโฉนด
                จะปรากฎ พิกัดละติจูด และลองจิจูด
              </p>
              <div className="img_box">
                <img src={image.how_to_coor4} alt="" />
              </div>
            </div>
            <div className="box_">
              <p>5. คลิกที่เลขพิกัด เพื่อคัดลอก</p>
            </div>
            <div className="box_">
              <p>
                6. นำมาใส่ในข้อมูลพิกัด พร้อมทั้งลบ เว้นวรรคหลัง ,
                เพื่อให้ตรงตามรูปแบบ
              </p>
              <div className="img_box" style={{ overflowX: "auto" }}>
                <img src={image.how_to_coor6} alt="" />
              </div>
            </div>

            <div className="btn_center">
              <button type="button" className="btn_" data-bs-dismiss="modal">
                กลับ
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddFarmerForm;
