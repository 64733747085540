import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { FaEye } from "react-icons/fa";
import EditContractFForm from "./form/EditContractFForm";
import useSwitchPage from "../../../../../../utils/hooks/useSwitchPage";
import { defaultApi } from "../../../../../../services/api";
import { getFactoryData } from "../../../../../../services/factory.slice";
import { convertDateThai } from "../../../../../../utils/format/Date.format";
import { QueryGetAll } from "../../../../../../helpers/api.helper";
import Pagination from "../../../../../../components/pagination/Pagination";

const pageSize = 10;

const EditContractF = ({ onSwitchBoxL }) => {
  const factoryData = useSelector(getFactoryData);

  const [switchPage, onSwitchPage] = useSwitchPage();
  const [allContractF, setAllContractF] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [_contractStatus, setContractStatus] = useState([]);

  /* Pagination Table : START */
  const onPaginate = useCallback(
    async (page) => {
      if (currentPage === page) return;

      let factoryId = factoryData?.id;
      const qryGetAll = {
        $count: "%24count=true",
        $expand:
          "&%24expand=contractFarmingDocNavigation%2Centerprise%2Cfactory",
        $top: "&%24top=10",
        $skip: `&%24skip=${page <= 1 ? "0" : page * 10 - 10}`,
        $filter: `&%24filter=factoryId%20eq%20${factoryId}`,
        $orderby: "",
      };
      const _queryAll = QueryGetAll(qryGetAll);
      try {
        const res = await defaultApi.getAllContractFarming(_queryAll);
        setAllContractF(res.data.datas);
        setTotalCount(res.data.total);
        setCurrentPage(page);
      } catch (error) {
        console.log(error);
      }
    },
    [factoryData, currentPage]
  );
  /* Pagination Table : END */

  const getAllContractFarming = useCallback(async () => {
    if (!factoryData?.id) return;

    let factoryId = factoryData?.id;
    const qryGetAll = {
      $count: "%24count=true",
      $expand: "&%24expand=contractFarmingDocNavigation%2Centerprise%2Cfactory",
      $top: "&%24top=10",
      $skip: "&%24skip=0",
      $filter: `&%24filter=factoryId%20eq%20${factoryId}`,
      $orderby: "",
    };
    const _query = QueryGetAll(qryGetAll);
    try {
      const res = await defaultApi.getAllContractFarming(_query);

      setAllContractF(res.data.datas);
      setTotalCount(res.data.total);
      setCurrentPage(1);
    } catch (error) {
      console.log(error);
    }
  }, [factoryData]);
  const getGbStatus = async () => {
    const query = `%24filter=statusCategories%20eq%20%272%27`;

    try {
      const res = await defaultApi.getGbStatus(query);

      setContractStatus(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllContractFarming();
    getGbStatus();
  }, [getAllContractFarming]);

  return (
    <div className="edit_farming">
      {switchPage === null && (
        <div className="table_container">
          <div className="table_box table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>ลำดับ</th>
                  <th>สัญญาเลขที่</th>
                  <th>สถานะสัญญา</th>
                  <th>วันเริ่มต้นสัญญา</th>
                  <th>ผู้ขาย</th>
                  <th>ผู้รับซื้อ</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {allContractF.map((item, i) => {
                  return (
                    <tr key={item.id}>
                      <td>{i + 1}</td>
                      <td>{item.no}</td>
                      <td>
                        {
                          _contractStatus.find(
                            (item1) => item1.id === item.status
                          )?.statusName
                        }
                      </td>
                      <td>{convertDateThai(item.signDate)}</td>
                      <td>{item.enterprise?.name}</td>
                      <td>{item.factory?.name}</td>
                      <td>
                        <FaEye
                          className="fa_eye"
                          onClick={() => onSwitchPage(item.id)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="pagination_">
            <Pagination
              pageSize={pageSize}
              totalCount={totalCount}
              currentPage={currentPage}
              onPageChange={(page) => onPaginate(page)}
            />
          </div>
        </div>
      )}

      {switchPage && (
        <EditContractFForm
          _switchID={switchPage}
          switchForm={onSwitchPage}
          _fetchData={getAllContractFarming}
          _contractStatus={_contractStatus}
        />
      )}

      {switchPage === null && (
        <div className="button_right">
          <button
            type="button"
            className="btn_"
            onClick={() => onSwitchBoxL(true)}
          >
            เพิ่มสัญญา
          </button>
        </div>
      )}
    </div>
  );
};

export default EditContractF;
