// CheckValidLength
export const CheckValidLength = (value) => {
  const isValidLength = /^.{1,}$/;
  if (!isValidLength.test(value)) {
    return "กรุณากรอกข้อมูล";
  }
  return null;
};
// CheckNumber
export const CheckNumber = (value) => {
  const isValidLength = /^.{1,}$/;
  const isValidNumber = /^\d+$/;
  if (!isValidLength.test(value)) {
    return "กรุณากรอกข้อมูล";
  }
  if (!isValidNumber.test(value)) {
    return "กรุณากรอกเฉพาะตัวเลข";
  }
  return null;
};
// CheckNumberDot
export const CheckNumberDot = (value) => {
  const isValidLength = /^.{1,}$/;
  const isValidNumber = /^\d*\.?\d*$/;
  if (!isValidLength.test(value)) {
    return "กรุณากรอกข้อมูล";
  }
  if (!isValidNumber.test(value)) {
    return "กรอกได้เฉพาะตัวเลขและจุด เช่น 10.10";
  }
  return null;
};
// CheckTitle
export const CheckTitle = (value) => {
  const isValidLength = /^.{1,}$/;
  if (!isValidLength.test(value)) {
    return "กรุณาเลือกคำนำหน้าชื่อ";
  }
  return null;
};

// CheckFname
export const CheckFname = (value) => {
  const isValidLength = /^.{1,}$/;
  if (!isValidLength.test(value)) {
    return "กรุณากรอกชื่อ";
  }
  // const isNonWhiteSpace = /^\S*$/;
  // if (!isNonWhiteSpace.test(value)) {
  //   return "ชื่อต้องไม่เว้นวรรค";
  // }
  const isValidTextTh = /[ก-๏a-zA-Z]+$/;
  if (!isValidTextTh.test(value)) {
    return "ต้องไม่ใส่ตัวเลข อักษรพิเศษ และไม่เว้นวรรค";
  }
  return null;
};
// CheckLname
export const CheckLname = (value) => {
  const isValidLength = /^.{1,}$/;
  if (!isValidLength.test(value)) {
    return "กรุณากรอกนามสกุล";
  }
  // const isNonWhiteSpace = /^\S*$/;
  // if (!isNonWhiteSpace.test(value)) {
  //   return "นามสกุลต้องไม่เว้นวรรค";
  // }
  const isValidTextTh = /[ก-๏a-zA-Z]+$/;
  if (!isValidTextTh.test(value)) {
    return "ต้องไม่ใส่ตัวเลข อักษรพิเศษ และไม่เว้นวรรค";
  }
  return null;
};
// CheckLname
export const CheckMobile = (value) => {
  const isValid = /^\d{10}$/;
  if (!isValid.test(value)) {
    return "กรุณากรอกเบอร์โทรศัพท์ให้ครบ 10 หลักและต้องเป็นตัวเลขเท่านั้น";
  }
  return null;
};
//CheckEmailValidity
export const CheckEmail = (value) => {
  /**
   * @param {string} value: emailValue
   */
  const isNonWhiteSpace = /^\S*$/;
  if (!isNonWhiteSpace.test(value)) {
    return "กรุณากรอกอีเมลที่ถูกต้อง";
  }
  const isValidLength = /^.{1,}$/;
  if (!isValidLength.test(value)) {
    return "กรุณากรอกอีเมล";
  }
  const isContainsSymbol =
    /^(?=.*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,}$).*$/;
  // const isContainsSymbol = /^(?=.*[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$).*$/;
  if (!isContainsSymbol.test(value)) {
    // [a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$
    // /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
    return `กรุณากรอกอีเมลที่ถูกต้อง`; // mail@example.com
  }
  return null;
};
// CheckuserGroupId
export const CheckuserGroupId = (value) => {
  const isValidLength = /^[0-9]*[1-9][0-9]*$/;
  if (!isValidLength.test(value)) {
    return "กรุณาเลือกกลุ่มผู้ใช้งาน";
  }
  return null;
};
// CheckuserOrgId
export const CheckuserOrgId = (value) => {
  const isValidLength = /^[0-9]*[1-9][0-9]*$/;
  if (!isValidLength.test(value)) {
    return "กรุณาเลือกหน่วยงาน";
  }
  return null;
};
// CheckuserLocalGroupId
export const CheckuserLocalGroupId = (value) => {
  const isValidLength = /^[0-9]*[1-9][0-9]*$/;
  if (!isValidLength.test(value)) {
    return "กรุณาเลือกกลุ่มผู้ใช้งานย่อย";
  }
  return null;
};
export const CheckUsername = (value) => {
  const isValidLength = /^.{1,}$/;
  if (!isValidLength.test(value)) {
    return "กรุณากรอกชื่อผู้ใช้งาน";
  }
  const engAndNum = /^[a-zA-Z0-9]+$/;
  if (!engAndNum.test(value)) {
    return "กรุณากรอกตัวอักษรภาษาอังกฤษและตัวเลขเท่านั้น";
  }
  return null;
};
// CheckPassword
export const CheckPassword = (value) => {
  const isValidLength = /^.{1,}$/;
  if (!isValidLength.test(value)) {
    return "กรุณากรอกรหัสผ่าน";
  }
  const isNonWhiteSpace = /^\S*$/;
  if (!isNonWhiteSpace.test(value)) {
    return "กรุณากรอกรหัสผ่านที่ถูกต้อง";
  }
  return null;
};
// CheckPasswordMatch
export const CheckPasswordMatch = (value1, value2) => {
  const isValidLength = /^.{1,}$/;
  if (!isValidLength.test(value2)) {
    return "กรุณากรอกรหัสผ่าน";
  }
  const isNonWhiteSpace = /^\S*$/;
  if (!isNonWhiteSpace.test(value2)) {
    return "กรุณากรอกรหัสผ่านที่ถูกต้อง";
  }
  if (value1 !== value2) {
    return "รหัสผ่านไม่ตรงกัน";
  }
  return null;
};
// CheckPasswordNotMatch
export const CheckPasswordNotMatch = (value1, value2) => {
  const isValidLength = /^.{1,}$/;
  if (!isValidLength.test(value2)) {
    return "กรุณากรอกรหัสผ่าน";
  }
  const isNonWhiteSpace = /^\S*$/;
  if (!isNonWhiteSpace.test(value2)) {
    return "กรุณากรอกรหัสผ่านที่ถูกต้อง";
  }
  if (value1 === value2) {
    return "รหัสผ่านใหม่ซ้ำกับรหัสผ่านเดิม";
  }
  return null;
};

// Check ละติจูด
export const CheckLat = (value) => {
  const isValidLength = /^.{1,}$/;
  const isValidNumber = /^\d*\.?\d*$/;
  if (!isValidLength.test(value)) {
    return "กรุณากรอกค่าละติจูด";
  }
  if (!isValidNumber.test(value)) {
    return "กรอกได้เฉพาะตัวเลขและจุด เช่น 10.00";
  }
  return null;
};
// Check ลองจิจูด
export const CheckLng = (value) => {
  const isValidLength = /^.{1,}$/;
  const isValidNumber = /^\d*\.?\d*$/;
  if (!isValidLength.test(value)) {
    return "กรุณากรอกค่าลองจิจูด";
  }
  if (!isValidNumber.test(value)) {
    return "กรอกได้เฉพาะตัวเลขและจุด เช่น 10.00";
  }
  return null;
};
// Check จังหวัด
export const CheckProvince = (value) => {
  const isValidLength = /^.{1,}$/;
  if (!isValidLength.test(value)) {
    return "กรุณาเลือกจังหวัด";
  }
  return null;
};
// Check อำเภอ
export const CheckAmphoe = (value) => {
  const isValidLength = /^.{1,}$/;
  if (!isValidLength.test(value)) {
    return "กรุณาเลือกอำเภอ";
  }
  return null;
};
// Check ตำบล
export const CheckTambol = (value) => {
  const isValidLength = /^.{1,}$/;
  if (!isValidLength.test(value)) {
    return "กรุณาเลือกตำบล";
  }
  return null;
};
// Check ชื่อโรงไฟฟ้า
export const CheckFactoryName = (value) => {
  const isValidLength = /^.{1,}$/;
  if (!isValidLength.test(value)) {
    return "กรุณากรอกชื่อโรงไฟฟ้า";
  }
  return null;
};
// Check ชื่อผู้ประกอบการ
export const CheckOwner = (value) => {
  const isValidLength = /^.{1,}$/;
  if (!isValidLength.test(value)) {
    return "กรุณากรอกชื่อ";
  }
  const isValidTextTh = /[ก-๏a-zA-Z]+$/;
  if (!isValidTextTh.test(value)) {
    return "ต้องไม่ใส่ตัวเลข อักษรพิเศษ";
  }
  return null;
};
// Check ประเภทโรงไฟฟ้า
export const CheckFactoryType = (value) => {
  const isValidLength = /^.{1,}$/;
  if (!isValidLength.test(value)) {
    return "กรุณาเลือกประเภทโรงไฟฟ้า";
  }
  return null;
};
// Check เลขที่คำเสนอขอขายไฟฟ้า
export const CheckProposalNo = (value) => {
  const isValidLength = /^.{1,}$/;
  if (!isValidLength.test(value)) {
    return "กรุณากรอกเลขที่คำเสนอขอขายไฟฟ้า";
  }
  return null;
};
// Check
export const CheckLicenseeNumber = (value) => {
  const isValidLength = /^.{1,}$/;
  if (!isValidLength.test(value)) {
    return "กรุณากรอกเลขที่ใบอนุญาต";
  }
  return null;
};
// Check
export const CheckLicenseeRegister = (value) => {
  const isValidLength = /^.{1,}$/;
  if (!isValidLength.test(value)) {
    return "กรุณากรอกเลขทะเบียนใบอนุญาต";
  }
  return null;
};
// Check ผู้รับอนุญาต
export const CheckManager = (value) => {
  const isValidLength = /^.{1,}$/;
  if (!isValidLength.test(value)) {
    return "กรุณาเลือกผู้รับอนุญาต";
  }
  return null;
};
// Check เลขที่โฉนด
export const CheckDeedNo = (value) => {
  const isValidLength = /^.{1,}$/;
  if (!isValidLength.test(value)) {
    return "กรุณาเลือกผู้รับอนุญาต";
  }
  return null;
};


///user admin///

export const GisLayerEPSG = (value) => {
  const isValidLength = /^[0-9]{4,4}$/;
  if (!isValidLength.test(value)) {
    return "กรอกเลข EPSG ให้ครบ 4 ตัว";
  } else {
    return null;
  }
}

export const AddSubGrp01 = (value, defaultGrp) => {
  //const isValidTextTh = /[ก-๏a-zA-Z]+$/;
  const isValidLength = /^.{1,}$/;
  // if (!isValidTextTh.test(value)) {
  //   return "ต้องไม่ใส่ตัวเลข อักษรพิเศษ และไม่เว้นวรรค";
  // } else 
  const chkCopyValue = (val, def) => {

    for (let index = 0; index < def.length; index++) {
      let obj = JSON.parse(JSON.stringify(def[index]));
      if (obj.name === val) {
        return "ชื่อซ้ำในระบบแล้ว"
      }
    }

    return null

  }
  if (!isValidLength.test(value)) {
    return "กรุณากรอกชื่อกลุ่มผู้ใช้งานย่อย";
  } else if (isValidLength.test(value)) {
    return chkCopyValue(value, defaultGrp)
  } else {
    return null
  }
}

export const AddSubGrp02 = (value, defaultOrg) => {
  //const isValidTextTh = /[ก-๏a-zA-Z]+$/;
  const isValidLength = /^.{1,}$/;
  // if (!isValidTextTh.test(value)) {
  //   return "ต้องไม่ใส่ตัวเลข อักษรพิเศษ และไม่เว้นวรรค";
  // } else 
  const chkCopyValue = (val, def) => {

    for (let index = 0; index < def.length; index++) {
      let obj = JSON.parse(JSON.stringify(def[index]));
      if (obj.name === val) {
        return null
      }
    }

    return "โปรดเลือกชื่อหน่วยงานให้ถูกต้อง"

  }

  if (!isValidLength.test(value)) {
    return "กรุณากรอกชื่อหน่วยงาน";
  } else if (isValidLength.test(value)) {
    return chkCopyValue(value, defaultOrg)
  } else {
    return null;
  }
}

export const EditSubGrp01 = (value, defaultGrp) => {
  //const isValidTextTh = /[ก-๏a-zA-Z]+$/;
  const isValidLength = /^.{1,}$/;
  // if (!isValidTextTh.test(value)) {
  //   return "ต้องไม่ใส่ตัวเลข อักษรพิเศษ และไม่เว้นวรรค";
  // } else 
  const chkCopyValue = (val, def) => {

    for (let index = 0; index < def.length; index++) {
      let obj = JSON.parse(JSON.stringify(def[index]));
      if (obj.name === val) {
        return "ชื่อซ้ำในระบบแล้ว"
      }
    }

    return null

  }
  if (!isValidLength.test(value)) {
    return "กรุณากรอกชื่อกลุ่มผู้ใช้งานย่อย";
  } else if (isValidLength.test(value)) {
    return chkCopyValue(value, defaultGrp)
  } else {
    return null
  }
}

export const EditSubGrp02 = (value, defaultOrg) => {
  //const isValidTextTh = /[ก-๏a-zA-Z]+$/;
  const isValidLength = /^.{1,}$/;
  // if (!isValidTextTh.test(value)) {
  //   return "ต้องไม่ใส่ตัวเลข อักษรพิเศษ และไม่เว้นวรรค";
  // } else 
  const chkCopyValue = (val, def) => {

    for (let index = 0; index < def.length; index++) {
      let obj = JSON.parse(JSON.stringify(def[index]));
      if (obj.name === val) {
        return null
      }
    }

    return "โปรดเลือกชื่อหน่วยงานให้ถูกต้อง"

  }

  if (!isValidLength.test(value)) {
    return "กรุณากรอกชื่อหน่วยงาน";
  } else if (isValidLength.test(value)) {
    return chkCopyValue(value, defaultOrg)
  } else {
    return null;
  }
}

export const AdminUserCheck = (value) => {
  const isValidLength = /^.{1,}$/;
  // if (!isValidTextTh.test(value)) {
  //   return "ต้องไม่ใส่ตัวเลข อักษรพิเศษ และไม่เว้นวรรค";
  // } else 
  if (!isValidLength.test(value)) {
    return "กรุณากรอกชื่อเข้าใช้งานระบบ";
  } else {
    return null;
  }
}

export const AdminCheckURL = (value) => {
  const isValidLength = /^.{1,}$/;
  // if (!isValidTextTh.test(value)) {
  //   return "ต้องไม่ใส่ตัวเลข อักษรพิเศษ และไม่เว้นวรรค";
  // } else 
  if (!isValidLength.test(value)) {
    return "กรุณากรอก URL";
  } else {
    return null;
  }
}

export const AdminCheckName = (value) => {
  const isValidLength = /^.{1,}$/;
  // if (!isValidTextTh.test(value)) {
  //   return "ต้องไม่ใส่ตัวเลข อักษรพิเศษ และไม่เว้นวรรค";
  // } else 
  if (!isValidLength.test(value)) {
    return "กรุณากรอกชื่อ";
  } else {
    return null;
  }
}

export const AdminFirstnameCheck = (value) => {
  const isValidLength = /^.{1,}$/;
  // if (!isValidTextTh.test(value)) {
  //   return "ต้องไม่ใส่ตัวเลข อักษรพิเศษ และไม่เว้นวรรค";
  // } else 
  if (!isValidLength.test(value)) {
    return "กรุณากรอกชื่อ";
  } else {
    return null;
  }
}

export const AdminLastnameCheck = (value) => {
  const isValidLength = /^.{1,}$/;
  // if (!isValidTextTh.test(value)) {
  //   return "ต้องไม่ใส่ตัวเลข อักษรพิเศษ และไม่เว้นวรรค";
  // } else 
  if (!isValidLength.test(value)) {
    return "กรุณากรอกนามสกุล";
  } else {
    return null;
  }
}

export const AdminCheckEmail = (value) => {
  const isContainsSymbol =
    /^(?=.*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,}$).*$/;
  // const isContainsSymbol = /^(?=.*[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$).*$/;
  if (!isContainsSymbol.test(value)) {
    // [a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$
    // /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
    return `กรุณากรอกอีเมล์ที่ถูกต้อง`; // mail@example.com
  }
  return null;
}

export const AdminCheckTel = (value) => {
  const isContainsSymbol = /^(?=.*[0]+[0-9]).*$/;
  if (!isContainsSymbol.test(value)) {
    // /^[0-9\b].{9,9}$/
    return `กรุณากรอกเบอร์โทรศัพท์ที่ถูกต้อง`;
  }
  return null;
}

///user admin///
// CheckAddPlantType
export const CheckAddPlantType = (value, plantArr) => {
  // const isValidTextTh = /[ก-๏a-zA-Z]+$/;
  const isValidLength = /^.{1,}$/;

  const checkForDuplicates = (val, plant) => {
    for (let i = 0; i < plant?.length; i++) {
      let obj = JSON.parse(JSON.stringify(plant[i]));
      if (obj.name === val) {
        return "เชื้อเพลิงซ้ำในระบบ";
      }
    }

    return null;
  };

  if (!isValidLength.test(value)) {
    return "กรุณากรอกข้อมูล";
  } else if (isValidLength.test(value)) {
    return checkForDuplicates(value, plantArr);
  } else {
    return null;
  }
};
export const CheckAddRecordName = (value, recordArr) => {
  // const isValidTextTh = /[ก-๏a-zA-Z]+$/;
  const isValidLength = /^.{1,}$/;

  const checkForDuplicates = (val, recordName) => {
    for (let i = 0; i < recordName?.length; i++) {
      let obj = JSON.parse(JSON.stringify(recordName[i]));
      if (obj.name === val) {
        return "รายการซ้ำในระบบ";
      }
    }

    return null;
  };

  if (!isValidLength.test(value)) {
    return "กรุณากรอกข้อมูล";
  } else if (isValidLength.test(value)) {
    return checkForDuplicates(value, recordArr);
  } else {
    return null;
  }
};
export const CheckNumberCard = (value) => {
  const isNonWhiteSpace = /^\S*$/;
  if (!isNonWhiteSpace.test(value)) {
    return "กรุณากรอกหมายเลขบัตร และห้ามเว้นวรรค";
  }
  const isValidLengthZero = /^.{13,13}$/;
  if (!isValidLengthZero.test(value)) {
    return "กรุณากรอกหมายเลขบัตรให้ครบ 13 ตัว";
  }
  const isContainsSymbol = /^\d+$/;
  if (!isContainsSymbol.test(value)) {
    // /^[0-9\b].{9,9}$/
    return `กรุณากรอกหมายเลขบัตรให้ถูกต้อง`;
  }
  return null;
};

export const CheckDataStDateEn = (value1, value2) => {
  if (value1 > value2) {
    return "วันเริ่มต้นสัญญา ต้องน้อยกว่า วันสิ้นสุดสัญญา";
  }
  return null;
};
export const CheckDateEnDataSt = (value1, value2) => {
  if (value1 < value2) {
    return "วันสิ้นสุดสัญญา ต้องมากกว่า วันเริ่มต้นสัญญา";
  }
  return null;
};

export const AdminMeter = (value1) => {
  const resIsNumber = /^([0-9]*[.])?[0-9]+$/;
  // const resIsNumber = /^\d+$/;
  if (!resIsNumber.test(value1)) {
    return 'กรุณากรอกเฉพาะเลข'
  } else {
    return null
  }
};
export const CheckCoordArrPolygon = (coord) => {
  const regex = /^(null|NaN|undefined)$/;

  for (let i = 0; i < coord?.length; i++) {
    for (let j = 0; j < coord[i].length; j++) {
      if (regex.test(String(coord[i][j]))) {
        return "รูปแบบการกรอกพิกัดไม่ถูกต้อง หรือยังไม่มีการกรอกพิกัด";
      }
    }
  }

  // เพิ่มเงื่อนไขการเช็คอาเรย์ตัวแรกกับอาเรย์ตัวที่สองและสามไม่ซ้ำกัน
  for (let i = 0; i < coord?.length - 2; i++) {
    if (JSON.stringify(coord[i]) === JSON.stringify(coord[i + 1])) {
      return "พิกัดจุดที่ 1 และพิกัดจุดที่ 2 ต้องไม่เหมือนกัน";
    } else if (JSON.stringify(coord[i + 1]) === JSON.stringify(coord[i + 2])) {
      return "พิกัดแต่ละจุดต้องไม่เหมือนกัน";
    } else if (JSON.stringify(coord[i]) === JSON.stringify(coord[i + 2])) {
      return "พิกัดจุดที่ 1 และพิกัดจุดที่ 3 ต้องไม่เหมือนกัน";
    }
  }

  return null;
};
export const CheckCoordLatLon = (latlon) => {
  const regex = /^-?\d+(\.\d+)?,-?\d+(\.\d+)?$/;

  if (!regex.test(latlon)) {
    return "รูปแบบการกรอกพิกัดไม่ถูกต้อง";
  }

  const [latitude, longitude] = latlon.split(",");

  if (latitude?.length < 7 || longitude?.length < 7) {
    return "รูปแบบการกรอกพิกัดไม่ถูกต้อง";
  }

  if (
    parseFloat(latitude) < -90 ||
    parseFloat(latitude) > 90 ||
    parseFloat(longitude) < -180 ||
    parseFloat(longitude) > 180
  ) {
    return "ค่าละติจูดและลองจิจูดไม่ถูกต้อง";
  }

  if (latlon.includes(" ")) {
    return "รูปแบบการกรอกพิกัดไม่ถูกต้อง";
  }

  return null;
};
export const CheckFileDoc = (value) => {
  const isValidLength = /^.{1,}$/;
  if (!isValidLength.test(value)) {
    return "กรุณาแนบไฟล์";
  }
  return null;
};
