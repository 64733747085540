import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { QueryGetAll } from "../../../helpers/api.helper";
import { defaultApi } from "../../../services/api";
import { getProfileData } from "../../../services/auth.slice";
import ChartsCompare from "./charts/ChartsCompare";
import ExportPopup from "../../../layouts/exportpopup/ExportPopup";
import { convertDateThai2 } from "../../../utils/format/Date.format";
import styles from "./scss/ReportCompare.module.scss";

const ReportCompare = ({ themeColor }) => {
  const chart = useRef();
  //const [date, setDate] = useState("");

  const [_setKeyFact, keyFact] = useState({
    entpId: "",
    factId: "",
  });

  const [_resName, resName] = useState(null);
  const [_resItem, resItem] = useState(null);

  const profileData = useSelector(getProfileData);

  const [_fstLoad, fstLoad] = useState(true);

  const [_triggerExport, setTriggerExport] = useState(false);
  const [stateYear, setStateYear] = useState(new Date().getFullYear());
  const [stateMonthYear, setStateMonthYear] = useState({
    stYear: "",
    edYear: "",
    active: false,
  });

  window.ChartTitleUnit = {
    title: 'เดือน',
    unit: ['คน'],
    totalStatus : false
  }

  const downloadCSV = () => {
    // if (chart && chart.current && chart.current.chart) {
    //   chart.current.chart.downloadCSV();
    // }
    if (chart && chart.current && chart.current.chart) {
      chart.current.chart.downloadCSV();
    }
  };

  const downloadImage = () => {
    if (chart && chart.current && chart.current.chart) {
      chart.current.chart.setTitle({ text: "เปรียบเทียบจำนวนสมาชิก" });
      chart.current.chart.setSubtitle({ text: _resName });

      chart.current.chart.exportChart({
        type: "image/jpeg",
        filename: "chart",
      });

      chart.current.chart.setTitle({ text: "" });
      chart.current.chart.setSubtitle({ text: "" });
    }
  };

  const info = useCallback(async () => {
    let qryGetAll = {
      $count: "%24count=true",
      $expand: "",
      $top: "",
      $skip: "",
      $filter: `&%24filter=createdBy%20eq%20${profileData.id}`,
      $orderby: "",
    };

    const resAllEnp = await defaultApi.getAllEnterpriseMember(``);

    if (resAllEnp?.data?.length > 0) {
      for (let index = 0; index < resAllEnp?.data?.length; index++) {
        const element = resAllEnp?.data[index];
        if (+element?.userId === +profileData?.id) {
          qryGetAll = {
            $count: "%24count=true",
            $expand: "",
            $top: "",
            $skip: "",
            $filter: `&%24filter=id%20eq%20${element.enterpriseId}`,
            $orderby: "",
          };
        }
      }
    }

    const _query = QueryGetAll(qryGetAll);
    const res_entp = await defaultApi.getAllEnterprise(_query);
    const entpId = res_entp.data.datas[0].id;
    const factId = res_entp.data.datas[0].factory.id;
    keyFact({
      entpId: entpId,
      factId: factId,
    });

    let date = new Date();
    //let month = date.getMonth()
    let year = date.getFullYear();

    // const res_item = await defaultApi.getreportMemberCount("enterpriseId=" + entpId + "&atYear=" + year)
    const res_item = await defaultApi.getreportMemberCountCompare(
      "enterpriseId=" + entpId + "&atYear=" + year + "&toYear=" + (year - 1)
    );
    resItem(res_item.data);

    resName(res_entp.data.datas[0].name);
  }, [profileData]);

  const dateTime = () => {
    let dt = new Date();
    let year = dt.getFullYear();
    let years = [];

    for (let index = 0; index < 10; index++) {
      years[index] = year;
      year--;
    }

    let optYear = years.map((data, index) => {
      return (
        <option key={index} value={data}>
          {data + 543}
        </option>
      );
    });

    return (
      <>
        <option key={`df_0`} style={{ display: "none" }}>
          ปี
        </option>
        {optYear}
      </>
    );
  };

  const iSearch = async () => {
    try {
      const res_item = await defaultApi.getreportMemberCountCompare(
        "enterpriseId=" +
          _setKeyFact.entpId +
          "&atYear=" +
          stateMonthYear.stYear +
          "&toYear=" +
          stateMonthYear.edYear
      );
      //const res_item = await defaultApi.getreportMemberCount("enterpriseId=" + _setKeyFact.entpId + "&atYear=" + item)
      resItem(res_item.data);
      setStateMonthYear((prev) => ({ ...prev, active: false }));
    } catch (error) {
      console.log(error);
    }
  };

  const dateDefault = useMemo(() => {
    const year = new Date().getFullYear();
    const stYear = stateMonthYear.stYear || (year - 1).toString();
    const edYear = stateMonthYear.edYear || year.toString();

    return {
      stYear: convertDateThai2(stYear, "year"),
      edYear: convertDateThai2(edYear, "year"),
    };
  }, [stateMonthYear]);

  useEffect(() => {
    if (stateMonthYear.active) iSearch();
  }, [stateMonthYear]);

  useEffect(() => {
    if (_fstLoad === true) {
      info();
      fstLoad(false);
    }
  }, [_fstLoad, info]);

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.date_filter}`}>
        <label style={{ margin: "0 7px" }}> เปรียบเทียบระหว่าง : </label>
        <select
          style={{ color: "black" }}
          name=""
          id=""
          onChange={(e) => {
            setStateYear(e.target.value);
            setStateMonthYear((prev) => ({
              ...prev,
              stYear: e.target.value,
              active: true,
            }));
          }}
        >
          {dateTime()}
        </select>
        <label style={{ margin: "0 7px" }}> กับ : </label>
        <select
          style={{ color: "black" }}
          name=""
          id=""
          onChange={(e) => {
            setStateYear(e.target.value);
            setStateMonthYear((prev) => ({
              ...prev,
              edYear: e.target.value,
              active: true,
            }));
          }}
        >
          {dateTime()}
        </select>
      </div>

      <h4
        className={`${styles.title_text}`}
        style={{ background: themeColor.backgroundColor }}
      >
        เปรียบเทียบจำนวนสมาชิก
      </h4>

      <h5 className={`${styles.title_text_}`}>{_resName}</h5>

      <div className={`${styles.show_date}`}>
        <p>ข้อมูลเปรียบเทียบระหว่างปี</p>
        <p>
          {dateDefault.stYear} กับ {dateDefault.edYear}
        </p>
      </div>

      <div className={`${styles.charts_}`}>
        <ChartsCompare
          ref={chart}
          yearCompare={stateMonthYear}
          datain={_resItem}
          yearChk={stateYear}
        />
      </div>

      <div className={`${styles.export_}`}>
        <ExportPopup
          label={"report"}
          trigger={_triggerExport}
          event={{ csv: downloadCSV, image: downloadImage }}
        />
        <button
          id="exporting"
          type="button"
          className={`${styles.btn_}`}
          onClick={() => {
            setTriggerExport(!_triggerExport);
          }}
        >
          Export
        </button>
      </div>
    </div>
  );
};

export default ReportCompare;
