import { useState } from "react";

const useSwitchChecked_1 = () => {
  const [switchChecked, setSwitchChecked] = useState("1");
  const [switchChecked2, setSwitchChecked2] = useState("4");
  // const [switchChecked2, setSwitchChecked2] = useState("3");
  const [switchChecked3, setSwitchChecked3] = useState("6");
  // const [switchChecked3, setSwitchChecked3] = useState("5");
  const [switchChecked4, setSwitchChecked4] = useState("7");

  const onSwitchChecked = (el) => {
    setSwitchChecked(el);
  };
  const onSwitchChecked2 = (el) => {
    setSwitchChecked2(el);
  };
  const onSwitchChecked3 = (el) => {
    setSwitchChecked3(el);
  };
  const onSwitchChecked4 = (el) => {
    setSwitchChecked4(el);
  };

  return [
    switchChecked,
    onSwitchChecked,
    switchChecked2,
    onSwitchChecked2,
    switchChecked3,
    onSwitchChecked3,
    switchChecked4,
    onSwitchChecked4,
  ];
};

export default useSwitchChecked_1;
