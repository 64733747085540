import React, { useEffect } from "react";
import { toast } from "react-hot-toast";
import MultipleInputAdd from "./MultipleInputAdd";
import icon from "../../../../../../assets/img/Icon";
import image from "../../../../../../assets/img/image";
import { defaultApi } from "../../../../../../services/api";
import Message from "../../../../../../utils/message/Message";
import {
  CheckFileDoc,
  CheckNumberDot,
  CheckValidLength,
} from "../../../../../../utils/validation_input/Validation";
import styles from "./scss/AddDeed.module.scss";

const AddForm = ({
  _data,
  setData,
  validErr,
  _switch,
  _themeColor,
  _inputListAdd,
  _setInputListAdd,
  _getPolygonArea,
}) => {
  const onChange = (e) => {
    const { name, value } = e.target;
    setData({ ..._data, [name]: value });
  };
  const createFileDetail = async (e) => {
    const file = document.querySelector("#add_file");

    const loading = toast.loading(Message.notify.add_file_loading);
    const param1 = "ParcelDoc";
    const param2 = new FormData();
    param2.append("FormFile", e.target.files[0]);
    try {
      const res = await defaultApi.createFileDetail(param1, param2);
      console.log(res.statusText);

      setData({ ..._data, parcelDoc: res?.data.id });
    } catch (error) {
      console.log(error);
    } finally {
      file.value = "";
      toast.dismiss(loading);
    }
  };

  useEffect(() => {
    // Use functions
    if (_switch) getFilePdfName();
  }, [_switch]);

  return (
    <>
      <div className={`${styles.input_form}`}>
        {/* <div className={`${styles.input_}`}>
        <label>ชื่อเจ้าของที่ดิน</label>
        <input type="text" name="ownership" onChange={onChange} />
      </div> */}
        <div className={`${styles.input_}`}>
          <label style={{ color: _themeColor?.color }}>
            เลขที่โฉนด <sup className={`${styles.asterisk}`}>*</sup>
          </label>
          <input
            type="text"
            name="parcelNo"
            className={`${validErr && _data.parcelNo === "" ? "error" : ""}`}
            onChange={onChange}
          />
        </div>
        {validErr && (
          <p className={`${styles.err_message}`}>
            {CheckValidLength(_data.parcelNo)}
          </p>
        )}
        <div className={`${styles.input_}`}>
          <label style={{ color: _themeColor?.color }}>
            ที่ตั้ง <sup className={`${styles.asterisk}`}>*</sup>
          </label>
          <input
            type="text"
            name="address"
            className={`${validErr && _data.address === "" ? "error" : ""}`}
            onChange={onChange}
          />
        </div>
        {validErr && (
          <p className={`${styles.err_message}`}>
            {CheckValidLength(_data.address)}
          </p>
        )}
        <div className={`${styles.input_}`}>
          <label style={{ color: _themeColor?.color }}>
            รหัสแปลง <sup className={`${styles.asterisk}`}>*</sup>
          </label>
          <input
            type="text"
            name="parcelId"
            className={`${validErr && _data.parcelId === "" ? "error" : ""}`}
            onChange={onChange}
          />
        </div>
        {validErr && (
          <p className={`${styles.err_message}`}>
            {CheckValidLength(_data.parcelId)}
          </p>
        )}
        {/* MultipleInputAdd : START */}
        <MultipleInputAdd
          _setData={setData}
          validErr={validErr}
          _themeColor={_themeColor}
          _inputListAdd={_inputListAdd}
          _setInputListAdd={_setInputListAdd}
          _getPolygonArea={_getPolygonArea}
        />
        {/* MultipleInputAdd : END */}
        <div className={`${styles.input_}`}>
          <label style={{ color: _themeColor?.color }}>
            พื้นที่ทั้งหมด <sup className={`${styles.asterisk}`}>*</sup>
          </label>
          <div
            className={`${styles.input_unit} ${
              validErr && _data.area === "" ? "error" : ""
            }`}
          >
            <input
              type="text"
              name="area"
              value={_data?.area}
              onChange={onChange}
            />
            <div className={`${styles.unit}`}>
              <span style={{ color: _themeColor?.color }}>ไร่</span>
            </div>
          </div>
        </div>
        {validErr && (
          <p className={`${styles.err_message}`}>
            {CheckNumberDot(_data.area)}
          </p>
        )}
        <div className={`${styles.input_file} ${styles.mb_0}`}>
          <label style={{ color: _themeColor?.color }}>
            แนบเอกสารสิทธิ์แปลงที่ดิน{" "}
            <sup className={`${styles.asterisk}`}>*</sup>
          </label>
          <div
            className={`${styles.input__} ${
              validErr && _data.parcelDoc === "" ? "error" : ""
            }`}
          >
            <div className={`${styles.info_box}`}>
              <p id="file_name"></p>
            </div>
            <div className={`${styles.unit}`}>
              <img
                src={icon.document}
                alt=""
                title="แนบไฟล์"
                onClick={() => document.querySelector("#file-label").click()}
              />
            </div>
          </div>
          <input
            type="file"
            name="parcelDoc"
            id="add_file"
            accept="application/pdf"
            onChange={createFileDetail}
            hidden
          />
          <label htmlFor="add_file" id="file-label" hidden></label>
        </div>
        {validErr && (
          <p className={`${styles.err_message} ${styles.pb_0}`}>
            {CheckFileDoc(_data.parcelDoc)}
          </p>
        )}
        <span className={`${styles.desc_text}`}>
          *ระบบรองรับไฟล์ที่มีนามสกุล .pdf และมีขนาดไม่เกิน 5 MB
        </span>
      </div>

      <ModalHowTo />
    </>
  );
};

const getFilePdfName = () => {
  const file = document.querySelector("#add_file");
  const label = document.querySelector("#file_name");

  file?.addEventListener("change", () => {
    if (file.value) {
      label.innerHTML = file.files[0].name;
    } else {
      label.innerHTML = "";
    }
  });
};

const ModalHowTo = () => {
  return (
    <div
      className={`modal fade ${styles.modal_how_to}`}
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              วิธีการหาพิกัดตำแหน่งที่ดิน
            </h5>
          </div>
          <div className="modal-body">
            <div className="box_">
              <p>
                1. เข้า <img src={image.how_to_coor1_1} alt="" /> Google Maps
                ผ่านเว็บบราวน์เซอร์ <img src={image.how_to_coor1_2} alt="" />
              </p>
            </div>
            <div className="box_">
              <p>2. ทำการเปลี่ยนรูปแบบแผนที่เป็นแบบ ภาพถ่ายดาวเทียม</p>
              <div className="img_box">
                <img src={image.how_to_coor2} alt="" />
              </div>
            </div>
            <div className="box_">
              <p>
                3. จากนั้นค้นหาที่ช่องค้นหา ด้วย ชื่อตำบล อำเภอ จังหวัด
                หรือสถานที่ใกล้เคียงกับ ตำแหน่งของโฉนดที่ดิน
              </p>
              <div className="img_box_flex">
                <div className="img_">
                  <img src={image.how_to_coor3_1} alt="" />
                </div>
                <p>หรือ</p>
                <div className="img_">
                  <img src={image.how_to_coor3_2} alt="" />
                </div>
              </div>
            </div>
            <div className="box_">
              <p>
                4. เมื่อพบตำแหน่งที่ดินแล้ว ให้คลิกขวาในพื้นที่ ที่ดินตามโฉนด
                จะปรากฎ พิกัดละติจูด และลองจิจูด
              </p>
              <div className="img_box">
                <img src={image.how_to_coor4} alt="" />
              </div>
            </div>
            <div className="box_">
              <p>5. คลิกที่เลขพิกัด เพื่อคัดลอก</p>
            </div>
            <div className="box_">
              <p>
                6. นำมาใส่ในข้อมูลพิกัด พร้อมทั้งลบ เว้นวรรคหลัง ,
                เพื่อให้ตรงตามรูปแบบ
              </p>
              <div className="img_box" style={{ overflowX: "auto" }}>
                <img src={image.how_to_coor6} alt="" />
              </div>
            </div>

            <div className="btn_center">
              <button type="button" className="btn_" data-bs-dismiss="modal">
                กลับ
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddForm;
