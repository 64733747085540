import CryptoJS from "crypto-js";

const secretKey = process.env.REACT_APP_CRYPTOJS_SECRECT_KEY;

// ฟังก์ชันเพื่อเข้ารหัส data ด้วย AES
export const encodeData = (data) => {
  if (!data || !secretKey) return;

  // เข้ารหัส data ด้วย AES
  const encryptedData = CryptoJS.AES.encrypt(data, secretKey).toString();
  // คืนค่า data ที่ถูกเข้ารหัส
  return encryptedData;
};

// ฟังก์ชันเพื่อถอดรหัส data ด้วย AES
export const decodeData = (encodedData) => {
  if (!encodedData || !secretKey) return;

  // ถอดรหัส data ด้วย AES
  const bytes = CryptoJS.AES.decrypt(encodedData, secretKey);
  const decodedData = bytes.toString(CryptoJS.enc.Utf8);
  // คืนค่า data ที่ถูกถอดรหัส
  return decodeURIComponent(decodedData);
};
