const ranges = [
    { divider: 1e18, suffix: "E" },
    { divider: 1e15, suffix: "P" },
    { divider: 1e12, suffix: "T" },
    { divider: 1e9, suffix: "G" },
    { divider: 1e6, suffix: "M" },
    { divider: 1e3, suffix: "k" },
];

const totalRanges = [
    { divider: 1e18, suffix: "E" },
    { divider: 1e12, suffix: "T" },
    { divider: 1e6, suffix: "M" },
];

export const formatterYaxis = (param) => {
    for (let index = 0; index < ranges.length; index++) {
        const elem = ranges[index];
        if (param.pos >= elem.divider) {
            return Number(param.pos / elem.divider).toLocaleString(undefined, { maximumFractionDigits: 2 }).toString() + elem.suffix
        }
    }
    return Number(param.pos).toLocaleString(undefined, { maximumFractionDigits: 2 }).toString()
}

export const formatterTooltip = {
    shared: true,
    useHTML: true,
    padding: 0,
    formatter: function () {
        const lst = this?.points || []
        
        let i = ""
        let titleHead = this?.x || ''
        let total = 0

        i += `<div style="display: flex, flex-direction: column; gap: 4px; max-height: 200px; overflow-y: auto; padding: 10px;">`
        i += `<div><b>${window.ChartTitleUnit.title}</b> : ${titleHead}</div>`

        lst?.forEach((elem) => {
            let value = 0

            let title = elem?.series?.name || ""
            for (let index = 0; index < totalRanges.length; index++) {
                const setSuffix = totalRanges[index];
                if (elem?.y > setSuffix.divider) {
                    value = Number(elem?.y / setSuffix.divider).toLocaleString(undefined, { maximumFractionDigits: 2 }).toString() + setSuffix.suffix
                    break
                }
                value = Number(elem?.y).toLocaleString(undefined, { maximumFractionDigits: 2 }).toString()
            }

            total += elem?.y

            if (elem?.y > 0) {
                const thm = "<b>" + title +"</b>" + " : " + value + " " + window.ChartTitleUnit?.unit[0]
                i += `<div>${thm}</div>`
            }
        });

        let resTotal = ""

        for (let index = 0; index < totalRanges.length; index++) {
            const setSuffix = totalRanges[index];
            if (total > setSuffix.divider) {
                resTotal = Number(total / setSuffix.divider).toLocaleString(undefined, { maximumFractionDigits: 2 }).toString() + setSuffix.suffix
                break
            }
            resTotal = Number(total).toLocaleString(undefined, { maximumFractionDigits: 2 }).toString()
        }

        window.ChartTitleUnit?.totalStatus && (i += `<div><b>รวม</b> : ${resTotal} ${window.ChartTitleUnit?.unit[0]}</div>`)

        i += `</div>`
        return i
    }
}

export const formatterTooltip_kwh = {
    shared: true,
    useHTML: true,
    padding: 0,
    formatter: function () {
        const lst = this?.points || []
        
        let i = ""
        let titleHead = this?.x || ''
        let total = 0

        i += `<div style="display: flex, flex-direction: column; gap: 4px; max-height: 200px; overflow-y: auto; padding: 10px;">`
        i += `<div><b>เดือน</b> : ${titleHead}</div>`

        lst?.forEach((elem) => {
            let value = 0

            let title = elem?.series?.name || ""
            for (let index = 0; index < totalRanges.length; index++) {
                const setSuffix = totalRanges[index];
                if (elem?.y > setSuffix.divider) {
                    value = Number(elem?.y / setSuffix.divider).toLocaleString(undefined, { maximumFractionDigits: 2 }).toString() + setSuffix.suffix
                    break
                }
                value = Number(elem?.y).toLocaleString(undefined, { maximumFractionDigits: 2 }).toString()
            }

            total += elem?.y

            if (elem?.y > 0) {
                const thm = "<b>" + title +"</b>" + " : " + value + " " + "kWh"
                i += `<div>${thm}</div>`
            }
        });

        let resTotal = ""

        for (let index = 0; index < totalRanges.length; index++) {
            const setSuffix = totalRanges[index];
            if (total > setSuffix.divider) {
                resTotal = Number(total / setSuffix.divider).toLocaleString(undefined, { maximumFractionDigits: 2 }).toString() + setSuffix.suffix
                break
            }
            resTotal = Number(total).toLocaleString(undefined, { maximumFractionDigits: 2 }).toString()
        }

        i += `<div><b>รวม</b> : ${resTotal} kWh</div>`

        i += `</div>`
        return i
    }
}

export const formatterTooltip_baht = {
    shared: true,
    useHTML: true,
    padding: 0,
    formatter: function () {
        const lst = this?.points || []
        
        let i = ""
        let titleHead = this?.x || ''
        let total = 0

        i += `<div style="display: flex, flex-direction: column; gap: 4px; max-height: 200px; overflow-y: auto; padding: 10px;">`
        i += `<div><b>เดือน</b> : ${titleHead}</div>`

        lst?.forEach((elem) => {
            let value = 0

            let title = elem?.series?.name || ""
            for (let index = 0; index < totalRanges.length; index++) {
                const setSuffix = totalRanges[index];
                if (elem?.y > setSuffix.divider) {
                    value = Number(elem?.y / setSuffix.divider).toLocaleString(undefined, { maximumFractionDigits: 2 }).toString() + setSuffix.suffix
                    break
                }
                value = Number(elem?.y).toLocaleString(undefined, { maximumFractionDigits: 2 }).toString()
            }

            total += elem?.y

            if (elem?.y > 0) {
                const thm = "<b>" + title +"</b>" + " : " + value + " " + "บาท"
                i += `<div>${thm}</div>`
            }
        });

        let resTotal = ""

        for (let index = 0; index < totalRanges.length; index++) {
            const setSuffix = totalRanges[index];
            if (total > setSuffix.divider) {
                resTotal = Number(total / setSuffix.divider).toLocaleString(undefined, { maximumFractionDigits: 2 }).toString() + setSuffix.suffix
                break
            }
            resTotal = Number(total).toLocaleString(undefined, { maximumFractionDigits: 2 }).toString()
        }

        i += `<div><b>รวม</b> : ${resTotal} บาท</div>`

        i += `</div>`
        return i
    }
}

export const formatterStack = function () {
    const value = this?.total || 0
    for (let index = 0; index < ranges.length; index++) {
        const elem = ranges[index];
        if (value > elem.divider) {
            return Number(value / elem?.divider).toLocaleString(undefined, { maximumFractionDigits: 2 }) + elem.suffix
        }
    }
    return Number(value).toLocaleString(undefined, { maximumFractionDigits: 2 })
}

export const formatterXaxis_1 = function () {
    const value = this?.y || 0
    for (let index = 0; index < ranges.length; index++) {
        const elem = ranges[index];
        if (value > elem?.divider) {
            return Number(value / elem?.divider).toLocaleString(undefined, { maximumFractionDigits: 2 }) + elem.suffix
        }
    }
    return Number(value).toLocaleString(undefined, { maximumFractionDigits: 2 })
}