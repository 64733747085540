import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import icon from "../../../../../../../assets/img/Icon";
import EditForm from "./EditForm";
import useSwitch from "../../../../../../../utils/hooks/useSwitch";
import { defaultApi } from "../../../../../../../services/api";
import { convertDateThai } from "../../../../../../../utils/format/Date.format";
import { QueryGetByID } from "../../../../../../../helpers/api.helper";
import { downloadFilePdf } from "../../../../../../../helpers/func.helper";
import styles from "./EditContractFForm.module.scss";

const animated = makeAnimated();

const EditContractFForm = ({
  _switchID,
  switchForm,
  _fetchData,
  _contractStatus,
}) => {
  const [_switch, onSwitch] = useSwitch();

  const [contractF, setContractF] = useState({});
  const [plantValue, setPlantValue] = useState([]);

  const getContractFarming = useCallback(async () => {
    const qryGetById = {
      $expand:
        "%24expand=factory%2C%20contractFarmingDocNavigation%2C%20enterprise",
      $filter: "",
      $orderby: "",
    };
    const _query = QueryGetByID(qryGetById);
    try {
      const res = await defaultApi.getContractFarming(_switchID, _query);
      setContractF(res.data);
    } catch (error) {
      console.log(error);
    }
  }, [_switchID]);

  useEffect(() => {
    /* แปลง JSON String to JSON Object : START */
    if (contractF?.plantType) {
      let json_obj = JSON.parse(contractF?.plantType);
      let plantArr = [];
      for (let i = 0; i < json_obj.length; i++) {
        json_obj.forEach((item) => {
          let obj_ = { value: item.id, label: item.name };
          plantArr[i] = obj_;
          ++i;
        });
      }
      setPlantValue(plantArr);
    }
    /* แปลง JSON String to JSON Object : END */
  }, [contractF]);

  useEffect(() => {
    // Use functions
    getContractFarming();
  }, [getContractFarming]);

  return (
    <div className={`${styles.container}`}>
      {_switch ? (
        <EditForm
          _switch={_switch}
          onSwitch={onSwitch}
          _contractF={contractF}
          _plantValue={plantValue}
          _fetchData={getContractFarming}
          _fetchDataTable={_fetchData}
          _contractStatus={_contractStatus}
        />
      ) : (
        <div className={`${styles.input_form}`}>
          <div className={`${styles.input_flex}`}>
            <div className={`${styles.input_1}`}>
              <h5>สัญญาเลขที่</h5>
              <div
                className={`${styles.info_box}`}
                style={{ background: "#f5f5f5" }}
              >
                <p>{contractF.no}</p>
              </div>
            </div>
            <div className={`${styles.input_2}`}>
              <h5>สถานะสัญญา</h5>
              <div className={`${styles.info_box}`}>
                <p>
                  {
                    _contractStatus?.find(
                      (item) => item.id === contractF.status
                    )?.statusName
                  }
                </p>
              </div>
            </div>
          </div>
          <div className={`${styles.input_flex}`}>
            <div className={`${styles.input_1}`}>
              <h5>วันเริ่มต้นสัญญา</h5>
              <div className={`${styles.info_box}`}>
                <p>{convertDateThai(contractF.signDate)}</p>
              </div>
            </div>
            <div className={`${styles.input_2}`}>
              <h5>วันสิ้นสุดสัญญา</h5>
              <div className={`${styles.info_box}`}>
                <p>{convertDateThai(contractF.endDate)}</p>
              </div>
            </div>
          </div>
          <div className={`${styles.input_flex}`}>
            <div className={`${styles.input_1}`}>
              <h5>สัญญาระหว่าง(ผู้ซื้อ)</h5>
              <div className={`${styles.info_con}`}>
                <div className={`${styles.info_box}`}>
                  <p>{contractF.factory?.name}</p>
                </div>
                <span>และ</span>
              </div>
            </div>
            <div className={`${styles.input_2}`}>
              <h5>สัญญาระหว่าง(ผู้ขาย)</h5>
              <div className={`${styles.info_box}`}>
                <p>{contractF.enterprise?.name}</p>
              </div>
            </div>
          </div>
          <div className={`${styles.input_flex}`}>
            <div className={`${styles.input_1}`}>
              <h5>คู่สัญญาหลัก / รอง</h5>
              <div className={`${styles.info_con}`}>
                <div className={`${styles.info_box}`}>
                  <p>{contractF.contractType}</p>
                </div>
              </div>
            </div>
            <div className={`${styles.input_2}`}></div>
          </div>
          <div className={`${styles.input_grid}`}>
            <div className={`${styles.input_1}`}>
              <h5>ระยะเวลาสัญญา</h5>
              <div className={`${styles.input_unit}`}>
                <div className={`${styles.info_box}`}>
                  <p>{contractF.period}</p>
                </div>
                <div className={`${styles.unit}`}>
                  <span>ปี</span>
                </div>
              </div>
            </div>
            <div className={`${styles.input_2}`}>
              <h5>จำนวนในการส่งมอบ (ตัน/วัน)</h5>
              <div className={`${styles.info_box}`}>
                <p>{contractF.amountDay}</p>
              </div>
            </div>
            <div className={`${styles.input_3}`}>
              <h5>จำนวนวันที่ขายและส่งมอบ</h5>
              <div className={`${styles.input_unit}`}>
                <div className={`${styles.info_box}`}>
                  <p>{contractF.sendDay}</p>
                </div>
                <div className={`${styles.unit}`}>
                  <span>วัน/ปี</span>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.input_flex}`}>
            <div className={`${styles.input_1}`}>
              <div className={`${styles.input_select}`}>
                <h5>เชื้อเพลิง</h5>
                <Select
                  className={`${styles.muti_select}`}
                  value={plantValue}
                  closeMenuOnSelect={false}
                  components={animated}
                  isSearchable={false}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      borderRadius: "10px",
                    }),
                  }}
                  placeholder={"ไม่มีข้อมูลเชื้อเพลิง"}
                  isDisabled={true}
                  isMulti
                />
              </div>
            </div>
            <div className={`${styles.input_2}`}>
              <div className={`${styles.input_file}`}>
                <h5>สัญญาฉบับจริง</h5>
                <div className={`${styles.input_unit}`}>
                  <div className={`${styles.info_box}`}>
                    <p>{contractF.contractFarmingDocNavigation?.fileName}</p>
                  </div>
                  <div className={`${styles.unit}`}>
                    <img
                      src={icon.document}
                      alt=""
                      title="ดาวน์โหลดไฟล์"
                      onClick={() =>
                        downloadFilePdf(
                          contractF.contractFarmingDoc,
                          contractF.contractFarmingDocNavigation
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.input_flex}`}>
            <div className={`${styles.input_1}`}>
              <h5>ปันผลหุ้นบุริมสิทธิ์</h5>
              <div className={`${styles.input_unit}`}>
                <div className={`${styles.info_box}`}>
                  <p>{contractF.preferredDividend}</p>
                </div>
                <div className={`${styles.unit}`}>
                  <span>บาท/ปี</span>
                </div>
              </div>
            </div>
            <div className={`${styles.input_2}`}>
              <h5>ส่วนแบ่งชุมชนรอบโรงไฟฟ้า</h5>
              <div className={`${styles.input_unit}`}>
                <div className={`${styles.info_box}`}>
                  <p>{contractF.communityShare}</p>
                </div>
                <div className={`${styles.unit}`}>
                  <span>บาท/ปี</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {!_switch && (
        <div className={`${styles.button_right}`}>
          <button
            type="button"
            className={`${styles.btn_}`}
            onClick={() => switchForm(null)}
          >
            กลับ
          </button>
          <button
            type="button"
            className={`${styles.btn_}`}
            onClick={() => onSwitch(true)}
          >
            แก้ไขสัญญา
          </button>
        </div>
      )}
    </div>
  );
};

export default EditContractFForm;
