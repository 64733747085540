import React from "react";
import { useSelector } from "react-redux";
import OfficerManage from "./officer/OfficerManage";
import FarmerManage from "./farmer/FarmerManage";
import GovermentManage from "./goverment/GovermentManage";
import GuestManage from "./guest/GuestManage";
import { getProfileData } from "../../services/auth.slice";

const Index = () => {
  const profile_ = useSelector(getProfileData);
  let userRole = profile_?.userGroupId;

  if (userRole === 1) {
    return <OfficerManage />;
  } else if (userRole === 2) {
    return <FarmerManage />;
  } else if (userRole === 3) {
    return <GovermentManage />;
  } else if (userRole === 4) {
    return <GuestManage />;
  }
};

export default Index;
