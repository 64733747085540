import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { getProfileData } from "../services/auth.slice";
import { getCookiesStorageData } from "../helpers/cookies_storage.helper";

const AuthRoutes = ({ redirectPath = "/" }) => {
  const profileData = useSelector(getProfileData);
  const token_ = getCookiesStorageData("token");
  
  if (!token_) {
    return <Navigate to={redirectPath} replace />;
  } else if (profileData?.userGroupId === 5) {
    return <Navigate to={"/admin/"} replace />;
  }
  return <Outlet />;
};

export default AuthRoutes;
