import TileLayer from "ol/layer/Tile";
import { fromLonLat } from "ol/proj";
import { OSM, XYZ } from "ol/source";
import { ATTRIBUTION } from "ol/source/OSM";
import { ScaleLine } from "ol/control";

export const DEFALUT_CENTER = fromLonLat([100.57, 13.71]);

export const OSM_LAYER = new TileLayer({
  source: new OSM({
    attributions: [ATTRIBUTION],
    opaque: false,
  }),
});
export const GOOGLE_HYBRID_LAYER = new TileLayer({
  source: new XYZ({
    url: "https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}",
    opaque: false,
    maxZoom: 18,
  }),
  opacity: 1,
});
export const GOOGLE_STREETS_LAYER = new TileLayer({
  source: new XYZ({
    url: "https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
    opaque: false,
    maxZoom: 18,
  }),
  opacity: 1,
});

/* covert 4326 to 3857 : START */
export const convertCoordinates = (lon, lat) => {
  var x = (lon * 20037508.34) / 180;
  var y = Math.log(Math.tan(((90 + lat) * Math.PI) / 360)) / (Math.PI / 180);
  y = (y * 20037508.34) / 180;
  return [x, y];
};
/* covert 4326 to 3857 : END */

/* covert wkt to coordinates : START */
export const convertWKT = (wkt) => {
  const polygonString = wkt?.replace("POLYGON ((", "")?.replace("))", "");
  const coordinates = polygonString?.split(", ")?.map((coordString) => {
    const [lon, lat] = coordString?.split(" ")?.map(Number);
    return [lon, lat];
  });
  return coordinates;
};
/* covert wkt to coordinates : END */

/* แปลงข้อมูล POLYGON WKT เป็น coordinate เพื่อ getCenter : START */
export const getPolygonCenter = (polygonString) => {
  const coordinates = polygonString?.match(/\d+\.\d+/g);
  let minX = Number.MAX_VALUE;
  let maxX = Number.MIN_VALUE;
  let minY = Number.MAX_VALUE;
  let maxY = Number.MIN_VALUE;

  for (let i = 0; i < coordinates?.length; i += 2) {
    const x = parseFloat(coordinates[i]);
    const y = parseFloat(coordinates[i + 1]);

    if (x < minX) minX = x;
    if (x > maxX) maxX = x;
    if (y < minY) minY = y;
    if (y > maxY) maxY = y;
  }

  const centerX = (minX + maxX) / 2;
  const centerY = (minY + maxY) / 2;
  const centerCoordinate = [centerX, centerY];
  return centerCoordinate;
};
/* แปลงข้อมูล POLYGON WKT เป็น coordinate เพื่อ getCenter : END */

export class CScaleLine extends ScaleLine {
  /**
   * @param {Options} [opt_options] Scale line options.
   */
  constructor(opt_options) {
    super(opt_options);

    const options = opt_options ? opt_options : {};

    /**
     * For display distance.
     */
    this.distElement_ = document.createElement("div");
    this.distElement_.className = "ol-scale-line-dist";
    this.element.prepend(this.distElement_);

    /**
     * For display compass icon.
     */
    if (options.icon) {
      this.iconElement_ = document.createElement("div");
      this.iconElement_.className = "ol-scale-line-icon";
      this.iconElement_.appendChild(options.icon);
      this.element.prepend(this.iconElement_);
    }

    /**
     * For display scale text.
     */
    if (options.text) {
      this.scaleElement_ = document.createElement("div");
      this.scaleElement_.className = "ol-scale-line-text";
      this.element.append(this.scaleElement_);
    }
  }

  tSuffix(suffix) {
    if (suffix === "μm") {
      return "ไมครอน";
    } else if (suffix === "mm") {
      return "มม.";
    } else if (suffix === "m") {
      return "ม.";
    }
    return "กม.";
  }

  /**
   * Update the scale line element.
   * @param {import("../MapEvent.js").default} mapEvent Map event.
   * @override
   */
  render(mapEvent) {
    super.render(mapEvent);

    // Translate suffix.
    const [dist, suffix] = this.renderedHTML_.split(" ");
    this.distElement_.innerHTML = `${Number(
      dist
    ).toLocaleString()} ${this.tSuffix(suffix)}`;

    // Update scale text.
    if (this.scaleElement_) {
      this.scaleElement_.innerHTML =
        "1 : " + Math.round(this.getScaleForResolution()).toLocaleString();
    }
  }
}

export const GeometryType = {
  POINT: "Point",
  LINE_STRING: "LineString",
  LINEAR_RING: "LinearRing",
  POLYGON: "Polygon",
  MULTI_POINT: "MultiPoint",
  MULTI_LINE_STRING: "MultiLineString",
  MULTI_POLYGON: "MultiPolygon",
  GEOMETRY_COLLECTION: "GeometryCollection",
  CIRCLE: "Circle",
};
