import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { authSlice } from "./auth.slice";
import { factorySlice } from "./factory.slice";
import { enterpriseSlice } from "./enterprise.slice";

export const store = configureStore({
  reducer: {
    [authSlice.name]: authSlice.reducer,
    [factorySlice.name]: factorySlice.reducer,
    [enterpriseSlice.name]: enterpriseSlice.reducer,
  },
  middleware: (defaultMiddlewares) =>
    defaultMiddlewares({ serializableCheck: false }),
});

setupListeners(store.dispatch);
