import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  convertDateEu,
  convertDateThai,
} from "../../../../../../utils/format/Date.format";
import {
  CheckNumberDot,
  CheckValidLength,
} from "../../../../../../utils/validation_input/Validation";
import icon from "../../../../../../assets/img/Icon";
import styles from "./scss/IncreaseCultivation.module.scss";

const EditCropForm = ({
  _cropData,
  _setCropData,
  _allPlant,
  _switch2,
  validErr,
  _themeColor,
  _farmerData,
  _gbStatusOpt4,
  _statusName4,
}) => {
  const [selectedDate, setSelectedDate] = useState({
    plantDate: new Date(),
    harvestDate: new Date(),
    harvestPlan: new Date(),
  });
  const [openPlantDate, setOpenPlantDate] = useState(false);
  const [openHarvestPlan, setOpenHarvestPlan] = useState(false);
  const [openHarvestDate, setOpenHarvestDate] = useState(false);

  /* DatePicker : START */
  const onChangePlantDate = (e) => {
    const dateFormat = convertDateEu(e);
    _setCropData({ ..._cropData, plantDate: dateFormat });
    setSelectedDate((prev) => ({ ...prev, plantDate: e }));
    setOpenPlantDate(!openPlantDate);
  };
  const onChangeHarvestDate = (e) => {
    const dateFormat = convertDateEu(e);
    _setCropData({ ..._cropData, harvestDate: dateFormat });
    setSelectedDate((prev) => ({ ...prev, harvestDate: e }));
    setOpenHarvestDate(!openHarvestDate);
  };
  const onChangeHarvestPlan = (e) => {
    const dateFormat = convertDateEu(e);
    _setCropData({ ..._cropData, harvestPlan: dateFormat });
    setSelectedDate((prev) => ({ ...prev, harvestPlan: e }));
    setOpenHarvestPlan(!openHarvestPlan);
  };
  /* DatePicker : END */

  const onChange = (e) => {
    const { name, value } = e.target;
    _setCropData({ ..._cropData, [name]: value });
  };

  useEffect(() => {
    if (!_switch2) {
      setOpenPlantDate(false);
      setOpenHarvestDate(false);
      setOpenHarvestPlan(false);
      setSelectedDate({
        plantDate: new Date(),
        harvestDate: new Date(),
        harvestPlan: new Date(),
      });
    }
  }, [_switch2]);

  useEffect(() => {
    /* Function close dropdown 'PlantDate and HarvestPlan and HarvestDate' : START */
    const onCloseDropdownPlantDate = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#ent_plant_date2_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenPlantDate(false);
      }
    };
    const onCloseDropdownHarvestPlan = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#ent_harvest_plan2_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenHarvestPlan(false);
      }
    };
    const onCloseDropdownHarvestDate = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#ent_harvest_date2_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenHarvestDate(false);
      }
    };

    if (openPlantDate) {
      document.addEventListener("click", onCloseDropdownPlantDate);
    }
    if (openHarvestPlan) {
      document.addEventListener("click", onCloseDropdownHarvestPlan);
    }
    if (openHarvestDate) {
      document.addEventListener("click", onCloseDropdownHarvestDate);
    }
    /* Function close dropdown 'PlantDate and HarvestPlan and HarvestDate' : END */

    return () => {
      document.removeEventListener("click", onCloseDropdownPlantDate);
      document.removeEventListener("click", onCloseDropdownHarvestPlan);
      document.removeEventListener("click", onCloseDropdownHarvestDate);
    };
  }, [openPlantDate, openHarvestPlan, openHarvestDate]);

  return (
    <div className={`${styles.right_}`}>
      <h4
        className={`${styles.title_text}`}
        style={{ backgroundColor: _themeColor?.backgroundColor }}
      >
        {_farmerData?.firstname} {_farmerData?.lastname}
      </h4>
      <div className={`${styles.input_form}`}>
        <div className={`${styles.input_}`}>
          <label style={{ color: _themeColor?.color }}>รหัสแปลง</label>
          <select name="parcelId" onChange={onChange} disabled={true}>
            <option style={{ display: "none" }}>
              {_cropData?.parcel?.parcelId}
            </option>
          </select>
        </div>
        {validErr && (
          <p className={`${styles.err_message}`}>
            {CheckValidLength(_cropData?.parcelId)}
          </p>
        )}
        <div className={`${styles.input_}`}>
          <label style={{ color: _themeColor?.color }}>
            ชนิดเชื้อเพลิง / วัตถุดิบ{" "}
            <sup className={`${styles.asterisk}`}>*</sup>
          </label>
          <select
            name="plantTypeId"
            onChange={onChange}
            disabled={!_switch2 ? true : false}
          >
            <option style={{ display: "none" }}>
              {_cropData?.plantType?.name}
            </option>
            {_allPlant?.map((item) => {
              return (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>
        {validErr && (
          <p className={`${styles.err_message}`}>
            {CheckValidLength(_cropData?.plantTypeId)}
          </p>
        )}
        <div className={`${styles.input_}`}>
          <label style={{ color: _themeColor?.color }}>
            พื้นที่ของเชื้อเพลิง <sup className={`${styles.asterisk}`}>*</sup>
          </label>
          <div
            className={`${styles.input_unit} ${
              validErr && _cropData?.area === "" ? "error" : ""
            }`}
            style={{
              backgroundColor: _switch2
                ? "transparent"
                : "rgba(239,239,239,0.8)",
            }}
          >
            <input
              type="text"
              name="area"
              value={_cropData?.area || ""}
              onChange={onChange}
              disabled={!_switch2}
            />
            <div className={`${styles.unit}`}>
              <span style={{ color: _themeColor?.color }}>ไร่</span>
            </div>
          </div>
        </div>
        {validErr && (
          <p className={`${styles.err_message}`}>
            {CheckNumberDot(_cropData?.area)}
          </p>
        )}
        <div className={`${styles.input_}`}>
          <label style={{ color: _themeColor?.color }}>
            วันที่เริ่มเพาะปลูก <sup className={`${styles.asterisk}`}>*</sup>
          </label>
          <div
            className={`${styles.input_unit} ${
              validErr && _cropData?.plantDate === "" ? "error" : ""
            }`}
          >
            <div className={`${styles.info_box_}`}>
              <p>
                {_cropData?.plantDate !== ""
                  ? convertDateThai(_cropData?.plantDate)
                  : ""}
              </p>
            </div>
            <div
              className={`${styles.unit}`}
              onClick={() => {
                if (!_switch2) return;
                setOpenPlantDate(!openPlantDate);
              }}
            >
              <img src={icon.calendar} alt="" id="ent_plant_date2_d" />
            </div>
          </div>
          {openPlantDate && (
            <DatePicker
              selected={selectedDate.plantDate}
              onChange={onChangePlantDate}
              inline
            />
          )}
        </div>
        <div className={`${styles.input_}`}>
          <label style={{ color: _themeColor?.color }}>
            วันที่คาดว่าจะเก็บเกี่ยว{" "}
            <sup className={`${styles.asterisk}`}>*</sup>
          </label>
          <div
            className={`${styles.input_unit} ${
              validErr && _cropData?.harvestPlan === "" ? "error" : ""
            }`}
          >
            <div className={`${styles.info_box_}`}>
              <p>
                {_cropData?.harvestPlan !== ""
                  ? convertDateThai(_cropData?.harvestPlan)
                  : ""}
              </p>
            </div>
            <div
              className={`${styles.unit}`}
              onClick={() => {
                if (!_switch2) return;
                setOpenHarvestPlan(!openHarvestPlan);
              }}
            >
              <img src={icon.calendar} alt="" id="ent_harvest_plan2_d" />
            </div>
          </div>
          {openHarvestPlan && (
            <DatePicker
              selected={selectedDate.harvestPlan}
              onChange={onChangeHarvestPlan}
              inline
            />
          )}
        </div>
        {validErr && (
          <p className={`${styles.err_message}`}>
            {CheckValidLength(_cropData?.harvestPlan)}
          </p>
        )}
        <div className={`${styles.input_}`}>
          <label style={{ color: _themeColor?.color }}>
            ผลผลิตที่คาดว่าจะได้รับ(ตัน){" "}
            <sup className={`${styles.asterisk}`}>*</sup>
          </label>
          <div
            className={`${styles.input_unit} ${
              validErr && _cropData?.yieldTarget === "" ? "error" : ""
            }`}
            style={{
              backgroundColor: _switch2
                ? "transparent"
                : "rgba(239,239,239,0.8)",
            }}
          >
            <input
              type="text"
              name="yieldTarget"
              value={_cropData?.yieldTarget || ""}
              onChange={onChange}
              disabled={!_switch2}
            />
            <div className={`${styles.unit}`}>
              <span style={{ color: _themeColor?.color }}>ตัน</span>
            </div>
          </div>
        </div>
        {validErr && (
          <p className={`${styles.err_message}`}>
            {CheckNumberDot(_cropData?.yieldTarget)}
          </p>
        )}
        <div className={`${styles.input_}`}>
          <label style={{ color: _themeColor?.color }}>
            สถานะการเก็บเกี่ยว <sup className={`${styles.asterisk}`}>*</sup>
          </label>
          <select
            name="cropStatus"
            onChange={(e) => {
              const { value } = e.target;
              if (value === _statusName4?.planting) {
                _setCropData({
                  ..._cropData,
                  cropStatus: value,
                  harvestDate: "",
                  yield: "",
                });
              } else {
                _setCropData({ ..._cropData, cropStatus: value });
              }
            }}
            disabled={!_switch2 ? true : false}
          >
            <option
              style={{ display: "none" }}
              selected={!_switch2 ? true : false}
            >
              {
                _gbStatusOpt4?.find((item) => item.id === _cropData?.cropStatus)
                  ?.statusName
              }
            </option>
            {_gbStatusOpt4?.map((item) => {
              return (
                <option key={item.id} value={item.id}>
                  {item.statusName}
                </option>
              );
            })}
          </select>
        </div>
        {validErr && (
          <p className={`${styles.err_message}`}>
            {CheckValidLength(_cropData?.cropStatus)}
          </p>
        )}
        {_cropData?.cropStatus === _statusName4?.harvested && (
          <>
            <div className={`${styles.input_}`}>
              <label style={{ color: _themeColor?.color }}>
                วันที่เก็บเกี่ยว
              </label>
              <div
                className={`${styles.input_unit} ${
                  validErr && _cropData?.harvestDate === null ? "error" : ""
                }`}
              >
                <div className={`${styles.info_box_}`}>
                  <p>
                    {_cropData?.harvestDate !== "" &&
                    _cropData?.harvestDate !== null
                      ? convertDateThai(_cropData?.harvestDate)
                      : ""}
                  </p>
                </div>
                <div
                  className={`${styles.unit}`}
                  onClick={() => {
                    if (!_switch2) return;
                    setOpenHarvestDate(!openHarvestDate);
                  }}
                >
                  <img src={icon.calendar} alt="" id="ent_harvest_date2_d" />
                </div>
              </div>
              {openHarvestDate && (
                <DatePicker
                  selected={selectedDate.harvestDate}
                  onChange={onChangeHarvestDate}
                  inline
                />
              )}
            </div>
            {validErr && _cropData?.harvestDate === null && (
              <p className={`${styles.err_message}`}>กรุณากรอกข้อมูล</p>
            )}
            <div className={`${styles.input_}`}>
              <label style={{ color: _themeColor?.color }}>
                ผลผลิตที่ได้รับ
              </label>
              <div
                className={`${styles.input_unit} ${
                  validErr && _cropData?.yield === 0 ? "error" : ""
                }`}
              >
                <input
                  type="text"
                  name="yield"
                  value={_cropData?.yield || ""}
                  onChange={onChange}
                  disabled={!_switch2 ? true : false}
                />
                <div className={`${styles.unit}`}>
                  <span style={{ color: _themeColor?.color }}>ตัน</span>
                </div>
              </div>
            </div>
            {validErr && _cropData?.yield === 0 && (
              <p className={`${styles.err_message}`}>กรุณากรอกข้อมูล</p>
            )}
          </>
        )}
        <div className={`${styles.input_}`}>
          <label style={{ color: _themeColor?.color }}>บันทึกเพิ่มเติม</label>
          <textarea
            name="note"
            cols="30"
            rows="3"
            value={_cropData?.note || ""}
            onChange={onChange}
            disabled={!_switch2 ? true : false}
          ></textarea>
        </div>
      </div>
    </div>
  );
};

export default EditCropForm;
