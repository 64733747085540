import React, { useCallback, useEffect, useState } from "react";
import { Feature } from "ol";
import { Polygon } from "ol/geom";
import { Draw } from "ol/interaction";
import { Vector as VectorSource } from "ol/source";
import { Vector as VectorLayer } from "ol/layer";
import { Fill, Stroke, Style } from "ol/style";
import { getArea } from "ol/sphere";

const INITIAL_STATE = {
  tool: {
    layer: null,
    draw: null,
    feature: null,
  },
};

const initData = {
  parcelId: "",
  plantTypeId: "",
  plantDate: "",
  area: "",
  harvestPlan: "",
  yieldTarget: "",
  cropStatus: "",
  harvestDate: "",
  yield: "",
  farmerId: "",
  polygon: "",
};

const LayerDraw2 = ({ _map, _switch, _setData }) => {
  const [tool_, setTool] = useState(INITIAL_STATE);

  const calculateArea = (geometry) => {
    const areaRai =
      geometry instanceof Polygon
        ? getArea(geometry)
        : Math.PI * Math.pow(geometry.getRadius(), 2);

    // const rai = Math.floor(areaRai / 1600); // แบบไม่มีทศนิยม
    const rai = Math.floor((areaRai / 1600) * 100) / 100; // แบบมีทศนิยม 2 ตำแหน่ง

    /* let output = `~ ${rai.toLocaleString(undefined, {
      maximumFractionDigits: 2,
    })} ไร่`; */

    return rai;
  };

  const onChangeCoord = useCallback(
    (feature) => {
      /* Clear parcel layers : START */
      _map
        .getLayers()
        .getArray()
        .filter((layer) => layer.get("coord"))
        .forEach((layer) => _map.removeLayer(layer));
      /* Clear parcel layers : END */

      let geom = feature.getGeometry().getCoordinates();
      let covert4326 = feature
        .getGeometry()
        .clone()
        .transform("EPSG:3857", "EPSG:4326");
      let coord = covert4326.getCoordinates();
      // Set data to state prop
      _setData((prve) => ({ ...prve, polygon: coord[0] }));

      let polygonFeature = new Feature({
        geometry: new Polygon(geom),
      });
      const layer = new VectorLayer({
        source: new VectorSource({
          features: [polygonFeature],
        }),
        style: () => {
          return [
            new Style({
              stroke: new Stroke({
                color: "blue",
                width: 2,
              }),
              fill: new Fill({
                color: "rgba(0, 0, 255, 0.1)",
              }),
            }),
          ];
        },
        zIndex: 1,
      });
      layer.setProperties({
        coord: coord,
      });
      _map?.addLayer(layer);

      /* คำนวณพื้นที่ที่วาด (ไร่) : START */
      if (coord[0] && coord[0].length > 2) {
        const areaRai = calculateArea(feature.getGeometry());
        // console.log("Area in rai:", areaRai);
        _setData((prev) => ({ ...prev, area: areaRai }));
      }
      /* คำนวณพื้นที่ที่วาด (ไร่) : END */
    },
    [_setData, _map]
  );

  const actDrawOn = useCallback(() => {
    const source = new VectorSource({ wrapX: false });
    const layer = new VectorLayer({
      source: source,
    });
    const draw = new Draw({
      source: source,
      type: "Polygon",
    });
    // Attach all interactions to map.
    _map?.addInteraction(draw);
    _map?.addLayer(layer);

    // Update state.
    setTool({ draw, layer });

    draw.on("drawstart", (evt) => {
      setTool((tool) => {
        source.clear();
        return { ...tool, feature: evt.feature };
      });
    });
    draw.on("drawend", (evt) => onChangeCoord(evt.feature));
  }, [_map, onChangeCoord]);

  const actDrawClear = useCallback(() => {
    setTool((tool) => {
      if (tool.draw) _map.removeInteraction(tool.draw);
      if (tool.layer) _map.removeLayer(tool.layer);
      return INITIAL_STATE.tool;
    });
  }, [_map]);

  useEffect(() => {
    if (_switch) {
      actDrawClear();
      actDrawOn();
    } else {
      actDrawClear();
      /* Clear parcel layers : START */
      _map
        ?.getLayers()
        .getArray()
        .filter((layer) => layer.get("coord"))
        .forEach((layer) => _map.removeLayer(layer));
      /* Clear parcel layers : END */
      _setData(initData);
    }
  }, [_map, _setData, _switch, actDrawOn, actDrawClear]);

  useEffect(() => {
    if (_switch) {
      console.log("draw on", tool_);
    } else {
      console.log("draw off", tool_);
    }
  }, [tool_, _switch]);

  return <></>;
};

export default LayerDraw2;
