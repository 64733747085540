import React, { useEffect, useCallback, useRef, useMemo } from "react";
import { Overlay } from "ol";
import icon from "../../../assets/img/Icon";
import { convertCoordinates } from "../../../helpers/map.helper";
import { defaultApi } from "../../../services/api";
import styles from "./FactoryPopup.module.scss";

const FactoryPopup = ({
  _map,
  _factory,
  _layerType,
  _setFactoryDetail,
  _setOpened,
  _setLayerClick,
}) => {
  const container = useRef([]);
  const filterFac = useMemo(() => {
    const datas = _factory.filter((item) => item?.geom !== null);
    return datas;
  }, [_factory]);

  const addRefArr = (el) => {
    if (el && !container.current.includes(el)) {
      container.current.push(el);
    }
  };
  const facIcon = (id) => {
    if (1 === id) {
      return icon.group4;
    } else {
      return icon.group5;
    }
  };
  const onPopupClick = async (id) => {
    try {
      const res = await defaultApi.getFactory(id);
      _setFactoryDetail(res.data);
      _setOpened(true);
      _setLayerClick("factory");
    } catch (error) {
      console.log(error);
    }
  };

  const onClearOverlay = useCallback(() => {
    /* Clear overlay : START */
    _map?.getOverlays().forEach((overlay) => {
      let facId = overlay.get("facId");
      if (facId) overlay.setPosition(undefined);
    });
    /* Clear overlay : END */
  }, [_map]);
  const _createOverlay = useCallback(() => {
    /* Remove overlay at position === undefined : START */
    const overlays = _map.getOverlays().getArray();

    overlays.forEach((overlay) => {
      if (overlay.getPosition() === undefined) {
        _map.removeOverlay(overlay);
      }
    });
    /* Remove overlay at position === undefined : END */

    /* Create overlay layer : START */
    for (let i = 0; i < filterFac.length; i++) {
      const element = filterFac[i];
      const el = container.current[i];
      const overlay = new Overlay({
        element: el,
        position: convertCoordinates(element?.lng, element?.lat),
        stopEvent: false,
      });
      overlay.setProperties({ facId: element?.id });

      _map?.addOverlay(overlay);
    }
    /* Create overlay layer : END */
  }, [_map, filterFac]);

  useEffect(() => {
    if (_layerType.active1) {
      _createOverlay();
    } else {
      onClearOverlay();
      _setFactoryDetail(null);
    }
  }, [_createOverlay, onClearOverlay, _layerType, _setFactoryDetail]);

  return filterFac.map((item, index) => {
    return (
      <div
        id={`popup_fac${index}`}
        className={`ol_popup ${styles.ol_popup}`}
        ref={addRefArr}
        key={item?.id || index}
        style={
          item?.factoryTypeId === 1
            ? { backgroundColor: "red" }
            : { backgroundColor: "blue" }
        }
        onClick={() => onPopupClick(item.id)}
      >
        <div
          id="fac_popup_content"
          className={`popup_content ${styles.popup_content}`}
        >
          <div className={`${styles.img_icon}`}>
            <img src={facIcon(item?.factoryTypeId)} alt="" />
          </div>
          <div className={`${styles.text_content}`}>
            <div>
              <p id="fac_text_name">{item?.name}</p>
            </div>
            <div
              style={{ display: "flex", justifyContent: "center", gap: "5px" }}
            >
              <p id="fac_text_details">{Number(item?.proposalPower).toFixed(2)}</p>
              <span>MW</span>
            </div>
          </div>
        </div>
      </div>
    );
  });
};

export default FactoryPopup;
