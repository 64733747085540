import React, { useEffect } from "react";
import { FaTrash } from "react-icons/fa";
import { CheckCoordLatLon } from "../../../../../../utils/validation_input/Validation";
import styles from "./scss/AddDeed.module.scss";

const MultipleInputAdd = ({
  validErr,
  _setData,
  _themeColor,
  _inputListAdd,
  _setInputListAdd,
  _getPolygonArea,
}) => {
  const onMultipleChanges = (e) => {
    const { name, value } = e.target;
    let currentInput = _inputListAdd.vals.find((val) => val.coorName === name);
    if (!currentInput) {
      currentInput = {
        coorName: name,
        coorVal: value,
      };
      _setInputListAdd({ vals: _inputListAdd.vals.concat(currentInput) });
    } else {
      currentInput.coorVal = value;
      _setInputListAdd((prevState) => ({
        ...prevState,
        vals: prevState.vals.map((val) =>
          val.coorName === currentInput.coorName ? currentInput : val
        ),
      }));
    }
  };

  const createInputs = () => {
    const number = _inputListAdd.vals.length + 1;
    const input_ = {
      coorName: `coord${number}`,
      coorVal: "",
    };

    _setInputListAdd({ vals: _inputListAdd.vals.concat(input_) });
  };

  const onDeleteInput = (index) => {
    if (index === 0 || index === 1 || index === 2) return;

    _setInputListAdd({
      vals: _inputListAdd.vals.filter((idx, i) => i !== index),
    });
  };

  useEffect(() => {
    /* Set data coordArr state : START */
    let arr = [];
    if (_inputListAdd?.vals.length !== 0) {
      _inputListAdd?.vals.forEach((item) => {
        const [lat, lon] = item.coorVal.split(",").map(parseFloat);
        arr.push([lon, lat]);
      });
      // ใส่ข้อมูลแรกอีกครั้งเพื่อสร้างวงวน
      if (arr.length > 0) {
        arr.push(arr[0]);
      }

      _setData((prev) => ({ ...prev, polygon: arr }));
    } else {
      // Clear state
      _setData((prev) => ({ ...prev, polygon: "" }));
    }
    /* Set data coordArr state : END  */
  }, [_inputListAdd, _setData]);

  return (
    <>
      {_inputListAdd?.vals.map((item, index) => {
        return (
          <div key={index}>
            <div className={`${styles.input_}`}>
              <label style={{ color: _themeColor?.color }}>
                พิกัดจุดที่ {index + 1}{" "}
                <sup className={`${styles.asterisk}`}>*</sup>
              </label>
              <div
                className={`${styles.input_unit} ${
                  validErr && item.coorVal === "" ? "error" : ""
                }`}
              >
                <input
                  type="text"
                  name={item.coorName}
                  value={item.coorVal || ""}
                  onChange={onMultipleChanges}
                  placeholder="ละติจูด,ลองจิจูด"
                />
                {index >= 3 && (
                  <div className={`${styles.unit}`}>
                    <FaTrash
                      className={`${styles.icon_}`}
                      size={20}
                      cursor={"pointer"}
                      onClick={() => onDeleteInput(index)}
                    />
                  </div>
                )}
              </div>
            </div>
            {validErr && (
              <p className={`${styles.err_message}`}>
                {CheckCoordLatLon(item.coorVal)}
              </p>
            )}
          </div>
        );
      })}
      <div className={`${styles.btn_gen}`}>
        <p data-bs-toggle="modal" data-bs-target="#staticBackdrop">
          วิธีหาค่าพิกัด
        </p>
        {!CheckCoordLatLon(_inputListAdd?.vals[2].coorVal) && (
          <button
            type="button"
            className={`${styles.btn_}`}
            onClick={() => _getPolygonArea("ADD")}
          >
            คำนวณพื้นที่
          </button>
        )}
        <button
          type="button"
          className={`${styles.btn_}`}
          onClick={createInputs}
        >
          เพิ่มพิกัด
        </button>
      </div>
    </>
  );
};

export default MultipleInputAdd;
