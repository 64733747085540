const Message = {
  notify: {
    /* Login : START */
    login_loading: "กำลังตรวจสอบ...",
    login_success: "เข้าสู่ระบบสำเร็จ",
    login_error: "เข้าสู่ระบบไม่สำเร็จ ❗️",
    login_already_error: "บัญชีถูกใช้งานอยู่",
    login_status_disable: "บัญชีผู้ใช้ยังไม่ได้รับการอนุมัติ ❗️",
    login_invalid_error: "บัญชีผู้ใช้ หรือ รหัสผ่าน ไม่ถูกต้อง ❗️",
    /* Login : END */

    /* Register : START */
    register_loading: "กำลังโหลด...",
    register_success: "สมัครสมาชิกสำเร็จ",
    register_error: "สมัครสมาชิกไม่สำเร็จ ❗️",
    register_error_email: "สมัครสมาชิกไม่สำเร็จ อีเมล ถูกใช้งานแล้ว ❗️",
    register_error_username:
      "สมัครสมาชิกไม่สำเร็จ ชื่อผู้ใช้งาน ถูกใช้งานแล้ว ❗️",
    register_confirm: "ยืนยันการสมัครสมาชิก ❓",
    /* Register : END */

    /* Factory : START */
    add_factory_success: "เพิ่มโรงไฟฟ้าสำเร็จ",
    add_factory_error: "เพิ่มโรงไฟฟ้าไม่สำเร็จ ❗️",
    add_factory_member_success: "เพิ่มผู้ประสานงานโรงไฟฟ้าสำเร็จ",
    add_factory_member_error: "เพิ่มผู้ประสานงานโรงไฟฟ้าไม่สำเร็จ ❗️",
    edit_factory_member_success: "แก้ไขผู้ประสานโรงไฟฟ้างานสำเร็จ",
    edit_factory_member_error: "แก้ไขผู้ประสานงานโรงไฟฟ้าไม่สำเร็จ ❗️",
    delete_factory_member_success: "ลบผู้ประสานงานโรงไฟฟ้าสำเร็จ",
    delete_factory_member_error: "ลบผู้ประสานงานโรงไฟฟ้าไม่สำเร็จ ❗️",
    /* Factory : END */
    confirm_delete_member: "ต้องการที่จะลบผู้ประสานงานหรือไม่ ❓",
    delete_member_warning: "ไม่สามารถลบผู้ประสานที่เป็นตัวคุณเองได้ ❗️",
    /* Enterprise : START */
    add_enterprise_success: "เพิ่มกลุ่มวิสาหกิจชุมชนสำเร็จ",
    add_enterprise_error: "เพิ่มกลุ่มวิสาหกิจชุมชนไม่สำเร็จ ❗️",
    add_enterprise_member_success: "เพิ่มผู้ประสานงานวิสาหกิจชุมชนสำเร็จ",
    add_enterprise_member_error: "เพิ่มผู้ประสานงานวิสาหกิจชุมชนไม่สำเร็จ ❗️",
    edit_enterprise_member_success: "แก้ไขผู้ประสานวิสาหกิจชุมชนงานสำเร็จ",
    edit_enterprise_member_error: "แก้ไขผู้ประสานงานวิสาหกิจชุมชนไม่สำเร็จ ❗️",
    delete_enterprise_member_success: "ลบผู้ประสานงานวิสาหกิจชุมชนสำเร็จ",
    delete_enterprise_member_error: "ลบผู้ประสานงานวิสาหกิจชุมชนไม่สำเร็จ ❗️",
    /* Enterprise : END */

    /* Other : START */
    loading: "กำลังโหลด...",
    insert_loading: "กำลังเพิ่มข้อมูล...",
    loading_success: "โหลดข้อมูลสำเร็จ",
    loading_error: "โหลดข้อมูลไม่สำเร็จ ❗️",
    data_not_found: "ไม่พบข้อมูล ❗️",
    insert_success: "เพื่มข้อมูลสำเร็จ",
    insert_error: "เพื่มข้อมูลไม่สำเร็จ ❗️",
    update_loading: "กำลังแก้ไขข้อมูล...",
    update_success: "แก้ไขข้อมูลสำเร็จ",
    update_error: "แก้ไขข้อมูลไม่สำเร็จ ❗️",
    change_pass_ok: "เปลี่ยนรหัสผ่านสำเร็จ",
    change_pass_err: "เปลี่ยนรหัสผ่านไม่สำเร็จ ❗️",
    change_pass_err_: "รหัสผ่านเดิมไม่ถูกต้อง ❗️",
    forgetPassword_confirm: "ต้องการที่จะแก้ไขรหัสผ่านของคุณหรือไม่ ❓",
    forgetPassword_send: "กำลังตรวจสอบ...",
    delete_loading: "กำลังลบข้อมูล...",
    forgetPassword_success: "แก้ไขรหัสผ่านสำเร็จ ส่งข้อมูลไปที่อีเมลของคุณแล้ว",
    fotgetPassword_error: "อีเมล หรือ ชื่อผู้ใช้งาน ไม่ถูกต้อง ❗️",
    delete_confirm: "ต้องการที่จะลบข้อมูลหรือไม่ ❓",
    delete_success: "ลบข้อมูลสำเร็จ",
    delete_error: "ลบข้อมูลสำเร็จไม่สำเร็จ ❗️",
    search_loading: "กำลังค้นหาข้อมูล...",
    search_success: "ค้นหาข้อมูลสำเร็จ",
    search_not_found: "ไม่พบข้อมูลที่ค้นหา ❗️",
    search_error: "ค้นหาข้อมูลไม่สำเร็จ ❗️",
    add_file_insert: "ต้องการที่จะเพิ่มไฟล์หรือไม่ ❓",
    add_file_loading: "กำลังเพิ่มไฟล์...",
    add_file_update: "ต้องการที่จะแก้ไขไฟล์หรือไม่ ❓",
    update_payment_status:
      "ยืนยันการเปลี่ยนสถานะการจ่ายเงิน (ไม่สามารถแก้ไขได้หลังจากกด ยืนยัน ไปแล้ว)❗️",
    checkUserCreatedFac:
      "บัญชีผู้ใช้งานนี้ ได้มีการสร้างข้อมูลโรงไฟฟ้าไปแล้ว ❗️",
    checkUserCreatedEnt:
      "บัญชีผู้ใช้งานนี้ ได้มีการสร้างข้อมูลกลุ่มวิสาหกิจชุมชนไปแล้ว ❗️",
    warnning_edit_crop: "กรุณาคลิกที่แปลงเพาะปลูก ที่ต้องการจะแก้ไข❗️",
    caculate_area_loading: "กำลังคำนวณพื้นที่...",
    caculate_area_success: "คำนวณพื้นที่สำเร็จ",
    caculate_area_error: "คำนวณพื้นที่ไม่สำเร็จ ❗️",
    delete_parcel_confirm: "ต้องการที่จะลบข้อมูลโฉนดหรือไม่ ❓",
    delete_parcel_confirm2:
      "การลบโฉนดจะลบข้อมูลแปลงเพาะปลูกไปด้วย คุณต้องการที่จะทำต่อหรือไม่ ❓",
    delete_parcel_success: "ลบข้อมูลโฉนดสำเร็จ",
    delete_parcel_error: "ลบข้อมูลโฉนดไม่สำเร็จ ❗️",
    delete_crop_confirm: "ต้องการที่จะลบข้อมูลแปลงเพาะปลูกหรือไม่ ❓",
    delete_crop_success: "ลบข้อมูลแปลงเพาะปลูกสำเร็จ",
    delete_crop_error: "ลบข้อมูลแปลงเพาะปลูกไม่สำเร็จ ❗️",
    cancel_crop_confirm: "ต้องการที่จะยกเลิกแปลงเพาะปลูกหรือไม่ ❓",
    cancel_crop_success: "ยกเลิกแปลงเพาะปลูกสำเร็จ",
    cancel_crop_error: "ยกเลิกแปลงเพาะปลูกไม่สำเร็จ ❗️",
    /* Other : END */
    access_in_error: 'ไม่สามารถเข้าถึงได้ ❗️'
  },
};

export default Message;
