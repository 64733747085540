import React, { useState, useEffect, useMemo } from "react";
import styles from "./scss/MenuBar.module.scss";

const initFilterGrp = {
  factoryTypeId: "",
  factoryTypeId1: "",
  factoryStatusId: "",
  factoryStatusId1: "",
  factoryStatusId2: "",
  factoryStatusId3: "",
  addressRegionCode: "",
  addressRegionCode1: "",
  addressRegionCode2: "",
  addressRegionCode3: "",
  addressRegionCode4: "",
  addressRegionCode5: "",
  addressProvCode: "",
  name: "",
  active: false,
};

const MenuBar = ({
  _allProvince,
  _allRegion,
  _switch,
  _onSwitch,
  onClearLayers,
  _onSearchLayer,
  _filterGroup,
  _setFilterGroup,
  _themeColor,
}) => {
  const selectDisable = useMemo(() => {
    if (
      _filterGroup.addressRegionCode !== "" ||
      _filterGroup.addressRegionCode1 !== "" ||
      _filterGroup.addressRegionCode2 !== "" ||
      _filterGroup.addressRegionCode3 !== "" ||
      _filterGroup.addressRegionCode4 !== "" ||
      _filterGroup.addressRegionCode5 !== ""
    ) {
      return true;
    } else {
      return false;
    }
  }, [_filterGroup]);

  const [placeholderDisabled, setPlaceholderDisabled] = useState(false);

  const onChange = (e) => {
    const { name, value } = e.target;
    _setFilterGroup({ ..._filterGroup, [name]: value });
  };
  const onChangeCheckBox = (e) => {
    const { name, value, checked } = e.target;
    if (checked) {
      _setFilterGroup({ ..._filterGroup, [name]: value });
    } else {
      _setFilterGroup({ ..._filterGroup, [name]: "" });
    }
  };
  const onChangeRegion = (e) => {
    const { name, value, checked } = e.target;
    if (checked) {
      _setFilterGroup({ ..._filterGroup, [name]: value });
    } else {
      _setFilterGroup({ ..._filterGroup, [name]: "" });
    }
  };

  useEffect(() => {
    switchMenuActive(_themeColor);
  }, [_themeColor]);
  return (
    <>
      <div className={`${styles.container}`}>
        {/* <div> */}
        <button
          onClick={() => {
            _onSwitch(false);
            if (_switch) {
              onClearLayers();
              _setFilterGroup(initFilterGrp);
            }
          }}
          className={`btn_menu active ${_themeColor?.class}`}
        >
          โรงไฟฟ้า
        </button>
        <button
          onClick={() => {
            _onSwitch(true);
            if (!_switch) {
              onClearLayers();
              _setFilterGroup(initFilterGrp);
            }
          }}
          className="btn_menu"
        >
          สถานะ
        </button>
        {/* </div> */}
        <div className={`${styles.menu_box_1}`}>
          {!_switch && (
            <>
              <h4 style={{ color: _themeColor?.color }}>ประเภทโรงไฟฟ้า</h4>
              <div>
                <input
                  type="checkbox"
                  name="factoryTypeId"
                  id="check1"
                  className={_themeColor?.class}
                  value={1}
                  onChange={onChangeCheckBox}
                />
                <label htmlFor="check1">ก๊าซชีวภาพ</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  name="factoryTypeId1"
                  id="check2"
                  className={_themeColor?.class}
                  value={2}
                  onChange={onChangeCheckBox}
                />
                <label htmlFor="check2">ชีวมวล</label>
              </div>
            </>
          )}
          {_switch && (
            <>
              <h4 style={{ color: _themeColor?.color }}>สถานะโรงไฟฟ้า</h4>
              <div>
                <input
                  type="checkbox"
                  name="factoryStatusId"
                  id="check9"
                  className={_themeColor?.class}
                  value={1}
                  onChange={onChangeCheckBox}
                />
                <label htmlFor="check9">อยู่ระหว่างก่อสร้าง</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  name="factoryStatusId1"
                  id="check10"
                  className={_themeColor?.class}
                  value={2}
                  onChange={onChangeCheckBox}
                />
                <label htmlFor="check10">เสร็จสิ้น</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  name="factoryStatusId2"
                  id="check11"
                  className={_themeColor?.class}
                  value={3}
                  onChange={onChangeCheckBox}
                />
                <label htmlFor="check11">ยื่นขอใบอนุญาต</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  name="factoryStatusId3"
                  id="check12"
                  className={_themeColor?.class}
                  value={4}
                  onChange={onChangeCheckBox}
                />
                <label htmlFor="check12">ดำเนินกิจการ</label>
              </div>
            </>
          )}
          <h4 style={{ color: _themeColor?.color }}>ภูมิภาค</h4>
          <div>
            <input
              type="checkbox"
              name="addressRegionCode"
              id="check3"
              className={_themeColor?.class}
              value={_allRegion[5]?.code}
              onChange={onChangeRegion}
              checked={_filterGroup.addressRegionCode !== "" ? true : false}
              disabled={_filterGroup.addressProvCode !== "" ? true : false}
            />
            <label htmlFor="check3">เหนือ</label>
          </div>
          <div>
            <input
              type="checkbox"
              name="addressRegionCode1"
              id="check4"
              className={_themeColor?.class}
              value={_allRegion[0]?.code}
              onChange={onChangeRegion}
              checked={_filterGroup.addressRegionCode1 !== "" ? true : false}
              disabled={_filterGroup.addressProvCode !== "" ? true : false}
            />
            <label htmlFor="check4">ใต้</label>
          </div>
          <div>
            <input
              type="checkbox"
              name="addressRegionCode2"
              id="check5"
              className={_themeColor?.class}
              value={_allRegion[2]?.code}
              onChange={onChangeRegion}
              checked={_filterGroup.addressRegionCode2 !== "" ? true : false}
              disabled={_filterGroup.addressProvCode !== "" ? true : false}
            />
            <label htmlFor="check5">กลาง</label>
          </div>
          <div>
            <input
              type="checkbox"
              name="addressRegionCode3"
              id="check6"
              className={_themeColor?.class}
              value={_allRegion[3]?.code}
              onChange={onChangeRegion}
              checked={_filterGroup.addressRegionCode3 !== "" ? true : false}
              disabled={_filterGroup.addressProvCode !== "" ? true : false}
            />
            <label htmlFor="check6">ตะวันตก</label>
          </div>
          <div>
            <input
              type="checkbox"
              name="addressRegionCode4"
              id="check7"
              className={_themeColor?.class}
              value={_allRegion[4]?.code}
              onChange={onChangeRegion}
              checked={_filterGroup.addressRegionCode4 !== "" ? true : false}
              disabled={_filterGroup.addressProvCode !== "" ? true : false}
            />
            <label htmlFor="check7">ตะวันออก</label>
          </div>
          <div>
            <input
              type="checkbox"
              name="addressRegionCode5"
              id="check8"
              className={_themeColor?.class}
              value={_allRegion[1]?.code}
              onChange={onChangeRegion}
              checked={_filterGroup.addressRegionCode5 !== "" ? true : false}
              disabled={_filterGroup.addressProvCode !== "" ? true : false}
            />
            <label htmlFor="check8">ตะวันออกเฉียงเหนือ</label>
          </div>
        </div>
        <div className={`${styles.menu_box_2}`}>
          <div>
            <h4 style={{ color: _themeColor?.color }}>จังหวัด</h4>
            <select
              name="addressProvCode"
              onChange={onChange}
              disabled={selectDisable}
            >
              <option
                selected={_filterGroup.addressProvCode === ""}
                style={{ display: "none" }}
              >
                เลือกจังหวัด
              </option>
              <option selected={selectDisable} value={""}>
                ทั้งหมด
              </option>
              {_allProvince
                .sort((a, b) => a.provName.localeCompare(b.provName))
                .map((item) => {
                  return (
                    <option key={item.gid} value={item.provCode}>
                      {item.provName}
                    </option>
                  );
                })}
            </select>
          </div>
          <div>
            <h4 style={{ color: _themeColor?.color }}>Keywords</h4>
            <input
              type="search"
              name="name"
              value={_filterGroup.name}
              onChange={onChange}
              onFocus={() => setPlaceholderDisabled(true)}
              onBlur={() => setPlaceholderDisabled(false)}
              placeholder={placeholderDisabled ? "" : "ค้นหาชื่อโรงไฟฟ้า"}
            />
          </div>
        </div>
        <div className={`${styles.menu_box_3}`}>
          <button
            className="button_sm"
            onClick={() => {
              _onSearchLayer();
              _setFilterGroup({ ..._filterGroup, active: true });
            }}
          >
            ค้นหา
          </button>
        </div>
      </div>
    </>
  );
};

function switchMenuActive(_themeColor) {
  const btn = document.querySelectorAll(".btn_menu");
  if (_themeColor === "") {
    btn.forEach((item) => {
      item.addEventListener("click", () => {
        btn.forEach((item) => item.classList.remove("active"));
        btn.forEach((item) => item.classList.remove("ent"));
        btn.forEach((item) => item.classList.remove("gov"));

        item.classList.add("active");
      });
    });
  } else {
    btn.forEach((item) => {
      item.addEventListener("click", () => {
        btn.forEach((item) => item.classList.remove("active"));
        btn.forEach((item) => item.classList.remove(_themeColor?.class));
        item.classList.add("active");
        item.classList.add(_themeColor?.class);
      });
    });
  }
}

export default MenuBar;
