const icon = {
  index_0: require("./icon/index_0.png"),
  index_1: require("./icon/index_1.png"),
  index_2: require("./icon/index_2.png"),
  index_3: require("./icon/index_3.png"),
  index_4: require("./icon/index_4.png"),
  hamburger: require("./icon/Hamburger.png"),
  document: require("./icon/document.png"),
  profileFac: require("./icon/profileFac.png"),
  profileEnt: require("./icon/profileEnt.png"),
  profileGvm: require("./icon/profileGvm.png"),
  logoutFac: require("./icon/logoutFac.png"),
  logoutEnt: require("./icon/logoutEnt.png"),
  logoutGvm: require("./icon/logoutGvm.png"),
  bin: require("./icon/Bin.png"),
  add: require("./icon/Add.png"),
  add2: require("./icon/Add2.png"),
  calendar: require("./icon/calendar.png"),
  chervonUp1: require("./icon/Chevron Up-1.png"),
  chervonUp: require("./icon/Chevron Up.png"),
  compassSouth: require("./icon/Compass South.png"),
  frame1: require("./icon/Frame-1.png"),
  frame2: require("./icon/Frame-2.png"),
  group74: require("./icon/Group 74.png"),
  group200: require("./icon/Group 200.png"),
  group240: require("./icon/Group 240.png"),
  group284: require("./icon/Group 284.png"),
  group291: require("./icon/Group 291.png"),
  group310: require("./icon/Group 310.png"),
  group312: require("./icon/Group 312.png"),
  group313: require("./icon/Group 313.png"),
  group384: require("./icon/Group 384.png"),
  group4: require("./icon/Group-4.png"),
  group5: require("./icon/Group-5.png"),
  group6: require("./icon/Group-6.png"),
  group7: require("./icon/Group-7.png"),
  group8: require("./icon/Group-8.png"),
  group9: require("./icon/Group-9.png"),
  group10: require("./icon/Group-10.png"),
  group11: require("./icon/Group11.png"),
  mail: require("./icon/Mail.png"),
  password: require("./icon/password.png"),
  pen: require("./icon/pen.png"),
  pic: require("./icon/Pic.png"),
  point: require("./icon/point.png"),
  search: require("./icon/Search.png"),
  user: require("./icon/User.png"),
  user1: require("./icon/User1.png"),
  vector71: require("./icon/Vector 71.png"),
  vector2: require("./icon/Vector-2.png"),
  vector3: require("./icon/Vector-3.png"),
  vector4: require("./icon/Vector-4.png"),
  vector5: require("./icon/Vector-5.png"),
  vector6: require("./icon/Vector-6.png"),
  vector7: require("./icon/Vector-7.png"),
  vector: require("./icon/Vector.png"),
  rising: require("./icon/Rising.png"),
  map_icon1: require("./icon/map_icon1.png"),
  map_icon2: require("./icon/map_icon2.png"),
  map_icon3: require("./icon/map_icon3.png"),
  map_icon4: require("./icon/map_icon4.png"),
};

export default icon;
