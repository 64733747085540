import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { GrFormClose } from "react-icons/gr";
import { toast } from "react-hot-toast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Table from "./Table";
import EditSalesRecordForm from "./form/EditSalesRecordForm";
import EditForm from "./form/EditForm";
import useSwitchPage from "../../../../../../utils/hooks/useSwitchPage";
import useSwitch from "../../../../../../utils/hooks/useSwitch";
import {
  convertDateEu,
  convertDateThai,
} from "../../../../../../utils/format/Date.format";
import { defaultApi } from "../../../../../../services/api";
import {
  QueryGetAll,
  QueryGetByID,
} from "../../../../../../helpers/api.helper";
import { getFactoryData } from "../../../../../../services/factory.slice";
import { clearObj } from "../../../../../../utils/format/Object.format";
import icon from "../../../../../../assets/img/Icon";
import Message from "../../../../../../utils/message/Message";
import {
  exportCsvFile,
  exportXlsxFile,
} from "../../../../../../helpers/func.helper";
import ExportPopup from "../../../../../../layouts/exportpopup/ExportPopup";

const SalesRecord = ({ onSwicthBoxR }) => {
  const factoryData = useSelector(getFactoryData);
  const [switchPage, onSwitchPage] = useSwitchPage();
  const [_switch, onSwitch] = useSwitch();

  const [allPpaTransaction, setAllPpaTransaction] = useState([]);
  const [ppaTransaction, setPpaTransaction] = useState({});
  const [allBuyer, setAllBuyer] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [_paymentStatus, setPaymentStatus] = useState([]);
  const [filterGroup, setFilterGroup] = useState({
    status: "",
    buyer: "",
    date: "",
  });
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [openSellDate, setOpenSellDate] = useState(false);
  const [_triggerExport, setTriggerExport] = useState(false);

  const _allBuyer = useMemo(() => {
    let buyer = allBuyer.map((item) => item.buyer);
    // ลบค่าที่ซำ้กันใน array
    let unique = [...new Set(buyer)];
    return unique;
  }, [allBuyer]);

  // const filterDataTable = useCallback(async () => {
  //   const qryGetAll = {
  //     $count: "%24count=true",
  //     $expand: "&%24expand=ppa",
  //     $top: "",
  //     $skip: "",
  //     $filter: "",
  //     $orderby: "",
  //   };
  //   const _queryAll = QueryGetAll(qryGetAll);
  //   const formatQry = (obj) => {
  //     const checkObj = clearObj({
  //       status: obj.status,
  //       buyer: obj.buyer,
  //       date: obj.date,
  //     });
  //     if (Object.keys(checkObj).length === 0) {
  //       return QueryGetAll(qryGetAll);
  //     } else {
  //       let status_ =
  //         checkObj.buyer === "" || checkObj.date === ""
  //           ? `paymentStatus%20eq%20%27${checkObj.status}%27`
  //           : `%20and%20paymentStatus%20eq%20%27${checkObj.status}%27`;
  //       if (status_.includes("undefined")) {
  //         status_ = "";
  //       }

  //       let buyer_ =
  //         checkObj.status === "" || checkObj.date === ""
  //           ? `buyer%20eq%20%27${checkObj.buyer}%27`
  //           : `%20and%20buyer%20eq%20%27${checkObj.buyer}%27`;
  //       if (buyer_.includes("undefined")) {
  //         buyer_ = "";
  //       }

  //       let date_ =
  //         checkObj.buyer === "" || checkObj.date === ""
  //           ? `sellDate%20eq%20${checkObj.date}`
  //           : `%20and%20sellDate%20eq%20${checkObj.date}`;
  //       if (date_.includes("undefined")) {
  //         date_ = "";
  //       }

  //       const url = `${status_}${buyer_}${date_}`;

  //       // console.log(url.slice(9));

  //       return `${_queryAll}&%24filter=${url.slice(9)}`;
  //     }
  //   };
  //   try {
  //     const res = await defaultApi.getAllPpaTransaction(formatQry(filterGroup));
  //     setAllPpaTransaction(res.data.datas);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, [filterGroup]);

  /* DatePicker : START */
  const onChangeSellDate = (e) => {
    const dateFormat = convertDateEu(e);
    setFilterGroup({ ...filterGroup, date: dateFormat });
    setSelectedDate(e);
    setOpenSellDate(!openSellDate);
  };
  /* DatePicker : END */

  /* Export csv and excel : START */
  const onExportCsv = () => {
    // ข้อมูลที่ต้องส่งไปให้ ฟังก์ชั่น ใช้งาน
    let exportName = "PpaTransactionReport";
    let type = "csv";
    let obj = {
      pms: filterGroup.status,
      buyer: filterGroup.buyer,
      selldate: filterGroup.date,
      factoryId: factoryData.id,
    };
    // Ready
    exportCsvFile(exportName, type, obj);
  };
  const onExportXlsx = () => {
    // ข้อมูลที่ต้องส่งไปให้ ฟังก์ชั่น ใช้งาน
    let exportName = "PpaTransactionReport";
    let type = "excel";
    let obj = {
      pms: filterGroup.status,
      buyer: filterGroup.buyer,
      selldate: filterGroup.date,
      factoryId: factoryData.id,
    };
    // Ready
    exportXlsxFile(exportName, type, obj);
  };
  /* Export csv and excel : END */

  /* Pagination table : START */
  const onPaginate = useCallback(
    async (page) => {
      if (currentPage === page) return;

      const qryGetAll = {
        $count: "%24count=true",
        $expand: "&%24expand=ppa",
        $top: "&%24top=10",
        $skip: `&%24skip=${page <= 1 ? "0" : page * 10 - 10}`,
        $filter: `&%24filter=factoryId%20eq%20${factoryData.id}`,
        $orderby: "",
      };
      const _queryAll = QueryGetAll(qryGetAll);
      const formatQry = (obj) => {
        const checkObj = clearObj({
          paymentStatus: obj.status,
          buyer: obj.buyer,
          sellDate: obj.date,
        });
        if (Object.keys(clearObj(checkObj)).length === 0) {
          return QueryGetAll(qryGetAll);
        } else {
          let objLng = Object.keys(clearObj(checkObj)).length;
          let count = 0;
          let filter1 = "";
          let filter2 = "";
          let filter3 = "";
          for (const [key, value] of Object.entries(clearObj(checkObj))) {
            count++;
            // # แบ่งเป็น 2 เคส #

            // 1 # มี objkey ตัวเดียว #
            if (objLng <= 1) {
              if (key === "sellDate") {
                filter1 = `${key}%20eq%20${value}`;
              } else {
                filter1 = `${key}%20eq%20%27${value}%27`;
              }
            } else {
              // 2 # มี objkey มากกว่า 1 ตัว #
              // ตัวแรก
              if (count === 1) {
                if (key === "sellDate") {
                  filter1 = `${key}%20eq%20${value}%20and%20`;
                } else {
                  filter1 = `${key}%20eq%20%27${value}%27%20and%20`;
                }
              }
              // ตัวสุดท้าย
              else if (count === objLng) {
                if (key === "sellDate") {
                  filter3 = `${key}%20eq%20${value}`;
                } else {
                  filter3 = `${key}%20eq%20%27${value}%27`;
                }
              }
              // ตัวอื่นๆ
              else {
                if (key === "sellDate") {
                  filter2 = `${key}%20eq%20${value}%20and%20`;
                } else {
                  filter2 = `${key}%20eq%20%27${value}%27%20and%20`;
                }
              }
            }
          }
          return `${_queryAll}%20and%20${filter1}${filter2}${filter3}`;
        }
      };
      try {
        const res = await defaultApi.getAllPpaTransaction(
          formatQry(filterGroup)
        );

        setAllPpaTransaction(res.data.datas);
        setTotalCount(res.data.total);
        setCurrentPage(page);
      } catch (error) {
        console.log(error);
      }
    },
    [factoryData, filterGroup, currentPage]
  );
  /* Pagination table : END */

  const filterDataTable = useCallback(async () => {
    const loading = toast.loading(Message.notify.search_loading);
    const qryGetAll = {
      $count: "%24count=true",
      $expand: "&%24expand=ppa",
      $top: "&%24top=10",
      $skip: "&%24skip=0",
      $filter: "",
      $orderby: "",
    };
    const _queryAll = QueryGetAll(qryGetAll);
    const formatQry = (obj) => {
      const checkObj = clearObj({
        paymentStatus: obj.status,
        buyer: obj.buyer,
        sellDate: obj.date,
      });
      if (Object.keys(clearObj(checkObj)).length === 0) {
        return QueryGetAll(qryGetAll);
      } else {
        let objLng = Object.keys(clearObj(checkObj)).length;
        let count = 0;
        let filter1 = "";
        let filter2 = "";
        let filter3 = "";
        for (const [key, value] of Object.entries(clearObj(checkObj))) {
          count++;
          // # แบ่งเป็น 2 เคส #

          // 1 # มี objkey ตัวเดียว #
          if (objLng <= 1) {
            if (key === "sellDate") {
              filter1 = `${key}%20eq%20${value}`;
            } else {
              filter1 = `${key}%20eq%20%27${value}%27`;
            }
          } else {
            // 2 # มี objkey มากกว่า 1 ตัว #
            // ตัวแรก
            if (count === 1) {
              if (key === "sellDate") {
                filter1 = `${key}%20eq%20${value}%20and%20`;
              } else {
                filter1 = `${key}%20eq%20%27${value}%27%20and%20`;
              }
            }
            // ตัวสุดท้าย
            else if (count === objLng) {
              if (key === "sellDate") {
                filter3 = `${key}%20eq%20${value}`;
              } else {
                filter3 = `${key}%20eq%20%27${value}%27`;
              }
            }
            // ตัวอื่นๆ
            else {
              if (key === "sellDate") {
                filter2 = `${key}%20eq%20${value}%20and%20`;
              } else {
                filter2 = `${key}%20eq%20%27${value}%27%20and%20`;
              }
            }
          }
        }
        return `${_queryAll}&%24filter=factoryId%20eq%20${factoryData.id}%20and%20${filter1}${filter2}${filter3}`;
      }
    };
    try {
      const res = await defaultApi.getAllPpaTransaction(formatQry(filterGroup));

      setAllPpaTransaction(res.data.datas);
      setTotalCount(res.data.total);
      setCurrentPage(1);
      if (res.data.datas.length === 0) {
        toast.error(Message.notify.search_not_found, { duration: 3000 });
      }
    } catch (error) {
      console.log(error);
    } finally {
      toast.dismiss(loading);
    }
  }, [filterGroup, factoryData]);

  const getMultipleApi = useCallback(async () => {
    if (!factoryData.id) return;

    let factoryId = factoryData?.id;
    const qryGetAll1 = {
      $count: "%24count=true",
      $expand: "&%24expand=ppa",
      $top: "&%24top=10",
      $skip: "&%24skip=0",
      $filter: `&%24filter=factoryId%20eq%20${factoryId}`,
      $orderby: "",
    };
    const qryGetAll2 = {
      $count: "%24count=true",
      $expand: "&%24expand=ppa",
      $top: "",
      $skip: "",
      $filter: `&%24filter=factoryId%20eq%20${factoryId}`,
      $orderby: "",
    };
    const _queryAll1 = QueryGetAll(qryGetAll1);
    const _queryAll2 = QueryGetAll(qryGetAll2);
    try {
      const [allPpaTransaction, allBuyer] = await Promise.all([
        defaultApi.getAllPpaTransaction(_queryAll1),
        defaultApi.getAllPpaTransaction(_queryAll2),
      ]);

      setAllPpaTransaction(allPpaTransaction.data.datas);
      setAllBuyer(allBuyer.data.datas);
      setTotalCount(allPpaTransaction.data.total);
      setCurrentPage(1);
    } catch (error) {
      console.log(error);
    }
  }, [factoryData]);
  const getPpaTransaction = useCallback(async () => {
    const _id = switchPage;

    if (!_id) return;

    const qryGetById = {
      $expand: "&%24expand=ppa%2CPpaTransactionReceiptNavigation",
      $filter: "",
      $orderby: "",
    };
    const _queryID = QueryGetByID(qryGetById);
    try {
      const res = await defaultApi.getPpaTransaction(_id, _queryID);
      setPpaTransaction(res.data);
    } catch (error) {
      console.log(error);
    }
  }, [switchPage]);
  const getGbStatus = async () => {
    const query = `%24filter=statusCategories%20eq%20%275%27`;

    try {
      const res = await defaultApi.getGbStatus(query);

      setPaymentStatus(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    /* Filter data table : START */
    if (
      filterGroup.status === "" &&
      filterGroup.buyer === "" &&
      filterGroup.date === ""
    ) {
      getMultipleApi();
    } else if (
      filterGroup.status !== "" ||
      filterGroup.buyer !== "" ||
      filterGroup.date !== ""
    ) {
      filterDataTable();
    }
    /* Filter data table : END */
  }, [getMultipleApi, filterDataTable, filterGroup]);

  useEffect(() => {
    // Use functions
    getPpaTransaction();
    getGbStatus();
  }, [getPpaTransaction]);

  useEffect(() => {
    /* Function close dropdown 'SellDate' : START */
    const onCloseDropdownSellDate = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#fac_sell_date_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenSellDate(false);
      }
    };

    if (openSellDate) {
      document.addEventListener("click", onCloseDropdownSellDate);
    }
    /* Function close dropdown 'SellDate' : END */

    return () => {
      document.removeEventListener("click", onCloseDropdownSellDate);
    };
  }, [openSellDate]);

  return (
    <div className="report_sales">
      {switchPage === null && (
        <div className="filter_groups">
          <div className="group_">
            <select
              onChange={(e) =>
                setFilterGroup({ ...filterGroup, status: e.target.value })
              }
            >
              <option value="">สถานะการจ่ายเงิน</option>
              {_paymentStatus.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.statusName}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="group_">
            <select
              onChange={(e) =>
                setFilterGroup({ ...filterGroup, buyer: e.target.value })
              }
            >
              <option value="">ผู้รับซื้อพลังงาน</option>
              {_allBuyer.map((item, i) => {
                return (
                  <option key={i} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="group_">
            <div className="input_unit">
              <div className="info_box">
                <p>
                  {filterGroup.date !== ""
                    ? convertDateThai(filterGroup.date)
                    : "วันที่"}
                </p>
                {filterGroup.date !== "" && (
                  <GrFormClose
                    size={25}
                    cursor={"pointer"}
                    onClick={() => {
                      setFilterGroup({ ...filterGroup, date: "" });
                      setSelectedDate(new Date());
                    }}
                  />
                )}
              </div>
              <div
                className="unit"
                onClick={() => setOpenSellDate(!openSellDate)}
              >
                <img src={icon.calendar} alt="" id="fac_sell_date_d" />
              </div>
            </div>
            {openSellDate && (
              <DatePicker
                selected={selectedDate}
                onChange={onChangeSellDate}
                inline
              />
            )}
          </div>
        </div>
      )}
      {switchPage === null && (
        <Table
          _dataArr={allPpaTransaction}
          _totalCount={totalCount}
          _currentPage={currentPage}
          _onPaginate={onPaginate}
          onSwitchPage={onSwitchPage}
          _paymentStatus={_paymentStatus}
        />
      )}
      {switchPage !== null && (
        <>
          {_switch && (
            <EditForm
              _onSwitch={onSwitch}
              _dataObj={ppaTransaction}
              _fetchData={() => {
                getMultipleApi();
                getPpaTransaction();
              }}
              _paymentStatus={_paymentStatus}
            />
          )}
          {!_switch && (
            <EditSalesRecordForm
              _onSwitchPage={onSwitchPage}
              _onSwitch={onSwitch}
              _dataObj={ppaTransaction}
              _paymentStatus={_paymentStatus}
            />
          )}
        </>
      )}

      {switchPage === null && (
        <div className="button_right">
          <ExportPopup
            label={"document"}
            trigger={_triggerExport}
            event={{ csv: onExportCsv, xlsx: onExportXlsx }}
          />
          <button
            type="button"
            className="btn_"
            onClick={() => onSwicthBoxR(true)}
          >
            เพิ่มบันทึก
          </button>
          <button
            type="button"
            className="btn_"
            onClick={() => setTriggerExport(!_triggerExport)}
          >
            Export
          </button>
        </div>
      )}
    </div>
  );
};

export default SalesRecord;
