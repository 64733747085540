import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import CollapseTable from "./CollapseTable";

const CollapseRight = ({ _themeColor }) => {
  const [opened, setOpened] = useState(false);
  return (
    <>
      {opened ? (
        <div className="collapse_open">
          <div className="close_arrow">
            <Link to={`#`} onClick={() => setOpened(!opened)}>
              <i style={{ color: _themeColor?.color }}>
                <FaChevronRight />
              </i>
            </Link>
          </div>
          {/* CollapseTable.js */}

          <CollapseTable _themeColor={_themeColor} />

          {/* CollapseTable.js */}
        </div>
      ) : (
        <div className="collapse_close">
          <div className="open_arrow">
            <Link to={`#`} onClick={() => setOpened(!opened)}>
              <i style={{ color: _themeColor?.color }}>
                <FaChevronLeft />
              </i>
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default CollapseRight;
