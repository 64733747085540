import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Autocomplete from "react-autocomplete";
import { toast } from "react-hot-toast";
import icon from "../../../../../../../assets/img/Icon";
import { defaultApi } from "../../../../../../../services/api";
import {
  convertDateEu,
  convertDateThai,
} from "../../../../../../../utils/format/Date.format";
import Message from "../../../../../../../utils/message/Message";
import {
  CheckDataStDateEn,
  CheckDateEnDataSt,
  CheckNumber,
  CheckNumberDot,
  CheckValidLength,
} from "../../../../../../../utils/validation_input/Validation";
import { getFactoryData } from "../../../../../../../services/factory.slice";
import { QueryGetAll } from "../../../../../../../helpers/api.helper";
import styles from "./EditContractPForm.module.scss";

const EditForm = ({ _onSwitch, _contractPpa, _fetchData, _contractStatus }) => {
  const factoryData = useSelector(getFactoryData);

  const [_data, setData] = useState([]);
  const [allEnterprise, setAllEnterprise] = useState([]);
  const [buyerArr, setBuyerArr] = useState([]);
  const [pdfName, setPdfName] = useState("");
  const [selectedDate, setSelectedDate] = useState({
    sign: new Date(),
    end: new Date(),
    scod: new Date(),
    cod: new Date(),
  });
  const [openDateSign, setOpenDateSign] = useState(false);
  const [openDateEnd, setOpenDateEnd] = useState(false);
  const [openDateScod, setOpenDateScod] = useState(false);
  const [openDateCod, setOpenDateCod] = useState(false);
  const [validErr, setValidErr] = useState(false);

  /* DatePicker : START */
  const onChangeDateSign = (e) => {
    const dateFormat = convertDateEu(e);
    setData({ ..._data, signDate: dateFormat });
    setSelectedDate({ ...selectedDate, sign: e });
    setOpenDateSign(!openDateSign);
  };
  const onChangeDateEnd = (e) => {
    const dateFormat = convertDateEu(e);
    setData({ ..._data, endDate: dateFormat });
    setSelectedDate({ ...selectedDate, end: e });
    setOpenDateEnd(!openDateEnd);
  };
  const onChangeDateScod = (e) => {
    const dateFormat = convertDateEu(e);
    setData({ ..._data, scod: dateFormat });
    setSelectedDate({ ...selectedDate, scod: e });
    setOpenDateScod(!openDateScod);
  };
  const onChangeDateCod = (e) => {
    const dateFormat = convertDateEu(e);
    setData({ ..._data, cod: dateFormat });
    setSelectedDate({ ...selectedDate, cod: e });
    setOpenDateCod(!openDateCod);
  };
  /* DatePicker : END */

  const onChange = (e) => {
    const { name, value } = e.target;
    setData({ ..._data, [name]: value });
  };
  const getFilePdfName = () => {
    const file = document.querySelector("#add_file");

    file?.addEventListener("change", () => {
      if (file.value) {
        setPdfName(file.files[0].name);
      } else {
        setPdfName("");
      }
    });
  };
  const getAllEnterprise = useCallback(async () => {
    if (!factoryData?.id) return;

    let factoryId = factoryData?.id;
    const qryGetAll = {
      $count: "%24count=true",
      $expand: "",
      $top: "",
      $skip: "",
      $filter: `&%24filter=factoryId%20eq%20${factoryId}`,
      $orderby: "",
    };
    const _queryAll = QueryGetAll(qryGetAll);
    try {
      const res = await defaultApi.getAllEnterprise(_queryAll);

      setAllEnterprise(res.data.datas);
    } catch (error) {
      console.log(error);
    }
  }, [factoryData]);
  const updateFileDetail = async (e) => {
    const file = document.querySelector("#add_file");
    if (!window.confirm("ต้องการที่จะแก้ไขไฟล์หรือไม่ ?")) {
      file.value = "";
      setPdfName("");
      return;
    }
    if (!e.target.files.length)
      return setData({ ..._data, ppaDoc: _data?.ppaDoc });

    const loading = toast.loading("กำลังเพิ่มสัญญาฉบับจริง");
    const _id = _data?.ppaDoc;
    const param1 = "PPA";
    const param2 = new FormData();
    param2.append("FormFile", e.target.files[0]);

    try {
      const res = await defaultApi.updateFileDetail(_id, param1, param2);
      console.log(res.statusText);
      setData({ ..._data, ppaDoc: res?.data.id });
      file.value = "";
      toast.success(Message.notify.update_success, { duration: 3000 });
    } catch (error) {
      console.log(error);
      toast.error(Message.notify.update_error, { duration: 3000 });
    } finally {
      toast.dismiss(loading);
    }
  };
  const updateContractPpa = async () => {
    if (CheckValidLength(_data.buyer)) return setValidErr(true);
    if (CheckNumber(_data.period)) return setValidErr(true);
    if (CheckNumberDot(_data.amount)) return setValidErr(true);
    if (CheckValidLength(_data.scod)) return setValidErr(true);
    if (CheckValidLength(_data.cod)) return setValidErr(true);
    if (CheckDataStDateEn(_data.signDate, _data.endDate))
      return setValidErr(true);
    if (CheckDateEnDataSt(_data.endDate, _data.signDate))
      return setValidErr(true);

    const loading = toast.loading(Message.notify.loading);
    const params = {};
    Object.keys(_data).forEach((key) => {
      if (key === "period") {
        params[key] = +_data[key];
      } else if (key === "amount") {
        params[key] = +_data[key];
      } else if (key === "ppaDoc") {
        params[key] = +_data[key];
      } else {
        params[key] = _data[key];
      }
    });
    try {
      const res = await defaultApi.updateContractPpa(_data?.id, params);
      console.log(res.statusText);
      setValidErr(false);
      _onSwitch(false);
      _fetchData();
      toast.success(Message.notify.update_success, { duration: 3000 });
    } catch (error) {
      console.log(error);
      toast.error(Message.notify.update_error, { duration: 3000 });
    } finally {
      toast.dismiss(loading);
    }
  };

  useEffect(() => {
    /* Format allEnterprise to new arr obj [id,name] : START */
    if (allEnterprise.length) {
      let newArr = [];
      for (let i = 0; i < allEnterprise.length; i++) {
        allEnterprise.forEach((item) => {
          let obj_ = { id: item.id, name: item.name };
          newArr[i] = obj_;
          ++i;
        });
      }
      setBuyerArr(newArr);
    }
    /* Format allEnterprise to new arr obj [id,name] : END */
  }, [allEnterprise]);

  useEffect(() => {
    // Use functions
    getAllEnterprise();
    getFilePdfName();
    setData(_contractPpa);
  }, [_contractPpa, getAllEnterprise]);

  useEffect(() => {
    /* Function close dropdown 'DateSign and DateEnd and DateScod' : START */
    const onCloseDropdownDateSign = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#fac_sign_date_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenDateSign(false);
      }
    };
    const onCloseDropdownDateEnd = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#fac_end_date_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenDateEnd(false);
      }
    };
    const onCloseDropdownDateScod = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#fac_scod_date_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenDateScod(false);
      }
    };

    if (openDateSign) {
      document.addEventListener("click", onCloseDropdownDateSign);
    }
    if (openDateEnd) {
      document.addEventListener("click", onCloseDropdownDateEnd);
    }
    if (openDateScod) {
      document.addEventListener("click", onCloseDropdownDateScod);
    }
    /* Function close dropdown 'DateSign and DateEnd and DateScod' : END */

    return () => {
      document.removeEventListener("click", onCloseDropdownDateSign);
      document.removeEventListener("click", onCloseDropdownDateEnd);
      document.removeEventListener("click", onCloseDropdownDateScod);
    };
  }, [openDateSign, openDateEnd, openDateScod]);

  return (
    <>
      <form className={`${styles.input_form}`}>
        <div className={`${styles.input_flex}`}>
          <div className={`${styles.input_1}`}>
            <h5>สัญญาเลขที่</h5>
            <input
              type="text"
              name="no"
              value={_data?.no || ""}
              style={{ background: "#f5f5f5" }}
              readOnly
            />
          </div>
          <div className={`${styles.input_2}`}>
            <h5>
              สถานะสัญญา <sup className={`${styles.asterisk}`}>*</sup>
            </h5>
            <select name="status" onChange={onChange}>
              <option style={{ display: "none" }}>
                {
                  _contractStatus?.find((item) => item.id === _data?.status)
                    ?.statusName
                }
              </option>
              {_contractStatus?.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.statusName}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className={`${styles.input_flex}`}>
          <div className={`${styles.input_1}`}>
            <h5>
              วันเริ่มต้นสัญญา PPA <sup className={`${styles.asterisk}`}>*</sup>
            </h5>
            <div className={`${styles.input_unit}`}>
              <div className={`${styles.info_box}`}>
                <p>
                  {_data?.signDate !== ""
                    ? convertDateThai(_data?.signDate)
                    : ""}
                </p>
              </div>
              <div
                className={`${styles.unit}`}
                onClick={() => {
                  setOpenDateSign(!openDateSign);
                }}
              >
                <img src={icon.calendar} alt="" id="fac_sign_date_d" />
              </div>
            </div>
            {openDateSign && (
              <DatePicker
                selected={selectedDate.sign}
                onChange={onChangeDateSign}
                inline
              />
            )}
            {validErr && (
              <p className={`${styles.err_message}`}>
                {CheckDataStDateEn(_data.signDate, _data.endDate)}
              </p>
            )}
          </div>
          <div className={`${styles.input_2}`}>
            <h5>
              วันสิ้นสุดสัญญา PPA <sup className={`${styles.asterisk}`}>*</sup>
            </h5>
            <div className={`${styles.input_unit}`}>
              <div className={`${styles.info_box}`}>
                <p>
                  {_data?.endDate !== "" ? convertDateThai(_data?.endDate) : ""}
                </p>
              </div>
              <div
                className={`${styles.unit}`}
                onClick={() => {
                  setOpenDateEnd(!openDateEnd);
                }}
              >
                <img src={icon.calendar} alt="" id="fac_end_date_d" />
              </div>
            </div>
            {openDateEnd && (
              <DatePicker
                selected={selectedDate.end}
                onChange={onChangeDateEnd}
                inline
              />
            )}
            {validErr && (
              <p className={`${styles.err_message}`}>
                {CheckDateEnDataSt(_data.endDate, _data.signDate)}
              </p>
            )}
          </div>
        </div>
        <div className={`${styles.input_flex}`}>
          <div className={`${styles.input_1}`}>
            <h5>
              การไฟฟ้าคู่สัญญา <sup className={`${styles.asterisk}`}>*</sup>
            </h5>
            <div className={`${styles.auto_com_box}`}>
              <Autocomplete
                inputProps={{
                  className: `${styles.input_box} ${
                    validErr && _data.buyer === "" ? "error" : ""
                  }`,
                }}
                wrapperStyle={{ fontSize: "16px" }}
                items={buyerArr}
                shouldItemRender={(item, value) =>
                  item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
                }
                getItemValue={(item) => item.name}
                renderItem={(item, highlighted) => (
                  <div
                    key={item.id}
                    style={{
                      backgroundColor: highlighted ? "#eee" : "transparent",
                    }}
                  >
                    {item.name}
                  </div>
                )}
                value={_data.buyer}
                onChange={(e) => setData({ ..._data, buyer: e.target.value })}
                onSelect={(name) => setData({ ..._data, buyer: name })}
              />
              <span>และ</span>
            </div>
            {validErr && (
              <p className={`${styles.err_message}`}>
                {CheckValidLength(_data.buyer)}
              </p>
            )}
          </div>
          <div className={`${styles.input_2}`}>
            <h5>ผู้ผลิตไฟฟ้าคู่สัญญา</h5>
            <input
              type="text"
              name="factoryId"
              value={_data?.factory?.name || ""}
              readOnly
            />
          </div>
        </div>
        <div className={`${styles.input_flex}`}>
          <div className={`${styles.input_1}`}>
            <h5>
              ระยะเวลาสัญญา <sup className={`${styles.asterisk}`}>*</sup>
            </h5>
            <div
              className={`${styles.input_unit} ${
                validErr && _data.period === "" ? "error" : ""
              }`}
            >
              <input
                type="text"
                name="period"
                value={_data?.period || ""}
                onChange={onChange}
              />
              <div className={`${styles.unit}`}>
                <span style={{ display: "block" }}>ปี</span>
              </div>
            </div>
            {validErr && (
              <p className={`${styles.err_message}`}>
                {CheckNumber(_data.period)}
              </p>
            )}
          </div>
          <div className={`${styles.input_2}`}>
            <h5>
              ปริมาณไฟฟ้าเสนอขาย <sup className={`${styles.asterisk}`}>*</sup>
            </h5>
            <div
              className={`${styles.input_unit} ${
                validErr && _data.amount === "" ? "error" : ""
              }`}
            >
              <input
                type="text"
                name="amount"
                value={String(_data?.amount || "")}
                onChange={onChange}
              />
              <div className={`${styles.unit}`}>
                <span>MW</span>
              </div>
            </div>
            {validErr && (
              <p className={`${styles.err_message}`}>
                {CheckNumberDot(_data.amount)}
              </p>
            )}
          </div>
        </div>
        <div className={`${styles.input_flex}`}>
          <div className={`${styles.input_1}`}>
            <div className={`${styles.input_file}`}>
              <h5>
                สัญญาฉบับจริง <sup className={`${styles.asterisk}`}>*</sup>
              </h5>
              <div className={`${styles.input_unit} ${styles.mb_0}`}>
                <div className={`${styles.info_box}`}>
                  <p>
                    {pdfName !== ""
                      ? pdfName
                      : _data?.ppaDocNavigation?.fileName}
                  </p>
                </div>
                <div className={`${styles.unit}`}>
                  <img
                    src={icon.document}
                    alt=""
                    title="แนบไฟล์"
                    onClick={() =>
                      document.querySelector("#file-label").click()
                    }
                  />
                </div>
              </div>
              <span className={`${styles.desc_text}`}>
                *ระบบรองรับไฟล์ที่มีนามสกุล .pdf และมีขนาดไม่เกิน 5 MB
              </span>
              <input
                type="file"
                name="ppaDoc"
                id="add_file"
                accept="application/pdf"
                onChange={updateFileDetail}
                hidden
              />
              <label htmlFor="add_file" id="file-label" hidden></label>
            </div>
          </div>
          <div className={`${styles.input_2}`}>
            <h5>
              SCOD <sup className={`${styles.asterisk}`}>*</sup>
            </h5>
            <div className={`${styles.input_unit}`}>
              <div className={`${styles.info_box}`}>
                <p>{_data?.scod !== "" ? convertDateThai(_data?.scod) : ""}</p>
              </div>
              <div
                className={`${styles.unit}`}
                onClick={() => {
                  setOpenDateScod(!openDateScod);
                }}
              >
                <img src={icon.calendar} alt="" id="fac_scod_date_d" />
              </div>
            </div>
            {openDateScod && (
              <DatePicker
                selected={selectedDate.scod}
                onChange={onChangeDateScod}
                inline
              />
            )}
          </div>
        </div>
        <div className={`${styles.input_flex}`}>
          <div className={`${styles.input_1}`}>
            <h5>
              COD <sup className={`${styles.asterisk}`}>*</sup>
            </h5>
            <div className={`${styles.input_unit}`}>
              <div className={`${styles.info_box}`}>
                <p>{_data?.cod !== "" ? convertDateThai(_data?.cod) : ""}</p>
              </div>
              <div
                className={`${styles.unit}`}
                onClick={() => {
                  setOpenDateCod(!openDateCod);
                }}
              >
                <img src={icon.calendar} alt="" id="fac_scod_date_d" />
              </div>
            </div>
            {openDateCod && (
              <DatePicker
                selected={selectedDate.cod}
                onChange={onChangeDateCod}
                inline
              />
            )}
          </div>
          <div className={`${styles.input_2}`}></div>
        </div>
      </form>

      <div className={`${styles.button_right}`}>
        <button
          type="button"
          className={`${styles.btn_}`}
          onClick={updateContractPpa}
        >
          บันทึก
        </button>
        <button
          type="button"
          className={`${styles.btn_}`}
          onClick={() => _onSwitch(false)}
        >
          ยกเลิก
        </button>
      </div>
    </>
  );
};

export default EditForm;
