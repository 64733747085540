import React from "react";
import HighCharts from "../../../../components/Highcharts/HighCharts";
import styles from '../scss/ProduceRateInfo.module.scss';
import { formatterTooltip, formatterXaxis_1, formatterYaxis } from "../../../../helpers/suffixchart.helper";



const ChartsProduceRate = React.forwardRef((prop, ref) => {

  let ranges = [
    { divider: 1e18, suffix: "E" },
    { divider: 1e15, suffix: "P" },
    { divider: 1e12, suffix: "T" },
    { divider: 1e9, suffix: "G" },
    { divider: 1e6, suffix: "M" },
    { divider: 1e3, suffix: "K" },
  ];


  let produce = []
  let sell = []

  try {

    let date = new Date()
    let dfyears = date.getFullYear()
    let dfmonths = date.getMonth() + 1
    let year = Number(prop.datetime.year)

    let i = prop.datain
    let x0 = i.factoryPowerGenerated
    let x1 = i.factoryPpaTransaction


    for (let index = 0; index < x0.length; index++) {

      if (year === dfyears) {
        if (x0[index].month <= dfmonths) {
          produce[index] = x0[index]?.value || 0
        } else if (x0[index].month > dfmonths) {
          produce[index] = null
        }
      } else if (year < dfyears) {
        produce[index] = x0[index]?.value || 0
      }

    }

    for (let index = 0; index < x1.length; index++) {

      if (year === dfyears) {
        if (x1[index].month <= dfmonths) {
          sell[index] = x1[index]?.value || 0
        } else if (x0[index].month > dfmonths) {
          sell[index] = null
        }
      } else if (year < dfyears) {
        sell[index] = x1[index]?.value || 0
      }


    }

  } catch (error) {

  }

  const options = {
    chart: {
      type: "column",
      // type: "line",
      height: 400,
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: [
        "ม.ค.",
        "ก.พ.",
        "มี.ค.",
        "เม.ย.",
        "พ.ค.",
        "มิ.ย.",
        "ก.ค.",
        "ส.ค.",
        "ก.ย.",
        "ต.ค.",
        "พ.ย.",
        "ธ.ค.",
      ],
    },
    yAxis: {
      labels: {
        enabled: true,
        formatter: formatterYaxis,
      },
      title: {
        text: "ปริมาณการผลิตไฟฟ้า (kWh)",
      },
    },
    tooltip: {
      //pointFormat: "<b>{series.name}: {point.y} MW</b>",
      style: {
        fontSize: "18px",
      },
      enabled: true,
      ...formatterTooltip
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          // format: "{point.y}",
          style: {
            fontSize: "16px",
          },
          formatter: formatterXaxis_1,
          // formatter: function () {
          //   for (let i = 0; i < ranges.length; i++) {
          //     if (this.y >= ranges[i].divider) {
          //       return (
          //         Number(this.y / ranges[i].divider).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + ranges[i].suffix
          //       );
          //     }
          //   }
          //   return Number(this.y).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
          // },
        },
      },

      series: {
        enableMouseTracking: true,
        marker: {
          enabled: false
        },
        dataLabels: {
          enabled: true,
          formatter: function () {
            for (let i = 0; i < ranges.length; i++) {
              if (this.y >= ranges[i].divider) {
                return (
                  Number(this.y / ranges[i].divider).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + ranges[i].suffix
                );
              }
            }
            if (Number(this.y) === 0) {
              return null
              //return this.y.toString();
            } else {
              return Number(this.y).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
            }
          },
          style: {
            fontSize: "16px",
          },
        }
      },
    },
    exporting: {
      chartOptions: { // specific options for the exported image
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true,
              style: {
                fontSize: "6px",
              },
              formatter: function () {
                for (let i = 0; i < ranges.length; i++) {
                  if (this.y >= ranges[i].divider) {
                    return (
                      Number(this.y / ranges[i].divider).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + ranges[i].suffix
                    );
                  }
                }
                if (Number(this.y) === 0) {
                  return null
                  //return this.y.toString();
                } else {
                  return Number(this.y).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
                }
              },
            },
            enableMouseTracking: true,
          },
        },
        xAxis: {
          labels: {
            style: {
              fontSize: '6px'
            },
          }
        },
        yAxis: {
          labels: {
            style: {
              fontSize: '6px'
            },
          }
        },
      },
      buttons: {
        contextButton: {
          enabled: false,
        },
        button: {
          text: "Export",
          theme: {
            fill: "#f9df70",
            stroke: "#f9df70",
            states: {
              hover: {
                fill: "#fcc",
                stroke: "#f00",
              },
              select: {
                fill: "#cfc",
                stroke: "#0f0",
              },
            },
          },
          menuItems: [
            "viewFullscreen",
            "separator",
            "downloadPNG",
            "downloadSVG",
            "downloadPDF",
            "separator",
            "downloadXLS",
          ],
        },
      },
      enabled: false,
    },
    navigation: {
      buttonOptions: {
        align: "right",
        verticalAlign: "bottom",
        y: 0,
      },
    },
    series: [
      {
        name: "ปริมาณการผลิตจริง",
        data: produce,
        color: "magenta",
      },
      {
        name: "ปริมาณการขาย",
        data: sell,
        color: "orange",
      },
    ],
  };
  return (
    <div className={`${styles.charts}`}>
      <HighCharts ref={ref} options={options} />
    </div>
  );
});

export default ChartsProduceRate;
