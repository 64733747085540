import $ from "jquery";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { defaultApi } from "../../../services/api";
import ChartsProducePlant from "./charts/ChartsProducePlant";
import ChartsProducePlant1 from "./charts/ChartsProducePlant1";
import ExportPopup from "../../../layouts/exportpopup/ExportPopup";
import { convertDateThai2 } from "../../../utils/format/Date.format";
import styles from "./scss/ProducePlantInfo.module.scss";
import { QueryGetAll } from "../../../helpers/api.helper";

let enterpriseList = [];

const ProducePlantInfo = ({ swCotent, _theme, barFact, typeFact }) => {
  const chart = useRef();
  const [_triggerExport, setTriggerExport] = useState(false);

  const [_entLst, setEntLst] = useState();
  const [_resYear, setResYear] = useState(null);
  const [_resMonth, setResMonth] = useState(null);

  const [_activeSelect, setActiveSelect] = useState(false)

  const [_valueRes, setValueRes] = useState({
    entpid: "",
    factid: "",
    facttype: "",
    day: "",
    month: "",
    year: "",
    plant: ""
  })

  const downloadCSV = () => {
    // if (chart && chart.current && chart.current.chart) {
    //   chart.current.chart.downloadCSV();
    // }
    if (chart && chart.current && chart.current.chart) {
      chart.current.chart.downloadCSV();
    }
  };

  const downloadImage = () => {
    if (chart && chart.current && chart.current.chart) {
      chart.current.chart.setTitle({
        text: "แผนผลการผลิตเชื้อเพลิง",
      });
      chart.current.chart.setSubtitle({
        text: checkWordTitle
      });
      // chart.current.chart.setSubtitle({
      //   text: allEn === "เลือกกลุ่มเกษตรกร" || allEn === "" ? "ทั้งหมด" : allEn,
      // });

      chart.current.chart.exportChart({
        type: "image/jpeg",
        filename: "chart",
      });

      chart.current.chart.setTitle({ text: "" });
      chart.current.chart.setSubtitle({ text: "" });
    }
  };

  const apiItem = async (barFact) => {

    let strquery = ""

    if (+typeFact?.id !== -99 && +barFact?.id !== -99) {
      strquery = `&$filter=factory/factoryTypeId eq ${typeFact?.id} and factory/id eq ${barFact?.id}`
    } else if (+typeFact?.id === -99 && +barFact?.id !== -99) {
      strquery = `&$filter=factory/id eq ${barFact?.id}`
    } else if (+typeFact?.id !== -99 && +barFact?.id === -99) {
      strquery = `&$filter=factory/factoryTypeId eq ${typeFact?.id}`
    } else {
      strquery = ""
    }

    let _query = {
      $count: "",
      $expand: "",
      $top: "",
      $skip: ``,
      $filter: `${strquery}`,
      $orderby: "",
    };

    const query = QueryGetAll(_query);

    const res_ent = await defaultApi.getAllEnterprise(query);
    const res_allplant = await defaultApi.getAllPlantType();

    for (let index = 0; index < res_ent.data.length; index++) {
      const element = res_ent.data[index];
      enterpriseList[index] = { id: element.id, name: element.name };
    }
    setEntLst(enterpriseList);

    let date = new Date();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    let factid = +barFact?.id === -99 ? "" : +barFact?.id
    let facttype = +typeFact?.id === -99 ? "" : +typeFact?.id

    setValueRes((prev) => ({
      ...prev,
      entpid: "",
      factid: factid,
      facttype: facttype,
      day: prev?.day ? prev?.day : date,
      month: prev?.month ? prev?.month : month,
      year: prev?.year ? prev?.year : Number(year),
      plant: res_allplant
    }))


    enterpriseList = [];
  };

  const checkWordTitle = useMemo(() => {
    const entpId = _valueRes?.entpid
    const factId = _valueRes?.factid
    const factTp = _valueRes?.facttype

    let formattname = "กลุ่มวิสาหกิจทั้งหมด";

    if (factTp) {
      formattname = `กลุ่มวิสาหกิจที่อยู่ภายใต้โรงไฟฟ้าประเภท${typeFact?.name}`
    }
    if (factId) {
      formattname = `กลุ่มวิสาหกิจที่อยู่ภายใต้${barFact?.name}`
    }
    if (entpId) {
      formattname = `${_entLst?.find((item) => +item?.id === +entpId)?.name || ""}`
    }

    return formattname
  }, [_valueRes, barFact, typeFact, _entLst])

  const entpLst = (pin) => {
    let x = null;
    try {
      x = _entLst.map((datax, index) => {
        let key = pin + index;
        return (
          <option key={key} value={datax.id}>
            {datax.name}
          </option>
        );
      });

      return (
        <>
          <option key={`df_${pin}`} value={""}>
            เลือกกลุ่มเกษตรกร
          </option>
          {x}
        </>
      );
    } catch (error) { }
  };

  const selectMonth = (pin) => {
    let monthNameLst = [
      "เดือน",
      "ม.ค.",
      "ก.พ.",
      "มี.ค.",
      "เม.ย.",
      "พ.ค.",
      "มิ.ย.",
      "ก.ค.",
      "ส.ค.",
      "ก.ย.",
      "ต.ค.",
      "พ.ย.",
      "ธ.ค.",
    ];

    return monthNameLst.map((datax, index) => <option key={`${pin}_${index}`} value={index === 0 ? "" : index}>
      {datax}
    </option>);
  };

  const selectYear = (pin) => {
    const date = new Date();
    let year = date.getFullYear();
    let yearLst = [];
    yearLst.push({ yearen: year-1, yearth: year-1 + 543 });
    for (let index = 0; index < 20; index++) {
      yearLst.push({ yearen: year, yearth: year + 543 });
      year = year + 1;
    }

    return yearLst.map((datax, index) => <option key={`${pin}_${index}`} value={datax.yearen}>
      {datax.yearth}
    </option>);
  };

  const resSearchMonth = useCallback(async () => {
    try {
      let entp = `enterpriseId=${_valueRes?.entpid}`
      let mont = `&atMonth=${_valueRes?.month}`
      let year = `&atYear=${_valueRes?.year}`
      let factid = `&factoryId=${_valueRes?.factid}`
      let facttype = `&factoryTypeId=${_valueRes?.facttype}`
      let monthParame = `${entp}${mont}${year}${factid}${facttype}`

      const res = await defaultApi.getreportEnterpriseTransactionMonth(
        monthParame
      );
      setResMonth({...res?.data, plant:_valueRes?.plant?.data});
    } catch (error) {

    }
  }, [_valueRes])

  const resSearchYear = useCallback(async () => {
    try {
      let entp = `enterpriseId=${_valueRes?.entpid}`
      let year = `&atYear=${_valueRes?.year}`
      let factid = `&factoryId=${_valueRes?.factid}`
      let facttype = `&factoryTypeId=${_valueRes?.facttype}`
      let yearParame = `${entp}${year}${factid}${facttype}`

      const res = await defaultApi.getreportEnterpriseTransactionYear(
        yearParame
      );
      setResYear({...res?.data, plant:_valueRes?.plant?.data});
    } catch (error) {

    }
  }, [_valueRes])

  const dateForm = useMemo(() => {
    if (swCotent === "31") {
      if (_activeSelect) {
        return `${convertDateThai2(
          _valueRes?.month.toString(),
          "month"
        )}/${convertDateThai2(_valueRes?.year.toString(), "year")}`
      } else {

        return `${convertDateThai2(_valueRes?.day, "day")}/${convertDateThai2(
          _valueRes?.month.toString(),
          "month"
        )}/${convertDateThai2(_valueRes?.year.toString(), "year")}`
      }
    }
    if (swCotent === "32") {
      return `${convertDateThai2(_valueRes?.year.toString(), "year")}`
    }
  }, [swCotent, _valueRes, _activeSelect])

  useEffect(() => {
    resSearchMonth()
  }, [resSearchMonth])

  useEffect(() => {
    resSearchYear()
  }, [resSearchYear])

  useEffect(() => {
    apiItem(barFact, typeFact);
  }, [barFact, typeFact]);

  if (swCotent === "31") {
    window.ChartTitleUnit = {
      title: 'วันที่',
      unit: ['ตัน'],
      totalStatus : true
    }
    return (
      <div className={`${styles.container}`}>
        <div className={`${styles.boxcontainer}`}>
          <div className={`${styles.drop_filter}`}>
            {/* <label>กลุ่มเกษตรกร :</label> */}
            <select
              style={{ color: "black" }}
              name=""
              id="sel01"
              onChange={(e) => {
                setValueRes((prev) => ({ ...prev, entpid: e?.target?.value }))
              }}
              value={_valueRes.entpid === "" ? "" : _valueRes.entpid}
            >
              {entpLst(1)}
            </select>
          </div>
          <div className={`${styles.drop_filter}`}>
            <select
              style={{ color: "black" }}
              name=""
              id="sel02"
              onChange={(e) => {
                setValueRes((prev) => ({ ...prev, month: e?.target?.value }))
                setActiveSelect(true)
              }}
              value={_valueRes.month === "" ? "" : _valueRes.month}
            >
              {selectMonth(1)}
            </select>
          </div>
          <div className={`${styles.drop_filter}`}>
            <select
              style={{ color: "black" }}
              name=""
              id="sel03"
              onChange={(e) => {
                setValueRes((prev) => ({ ...prev, year: e?.target?.value }))
                setActiveSelect(true)
              }}
              value={_valueRes.year === "" ? "" : _valueRes.year}
            >
              {selectYear(1)}
            </select>
          </div>
        </div>

        <h4
          className={`${styles.title_text}`}
          style={{ background: _theme?.backgroundColor }}
        >
          แผนผลการผลิตเชื้อเพลิง
        </h4>
        <h4 id="subtitle01" className={`${styles.subtitle_text}`}>
          {checkWordTitle}
        </h4>

        <div className={`${styles.show_date}`}>
          <p>ข้อมูล ณ</p>
          <p>{dateForm}</p>
        </div>

        <div className={`${styles.charts_}`}>
          <ChartsProducePlant1
            ref={chart}
            datain={_resMonth}
            datetime={_valueRes}
          />
        </div>

        <div className={`${styles.export_}`}>
          <ExportPopup
            label={"report"}
            trigger={_triggerExport}
            event={{ csv: downloadCSV, image: downloadImage }}
          />
          <button
            id="exporting01"
            type="button"
            className={`${styles.btn_}`}
            onClick={() => {
              setTriggerExport(!_triggerExport);
            }}
          >
            Export
          </button>
        </div>
      </div>
    );
  }

  if (swCotent === "32") {
    window.ChartTitleUnit = {
      title: 'เดือน',
      unit: ['ตัน'],
      totalStatus : true
    }
    return (
      <div className={`${styles.container}`}>
        <div className={`${styles.boxcontainer}`}>
          <div className={`${styles.drop_filter}`}>
            <select
              style={{ color: "black" }}
              name=""
              id="sel04"
              onChange={(e) => {
                setValueRes((prev) => ({ ...prev, entpid: e?.target?.value }))
              }}
              value={_valueRes.entpid === "" ? "" : _valueRes.entpid}
            >
              {entpLst(2)}
            </select>
          </div>
          <div className={`${styles.drop_filter}`}>
            <select
              style={{ color: "black" }}
              name=""
              id="sel05"
              onChange={(e) => {
                setValueRes((prev) => ({ ...prev, year: e?.target?.value }))
              }}
              value={_valueRes.year === "" ? "" : _valueRes.year}
            >
              {selectYear(2)}
            </select>
          </div>
        </div>

        <h4
          className={`${styles.title_text}`}
          style={{ background: _theme?.backgroundColor }}
        >
          แผนผลการผลิตเชื้อเพลิง
        </h4>
        <h4 id="subtitle02" className={`${styles.subtitle_text}`}>
          {checkWordTitle}
        </h4>

        <div className={`${styles.show_date}`}>
          <p>ข้อมูลล่าสุด ณ ปีที่</p>
          <p>{dateForm}</p>
        </div>

        <div className={`${styles.charts_}`}>
          <ChartsProducePlant
            ref={chart}
            datain={_resYear}
            datetime={_valueRes}
          />
        </div>

        <div className={`${styles.export_}`}>
          <ExportPopup
            label={"report"}
            trigger={_triggerExport}
            event={{ csv: downloadCSV, image: downloadImage }}
          />
          <button
            id="exporting02"
            type="button"
            className={`${styles.btn_}`}
            onClick={() => {
              setTriggerExport(!_triggerExport);
            }}
          >
            Export
          </button>
        </div>
      </div>
    );
  }
};

export default ProducePlantInfo;
