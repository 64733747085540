import React, { useState, useEffect, useCallback, useRef } from "react";
import { Overlay } from "ol";
import icon from "../../../assets/img/Icon";
import { defaultApi } from "../../../services/api";
import { QueryGetAll } from "../../../helpers/api.helper";
import { convertCoordinates } from "../../../helpers/map.helper";
import styles from "./EnterprisePopup.module.scss";

const EnterprisePopup = ({
  _map,
  _allEnterprise,
  _setLayerType,
  _setCoordFeature,
  _fetchEntId,
}) => {
  const container = useRef([]);

  const [allFarmer, setAllFarmer] = useState([]);

  const addRefArr = (el) => {
    if (el && !container.current.includes(el)) {
      container.current.push(el);
    }
  };
  const entIcon = (id) => {
    let image;
    if (1 === id) {
      image = icon.index_2;
    } else if (2 === id) {
      image = icon.index_3;
    } else if (3 === id) {
      image = icon.group10;
    } else if (4 === id) {
      image = icon.group11;
    }
    return image;
  };
  const allFarmerC = (id) => {
    let count;
    allFarmer.forEach((item) => {
      if (item.entId === id) {
        count = item.data.length;
      }
    });
    return count;
  };

  const onPopupClick = (obj) => {
    // Set detail type
    _setLayerType("enterprise");
    // Set coordi เอาไปทำ map center ตรง feature ที่คลิก
    _setCoordFeature([obj.lng, obj.lat]);
    // Get ent data by id
    _fetchEntId(obj.entId);
  };

  const getAllFarmer = useCallback(async () => {
    let arr = [];
    for (let i = 0; i < _allEnterprise.length; i++) {
      const entId = _allEnterprise[i].id;
      const qryGetAll = {
        $count: "%24count=true",
        $expand: "",
        $top: "",
        $skip: "",
        $filter: `&%24filter=enterpriseId%20eq%20${entId}`,
        $orderby: "",
      };
      const _queryAll = QueryGetAll(qryGetAll);
      try {
        const res = await defaultApi.getAllFarmer(_queryAll);
        const newObj = { entId: entId, data: res.data.datas };
        arr[i] = newObj;
      } catch (error) {
        console.log(error);
      }
    }
    setAllFarmer(arr);
  }, [_allEnterprise]);

  useEffect(() => {
    /* Create overlay layer : START */
    for (let i = 0; i < _allEnterprise.length; i++) {
      const el = container.current[i];
      const overlay = new Overlay({
        element: el,
        position: convertCoordinates(
          _allEnterprise[i].lng,
          _allEnterprise[i].lat
        ),
        stopEvent: false,
      });
      _map?.addOverlay(overlay);
    }
    /* Create overlay layer : END */
  }, [_map, _allEnterprise]);

  useEffect(() => {
    // Use functions
    getAllFarmer();
  }, [getAllFarmer]);

  return _allEnterprise.map((item, index) => {
    return (
      <div
        id={`popup_ent${index}`}
        className={`ol_popup ${styles.ol_popup}`}
        ref={addRefArr}
        key={item.id}
        onClick={() => {
          let obj = { entId: item.id, lng: item.lng, lat: item.lat };
          onPopupClick(obj);
        }}
      >
        <div
          id="ent_popup_content"
          className={`popup_content ${styles.popup_content}`}
        >
          <div className={`${styles.img_icon}`}>
            <img src={entIcon(item.enterpriseTypeId)} alt="" />
          </div>
          <div className={`${styles.text_content}`}>
            <div>
              <p id="ent_text_name">{item.name}</p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "5px",
              }}
            >
              <p id="ent_text_details">{allFarmerC(item.id)}</p>
              <span>คน</span>
            </div>
          </div>
        </div>
      </div>
    );
  });
};

export default EnterprisePopup;
