import { useState } from "react";

const useSwitchContent = () => {
  const [switchContent, setSwitchContent] = useState({
    type: "1",
  });
  const onClickType = (el) => {
    setSwitchContent({ type: el });
  };
  return [switchContent, onClickType];
};

export default useSwitchContent;
