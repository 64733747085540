import React, { useCallback, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import icon from "../../../assets/img/Icon";
import { QueryGetAll } from "../../../helpers/api.helper";
import { defaultApi } from "../../../services/api";
import { getProfileData } from "../../../services/auth.slice";
import {
  convertDateEu,
  convertDateThai,
} from "../../../utils/format/Date.format";
import ChartsFarmland from "./charts/ChartsFarmland";
import ExportPopup from "../../../layouts/exportpopup/ExportPopup";
import styles from "./scss/ReportFarmland.module.scss";

let dateDf = "";

const ReportFarmland = ({ themeColor }) => {
  const chart = useRef();

  const [date, setDate] = useState("");
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [_fstLoad, fstLoad] = useState(true);

  const [_setKeyFact, keyFact] = useState({
    entpId: "",
    factId: "",
  });

  //const [_setDfset, setDfset] = useState(null)
  let _setDfset = null;

  const [_resDate, resDate] = useState(null);
  const [_resName, resName] = useState(null);
  const [_resTotal, resTotal] = useState(0);

  const [_colors, SetColor] = useState({});

  const onChangeDateStartL = (e) => {
    const dateFormat = convertDateEu(e);
    setDate(dateFormat);
    setOpenDatePicker(!openDatePicker);

    iSearch(dateFormat);
  };

  const [_triggerExport, setTriggerExport] = useState(false);

  const downloadCSV = () => {
    // if (chart && chart.current && chart.current.chart) {
    //   chart.current.chart.downloadCSV();
    // }
    if (chart && chart.current && chart.current.chart) {
      chart.current.chart.downloadCSV();
    }
  };

  const downloadImage = () => {
    if (chart && chart.current && chart.current.chart) {
      chart.current.chart.setTitle({
        text: "พื้นที่การเพาะปลูกรวมของพืชแต่ละชนิด",
      });
      chart.current.chart.setSubtitle({ text: _resName });

      chart.current.chart.exportChart({
        type: "image/jpeg",
        filename: "chart",
      });

      chart.current.chart.setTitle({ text: "" });
      chart.current.chart.setSubtitle({ text: "" });
    }
  };

  const profileData = useSelector(getProfileData);

  const info = useCallback(async () => {
    let qryGetAll = {
      $count: "%24count=true",
      $expand: "",
      $top: "",
      $skip: "",
      $filter: `&%24filter=createdBy%20eq%20${profileData.id}`,
      $orderby: "",
    };

    const resAllEnp = await defaultApi.getAllEnterpriseMember(``);

    if (resAllEnp?.data?.length > 0) {
      for (let index = 0; index < resAllEnp?.data?.length; index++) {
        const element = resAllEnp?.data[index];
        if (+element?.userId === +profileData?.id) {
          qryGetAll = {
            $count: "%24count=true",
            $expand: "",
            $top: "",
            $skip: "",
            $filter: `&%24filter=id%20eq%20${element.enterpriseId}`,
            $orderby: "",
          };
        }
      }
    }

    const _query = QueryGetAll(qryGetAll);
    const res_entp = await defaultApi.getAllEnterprise(_query);
    const entpId = res_entp.data.datas[0]?.id;
    const factId = res_entp.data.datas[0]?.factory?.id;
    keyFact({
      entpId: entpId,
      factId: factId,
    });

    let date = new Date();
    //let month = date.getMonth()
    let year = date.getFullYear();

    // const dfset = await defaultApi.getReportDashboard("powergenYear=" + year + "&factoryTransactionYear=" + year + "&enterpriseTransactionYear=" + year)
    const res = await defaultApi.getReportPlantingArea(
      "factoryId=" +
        factId +
        "&enterpriseId=" +
        entpId +
        "&atDate=" +
        date.toISOString().split("T")[0] +
        "&toDate=" +
        date.toISOString().split("T")[0]
    );
    const colors = await defaultApi.getColors();
    SetColor(colors);
    // setDfset(dfset.data)
    resDate(res.data.plantingArea);
    dateDf = date.toISOString().split("T")[0];

    let i = 0;
    for (let index = 0; index < res.data.plantingArea.length; index++) {
      const element = res.data.plantingArea[index].area;
      i = i + element;
    }
    resTotal(i);

    resName(res_entp.data.datas[0].name);
  }, [profileData]);

  const iSearch = async (dateSel) => {
    let i = 0;
    let years = null;
    years = dateSel.split("-");
    years = years[0];

    // const dfset = await defaultApi.getReportDashboard("powergenYear=" + years + "&factoryTransactionYear=" + years + "&enterpriseTransactionYear=" + years)
    const res = await defaultApi.getReportPlantingArea(
      "factoryId=" +
        _setKeyFact.factId +
        "&enterpriseId=" +
        _setKeyFact.entpId +
        "&atDate=" +
        dateSel +
        "&toDate=" +
        dateSel
    );
    // setDfset(dfset.data)
    resDate(res.data.plantingArea);
    for (let index = 0; index < res.data.plantingArea.length; index++) {
      const element = res.data.plantingArea[index].area;
      i = i + element;
    }
    resTotal(i);
  };

  useEffect(() => {
    if (_fstLoad === true) {
      info();
      fstLoad(false);
    }
  }, [_fstLoad, info]);

  useEffect(() => {
    /* Function close dropdown 'DatePicker' : START */
    const onCloseDropdownDatePicker = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#ent_datepicker_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenDatePicker(false);
      }
    };

    if (openDatePicker) {
      document.addEventListener("click", onCloseDropdownDatePicker);
    }
    /* Function close dropdown 'DatePicker' : END */

    return () => {
      document.removeEventListener("click", onCloseDropdownDatePicker);
    };
  }, [openDatePicker]);

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.date_filter}`}>
        <div className={`${styles.group_}`}>
          <div className={styles.input_unit}>
            <div className={styles.info_box}>
              <p style={{ color: "black" }}>
                {date !== "" ? convertDateThai(date) : "วันที่"}
              </p>
            </div>
            <div
              className={styles.unit}
              onClick={() => {
                setOpenDatePicker(!openDatePicker);
              }}
            >
              <img src={icon.calendar} alt="" id="ent_datepicker_d" />
            </div>
          </div>
          {openDatePicker && (
            <DatePicker onChange={onChangeDateStartL} inline />
          )}
        </div>
      </div>

      <h4
        className={`${styles.title_text}`}
        style={{ background: themeColor.backgroundColor }}
      >
        พื้นที่การเพาะปลูกรวมของพืชแต่ละชนิด
      </h4>

      <h5 className={`${styles.title_text_}`}>{_resName}</h5>

      <div className={`${styles.charts_} row mx-auto`}>
        <div className={`${styles.left_} col-9`}>
          <ChartsFarmland
            ref={chart}
            colors={_colors}
            dfset={_setDfset}
            datain={_resDate}
          />
        </div>
        <div className={`${styles.right_} col-3`}>
          <div
            style={
              date === "" && dateDf === ""
                ? { display: "none" }
                : { display: "block" }
            }
          >
            <p>
              {date === "" ? convertDateThai(dateDf) : convertDateThai(date)}
            </p>
            <p>
              พื้นที่กำลังเพาะปลูกทั้งหมด{" "}
              {Number(_resTotal).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}{" "}
              ไร่
            </p>
          </div>
        </div>
      </div>

      <div className={`${styles.export_}`}>
        <ExportPopup
          label={"report"}
          trigger={_triggerExport}
          event={{ csv: downloadCSV, image: downloadImage }}
        />
        <button
          id="exporting"
          type="button"
          className={`${styles.btn_}`}
          onClick={() => {
            setTriggerExport(!_triggerExport);
          }}
        >
          Export
        </button>
      </div>
    </div>
  );
};

export default ReportFarmland;
