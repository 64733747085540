import React, { useEffect } from "react";
import styles from "./scss/SwitchMenu.module.scss";

const SwitchMenu = ({ _onSwitch3, btn1, btn2 }) => {
  useEffect(() => {
    switchMenuActive();
  }, []);

  return (
    <div className={`${styles.container}`}>
      <button className="btn_menu active" onClick={() => _onSwitch3(false)}>
        {btn1}
      </button>
      <button className="btn_menu" onClick={() => _onSwitch3(true)}>
        {btn2}
      </button>
    </div>
  );
};

const switchMenuActive = () => {
  const btn = document.querySelectorAll(".btn_menu");

  btn.forEach((item) => {
    item.addEventListener("click", () => {
      btn.forEach((item) => item.classList.remove("active"));
      item.classList.add("active");
    });
  });
};

export default SwitchMenu;
