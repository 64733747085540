import React from "react";
import Footer from "../../../layouts/footer_2/Footer";
import Header from "../../../layouts/header/Header";
import Navbar from "../../../layouts/navbar/Navbar";
import useSwitchChecked_1 from "../../../utils/hooks/useSwitchChecked_1";
import useSwitchPath from "../../../utils/hooks/useSwitchPath";
import MenuBar from "./MenuBar";
import ReportMonthCostAndIncome from "./ReportMonthCostAndIncome";
import ReportMonthProductionCapacity from "./ReportMonthProductionCapacity";
import ReportMonthProductionPlan from "./ReportMonthProductionPlan";
import ReportMonthSellingPlants from "./ReportMonthSellingPlants";
import ReportPlantationArea from "./ReportPlantationArea";
import ReportYearCostAndIncome from "./ReportYearCostAndIncome";
import ReportYearProductionCapacity from "./ReportYearProductionCapacity";
import ReportYearProductionPlan from "./ReportYearProductionPlan";
import ReportYearSellingPlants from "./ReportYearSellingPlants";
import styles from "./scss/OfficerReport.module.scss";
import ReportResourceHarvestFactory from "./ReportResourceHarvestFactory";

const OfficerReport = () => {
  const [_location, navigate] = useSwitchPath();
  const [
    switchChecked,
    onSwitchChecked,
    switchChecked2,
    onSwitchChecked2,
    switchChecked3,
    onSwitchChecked3,
    switchChecked4,
    onSwitchChecked4,
  ] = useSwitchChecked_1();

  return (
    <div className={`${styles.container} background_1`}>
      <Header />
      <section className={`section ${styles.section_} mx-auto`}>
        <Navbar />
        <article className={`article ${styles.article_} row mx-auto`}>
          <aside className={`${styles.menu_bar} col-3`}>
            <MenuBar
              switchChecked={switchChecked}
              onSwitchChecked={onSwitchChecked}
              switchChecked2={switchChecked2}
              onSwitchChecked2={onSwitchChecked2}
              switchChecked3={switchChecked3}
              onSwitchChecked3={onSwitchChecked3}
              switchChecked4={switchChecked4}
              onSwitchChecked4={onSwitchChecked4}
              _location={_location}
              _navigate={navigate}
            />
          </aside>
          <aside className={`${styles.content_} col-9`}>
            <div className={`${styles.content_box}`}>
              {_location === "/report" && <ReportPlantationArea />}

              {/* {_location === "/report/production-plan" &&
                switchChecked === "1" && <ReportMonthProductionPlan />}
              {_location === "/report/production-plan" &&
                switchChecked === "2" && <ReportYearProductionPlan />} */}

              {/* {_location === "/report/production-capacity" &&
                switchChecked2 === "3" && <ReportMonthProductionCapacity />} */}
              {_location === "/report/production-capacity" &&
                switchChecked2 === "4" && <ReportYearProductionCapacity />}

              {/* {_location === "/report/cost&income" &&
                switchChecked3 === "5" && <ReportMonthCostAndIncome />} */}
              {_location === "/report/cost&income" &&
                switchChecked3 === "6" && <ReportYearCostAndIncome />}

              {_location === "/report/selling-plants" &&
                switchChecked4 === "7" && <ReportMonthSellingPlants />}
              {_location === "/report/selling-plants" &&
                switchChecked4 === "8" && <ReportYearSellingPlants />}
              {_location === "/report/resource-harvest-2" &&
                <ReportResourceHarvestFactory />}
            </div>
          </aside>
        </article>
      </section>
      <Footer />
    </div>
  );
};

export default OfficerReport;
