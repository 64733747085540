import $ from "jquery"
import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { FaPen } from "react-icons/fa"
import icon from '../../assets/img/Icon'
import Pagination from '../../components/pagination/Pagination'
import { QueryGetAll } from '../../helpers/api.helper'
import { defaultApi } from '../../services/api'
import { convertDateThai } from '../../utils/format/Date.format'
import Message from '../../utils/message/Message'
import { FormManageSubgrp1 } from './form/FormManageSubgrp1'
import { FormManageSubgrp2 } from './form/FormManageSubgrp2'
import style from "./scss/Headtable.module.scss"

//let _toastState = false

export const ManageSubgrpData = ({ toggerCheck }) => {

    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const [itemEdit, setitemEdit] = useState([])
    //const [_defaultOrg, defaultOrg] = useState([])
    const [_defaultLocalGrp, defaultLocalGrp] = useState([])
    const [textSearch, setTextSearch] = useState("")

    const [_toastState, toastState] = useState(false)
    //const [_lastPage, lastPage] = useState(0)

    const [_setPlaceHolder, setPlaceHolder] = useState(false)
    const [_fstLoad, fstLoad] = useState(true)

    const infoData = useCallback(async () => {

        const _top = currentPage * 10;

        let qryGetAllWithText1 = {
            $count: "%24count=true",
            $expand: "&%24expand=org",
            $top: "&%24top=10",
            $skip: `&%24skip=${_top > 10 ? currentPage * 10 - 10 : "0"}`,
            $filter: `&%24filter=contains%28name%2C%20%27${textSearch}%27%29`,
            $orderby: "&%24orderby=createdAt desc",
        };

        let qryGetAllWithOutText1 = {
            $count: "%24count=true",
            $expand: "&%24expand=org",
            $top: "&%24top=10",
            $skip: `&%24skip=${_top > 10 ? currentPage * 10 - 10 : "0"}`,
            $filter: "",
            $orderby: "",
        };

        let qryGetAllWithText2 = {
            $count: "%24count=true",
            $expand: "",
            $top: "",
            $skip: "",
            //&%24filter=contains%28name%2C%20%27${textSearch}%27%29
            $filter: `&%24filter=contains%28name%2C%20%27${textSearch}%27%29`,
            $orderby: "",
        };

        // let qryGetById = {
        //     $expand: "",
        //     $filter: "",
        //     $orderby: "",
        // };

        const _query1_1 = QueryGetAll(qryGetAllWithText1);
        const _query1_2 = QueryGetAll(qryGetAllWithOutText1);
        const _query2_1 = QueryGetAll(qryGetAllWithText2);

        const loading = toast;

        try {
            let resOrg = null
            let resLocalGrp = null
            let resLocalGrps = null

            resOrg = await defaultApi.getOrgQuery(_query2_1);
            resLocalGrp = await defaultApi.getLocalGrp(_query1_1);
            resLocalGrps = await defaultApi.getLocalGrp(_query1_2);

            if (textSearch.length > 0) {
                let listArrItme = []
                let org = resOrg.data.datas
                let localGrp = resLocalGrp.data.datas
                let localGrps = resLocalGrps.data.datas

                //1.หาด้วยหน่วยงาน + กลุ่ม อยู่ในคำเดียวกัน
                const fstCon = localGrp.map((data1) => {
                    return org.map((data2) => {
                        let i
                        if (Number(data1.orgId) === Number(data2.id)) {
                            i = data1
                        }
                        return i
                    }).filter((item) => item !== undefined)
                }).filter((item) => item.length > 0)

                for (let index = 0; index < fstCon.length; index++) {
                    const element = fstCon[index];
                    listArrItme.push(element[0])
                }

                //2.หาด้วยหน่วยงาน
                const secCon = org.map((data1) => {
                    return localGrps.map((data2) => {
                        let i
                        if (Number(data1.id) === Number(data2.orgId)) {
                            i = data2
                        }
                        return i
                    }).filter((item) => item !== undefined)
                }).filter((item) => item.length > 0);

                for (let index = 0; index < secCon.length; index++) {
                    const element = secCon[index];
                    for (let i = 0; i < element.length; i++) {
                        listArrItme.push(element[i])
                    }
                }

                //3.หาด้วยกลุ่ม
                const thdCon = localGrp.map((data) => {
                    return data
                })

                for (let index = 0; index < thdCon.length; index++) {
                    const element = thdCon[index];
                    listArrItme.push(element)
                }

                //เอามารวมกันเป็นก้อนเดียวกัน แล้ว filter ออกอันที่ซ้ำกัน
                listArrItme = listArrItme.map((data) => {
                    return data
                }).filter((item, index, self) => {
                    return self.findIndex((itemx) => itemx.id === item.id) === index
                })

                //defaultOrg(resOrg.data.datas)
                //defaultLocalGrp(resLocalGrp.data.datas);
                defaultLocalGrp(listArrItme);

                if (_toastState === true && listArrItme.length <= 0) {
                    toast.error(Message.notify.search_not_found, { duration: 3000 });
                }

            } else {
                //defaultOrg(resOrg.data.datas)
                defaultLocalGrp(resLocalGrp.data.datas);

                if (_toastState === true && resLocalGrp.data.datas.length <= 0) {
                    toast.error(Message.notify.search_not_found, { duration: 3000 });
                }
            }

            if (_toastState === true) {
                setCurrentPage(1)
            }

            setTotalCount(resLocalGrp.data.total)
        } catch (error) {
            console.log(error);

        } finally {
            toast.dismiss(loading);
            toastState(false)
        }

    }, [_toastState, currentPage, textSearch])

    const onPaginate = useCallback(async (page) => {
        if (Number(currentPage) === Number(page)) {
            return
        }
        const _top = page * 10;

        let qryGetAllWithText1 = {
            $count: "%24count=true",
            $expand: "&%24expand=org",
            $top: "&%24top=10",
            $skip: `&%24skip=${_top > 10 ? page * 10 - 10 : "0"}`,
            $filter: `&%24filter=contains%28name%2C%20%27${textSearch}%27%29`,
            $orderby: "&%24orderby=createdAt desc",
        };

        let qryGetAllWithOutText1 = {
            $count: "%24count=true",
            $expand: "&%24expand=org",
            $top: "&%24top=10",
            $skip: `&%24skip=${_top > 10 ? page * 10 - 10 : "0"}`,
            $filter: "",
            $orderby: "",
        };

        let qryGetAllWithText2 = {
            $count: "%24count=true",
            $expand: "",
            $top: "",
            $skip: "",
            $filter: `&%24filter=contains%28name%2C%20%27${textSearch}%27%29`,
            $orderby: "",
        };

        //const _query = QueryGetAll(qryGetAll);
        const _query1_1 = QueryGetAll(qryGetAllWithText1);
        const _query1_2 = QueryGetAll(qryGetAllWithOutText1);
        const _query2_1 = QueryGetAll(qryGetAllWithText2);

        const loading = toast;

        try {
            let resOrg = null
            let resLocalGrp = null
            let resLocalGrps = null

            resOrg = await defaultApi.getOrgQuery(_query2_1);
            resLocalGrp = await defaultApi.getLocalGrp(_query1_1);
            resLocalGrps = await defaultApi.getLocalGrp(_query1_2);

            // let resOrg = null
            // let resLocalGrp = null
            // resOrg = await defaultApi.getOrgQuery(_query2_1);
            // resLocalGrp = await defaultApi.getLocalGrp(_query1_2);

            //const resLocalGrp = await defaultApi.getLocalGrp(_query);
            if (resLocalGrp.data.datas.length === 0) return;

            if (textSearch.length > 0) {
                let listArrItme = []
                let org = resOrg.data.datas
                let localGrp = resLocalGrp.data.datas
                let localGrps = resLocalGrps.data.datas

                //1.หาด้วยหน่วยงาน + กลุ่ม อยู่ในคำเดียวกัน
                const fstCon = localGrp.map((data1) => {
                    return org.map((data2) => {
                        let i
                        if (Number(data1.orgId) === Number(data2.id)) {
                            i = data1
                        }
                        return i
                    }).filter((item) => item !== undefined)
                }).filter((item) => item.length > 0)

                for (let index = 0; index < fstCon.length; index++) {
                    const element = fstCon[index];
                    listArrItme.push(element[0])
                }

                //2.หาด้วยหน่วยงาน
                const secCon = org.map((data1) => {
                    return localGrps.map((data2) => {
                        let i
                        if (Number(data1.id) === Number(data2.orgId)) {
                            i = data2
                        }
                        return i
                    }).filter((item) => item !== undefined)
                }).filter((item) => item.length > 0);

                for (let index = 0; index < secCon.length; index++) {
                    const element = secCon[index];
                    for (let i = 0; i < element.length; i++) {
                        listArrItme.push(element[i])
                    }
                }

                //3.หาด้วยกลุ่ม
                const thdCon = localGrp.map((data) => {
                    return data
                })

                for (let index = 0; index < thdCon.length; index++) {
                    const element = thdCon[index];
                    listArrItme.push(element)
                }

                //เอามารวมกันเป็นก้อนเดียวกัน แล้ว filter ออกอันที่ซ้ำกัน
                listArrItme = listArrItme.map((data) => {
                    return data
                }).filter((item, index, self) => {
                    return self.findIndex((itemx) => itemx.id === item.id) === index
                })

                //defaultOrg(resOrg.data.datas)
                //defaultLocalGrp(resLocalGrp.data.datas);
                defaultLocalGrp(listArrItme);

                if (_toastState === true && listArrItme.length <= 0) {
                    toast.error(Message.notify.search_not_found, { duration: 3000 });
                }

            } else {
                //defaultOrg(resOrg.data.datas)
                defaultLocalGrp(resLocalGrp.data.datas);

                if (_toastState === true && resLocalGrp.data.datas.length <= 0) {
                    toast.error(Message.notify.search_not_found, { duration: 3000 });
                }
            }

            // defaultOrg(resOrg.data.datas)
            // defaultLocalGrp(resLocalGrp.data.datas);

            //defaultLocalGrp(resLocalGrp.data.datas);
            setCurrentPage(page);
            setTotalCount(resLocalGrp.data.total);
        } catch (error) {
            console.log(error);
        } finally {
            toast.dismiss(loading);
            toastState(false)
        }

    }, [currentPage, textSearch, _toastState])

    const getOrgName = (val) => {
        let obj = JSON.parse(JSON.stringify(val))
        return obj.name
    }

    const delItem = async (val) => {
        if (window.confirm("ต้องการลบรายการ?") === true) {
            const loading = toast.loading(Message.notify.delete_loading);
            try {
                await defaultApi.deleteLocalGroup(val)
                toast.success(Message.notify.delete_success, { duration: 3000 });
                infoData()
            } catch (error) {
                console.log(error)
                //toast.error(error.response, { duration: 3000 });
                toast.error(Message.notify.delete_error, { duration: 3000 });
            } finally {
                toast.dismiss(loading);
            }
        }
    }

    const tb03 = () => {
        return _defaultLocalGrp.map((detail, index) => {
            return (
                <tr key={detail.id}>
                    <th scope="row">{(currentPage - 1) * 10 + (index + 1)}</th>
                    <td align='left'>{detail.name}</td>
                    <td align='left'>{getOrgName(detail.org)}</td>
                    <td>{convertDateThai(detail.createdAt)}</td>
                    <td><FaPen
                        className={`${style.iconsize}`}
                        onClick={() => {
                            let obj = JSON.parse(JSON.stringify(detail.org))
                            setitemEdit({
                                id: detail.id,
                                subname: detail.name,
                                //orgname: getOrgName(detail.org)
                                orgname: obj.id
                            });
                            $("#show_modal_edit_2").click();
                        }}
                    /></td>
                    <td><img className={`${style.iconsize}`} src={icon.bin} alt="del" onClick={() => { delItem(detail.id) }} /></td>
                </tr>
            )
        })
    }

    const searchItem = useCallback(() => {

        if (_toastState === false && currentPage > 1 && totalCount <= 10) {
            setCurrentPage(1)
        }
        if (_toastState === false && currentPage > 1 && totalCount >= 11) {
            setCurrentPage(1)
        }
        toastState(true);

    }, [_toastState, currentPage, totalCount])

    useEffect(() => {
        if (_fstLoad === true) {
            infoData()
            fstLoad(false)
        }
    }, [infoData, _fstLoad])

    useEffect(() => {
        if (_toastState === true) {
            infoData()
        }
        if (currentPage > 1 && totalCount <= 10) {
            searchItem()
        }
    }, [infoData, searchItem, _toastState, currentPage, totalCount])

    return (
        <>
            <div style={toggerCheck === true ? { display: 'flex' } : { display: 'none' }} className={`${style.bodytab3}`}>
                <div>
                    <div className={`${style.hpsub}`}>
                        <div className="col" >
                            <img src={icon.add2} alt="add" style={{ "cursor": "pointer" }} onClick={() => { $("#show_modal_edit_1").trigger("click") }} />
                            <h5 style={{ "cursor": "pointer" }} onClick={() => { $("#show_modal_edit_1").trigger("click") }} >เพิ่มกลุ่มผู้ใช้งานย่อย</h5>
                        </div>
                        <div className="col">
                            <input type={"text"}
                                placeholder={_setPlaceHolder === false ? 'กรอกข้อความเพื่อค้นหา' : ''}
                                onFocus={() => { setPlaceHolder(true) }}
                                onBlur={() => { setPlaceHolder(false) }}
                                onChange={(e) => { setTextSearch(e.target.value); }} />
                            <img src={icon.search} alt="search" onClick={() => { searchItem() }} style={{ "cursor": "pointer" }} />
                        </div>
                    </div>
                </div>
                <div>
                    <div className="collapse_table table-responsive">
                        <table className="table text-center">
                            <thead>
                                <tr className={`${style.tr}`}>
                                    <th scope="col">ลำดับ</th>
                                    <th scope="col">ชื่อกลุ่มผู้ใช้งานย่อย</th>
                                    <th scope="col">หน่วยงาน</th>
                                    <th scope="col">วันที่ - เวลา</th>
                                    <th scope="col">แก้ไข</th>
                                    <th scope="col">ลบ</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    tb03()
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div>
                    <Pagination pageSize={10} totalCount={totalCount} currentPage={currentPage} onPageChange={(page) => onPaginate(page)} />
                </div>
            </div>
            <button type="button" id='show_modal_edit_1' className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal1" hidden></button>
            {/* data-bs-target="#exampleModal2" */}
            <button type="button" id='show_modal_edit_2' className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#exampleModal2${itemEdit.id}`} hidden></button>
            <FormManageSubgrp1 infoDatax={infoData} />
            <FormManageSubgrp2 datain={itemEdit} infoDatax={infoData} idForm={`exampleModal2${itemEdit.id}`} />
        </>
    )
}
