import React, { useMemo } from "react";
import InfoWindow from "./InfoWindow";
import { convertDateThai } from "../../../utils/format/Date.format";
import styles from "./FeatureInfo.module.scss";

const ElementFac = ({
  _factoryDetail,
  _setFactoryDetail,
  _setLayerType,
  _setLayerClick,
  _themeColor,
}) => {
  return (
    <div className={`${styles.container}`}>
      <h6
        className={`${styles.title_text}`}
        style={{ color: _themeColor?.color }}
      >
        รายละเอียดข้อมูลโรงไฟฟ้า
      </h6>
      <div className={`${styles.list_box} row mx-auto`}>
        <p className={`${styles.list_key} col-5`}>ชื่อโรงไฟฟ้า</p>
        <p className={`${styles.list_value} col-7`}>{_factoryDetail?.name}</p>
      </div>
      <div className={`${styles.list_box} row mx-auto`}>
        <p className={`${styles.list_key} col-5`}>ที่ตั้ง</p>
        <p className={`${styles.list_value} col-7`}>
          {_factoryDetail?.address}
        </p>
      </div>
      <div className={`${styles.list_box} row mx-auto`}>
        <p className={`${styles.list_key} col-5`}>สถานะ</p>
        <p className={`${styles.list_value} col-7`}>
          {_factoryDetail?.factoryStatus.name}
        </p>
      </div>
      <div className={`${styles.list_box} row mx-auto`}>
        <p className={`${styles.list_key} col-5`}>ประเภทโรงไฟฟ้า</p>
        <p className={`${styles.list_value} col-7`}>
          {_factoryDetail?.factoryType.name}
        </p>
      </div>
      <div className={`${styles.list_box} row mx-auto`}>
        <p className={`${styles.list_key} col-5`}>เลขที่คำเสนอขอขายไฟฟ้า</p>
        <p className={`${styles.list_value} col-7`}>
          {_factoryDetail?.proposalNo}
        </p>
      </div>
      <div className={`${styles.list_box} row mx-auto`}>
        <p className={`${styles.list_key} col-5`}>กำลังการผลิตไฟฟ้า</p>
        <p className={`${styles.list_value} col-7 text_unit`}>
          {_factoryDetail?.capacityProductionMonth}
          <span>MW</span>
        </p>
      </div>
      <div className={`${styles.btn_left}`}>
        <button
          className="button_sm"
          onClick={() => {
            _setFactoryDetail(null);
            _setLayerType((prev) => ({
              ...prev,
              activeCount: [...prev.activeCount, "factory"],
            }));
            _setLayerClick(null);
          }}
        >
          กลับ
        </button>
      </div>
    </div>
  );
};
const ElementEnt = ({
  _enterpriseDetail,
  _setEnterpriseDetail,
  _setLayerType,
  _allFarmer,
  _allParcel,
  _allCrop,
  _setLayerClick,
  _themeColor,
}) => {
  return (
    <div className={`${styles.container}`}>
      <h6
        className={`${styles.title_text}`}
        style={{ color: _themeColor?.color }}
      >
        รายละเอียดข้อมูลกลุ่มวิสาหกิจชุมชน
      </h6>
      <div className={`${styles.list_box} row mx-auto`}>
        <p className={`${styles.list_key} col-5`}>ชื่อกลุ่มวิสาหกิจชุมชน</p>
        <p className={`${styles.list_value} col-7`}>{_enterpriseDetail.name}</p>
      </div>
      <div className={`${styles.list_box} row mx-auto`}>
        <p className={`${styles.list_key} col-5`}>ที่ตั้ง</p>
        <p className={`${styles.list_value} col-7`}>
          {_enterpriseDetail.address}
        </p>
      </div>
      <div className={`${styles.list_box} row mx-auto`}>
        <p className={`${styles.list_key} col-5`}>ประเภทกลุ่ม</p>
        <p className={`${styles.list_value} col-7`}>
          {_enterpriseDetail.enterpriseType.name}
        </p>
      </div>
      <div className={`${styles.list_box} row mx-auto`}>
        <p className={`${styles.list_key} col-5`}>โรงไฟฟ้าที่รับซื้อ</p>
        <p className={`${styles.list_value} col-7`}>
          {_enterpriseDetail.factory.name}
        </p>
      </div>
      <div className={`${styles.list_box} row mx-auto`}>
        <p className={`${styles.list_key} col-5`}>จำนวนสมาชิก</p>
        <p className={`${styles.list_value} col-7 text_unit`}>
          {
            _allFarmer.filter(
              (item) => item.enterpriseId === _enterpriseDetail.id
            ).length
          }
          <span>คน</span>
        </p>
      </div>
      <div className={`${styles.list_box} row mx-auto`}>
        <p className={`${styles.list_key} col-5`}>พื้นที่ทั้งหมด</p>
        <p className={`${styles.list_value} col-7 text_unit`}>
          {_allParcel
            .filter((item1) => item1.enterpriseId === _enterpriseDetail.id)
            .map((item2) => item2.area)
            .reduce((acc, curr) => acc + curr, 0)
            ?.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            })}
          <span>ไร่</span>
        </p>
      </div>
      <div className={`${styles.list_box} row mx-auto`}>
        <p className={`${styles.list_key} col-5`}>พื้นที่ของเชื้อเพลิง</p>
        <p className={`${styles.list_value} col-7 text_unit`}>
          {_allCrop
            .filter((item1) => item1.enterpriseId === _enterpriseDetail.id)
            .map((item2) => item2.area)
            .reduce((acc, curr) => acc + curr, 0)
            ?.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            })}
          <span>ไร่</span>
        </p>
      </div>
      <div className={`${styles.btn_left}`}>
        <button
          className="button_sm"
          onClick={() => {
            _setEnterpriseDetail(null);
            _setLayerType((prev) => ({
              ...prev,
              activeCount: [...prev.activeCount, "enterprise"],
            }));
            _setLayerClick(null);
          }}
        >
          กลับ
        </button>
      </div>
    </div>
  );
};
const ElementCrp = ({
  _cropDetail,
  _setCropDetail,
  _setLayerType,
  _allFarmer,
  _allEnterprise,
  _setLayerClick,
  _themeColor,
}) => {
  return (
    <div className={`${styles.container}`}>
      <h6
        className={`${styles.title_text}`}
        style={{ color: _themeColor?.color }}
      >
        รายละเอียดข้อมูลการเพาะปลูก
      </h6>
      <div className={`${styles.list_box} row mx-auto`}>
        <p className={`${styles.list_key} col-5`}>ชื่อเจ้าของที่ดิน</p>
        <p className={`${styles.list_value} col-7`}>
          {_allFarmer
            ?.filter((item1) => item1.id === _cropDetail?.farmerId)
            .map((item2) => item2.title)}{" "}
          {_allFarmer
            ?.filter((item1) => item1.id === _cropDetail?.farmerId)
            .map((item2) => item2.firstname)}{" "}
          {_allFarmer
            ?.filter((item1) => item1.id === _cropDetail?.farmerId)
            .map((item2) => item2.lastname)}
        </p>
      </div>
      <div className={`${styles.list_box} row mx-auto`}>
        <p className={`${styles.list_key} col-5`}>ชื่อกลุ่มวิสาหกิจชุมชน</p>
        <p className={`${styles.list_value} col-7`}>
          {_allEnterprise
            .filter((item1) => item1.id === _cropDetail?.enterpriseId)
            .map((item2) => item2.name)}
        </p>
      </div>
      <div className={`${styles.list_box} row mx-auto`}>
        <p className={`${styles.list_key} col-5`}>รหัสแปลง</p>
        <p className={`${styles.list_value} col-7`}>
          {_cropDetail?.parcel.parcelId}
        </p>
      </div>
      <div className={`${styles.list_box} row mx-auto`}>
        <p className={`${styles.list_key} col-5`}>ชนิดเชื้อเพลิง / วัตถุดิบ</p>
        <p className={`${styles.list_value} col-7`}>
          {_cropDetail?.plantType.name}
        </p>
      </div>
      <div className={`${styles.list_box} row mx-auto`}>
        <p className={`${styles.list_key} col-5`}>พื้นที่ของเชื้อเพลิง</p>
        <p className={`${styles.list_value} col-7 text_unit`}>
          {_cropDetail?.area}
          <span>ไร่</span>
        </p>
      </div>
      <div className={`${styles.list_box} row mx-auto`}>
        <p className={`${styles.list_key} col-5`}>วันที่เริ่มเพาะปลูก</p>
        <p className={`${styles.list_value} col-7`}>
          {convertDateThai(_cropDetail?.plantDate)}
        </p>
      </div>
      <div className={`${styles.list_box} row mx-auto`}>
        <p className={`${styles.list_key} col-5`}>วันที่คาดว่าจะเก็บเกี่ยว</p>
        <p className={`${styles.list_value} col-7`}>
          {_cropDetail?.harvestPlan === null
            ? "-"
            : convertDateThai(_cropDetail?.harvestPlan)}
        </p>
      </div>
      <div className={`${styles.btn_left}`}>
        <button
          className="button_sm"
          onClick={() => {
            _setCropDetail(null);
            _setLayerType((prev) => ({
              ...prev,
              activeCount: [...prev.activeCount, "crop"],
            }));
            _setLayerClick(null);
          }}
        >
          กลับ
        </button>
      </div>
    </div>
  );
};

const FeatureInfo = ({
  _allFactory,
  _factoryDetail,
  _allEnterprise,
  _enterpriseDetail,
  _allFarmer,
  _allCrop,
  _cropDetail,
  _allParcel,
  _layerType,
  _setLayerType,
  _opened,
  _setOpened,
  _setFactoryDetail,
  _setEnterpriseDetail,
  _setCropDetail,
  _layerClick,
  _setLayerClick,
  _extentToLayer,
  _themeColor,
}) => {
  const onCheckLastArr = () => {
    let lastVal = _layerType.activeCount[_layerType.activeCount.length - 1];
    // Return last value
    return lastVal;
  };

  const layerDetails = useMemo(() => {
    let element;
    if (_layerClick === "factory") {
      element = (
        <ElementFac
          _factoryDetail={_factoryDetail}
          _setFactoryDetail={_setFactoryDetail}
          _setLayerType={_setLayerType}
          _setLayerClick={_setLayerClick}
          _themeColor={_themeColor}
        />
      );
    } else if (_layerClick === "enterprise") {
      element = (
        <ElementEnt
          _enterpriseDetail={_enterpriseDetail}
          _setEnterpriseDetail={_setEnterpriseDetail}
          _setLayerType={_setLayerType}
          _allFarmer={_allFarmer}
          _allParcel={_allParcel}
          _allCrop={_allCrop}
          _setLayerClick={_setLayerClick}
          _themeColor={_themeColor}
        />
      );
    } else if (_layerClick === "crop") {
      element = (
        <ElementCrp
          _cropDetail={_cropDetail}
          _setCropDetail={_setCropDetail}
          _setLayerType={_setLayerType}
          _allFarmer={_allFarmer}
          _allEnterprise={_allEnterprise}
          _setLayerClick={_setLayerClick}
          _themeColor={_themeColor}
        />
      );
    } else {
      element = null;
    }

    return element;
  }, [
    _factoryDetail,
    _setFactoryDetail,
    _setLayerType,
    _layerClick,
    _setLayerClick,
    _enterpriseDetail,
    _setEnterpriseDetail,
    _allCrop,
    _allParcel,
    _allFarmer,
    _cropDetail,
    _setCropDetail,
    _allEnterprise,
    _themeColor,
  ]);

  const allCropFilter = useMemo(() => {
    const data = _allCrop;
    const newData = data?.filter(
      (item) => item.cropStatus !== "เก็บเกี่ยวแล้ว"
    );

    return newData;
  }, [_allCrop]);

  return (
    <InfoWindow opened={_opened} onToggle={(evt) => _setOpened(evt)}>
      {layerDetails === null && _layerType.checked1 === onCheckLastArr() && (
        <div className={`${styles.container} table-responsive`}>
          <table className="table table-bordered text-center">
            <thead style={{ backgroundColor: _themeColor?.backgroundColor }}>
              <tr>
                <th scope="col">ลำดับ</th>
                <th scope="col">ชื่อโรงไฟฟ้า</th>
                <th scope="col">ประเภทโรงไฟฟ้า</th>
                <th scope="col">เลขที่คำเสนอขอขายไฟฟ้า</th>
                <th scope="col">ที่ตั้ง</th>
              </tr>
            </thead>
            <tbody>
              {_allFactory.map((item, index) => {
                return (
                  <tr
                    key={item?.id || index}
                    onClick={(e) => _extentToLayer(e, item?.geom)}
                  >
                    <td>{index + 1}</td>
                    <td>{item?.name}</td>
                    <td>{item?.factoryType.name}</td>
                    <td>{item?.proposalNo}</td>
                    <td>{item?.address}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      {_layerClick !== null && layerDetails !== null && (
        <>
          {/* <div className={`${styles.container}`}>
          <h6 className={`${styles.title_text}`}>รายละเอียดข้อมูลโรงไฟฟ้า</h6>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-5`}>ชื่อโรงไฟฟ้า</p>
            <p className={`${styles.list_value} col-7`}>
              {_factoryDetail?.name}
            </p>
          </div>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-5`}>ที่ตั้ง</p>
            <p className={`${styles.list_value} col-7`}>
              {_factoryDetail?.address}
            </p>
          </div>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-5`}>สถานะ</p>
            <p className={`${styles.list_value} col-7`}>
              {_factoryDetail?.factoryStatus.name}
            </p>
          </div>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-5`}>ประเภทโรงไฟฟ้า</p>
            <p className={`${styles.list_value} col-7`}>
              {_factoryDetail?.factoryType.name}
            </p>
          </div>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-5`}>เลขที่คำเสนอขอขายไฟฟ้า</p>
            <p className={`${styles.list_value} col-7`}>
              {_factoryDetail?.proposalNo}
            </p>
          </div>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-5`}>กำลังการผลิตไฟฟ้า</p>
            <p className={`${styles.list_value} col-7 text_unit`}>
              {_factoryDetail?.capacityProductionMonth}
              <span>MW</span>
            </p>
          </div>
          <div className={`${styles.btn_left}`}>
            <button
              className="button_sm"
              onClick={() => {
                _setFactoryDetail(null);
                _setLayerType((prev) => ({
                  ...prev,
                  activeCount: [...prev.activeCount, "factory"],
                }));
              }}
            >
              กลับ
            </button>
          </div>
        </div> */}
          {layerDetails}
        </>
      )}

      {layerDetails === null && _layerType.checked2 === onCheckLastArr() && (
        <div className={`${styles.container} table-responsive`}>
          <table className="table table-bordered text-center">
            <thead style={{ backgroundColor: _themeColor?.backgroundColor }}>
              <tr>
                <th scope="col">ลำดับ</th>
                <th scope="col">ชื่อกลุ่มวิสาหกิจชุมชน</th>
                <th scope="col">ประเภทกลุ่ม</th>
                <th scope="col">ที่ตั้ง</th>
                <th scope="col">พื้นที่ของเชื้อเพลิง(ไร่)</th>
                <th scope="col">จำนวนสมาชิก(คน)</th>
              </tr>
            </thead>
            <tbody>
              {_allEnterprise.map((item, index) => {
                return (
                  <tr
                    key={item?.id || index}
                    onClick={(e) => _extentToLayer(e, item?.geom)}
                  >
                    <td>{index + 1}</td>
                    <td>{item?.name}</td>
                    <td>{item?.enterpriseType.name}</td>
                    <td>{item?.address}</td>
                    <td>
                      {_allCrop
                        .filter((item1) => item1?.enterpriseId === item?.id)
                        .map((item2) => item2?.area)
                        .reduce((acc, curr) => acc + curr, 0)
                        ?.toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                        })}
                    </td>
                    <td>
                      {
                        _allFarmer.filter(
                          (item1) => item1?.enterpriseId === item?.id
                        ).length
                      }
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      {/* {_enterpriseDetail !== null && _layerType === "enterprise" && (
        <div className={`${styles.container}`}>
          <h6 className={`${styles.title_text}`}>
            รายละเอียดข้อมูลกลุ่มวิสาหกิจชุมชน
          </h6>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-5`}>ชื่อกลุ่มวิสาหกิจชุมชน</p>
            <p className={`${styles.list_value} col-7`}>
              {_enterpriseDetail.name}
            </p>
          </div>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-5`}>ที่ตั้ง</p>
            <p className={`${styles.list_value} col-7`}>
              {_enterpriseDetail.address}
            </p>
          </div>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-5`}>ประเภทกลุ่ม</p>
            <p className={`${styles.list_value} col-7`}>
              {_enterpriseDetail.enterpriseType.name}
            </p>
          </div>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-5`}>โรงไฟฟ้าที่รับซื้อ</p>
            <p className={`${styles.list_value} col-7`}>
              {_enterpriseDetail.factory.name}
            </p>
          </div>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-5`}>จำนวนสมาชิก</p>
            <p className={`${styles.list_value} col-7 text_unit`}>
              {
                _allFarmer.filter(
                  (item) => item.enterpriseId === _enterpriseDetail.id
                ).length
              }
              <span>คน</span>
            </p>
          </div>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-5`}>พื้นที่ทั้งหมด</p>
            <p className={`${styles.list_value} col-7 text_unit`}>
              {_allParcel
                .filter((item1) => item1.enterpriseId === _enterpriseDetail.id)
                .map((item2) => item2.area)
                .reduce((acc, curr) => acc + curr, 0)}
              <span>ไร่</span>
            </p>
          </div>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-5`}>พื้นที่ของเชื้อเพลิง</p>
            <p className={`${styles.list_value} col-7 text_unit`}>
              {_allCrop
                .filter((item1) => item1.enterpriseId === _enterpriseDetail.id)
                .map((item2) => item2.area)
                .reduce((acc, curr) => acc + curr, 0)}
              <span>ไร่</span>
            </p>
          </div>
          <div className={`${styles.btn_left}`}>
            <button
              className="button_sm"
              onClick={() => {
                _setEnterpriseDetail(null);
                _setLayerType((prev) => ({
                  ...prev,
                  activeCount: [...prev.activeCount, "enterprise"],
                }));
              }}
            >
              กลับ
            </button>
          </div>
        </div>
      )} */}

      {layerDetails === null && _layerType.checked3 === onCheckLastArr() && (
        <div className={`${styles.container} table-responsive`}>
          <table className="table table-bordered text-center">
            <thead style={{ backgroundColor: _themeColor?.backgroundColor }}>
              <tr>
                <th scope="col">ลำดับ</th>
                <th scope="col">ชื่อเจ้าของที่ดิน</th>
                <th scope="col">ชนิดเชื้อเพลิง / วัตถุดิบ</th>
                <th scope="col">พื้นที่ของเชื้อเพลิง</th>
                <th scope="col">วันที่เริ่มเพาะปลูก</th>
                <th scope="col">วันที่เก็บเกี่ยว</th>
                <th scope="col">ผลผลิตที่ได้</th>
                <th scope="col">ชื่อกลุ่มวิสาหกิจชุมชน</th>
              </tr>
            </thead>
            <tbody>
              {allCropFilter?.map((item, index) => {
                return (
                  <tr
                    key={item.id}
                    onClick={(e) => _extentToLayer(e, item.geom)}
                  >
                    <td>{index + 1}</td>
                    <td>
                      {_allFarmer
                        .filter((item1) => item1.id === item.farmerId)
                        .map((item2) => item2.title)}{" "}
                      {_allFarmer
                        .filter((item1) => item1.id === item.farmerId)
                        .map((item2) => item2.firstname)}{" "}
                      {_allFarmer
                        .filter((item1) => item1.id === item.farmerId)
                        .map((item2) => item2.lastname)}
                    </td>
                    <td>{item.plantType.name}</td>
                    <td>{item.area}</td>
                    <td>{convertDateThai(item.plantDate)}</td>
                    <td>
                      {item.harvestDate === null
                        ? "-"
                        : convertDateThai(item.harvestDate)}
                    </td>
                    <td>{item.yield || "-"}</td>
                    <td>
                      {_allEnterprise
                        .filter((item1) => item1.id === item.enterpriseId)
                        .map((item2) => item2.name)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      {/* {_cropDetail !== null && _layerType === "crop" && (
        <div className={`${styles.container}`}>
          <h6 className={`${styles.title_text}`}>
            รายละเอียดข้อมูลการเพาะปลูก
          </h6>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-5`}>ชื่อเจ้าของที่ดิน</p>
            <p className={`${styles.list_value} col-7`}>
              {_allFarmer
                .filter((item1) => item1.id === _cropDetail.farmerId)
                .map((item2) => item2.title)}{" "}
              {_allFarmer
                .filter((item1) => item1.id === _cropDetail.farmerId)
                .map((item2) => item2.firstname)}{" "}
              {_allFarmer
                .filter((item1) => item1.id === _cropDetail.farmerId)
                .map((item2) => item2.lastname)}
            </p>
          </div>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-5`}>ชื่อกลุ่มวิสาหกิจชุมชน</p>
            <p className={`${styles.list_value} col-7`}>
              {_allEnterprise
                .filter((item1) => item1.id === _cropDetail.enterpriseId)
                .map((item2) => item2.name)}
            </p>
          </div>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-5`}>รหัสแปลง</p>
            <p className={`${styles.list_value} col-7`}>
              {_cropDetail.parcel.parcelId}
            </p>
          </div>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-5`}>ชนิดเชื้อเพลิง / วัตถุดิบ</p>
            <p className={`${styles.list_value} col-7`}>
              {_cropDetail.plantType.name}
            </p>
          </div>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-5`}>พื้นที่ของเชื้อเพลิง</p>
            <p className={`${styles.list_value} col-7 text_unit`}>
              {_cropDetail.area}
              <span>ไร่</span>
            </p>
          </div>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-5`}>วันที่เริ่มเพาะปลูก</p>
            <p className={`${styles.list_value} col-7`}>
              {convertDateThai(_cropDetail.plantDate)}
            </p>
          </div>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-5`}>
              วันที่คาดว่าจะเก็บเกี่ยว
            </p>
            <p className={`${styles.list_value} col-7`}>
              {_cropDetail.harvestDate === null
                ? "-"
                : convertDateThai(_cropDetail.harvestDate)}
            </p>
          </div>
          <div className={`${styles.btn_left}`}>
            <button
              className="button_sm"
              onClick={() => {
                _setCropDetail(null);
                _setLayerType((prev) => ({
                  ...prev,
                  activeCount: [...prev.activeCount, "crop"],
                }));
              }}
            >
              กลับ
            </button>
          </div>
        </div>
      )} */}
    </InfoWindow>
  );
};

export default FeatureInfo;
