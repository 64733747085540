import React from "react";
import icon from "../../assets/img/Icon";
import styles from "./scss/Index.module.scss";

const InfoBox = ({ valueInput }) => {
  let x01 = 0,
    x02 = 0,
    x03 = 0,
    x03_1 = 0,
    x04 = 0,
    x04_1 = 0;
  try {
    x01 = Number(valueInput?.factoryOverview[0]?.factoryCount).toLocaleString(
      undefined,
      { minimumFractionDigits: 0 }
    );
    x02 = Number(
      valueInput?.factoryOverview[0]?.factoryPowerGenerate
    ).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    x03 = Number(valueInput?.factoryOverview[0]?.factoryTypeGas).toLocaleString(
      undefined,
      { minimumFractionDigits: 0 }
    );
    x03_1 = Number(
      valueInput?.factoryOverview[0]?.factoryTypeGasPowerGenerate
    ).toLocaleString(undefined, { minimumFractionDigits: 0 });
    x04 = Number(valueInput?.factoryOverview[0]?.factoryTypeBio).toLocaleString(
      undefined,
      { minimumFractionDigits: 0 }
    );
    x04_1 = Number(
      valueInput?.factoryOverview[0]?.factoryTypeBioPowerGenerate
    ).toLocaleString(undefined, { minimumFractionDigits: 0 });
  } catch (error) {}

  return (
    <>
      <div className={`${styles.info_box}`}>
        <div className={`${styles.box1}`}>
          <i>
            <img src={icon.index_0} alt="" />
          </i>
          <div className={`${styles.box_details}`}>
            <div className={`${styles.details_1} row mx-auto`}>
              <span className="col-6">โรงไฟฟ้าชุมชมรวม</span>
              <span className={`${styles.count} col-4`}>{x01}</span>
              <span className={`${styles.type} col-2`}>แห่ง</span>
            </div>
            <div className={`${styles.details_2} row mx-auto`}>
              <span className="col-6">กำลังการผลิตไฟฟ้ารวม</span>
              <span className={`${styles.count} col-4`}>{x02}</span>
              <span className={`${styles.type} col-2`}>MW</span>
            </div>
          </div>
        </div>
        <div className={`${styles.box2}`}>
          <i>
            <img src={icon.index_1} alt="" />
          </i>
          <div className={`${styles.box_details}`}>
            <div className={`${styles.details_1}`}>
              <span className={`${styles.title}`}>โรงไฟฟ้าก๊าซชีวภาพ</span>
              <div className={`${styles.total_box_flex}`}>
                <div className={`${styles.t_b_left}`}>
                  <span className={`${styles.count}`}>{x03}</span>
                  <span className={`${styles.type}`}>แห่ง</span>
                </div>
                <div className={`${styles.t_b_right}`}>
                  <span className={`${styles.count}`}>{x03_1}</span>
                  <span className={`${styles.type}`}>MW</span>
                </div>
              </div>
            </div>
            <div className={`${styles.details_2}`}>
              <span className={`${styles.title}`}>โรงไฟฟ้าชีวมวล</span>
              <div className={`${styles.total_box_flex}`}>
                <div className={`${styles.t_b_left}`}>
                  <span className={`${styles.count}`}>{x04}</span>
                  <span className={`${styles.type}`}>แห่ง</span>
                </div>
                <div className={`${styles.t_b_right}`}>
                  <span className={`${styles.count}`}>{x04_1}</span>
                  <span className={`${styles.type}`}>MW</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InfoBox;
