import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import WKT from "ol/format/WKT";
import { Vector as VectorSource } from "ol/source";
import { Vector as VectorLayer } from "ol/layer";
import { Icon, Style } from "ol/style";
import Header from "../../layouts/header/Header";
import Navbar from "../../layouts/navbar/Navbar";
import Footer from "../../layouts/footer_2/Footer";
import MenuBar from "./MenuBar";
import CollapseRight from "./CollapseRight";
import CollapseTable from "./CollapseTable";
import InfoList from "./InfoList";
import AddPowerPlantsForm from "./form/AddPowerPlantsForm";
import AddFarmerForm from "./form/AddFarmerForm";
import ChartsPower from "./charts/ChartsPower";
import ChartsStatus from "./charts/ChartsStatus";
import MapElement from "./_partials/MapElement";
import useSwitch from "../../utils/hooks/useSwitch";
import { getProfileData } from "../../services/auth.slice";
import { defaultApi } from "../../services/api";
import { QueryGetAll } from "../../helpers/api.helper";
import icon from "../../assets/img/Icon";
import { clearObj } from "../../utils/format/Object.format";
import Message from "../../utils/message/Message";
import { setAllFactoryData } from "../../services/factory.slice";
import { setLocalStorageData } from "../../helpers/local_storage.helper";
import styles from "./scss/Home.module.scss";

const initFilterGrp = {
  factoryTypeId: "",
  factoryTypeId1: "",
  factoryStatusId: "",
  factoryStatusId1: "",
  factoryStatusId2: "",
  factoryStatusId3: "",
  addressRegionCode: "",
  addressRegionCode1: "",
  addressRegionCode2: "",
  addressRegionCode3: "",
  addressRegionCode4: "",
  addressRegionCode5: "",
  addressProvCode: "",
  name: "",
  active: false,
};

const Home = () => {
  const dispatchFn_ = useDispatch();
  const profile_ = useSelector(getProfileData);
  const [_switch, onSwitch] = useSwitch();

  const [_map, setMap] = useState(null);
  const [allFactory, setAllFactory] = useState([]);
  const [allFactoryCheck, setAllFactoryCheck] = useState([]);
  const [allEnterprise, setAllEnterprise] = useState([]);
  const [allFactoryMember, setAllFactoryMember] = useState([]);
  const [allEnterpriseMember, setAllEnterpriseMember] = useState([]);
  const [allProvince, setAllProvince] = useState([]);
  const [allRegion, setAllRegion] = useState([]);
  const [reportFacRegType, setReportFacRegType] = useState([]);
  const [reportFacRegStatus, setReportFacRegStatus] = useState([]);
  const [filterGroup, setFilterGroup] = useState(initFilterGrp);

  const changeThemeByRole = useMemo(() => {
    const role = profile_?.userGroupId;
    let theme;

    if (role === 2) {
      theme = {
        color: "#789b33",
        backgroundColor: "#789b33",
        class: "ent",
      };
    } else if (role === 3) {
      theme = {
        color: "#2f9ced",
        backgroundColor: "#2f9ced",
        class: "gov",
      };
    } else {
      theme = "";
    }

    return theme;
  }, [profile_]);

  const styleFunction = useCallback(
    (feature) => {
      const factoryType = feature.getProperties().factoryType;
      const factoryStatus = feature.getProperties().factoryStatus;
      let image = "";
      if (!_switch) {
        if (factoryType === 1) {
          image = icon.group4;
        } else {
          image = icon.group5;
        }
      } else {
        if (factoryStatus === 1) {
          image = icon.group6;
        } else if (factoryStatus === 2) {
          image = icon.group7;
        } else if (factoryStatus === 3) {
          image = icon.group8;
        } else if (factoryStatus === 4) {
          image = icon.group9;
        }
      }
      return [
        new Style({
          image: new Icon({
            anchor: [0.5, 46],
            anchorXUnits: "fraction",
            anchorYUnits: "pixels",
            src: image,
          }),
        }),
      ];
    },
    [_switch]
  );
  const _createLayer = useCallback(
    (data) => {
      /* Clear layers : START */
      _map
        ?.getLayers()
        .getArray()
        .filter((layer) => layer.get("factoryId"))
        .forEach((layer) => _map.removeLayer(layer));
      /* Clear layers : END */

      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        const wkt = `${element.geom}`;
        const format = new WKT();
        const feature = format.readFeature(wkt, {
          dataProjection: "EPSG:4326",
          featureProjection: "EPSG:3857",
        });
        const vector = new VectorLayer({
          source: new VectorSource({
            features: [feature],
            loader: function () {
              feature.setProperties({
                factoryId: element.id,
                factoryType: element.factoryTypeId,
                factoryStatus: element.factoryStatusId,
              });
            },
          }),
          style: styleFunction,
        });
        vector.setProperties({ factoryId: element.id });
        _map?.addLayer(vector);
      }
    },
    [_map, styleFunction]
  );

  const onClearLayers = () => {
    const _layers = [..._map.getLayers().getArray()];
    if (_layers.length > 1) {
      _layers.forEach((layer, index) => {
        if (index !== 0) _map.removeLayer(layer);
      });
    }
  };
  const onSearchLayer = async () => {
    onClearLayers();
    const loading = toast.loading(Message.notify.search_loading);
    const qryGetAll = {
      $count: "%24count=true",
      $expand: "",
      $top: "",
      $skip: "",
      $filter: "",
      $orderby: "",
    };
    const _queryAll = QueryGetAll(qryGetAll);
    const formatQry = (obj) => {
      const checkObj = clearObj({
        factoryTypeId: +obj.factoryTypeId,
        factoryTypeId1: +obj.factoryTypeId1,
        factoryStatusId: +obj.factoryStatusId,
        factoryStatusId1: +obj.factoryStatusId1,
        factoryStatusId2: +obj.factoryStatusId2,
        factoryStatusId3: +obj.factoryStatusId3,
        addressRegionCode: obj.addressRegionCode,
        addressRegionCode1: obj.addressRegionCode1,
        addressRegionCode2: obj.addressRegionCode2,
        addressRegionCode3: obj.addressRegionCode3,
        addressRegionCode4: obj.addressRegionCode4,
        addressRegionCode5: obj.addressRegionCode5,
        addressProvCode: obj.addressProvCode,
        name: obj.name,
      });
      if (Object.keys(clearObj(checkObj)).length === 0) {
        return QueryGetAll(qryGetAll);
      } else {
        let filter1 = "";
        let filter2 = "";
        let filter3 = "";
        let filter4 = "";
        let filter5 = "";
        let factoryTypeIdsArray = [];
        let factoryStatusIdsArray = [];
        let addressRegionCodesArray = [];

        /* FactoryTypeId */
        if (obj.factoryTypeId) {
          factoryTypeIdsArray.push(obj.factoryTypeId);
        }
        if (obj.factoryTypeId1) {
          factoryTypeIdsArray.push(obj.factoryTypeId1);
        }

        /* FactoryStatusId */
        if (obj.factoryStatusId) {
          factoryStatusIdsArray.push(obj.factoryStatusId);
        }
        if (obj.factoryStatusId1) {
          factoryStatusIdsArray.push(obj.factoryStatusId1);
        }
        if (obj.factoryStatusId2) {
          factoryStatusIdsArray.push(obj.factoryStatusId2);
        }
        if (obj.factoryStatusId3) {
          factoryStatusIdsArray.push(obj.factoryStatusId3);
        }

        /* AddressRegionCodes */
        if (obj.addressRegionCode) {
          addressRegionCodesArray.push(obj.addressRegionCode);
        }
        if (obj.addressRegionCode1) {
          addressRegionCodesArray.push(obj.addressRegionCode1);
        }
        if (obj.addressRegionCode2) {
          addressRegionCodesArray.push(obj.addressRegionCode2);
        }
        if (obj.addressRegionCode3) {
          addressRegionCodesArray.push(obj.addressRegionCode3);
        }
        if (obj.addressRegionCode4) {
          addressRegionCodesArray.push(obj.addressRegionCode4);
        }
        if (obj.addressRegionCode5) {
          addressRegionCodesArray.push(obj.addressRegionCode5);
        }

        /* FactoryTypeId */
        if (factoryTypeIdsArray.length !== 0) {
          filter1 = `(factoryTypeId in (${factoryTypeIdsArray.join(",")}))`;
        } else {
          filter1 = ``;
        }

        /* FactoryStatusId */
        if (factoryStatusIdsArray.length !== 0) {
          filter2 = `(factoryStatusId in (${factoryStatusIdsArray.join(",")}))`;
        } else {
          filter2 = ``;
        }

        /* AddressRegionCodes */
        if (addressRegionCodesArray.length !== 0) {
          filter3 = `(addressRegionCode in (${addressRegionCodesArray
            .map((item) => `"${item}"`)
            .join(",")}))`;
        } else {
          filter3 = ``;
        }

        /* addressProvCode */
        if (obj.addressProvCode) {
          filter4 = `(addressProvCode in ('${obj.addressProvCode}'))`;
        } else {
          filter4 = ``;
        }

        /* name */
        if (obj.name) {
          filter5 = `(contains(name,'${obj.name}'))`;
        } else {
          filter5 = ``;
        }

        let allFilter = [filter1, filter2, filter3, filter4, filter5]
          .filter((item) => item !== "")
          .join(" and ");

        return `${_queryAll}&$filter=${allFilter}`;
      }
    };

    try {
      const res = await defaultApi.getAllFactory(formatQry(filterGroup));
      const datas = res.data.datas;
      const filter = datas.filter((item) => item.geom !== null);
      const FacRegTypeDataChart = formatFacRegTypeData(datas);
      const FacRegStatusDataChart = formatFacRegStatusData(datas);

      if (datas.length !== 0) {
        if (!_switch) {
          _createLayer(filter);
        } else {
          _createLayer(filter);
        }
        toast.success(Message.notify.search_success, { duration: 3000 });
      } else {
        toast.error(Message.notify.search_not_found, { duration: 3000 });
      }

      dispatchFn_(setAllFactoryData(datas));
      setAllFactory(datas);
      setReportFacRegType(FacRegTypeDataChart);
      setReportFacRegStatus(FacRegStatusDataChart);
    } catch (error) {
      console.log(error);
      toast.error(Message.notify.search_error, { duration: 3000 });
    } finally {
      toast.dismiss(loading);
    }
  };

  const getAllFactory = useCallback(async () => {
    const qryGetAll = {
      $count: "%24count=true",
      $expand: "",
      $top: "",
      $skip: "",
      $filter: "",
      $orderby: "",
    };
    const _queryAll = QueryGetAll(qryGetAll);
    try {
      const [allFactory, reportFacReg] = await Promise.all([
        defaultApi.getAllFactory(_queryAll),
        defaultApi.getReportFactoryRegoin(),
      ]);
      const datas = allFactory.data.datas;
      const filter = datas?.filter((item) => item.geom !== null);

      // Set datas
      dispatchFn_(setAllFactoryData(datas));
      setAllFactory(datas);
      setAllFactoryCheck(datas);
      setLocalStorageData("allFactory", datas);
      setReportFacRegStatus(reportFacReg.data.factoryStatus);
      setReportFacRegType(reportFacReg.data.factoryType);
      // Create layer by data wkt
      if (datas.length !== 0) {
        if (!_switch) {
          _createLayer(filter);
        } else {
          _createLayer(filter);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [_createLayer, dispatchFn_, _switch]);
  const getMultipleApi = async () => {
    try {
      const [
        allProvince,
        allRegion,
        allEnterprise,
        allFactoryMember,
        allEnterpriseMember,
      ] = await Promise.all([
        defaultApi.getAllProvince(),
        defaultApi.getAllRegion(),
        defaultApi.getAllEnterprise(),
        defaultApi.getAllFactoryMember(),
        defaultApi.getAllEnterpriseMember(),
      ]);

      setAllProvince(allProvince.data);
      setAllRegion(allRegion.data);
      setAllEnterprise(allEnterprise.data);
      setAllFactoryMember(allFactoryMember.data);
      setAllEnterpriseMember(allEnterpriseMember.data);
      setLocalStorageData("allEnterprise", allEnterprise.data);
    } catch (error) {
      console.log(error);
    }
  };

  const checkUserCreatedFac = useMemo(() => {
    if (allFactoryCheck.length === 0) return true;

    const filterCreatedBy = allFactoryCheck?.find(
      (item) => item.createdBy === profile_?.id
    );

    // สร้างเซตของ factoryId ที่ต้องการตัดออก
    const facIdsToRemove = new Set(
      allFactoryCheck.map((factory) => factory.id)
    );

    // กรองข้อมูลใน allFacMemByFacId โดยตรวจสอบว่า factoryId อยู่ในเซต factoryIdsToRemove หรือไม่
    const filteredUserData = allFactoryMember.filter((user) =>
      facIdsToRemove.has(user.factoryId)
    );

    // กรองข้อมูลใน filteredUserData โดยตรวจสอบว่า userId ตรงกับ profileData.id หรือไม่
    const findedUserData = filteredUserData.find(
      (user) => profile_?.id === user.userId
    );

    // เช็คว่ามีโรงไฟฟ้าที่ผู้ใช้นั้นๆ ว่ามีการสร้างแล้วหรือไม่ ถ้ามีให้โชว์ข้อความบอก
    if (
      filterCreatedBy?.createdBy === profile_?.id ||
      findedUserData?.userId === profile_?.id
    ) {
      return true;
    } else {
      return false;
    }
  }, [allFactoryCheck, profile_, allFactoryMember]);
  const checkUserCreatedEnt = useMemo(() => {
    if (allEnterprise.length === 0) return true;

    const filterCreatedBy = allEnterprise?.find(
      (item) => item.createdBy === profile_?.id
    );

    // สร้างเซตของ enterpriseId ที่ต้องการตัดออก
    const entIdsIoRemove = new Set(
      allEnterprise.map((enterprise) => enterprise.id)
    );

    // กรองข้อมูลใน allEntMemByEntId โดยตรวจสอบว่า enterpriseId อยู่ในเซต entIdsToRemove หรือไม่
    const filteredUserData = allEnterpriseMember.filter((user) =>
      entIdsIoRemove.has(user.enterpriseId)
    );

    // กรองข้อมูลใน filteredUserData โดยตรวจสอบว่า userId ตรงกับ profileData.id หรือไม่
    const findedUserData = filteredUserData.find(
      (user) => profile_?.id === user.userId
    );

    // เช็คว่ามีกลุ่มวิสาหกิจชุมชนที่ผู้ใช้นั้นๆ ว่ามีการสร้างแล้วหรือไม่ ถ้ามีให้โชว์ข้อความบอก
    if (
      filterCreatedBy?.createdBy === profile_?.id ||
      findedUserData?.userId === profile_?.id
    ) {
      return true;
    } else {
      return false;
    }
  }, [allEnterprise, profile_, allEnterpriseMember]);

  useEffect(() => {
    // Use functions
    if (!filterGroup.active) {
      getAllFactory();
    }
  }, [getAllFactory, filterGroup]);

  useEffect(() => {
    getMultipleApi();
  }, []);

  return (
    <div className={`${styles.container} background_1`}>
      <Header />
      <section className={`section ${styles.section_}`}>
        <Navbar />
        <article className={`article ${styles.article_} row mx-auto`}>
          <aside className={`${styles.menu_bar} col-3`}>
            <MenuBar
              _allProvince={allProvince}
              _allRegion={allRegion}
              _switch={_switch}
              _onSwitch={onSwitch}
              onClearLayers={onClearLayers}
              _onSearchLayer={onSearchLayer}
              _filterGroup={filterGroup}
              _setFilterGroup={setFilterGroup}
              _themeColor={changeThemeByRole}
            />
          </aside>
          <aside className={`${styles.map} col-5`}>
            <div className="map_element">
              <MapElement onInit={(map) => setMap(map)} />
            </div>
          </aside>
          <aside className={`${styles.content_info} col-4`}>
            <div className={`${styles.info_box}`}>
              {!_switch && (
                <div className={`${styles.box_1}`}>
                  <InfoList
                    _allFactory={allFactory}
                    _themeColor={changeThemeByRole}
                  />
                </div>
              )}
              <div className={`${styles.box_2}`}>
                {!_switch && (
                  <ChartsPower
                    _themeColor={changeThemeByRole}
                    datas={reportFacRegType}
                  />
                )}
                {_switch && (
                  <ChartsStatus
                    _themeColor={changeThemeByRole}
                    datas={reportFacRegStatus}
                  />
                )}
              </div>
              <div className={`${styles.box_3}`}>
                {profile_?.userGroupId === 1 && (
                  <button
                    type="button"
                    className="button_sm"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                    style={
                      checkUserCreatedFac
                        ? {
                            pointerEvents: "none",
                            backgroundColor: "#f5f5f5",
                            color: "#000000",
                          }
                        : { pointerEvents: "auto" }
                    }
                  >
                    เพิ่มโรงไฟฟ้า
                  </button>
                )}
                {profile_?.userGroupId === 2 && (
                  <button
                    type="button"
                    className="button_sm"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                    style={
                      checkUserCreatedEnt
                        ? {
                            pointerEvents: "none",
                            backgroundColor: "#f5f5f5",
                            color: "#000000",
                          }
                        : { pointerEvents: "auto" }
                    }
                  >
                    เพิ่มกลุ่มวิสาหกิจชุมชน
                  </button>
                )}
              </div>
            </div>
            <div className={`${styles.box_4}`}>
              <CollapseRight _themeColor={changeThemeByRole} />
            </div>
          </aside>
          <aside className={`${styles.collapse_table_box} col`}>
            <CollapseTable
              _themeColor={changeThemeByRole}
              _allFactory={allFactory}
            />
          </aside>
        </article>
        {/* AddPowerPlabts & Farmer */}
        {profile_?.userGroupId === 1 && (
          <AddPowerPlantsForm
            _fetchData={getAllFactory}
            _checkUserCreatedFac={checkUserCreatedFac}
          />
        )}
        {profile_?.userGroupId === 2 && (
          <AddFarmerForm
            _checkUserCreatedEnt={checkUserCreatedEnt}
            _themeColor={changeThemeByRole}
          />
        )}
        {/* End */}
      </section>
      <Footer _themeColor={changeThemeByRole} />
    </div>
  );
};

const formatFacRegTypeData = (datas) => {
  const regionMapping = {
    S: "south",
    NE: "northEast",
    C: "center",
    W: "west",
    E: "east",
    N: "north",
  };

  const factoryType = {
    center: [],
    east: [],
    north: [],
    northEast: [],
    south: [],
    west: [],
  };

  datas.forEach((fac) => {
    const region = regionMapping[fac.addressRegionCode];
    const facKey = `fac${fac.factoryTypeId}`;

    const existingRegion = factoryType[region].find((item) => item[facKey]);

    if (existingRegion) {
      existingRegion[facKey].push(fac);
    } else {
      const newEntry = {};
      newEntry[facKey] = [fac];
      factoryType[region].push(newEntry);
    }
  });

  // console.log(factoryType);

  const calculateTotals = (data) => {
    const totals = {
      center: [{}, {}],
      east: [{}, {}],
      north: [{}, {}],
      northEast: [{}, {}],
      south: [{}, {}],
      west: [{}, {}],
    };

    for (const region in data) {
      const fac1Count = data[region]
        .filter((group) => group.fac1)
        .flatMap((group) => group.fac1).length;
      const fac2Count = data[region]
        .filter((group) => group.fac2)
        .flatMap((group) => group.fac2).length;

      totals[region][0] = { value: fac1Count };
      totals[region][1] = { value: fac2Count };
    }

    return totals;
  };

  const factoryTotals = calculateTotals(factoryType);

  return factoryTotals;
};
const formatFacRegStatusData = (datas) => {
  const regionMapping = {
    S: "south",
    NE: "northEast",
    C: "center",
    W: "west",
    E: "east",
    N: "north",
  };

  const factoryType = {
    center: [],
    east: [],
    north: [],
    northEast: [],
    south: [],
    west: [],
  };

  datas.forEach((fac) => {
    const region = regionMapping[fac.addressRegionCode];
    const facKey = `fac${fac.factoryStatusId}`; // Changed to factoryStatusId for consistency

    const existingEntry = factoryType[region].find((entry) => entry[facKey]);

    if (existingEntry) {
      existingEntry[facKey].push(fac);
    } else {
      const newEntry = {};
      newEntry[facKey] = [fac];
      factoryType[region].push(newEntry);
    }
  });

  // console.log(factoryType);

  const calculateTotals = (data) => {
    const totals = {
      center: [{}, {}, {}, {}],
      east: [{}, {}, {}, {}],
      north: [{}, {}, {}, {}],
      northEast: [{}, {}, {}, {}],
      south: [{}, {}, {}, {}],
      west: [{}, {}, {}, {}],
    };

    for (const region in data) {
      const fac1Count = data[region]
        .filter((group) => group.fac1)
        .flatMap((group) => group.fac1).length;
      const fac2Count = data[region]
        .filter((group) => group.fac2)
        .flatMap((group) => group.fac2).length;
      const fac3Count = data[region]
        .filter((group) => group.fac3)
        .flatMap((group) => group.fac3).length;
      const fac4Count = data[region]
        .filter((group) => group.fac4)
        .flatMap((group) => group.fac4).length;

      totals[region][0] = { value: fac1Count };
      totals[region][1] = { value: fac2Count };
      totals[region][2] = { value: fac3Count };
      totals[region][3] = { value: fac4Count };
    }

    return totals;
  };

  const factoryTotals = calculateTotals(factoryType);

  return factoryTotals;
};

export default Home;
